import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { Box, Button, tooltipClasses, Tooltip, TextField } from "@mui/material";
import arrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../../../assets/CheckmarksIcons/approve_white.svg";
import filterIcon from "../../../../assets/FilterSearchIcons/filter.svg";
import calendarIcon_blue from "../../../../../src/assets/CalendarIcons/Calender_blue.svg";
import AdditionalDetailsIcon_blue from "../../../../../src/assets/GeneralIcons/additional_fields_blue.svg";
import selectWithWhiteFilledCircle from "../../../../../src/assets/CheckmarksIcons/select_all_with_white_filled_circle.svg";
import selectWithGreenFilledCircle from "../../../../../src/assets/CheckmarksIcons/selected_with_green_circle.svg";
import ArrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowDown5 from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import {
  downloadAllOwnerStatement,
  getAllOwnerAccStatement,
  ownerListViewAllAcc,
  resetStatementData,
  setOwnerAllAccStatementLoading,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import noaccount from "../../../../assets/Beneficiary Icons/noaccfound.png";
import DatePickerComponent from "../../../../components/_utils/Calendar";
import { useLocation, useNavigate } from "react-router-dom";
import SelectBank from "../../../../components/_utils/SelectBank";
import clearFilterImg from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import arrowforward from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import { useDispatch, useSelector } from "react-redux";
import TypeDropdown from "../../../../components/_utils/typeDropdown/typedropdown";
import download_icon from "../../../../assets/GeneralIcons/download_statement.svg";
import PaginationComponent from "../../../../components/_utils/PaginationComponent";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import OwnerAccountPopUp from "../../../../components/BeneTransaction/OwnerAccountPopUp";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";

const OwnerAllStatement = (props) => {
  const amtDivRef = useRef(null);
  const dateRef = useRef(null);
  const dropdownOptions = [50, 100];
  const [selectStatement, setSelectStatement] = useState(false);
  const [showOwnerPopup, setShowOwnerPopup] = useState(true);
  const [showAmtFilterDiv, setShowAmtFilterDiv] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAmtFilterApplied, setIsAmtFilterApplied] = useState(false);
  const [isDateConfirm, setIsDateConfirm] = useState(false);
  const { ownerAllAccStatementData, ownerAllAccStatementLoading } = useSelector(
    (state) => state.ownerAllVerification
  );
  const yearDropdownRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const calenderRef = useRef(null);
  const calenderButtonRef = useRef(null);
  const additionalDetailsRef = useRef(null);
  const additionalDetailsBtnRef = useRef(null);
  const filterButtonRef = useRef(null);
  const location = useLocation();
  const [bankOptions, setBankOptions] = useState([]);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterRef = useRef(null);
  const [showSelectBank, setShowSelectBank] = useState(false);
  const [selectedtBankState, setSelectedtBankState] = useState("");
  const [selectedtBank, setSelectedtBank] = useState("");
  const [typeOpen, setTypeOpen] = useState(false);
  const [statementType, setStatementType] = useState("");
  const [statementTypeState, setStatementTypeState] = useState("");
  const [amountLimitState, setAmountLimitState] = useState({
    min: "",
    max: "",
  });
  const [amountLimit, setAmountLimit] = useState({
    min: "",
    max: "",
  });

  const [accountnumberstate, setaccountnumberstate] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [accountDefaultNumber, setDefaultAccountNumber] = useState([]);
  const [selectedType, setSelectedType] = useState("PDF");
  const fileTypeRef = useRef(null);
  const [startDateState, setStartDateState] = useState(
    new Date(new Date().setDate(1))
  );
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(1)));
  const [endDateState, setEndDateState] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const order = [
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
    "closingbalance",
    "name",
    "debitamount",
    "creditamount",
  ];
  const [additionalDetailCol, setAdditionalDetailsCol] = useState([
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
  ]);
  const [defaultAdditionalDetailCol, setDefaultAdditionalDetailsCol] = useState(
    [
      "bank/bank name",
      "bankref",
      "accno",
      "narration",
      "transactiondateandtime",
      "type",
      "valuedate",
      "amount",
    ]
  );
  const [additionalDetailColState, setAdditionalDetailsColState] = useState([
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(checkUsertoken());
        let response = await dispatch(ownerListViewAllAcc({}));
        setBankOptions(response?.payload?.summary?.available_banks || []);
      } catch (error) {}
    };

    fetchData();

    return () => {
      dispatch(resetStatementData());
    };
  }, []);

  useEffect(() => {
    const handleFetch = () => {
      dispatch(checkUsertoken());
      dispatch(
        getAllOwnerAccStatement({
          id: accountNumber,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          page_no: currentPage,
          credit_debit: statementType,
          start_amount: isAmtFilterApplied ? amountLimit.min : "",
          end_amount: isAmtFilterApplied ? amountLimit.max : "",
          page_size: pageSize,
          bank_name: selectedtBank.length > 0 ? selectedtBank : undefined,
        })
      );
    };
    if (accountNumber.length > 0) {
      handleFetch();
    } else {
      dispatch(setOwnerAllAccStatementLoading(false));
    }
  }, [
    currentPage,
    statementType,
    pageSize,
    accountNumber,
    selectedtBank,
    amountLimit,
    startDate,
    endDate,
  ]);

  const downloadStatement = async () => {
    try {
      const response = await dispatch(
        downloadAllOwnerStatement({
          id: accountNumber,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          credit_debit: statementType,
          start_amount: isAmtFilterApplied ? amountLimit.min : "",
          end_amount: isAmtFilterApplied ? amountLimit.max : "",
          page_size: pageSize,
          formatType: selectedType.toLowerCase(),
          bank_name: selectedtBank.length > 0 ? selectedtBank : undefined,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDateState(start);
    setEndDateState(end);
  };

  const handleConfirm = () => {
    setIsDateConfirm(true);
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDateState(new Date(new Date().setDate(1)));
    setEndDateState(new Date());
  };

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        additionalDetailsRef.current &&
        additionalDetailsRef.current.contains(e.target)
      ) {
      } else if (
        additionalDetailsBtnRef.current &&
        additionalDetailsBtnRef.current.contains(e.target)
      ) {
      } else {
        setShowAdditionalDetails(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [additionalDetailsRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        filterButtonRef.current &&
        filterButtonRef.current.contains(e.target)
      ) {
      } else {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (calenderRef.current && calenderRef.current.contains(e.target)) {
      } else if (
        calenderButtonRef.current &&
        calenderButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [calenderRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dateRef.current && dateRef.current.contains(e.target)) {
      } else {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dateRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (fileTypeRef.current && fileTypeRef.current.contains(e.target)) {
      } else {
        setSelectStatement(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fileTypeRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (amtDivRef.current && amtDivRef.current.contains(e.target)) {
      } else {
        setShowAmtFilterDiv(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [amtDivRef]);

  const handleApplyEnable = () => {
    if (
      accountDefaultNumber.length === accountnumberstate.length &&
      accountDefaultNumber.every((ac) => {
        return accountnumberstate.includes(ac);
      }) &&
      selectedtBank.length === 0 &&
      amountLimit.min.length === 0 &&
      amountLimit.max.length === 0 &&
      statementType.length === 0 &&
      selectedtBankState.length === 0 &&
      !isAmtFilterApplied &&
      statementTypeState.length === 0
    ) {
      return false;
    }
    return true;
  };

  const handleAddDetailaApplyEnable = () => {
    return (
      JSON.stringify(defaultAdditionalDetailCol) !=
        JSON.stringify(additionalDetailColState) ||
      JSON.stringify(defaultAdditionalDetailCol) !=
        JSON.stringify(additionalDetailCol)
    );
  };

  const handleAddDetail = (name) => {
    if (!additionalDetailColState.includes(name)) {
      let arr = [...additionalDetailColState, name].sort(
        (a, b) => order.indexOf(a) - order.indexOf(b)
      );
      setAdditionalDetailsColState(arr);
    } else {
      if (additionalDetailColState.length == 1) {
        return toast.error("Atleat one column is required", toast_position);
      }

      setAdditionalDetailsColState(
        additionalDetailColState
          .filter((itm) => {
            return itm !== name;
          })
          .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      );
    }
  };

  const formatDateandTime = (date, showtime, value) => {
    let hours = date.getHours();
    let seconds = date.getSeconds();
    hours %= 12;
    hours = hours || 12;
    const minutes = date.getMinutes();
    if (showtime) {
      if (value) {
        return `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}, ${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }`;
      } else {
        return `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}, ${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`;
      }
    } else {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  const filterCategory = () => {
    setCurrentPage(1);
    setStartDate(startDateState);
    setEndDate(endDateState);
    setShowDatePicker(false);
    setShowCalendar(false);
  };

  const handleAmtFilterApply = () => {
    if (Object.values(amountLimitState).includes("")) return;
    setIsAmtFilterApplied(true);
    setShowAmtFilterDiv(false);
  };

  const handleClearAmts = () => {
    setAmountLimitState((prev) => ({
      ...prev,
      min: "",
      max: "",
    }));
    setIsAmtFilterApplied(false);
  };

  const handleAmtInput = (e) => {
    const { name, value } = e.target;
    const sanitizedText = value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    const amount = parseFloat(sanitizedText);
    if (sanitizedText.length !== "" && !isNaN(amount)) {
      if (value.length > 9) return;
      const regex = /^\d*\.?\d{0,2}$/;

      if (regex.test(value)) {
        setAmountLimitState((prevState) => ({
          ...prevState,
          [name]: sanitizedText,
        }));
      }
    } else {
      setIsAmtFilterApplied(false);
      setAmountLimitState((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleEnterKey = (e) => {
    if (
      amountLimitState.min !== "" &&
      amountLimitState.max !== "" &&
      Number(amountLimitState.max) >= Number(amountLimitState.min) &&
      e.key === "Enter"
    ) {
      handleAmtFilterApply();
    }
  };

  return (
    <>
      {showOwnerPopup && (
        <div
          style={{ zIndex: "2147483647" }}
          className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
        >
          <OwnerAccountPopUp
            setIsSidebarOpen={filterOpen}
            setOpenOwner={() => {
              if (accountNumber.length == 0) {
                navigate(-1);
              } else {
                setaccountnumberstate(accountNumber);
                setShowOwnerPopup(false);
              }
            }}
            onClosed={() => setShowOwnerPopup(false)}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
            accountnumberstate={accountnumberstate}
            setaccountnumberstate={setaccountnumberstate}
            multiSelect={true}
            setDefaultAccountNumber={setDefaultAccountNumber}
            flow={"txnViewAll"}
          />
        </div>
      )}
      {/* {
        <div
          style={{
            zIndex:
              showCalendar || filterOpen || showAdditionalDetails
                ? "0"
                : "9999",
            width: "100%",
            bottom: "0",
          }}
          className="fixed w-full bg-[#244277] h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={ownerAllAccStatementData || []}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      } */}
      <div
        className={`bg-custom-appBack flex flex-col overflow-y-hidden h-screen`}
        style={{ overflow: filterOpen && "hidden" }}
      >
        <div className={`OAS-owner_div_container`}>
          <div className={"OAS-owner_div"}>
            <div style={{ position: "relative", top: "20px" }}>
              <p className="text-[16px] text-[#F9F9F9]">
                Total Entries : {ownerAllAccStatementData?.total_count || 0}
              </p>
              <p className="text-[10px] text-[#DADADA]">
                As on {formatDateandTime(new Date())} - Statement update depends
                on availability from the bank
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "430px",
                position: "relative",
                top: "22px",
              }}
            >
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Open Calendar"
              >
                <div
                  ref={calenderButtonRef}
                  style={{
                    backgroundColor: "#f9f9f9",
                    opacity: showAdditionalDetails || filterOpen ? "0.6" : "1",
                    cursor:
                      showAdditionalDetails || filterOpen
                        ? "default"
                        : "pointer",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => {
                    if (!showAdditionalDetails && !filterOpen)
                      setShowCalendar(!showCalendar);
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={calendarIcon_blue} // Use your calendar icon here
                    alt="calendar-image"
                  />
                  <img
                    style={{
                      transform: showCalendar ? "rotate(180deg)" : "none",
                    }}
                    src={ArrowDown8} // Same arrow icon logic
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Additional Details"
              >
                <div
                  ref={additionalDetailsBtnRef}
                  style={{
                    backgroundColor: "#f9f9f9",
                    opacity: showCalendar || filterOpen ? "0.6" : "1",
                    cursor: showCalendar || filterOpen ? "default" : "pointer",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => {
                    if (!showCalendar && !filterOpen) {
                      setShowAdditionalDetails(!showAdditionalDetails); // Toggle visibility
                    }
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={AdditionalDetailsIcon_blue}
                    alt="additional-details"
                  />
                  <img
                    style={{
                      transform: showAdditionalDetails && "rotate(180deg)", // Rotate icon based on visibility
                    }}
                    src={ArrowDown8}
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <div
                ref={filterButtonRef}
                onClick={() => {
                  if (!showAdditionalDetails && !showCalendar) {
                    setFilterOpen(!filterOpen);
                  }
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        accountNumber.length > 0 ||
                        statementType.length > 0 ||
                        selectedtBank.length > 0 ||
                        (amountLimit.max.length > 0 &&
                          amountLimit.max.length > 0)
                          ? "#F9F9F9"
                          : "",
                      opacity:
                        showAdditionalDetails || showCalendar ? "0.6" : "1",
                      cursor:
                        showAdditionalDetails || showCalendar
                          ? "default"
                          : "pointer",
                    }}
                    onClick={() => {
                      if (!showAdditionalDetails && !showCalendar) {
                        setFilterOpen(!filterOpen);
                      }
                    }}
                    className="OAS-filter-containers"
                  >
                    <Box className="filters-box">
                      <img
                        src={
                          accountNumber.length > 0 ||
                          statementType.length > 0 ||
                          selectedtBank.length > 0 ||
                          (amountLimit.max.length > 0 &&
                            amountLimit.max.length > 0)
                            ? FilterBlue
                            : filterIcon
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? accountNumber.length > 0 ||
                              statementType.length > 0 ||
                              selectedtBank.length > 0 ||
                              (amountLimit.max.length > 0 &&
                                amountLimit.max.length > 0)
                              ? ArrowUp4
                              : ArrowUp
                            : accountNumber.length > 0 ||
                              statementType.length > 0 ||
                              selectedtBank.length > 0 ||
                              (amountLimit.max.length > 0 &&
                                amountLimit.max.length > 0)
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
              <div
                style={{
                  opacity:
                    showAdditionalDetails || filterOpen || showCalendar
                      ? "0.6"
                      : "1",
                  cursor:
                    showAdditionalDetails || filterOpen || showCalendar
                      ? "default"
                      : "pointer",
                }}
                className="flex w-[247px] justify-between"
              >
                <div className="flex justify-between h-[30px]">
                  <div className=" flex bg-[#F05A6A] rounded-[5px] h-[30px]">
                    <img
                      style={{
                        cursor:
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                            ? "default"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        if (
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                        ) {
                          e.stopPropagation();
                          return;
                        } else {
                          downloadStatement();
                        }
                      }}
                      className="w-[20px] h-[20px] m-auto ml-2"
                      src={download_icon}
                      alt="download statement"
                    />
                    <div
                      style={{
                        cursor:
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                            ? "default"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        if (
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                        ) {
                          e.stopPropagation();
                          return;
                        } else {
                          downloadStatement();
                        }
                      }}
                      className="flex justify-center items-center h-[30px] w-[136px] OAS-download_statement"
                    >
                      Download Statement
                    </div>
                    <div
                      style={{
                        borderRight: "1px solid #F9F9F9",
                        margin: "5px 0px",
                      }}
                    ></div>
                    <div ref={fileTypeRef} className="OAS-statement-type">
                      <Button
                        sx={{
                          cursor:
                            showAdditionalDetails || filterOpen || showCalendar
                              ? "default"
                              : "pointer",
                        }}
                        className="OAS-pdf-main-btn"
                        onClick={(e) => {
                          if (
                            showAdditionalDetails ||
                            filterOpen ||
                            showCalendar
                          ) {
                            e.stopPropagation();
                            return;
                          } else {
                            setSelectStatement(!selectStatement);
                          }
                        }}
                        style={{
                          height: selectStatement ? "180px" : "30px",
                          outline: selectStatement ? "1px solid #E25869" : "",
                        }}
                      >
                        <div
                          style={{
                            height: "30px",
                            width: "80px",
                          }}
                          className="OAS-bank_high_to_low_select_select"
                        >
                          <span
                            className="font-semibold pt-[0.1rem]"
                            style={{
                              fontSize: "12px",
                              paddingLeft: "10px",
                              color: "#f9f9f9",
                            }}
                          >
                            {selectedType}
                          </span>
                          <img
                            src={arrowDown}
                            alt="Status Icon"
                            style={{
                              height: "12px",
                              width: "12px",
                              transform: selectStatement
                                ? "rotate(180deg)"
                                : "",
                            }}
                            className="status_icon-img"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "60px",
                            top: "36px",
                            borderBottom: "1px solid #102C5D",
                          }}
                        ></div>
                        <div
                          style={{
                            padding: "10px 5px",
                            top: "26px",
                            width: "80px",
                          }}
                          className="OAS-bank_high_to_low_select_scroll"
                        >
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "PDF" && "#6D3034",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("PDF");
                              setSelectStatement(false);
                            }}
                          >
                            PDF
                            <div
                              style={{
                                width: "70px",
                                display:
                                  selectedType == "PDF" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                                paddingRight: "5px",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "XLS" && "#6D3034",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("XLS");

                              setSelectStatement(false);
                            }}
                          >
                            XLS
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "XLS" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>

                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "TXT" && "#6D3034",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("TXT");

                              setSelectStatement(false);
                            }}
                          >
                            TXT
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "TXT" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "CSV" && "#6D3034",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("CSV");

                              setSelectStatement(false);
                            }}
                          >
                            CSV
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "CSV" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`OAS-filter-transition-${filterOpen ? "open" : "close"}`}
          >
            {filterOpen && (
              <div
                style={{
                  maxWidth: "1024px",
                  margin: "0 auto",
                  borderTop: "1px solid #031f4f",
                  paddingTop: "10px",
                }}
                ref={filterRef}
              >
                <span className="text-[#F05A6A] font-normal ml-[10px]">
                  Filter{" "}
                </span>
                <div className="OAS-owner_filterdropdown">
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      width: "25%",
                      gap: "20px",
                      height: "180px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <p
                        style={{
                          color: "#dadada",
                          height: "22px",
                          fontSize: "12px",
                          marginTop: "1px",
                        }}
                      >
                        Account
                      </p>
                      <div
                        onClick={() => {
                          setShowOwnerPopup(true);
                        }}
                        className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] cursor-pointer"
                        style={{
                          height: "37px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "#dadada",
                            padding: "8px 15px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {accountnumberstate.length > 0
                              ? `${accountnumberstate.length} selected`
                              : "Select accounts"}
                          </span>
                          <img
                            src={ArrowDown5}
                            alt="down"
                            style={{
                              height: "12px",
                              transform: "rotate(270deg) translate(0px, 5px)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <TypeDropdown
                        statementTypeState={statementTypeState}
                        setStatementTypeState={setStatementTypeState}
                        typeOpen={typeOpen}
                        setTypeOpen={setTypeOpen}
                        owner={true}
                      />
                    </div>
                    {/* <SelectBank
                      option={bankOptions || []}
                      selectedtBankState={selectedtBankState}
                      setSelectedtBankState={setSelectedtBankState}
                      selectBank={showSelectBank}
                      setSelectBank={setShowSelectBank}
                      statement={true}
                    /> */}
                  </div>
                  <div className="OAS-owner-border-div-line"></div>
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      width: "25%",
                      gap: "20px",
                      height: "180px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          color: "#dadada",
                          height: "22px",
                          fontSize: "12px",
                          marginTop: "1px",
                        }}
                      >
                        Amount
                      </p>
                      <div ref={amtDivRef} className="OAS-amtDiv_type">
                        <div
                          className="OAS-amount-main-btn"
                          onClick={() => {
                            setShowAmtFilterDiv(!showAmtFilterDiv);
                          }}
                          style={{
                            height: showAmtFilterDiv ? "250px" : "37px",
                            cursor: "default",
                          }}
                        >
                          <div
                            style={{
                              height: "37px",
                              backgroundColor: "#667ca3",
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              cursor: "pointer",
                              zIndex: "9999",
                            }}
                            className="OAS-bank_high_to_low_select_select"
                          >
                            <span className="text-[#dadada] text-[12px] pl-[14px]">
                              {isAmtFilterApplied && !showAmtFilterDiv
                                ? `₹ ${amountLimitState.min} - ₹ ${amountLimitState.max}`
                                : "Set range"}
                            </span>

                            <img
                              src={ArrowDown5}
                              alt="Status Icon"
                              style={{
                                height: "12px",
                                width: "12px",

                                transform: showAmtFilterDiv
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                              className="status_icon-img"
                            />
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              width: "176px",
                              margin: "0 20px",
                              top: "37px",
                              borderBottom: "1px solid #102C5D",
                            }}
                          ></div>
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="OAS-amount-inputs-div"
                          >
                            <div className="OAS-input-container">
                              <label for="min">Minimum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimitState.min === ""
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="min"
                                  onChange={handleAmtInput}
                                  type="text"
                                  value={amountLimitState.min}
                                  name="min"
                                  placeholder="Enter amount"
                                  onKeyDown={handleEnterKey}
                                />
                              </div>
                            </div>

                            <div className="OAS-input-container">
                              <label for="max">Maximum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5.5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimitState.max === ""
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="max"
                                  onChange={handleAmtInput}
                                  type="text"
                                  value={amountLimitState.max}
                                  name="max"
                                  placeholder="Enter amount"
                                  onKeyDown={handleEnterKey}
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                margin: "10px 0",
                              }}
                            >
                              * Specify the amount to filter by.
                            </p>
                            <div className="OAS-inputs-div-btns">
                              <button
                                className={`OAS-clear-button ${
                                  amountLimitState.min === "" ||
                                  amountLimitState.max === ""
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={handleClearAmts}
                              >
                                Clear
                              </button>
                              <button
                                onClick={handleAmtFilterApply}
                                className={`OAS-apply-button ${
                                  amountLimitState.min === "" ||
                                  amountLimitState.max === "" ||
                                  Number(amountLimitState.min) >=
                                    Number(amountLimitState.max)
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <TypeDropdown
                        statementTypeState={statementTypeState}
                        setStatementTypeState={setStatementTypeState}
                        typeOpen={typeOpen}
                        setTypeOpen={setTypeOpen}
                        owner={true}
                      />
                    </div> */}
                  </div>
                </div>
                <div
                  className="pb-[20px]"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%%",
                  }}
                >
                  <div className="OAS-buttoncontainer">
                    <div
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                        opacity: handleApplyEnable() ? "1" : "0.6",
                      }}
                      onClick={() => {
                        if (handleApplyEnable()) {
                          setAccountNumber(accountDefaultNumber);
                          setaccountnumberstate(accountDefaultNumber);
                          setSelectedtBank("");
                          setSelectedtBankState("");
                          setAmountLimit({ min: "", max: "" });
                          setAmountLimitState({ min: "", max: "" });
                          setStatementType("");
                          setStatementTypeState("");
                          setIsAmtFilterApplied(false);
                          setCurrentPage(1);
                        }
                      }}
                      className={`OAS-acc-viewall-clear-filter border border-[#F9F9F9] text-[#f9f9f9] text-xs text-center w-[87px] h-[25px] ${
                        handleApplyEnable() ? "hover:bg-[#506994]" : ""
                      }`}
                    >
                      Default
                    </div>
                    <div
                      onClick={() => {
                        if (handleApplyEnable()) {
                          setAccountNumber(accountnumberstate);
                          setSelectedtBank(selectedtBankState);
                          if (isAmtFilterApplied) {
                            setAmountLimit(amountLimitState);
                          } else if (
                            amountLimitState.min.length == 0 ||
                            amountLimitState.max.length == 0
                          ) {
                            setAmountLimit(amountLimitState);
                          }
                          setStatementType(statementTypeState);
                          setCurrentPage(1);
                          setFilterOpen(false);
                        }
                      }}
                      className={`apply ${
                        handleApplyEnable() ? "hover:opacity-90" : ""
                      }`}
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                        backgroundColor: handleApplyEnable()
                          ? "#FB5963"
                          : "#707070",
                      }}
                    >
                      <p
                        style={{
                          color: handleApplyEnable() ? "#f9f9f9" : "#dadada",
                        }}
                        className="OAS-buttoncontainer-text"
                      >
                        Apply
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            ref={calenderRef}
            className={`OAS-beneTxnviewallsidebar ${
              showCalendar ? "open" : "closed"
            }`}
            style={{
              marginTop: "170px",
              backgroundColor: "#3a5686",
            }}
          >
            <div
              className="OAS-beneTxnviewallFilterDiv flex justify-between"
              style={{
                visibility: showCalendar ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showCalendar ? "0s" : "0.2s"
                }`,
                opacity: showCalendar ? "1" : "0",
                borderTop: "1px solid rgb(29, 58, 109)",
              }}
            >
              <div className="OAS-beneTxnFilterSection1">
                <span className="text-[#F05A6A] font-normal">Calender </span>
                <div className="OAS-beneTxnFilterSection3 mt-[15px]">
                  <span
                    style={{
                      color: "#F9F9F9",
                      width: "62px",
                      height: "17px",
                      font: "normal normal 12px/19px Open Sans",
                      marginBottom: "10px",
                    }}
                  >
                    Date range
                  </span>
                  <div
                    className="OAS-bene_Txn_Calendar_main_div relative flex flex-col items-center bg-[#667CA3] cursor-pointer"
                    ref={dateRef}
                    style={{
                      width: "298px",
                      height: showDatePicker ? "" : "39px",
                      border: showDatePicker ? "1px solid #ff5860" : "none",
                    }}
                  >
                    <div
                      className="flex items-center justify-around rounded-[5px] bg-[#667CA3] cursor-pointer"
                      style={{
                        width: "290px",
                        height: showDatePicker ? "" : "39px",
                      }}
                      onClick={() => {
                        setShowDatePicker(!showDatePicker);
                      }}
                    >
                      <div className="OAS-beneTxn_date_heading_div cursor-pointer">
                        <div className="OAS-beneTxn_date_heading_left">
                          {startDateState
                            ? formatDate(startDateState)
                            : "Select from date"}
                        </div>
                      </div>
                      <img
                        src={arrowforward}
                        className="OAS-bene_txn_viewall_calendar_icon"
                      />
                      <div className="OAS-beneTxn_date_select_div cursor-pointer">
                        <div className="OAS-beneTxn_date_heading_right">
                          {" "}
                          {endDateState
                            ? formatDate(endDateState)
                            : "Select to date"}
                        </div>
                      </div>
                    </div>
                    {showDatePicker && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <DatePickerComponent
                          startDate={startDateState}
                          endDate={endDateState}
                          handleDateChange={handleDateChange}
                          handleCancel={handleCancel}
                          handleConfirm={handleConfirm}
                          showDatePicker={showDatePicker}
                          yearDropdownRef={yearDropdownRef}
                          dateRef={dateRef}
                          setShowDatePicker={setShowDatePicker}
                          isOpen={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="OAS-beneTxnFilterbuttonContainer">
                <div
                  className="OAS-acc-viewall-clear-filter border border-[#F9F9F9] text-[#f9f9f9] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      +startDate !== new Date(new Date().setDate(1)) &&
                      +endDate !== new Date()
                    ) {
                      setStartDate(new Date(new Date().setDate(1)));
                      setStartDateState(new Date(new Date().setDate(1)));
                      setEndDate(new Date());
                      setEndDateState(new Date());
                      setIsDateConfirm(false);
                    }
                  }}
                  style={{
                    cursor:
                      +startDate !== new Date(new Date().setDate(1)) &&
                      +endDate !== new Date() &&
                      isDateConfirm
                        ? "pointer"
                        : "default",
                    opacity:
                      +startDate !== new Date(new Date().setDate(1)) &&
                      +endDate !== new Date() &&
                      isDateConfirm
                        ? "1"
                        : "0.6",
                  }}
                >
                  Default
                </div>
                <div
                  className="OAS-acc-viewall-apply-filter text-center text-[#f9f9f9] text-xs ml-[10px] w-[87px] h-[25px]"
                  onClick={() => {
                    if (isDateConfirm) {
                      filterCategory();
                    }
                  }}
                  style={{
                    backgroundColor: isDateConfirm ? "#F05A6A" : "#727272",
                    cursor: isDateConfirm ? "pointer" : "default",
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>

          <div
            ref={additionalDetailsRef}
            className={`OAS-beneTxnviewallsidebar ${
              showAdditionalDetails ? "open" : "closed"
            }`}
            style={{
              marginTop: "170px",
              backgroundColor: "#3a5686",
            }}
          >
            <div
              className="max-w-[1024px] mx-auto justify-between pt-[10px]"
              style={{
                visibility: showAdditionalDetails ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showAdditionalDetails ? "0s" : "0.2s"
                }`,
                opacity: showAdditionalDetails ? "1" : "0",
                borderTop: "1px solid rgb(29, 58, 109)",
              }}
            >
              <div className="h-auto">
                <span className="text-[#F05A6A] font-normal">
                  Additional Fields{" "}
                </span>
                <div className="flex justify-between text-[12px] text-[#DADADA]">
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("bank/bank name")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Bank/ Bank name</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("bank/bank name")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("bankref")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Bank refference no.</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("bankref")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("debitamount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Debit amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("debitamount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("name")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Name</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("name")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("accno")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Account number</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("accno")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("narration")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Narration</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("narration")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("creditamount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Credit amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("creditamount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("transactiondateandtime")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Transaction date and time</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes(
                              "transactiondateandtime"
                            )
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("type")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Type</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("type")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("closingbalance")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Closing balance</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("closingbalance")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("valuedate")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Value date</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("valuedate")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("amount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("amount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("ifcscode")}
                      className="w-[216px] h-[36px] cursor-pointer bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>IFSC code</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("ifcscode")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-[20px] pb-[20px] justify-end">
                <div
                  className={`OAS-acc-viewall-clear-filter border border-[#F9F9F9] text-[#F9F9F9] text-xs text-center w-[87px] h-[25px] ${
                    handleAddDetailaApplyEnable() ? "hover:bg-[#506994]" : ""
                  }`}
                  onClick={() => {
                    if (handleAddDetailaApplyEnable()) {
                      setAdditionalDetailsColState([
                        "bank/bank name",
                        "bankref",
                        "accno",
                        "narration",
                        "transactiondateandtime",
                        "type",
                        "valuedate",
                        "amount",
                      ]);
                      setAdditionalDetailsCol([
                        "bank/bank name",
                        "bankref",
                        "accno",
                        "narration",
                        "transactiondateandtime",
                        "type",
                        "valuedate",
                        "amount",
                      ]);
                    }
                  }}
                  style={{
                    cursor: handleAddDetailaApplyEnable()
                      ? "pointer"
                      : "default",
                    opacity: handleAddDetailaApplyEnable() ? "1" : "0.6",
                  }}
                >
                  Default
                </div>
                <div
                  className={`OAS-acc-viewall-apply-filter text-center text-[#F9F9F9] text-xs ml-[10px] w-[87px] h-[25px] ${
                    handleAddDetailaApplyEnable() ? "hover:opacity-90" : ""
                  }`}
                  onClick={() => {
                    if (handleAddDetailaApplyEnable()) {
                      setAdditionalDetailsCol(additionalDetailColState);
                      setShowAdditionalDetails(false);
                    }
                  }}
                  style={{
                    backgroundColor: handleAddDetailaApplyEnable()
                      ? "#F05A6A"
                      : "#727272",
                    cursor: handleAddDetailaApplyEnable()
                      ? "pointer"
                      : "default",
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        </div>
        {startDate && endDate && (
          <div className="OAS-filter_applied_div w-full">
            <div
              style={{
                borderTop: "1px solid #031F4F",
              }}
              className="w-[1024px] mx-auto py-[10px] h-[60px] flex justify-between items-center"
            >
              <div className="flex">
                <p className="filterby_text">Filtered by :</p>
                <div className="filter_badge_div">
                  {startDate && endDate && (
                    <div className="OAS-filter_badge">
                      <p
                        style={{ maxWidth: "160px" }}
                        className="filter_badge_text"
                      >
                        {`${formatDate(startDate)} - ${formatDate(endDate)}`}
                      </p>
                    </div>
                  )}
                  {accountNumber.length > 0 && (
                    <div className="OAS-filter_badge">
                      <p
                        style={{ maxWidth: "130px" }}
                        className="filter_badge_text"
                      >
                        {`${accountNumber.length} ${
                          accountNumber.length > 1
                            ? "accounts selected"
                            : "account selected"
                        }`}
                      </p>
                    </div>
                  )}
                  {selectedtBank.length > 0 && (
                    <div className="OAS-filter_badge">
                      <p className="filter_badge_text">{selectedtBank}</p>
                      <img
                        onClick={() => {
                          setSelectedtBank("");
                          setSelectedtBankState("");
                        }}
                        className="filter_badge_img"
                        src={clearFilterImg}
                        alt="X"
                      />
                    </div>
                  )}
                  {statementType.length > 0 && (
                    <div className="OAS-filter_badge">
                      <p className="filter_badge_text">{statementType}</p>
                      <img
                        onClick={() => {
                          setStatementType("");
                          setStatementTypeState("");
                        }}
                        className="filter_badge_img"
                        src={clearFilterImg}
                        alt="X"
                      />
                    </div>
                  )}
                  {amountLimit.min.length > 0 && amountLimit.max.length > 0 && (
                    <div className="OAS-filter_badge">
                      <p className="filter_badge_text">
                        {`₹ ${amountLimit.min} - ₹ ${amountLimit.max}`}
                      </p>
                      <img
                        onClick={() => {
                          setAmountLimit({ min: "", max: "" });
                          setAmountLimitState({ min: "", max: "" });
                          setIsAmtFilterApplied(false);
                        }}
                        className="filter_badge_img"
                        src={clearFilterImg}
                        alt="X"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            position:
              showCalendar || filterOpen || showAdditionalDetails
                ? "absolute"
                : "relative",
            opacity:
              showCalendar || filterOpen || showAdditionalDetails ? "0.8" : "0",
            display:
              showCalendar || filterOpen || showAdditionalDetails
                ? "block"
                : "hidden",
            backgroundColor:
              showCalendar || showAdditionalDetails || filterOpen ? "#000" : "",
            height:
              showCalendar || filterOpen || showAdditionalDetails
                ? "100%"
                : "0",
            width:
              showCalendar || filterOpen || showAdditionalDetails
                ? "100%"
                : "0",
            zIndex: "99",
          }}
        ></div>
        <div
          style={{
            overflowY:
              ownerAllAccStatementLoading ||
              ownerAllAccStatementData?.owner_account_details?.statement
                ?.length == 0
                ? "hidden"
                : "auto",
            marginBottom: "5px",
            // marginBottom: "55px",
            height: "100%",
          }}
          className="overflow-x-auto w-screen"
        >
          <div className="sticky top-0">
            <div className="w-max min-w-full z-10 mt-[23px]">
              <div className="OAS-table-div-container mb-1">
                {additionalDetailCol.includes("bank/bank name") && (
                  <>
                    <p className="OAS-label-div-trans OAS-label-bank-width w-[130px]">
                      Bank
                    </p>
                    <div
                      style={{
                        display: "block",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("bank/bank name") && (
                  <>
                    <p className="OAS-label-div-trans OAS-label-bank-width w-[130px]">
                      Bank name
                    </p>
                    <div
                      style={{
                        display:
                          additionalDetailCol.length > 1 ? "block" : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("ifcscode") && (
                  <>
                    <p className="OAS-label-div-ref">IFSC code</p>
                    <div
                      style={{
                        display: [
                          "transactiondateandtime",
                          "valuedate",
                          "accno",
                          "bankref",
                          "narration",
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("accno") && (
                  <>
                    <p className="OAS-label-div-trans OAS-label-account-width">
                      Account Number
                    </p>
                    <div
                      style={{
                        display: [
                          "transactiondateandtime",
                          "valuedate",
                          "bankref",
                          "narration",
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("transactiondateandtime") && (
                  <>
                    <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                      Transaction date <br />
                      and time
                    </p>
                    <div
                      style={{
                        display: [
                          "valuedate",
                          "bankref",
                          "narration",
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("valuedate") && (
                  <>
                    <p className="OAS-label-div-val">Value date</p>
                    <div
                      style={{
                        display: [
                          "bankref",
                          "narration",
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("bankref") && (
                  <>
                    <p className="OAS-label-div-ref">Bank reference no.</p>
                    <div
                      style={{
                        display: [
                          "narration",
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("narration") && (
                  <>
                    <p className="OAS-label-div-narr">Narration</p>
                    <div
                      style={{
                        display: [
                          "type",
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("type") && (
                  <>
                    <div className="OAS-label-div-type">Type</div>
                    <div
                      style={{
                        display: [
                          "amount",
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("amount") && (
                  <>
                    <div className="OAS-label-div-amt">Amount</div>
                    <div
                      style={{
                        display: [
                          "closingbalance",
                          "name",
                          "debitamount",
                          "creditamount",
                        ].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("creditamount") && (
                  <>
                    <div className="OAS-label-div-amt">Credit balance</div>
                    <div
                      style={{
                        display: ["closingbalance", "name", "debitamount"].some(
                          (col) => {
                            return additionalDetailCol.includes(col);
                          }
                        )
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("debitamount") && (
                  <>
                    <div className="OAS-label-div-amt">Debit balance</div>
                    <div
                      style={{
                        display: ["closingbalance", "name"].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}
                {additionalDetailCol.includes("closingbalance") && (
                  <>
                    <p className="OAS-label-div-clamt">Closing Balance</p>
                    <div
                      style={{
                        display: ["name"].some((col) => {
                          return additionalDetailCol.includes(col);
                        })
                          ? "block"
                          : "none",
                      }}
                      className="OAS-border-mid-div"
                    ></div>
                  </>
                )}

                {additionalDetailCol.includes("name") && (
                  <>
                    <p className="OAS-label-div-narr">Name</p>
                  </>
                )}
              </div>
            </div>
          </div>

          {ownerAllAccStatementLoading ? (
            <div className="h-full m-auto justify-center items-center flex">
              <img
                className="max-w-[50px] max-h-[50px] w-[50px] h-[50px]"
                src={dataLoading}
                alt=""
              />
            </div>
          ) : ownerAllAccStatementData?.statement?.length > 0 ? (
            <div className="overflow-auto w-max min-w-full">
              {ownerAllAccStatementData?.statement?.map((statement) => {
                return (
                  <div className="OAS-table-div-content mb-1">
                    {additionalDetailCol.includes("bank/bank name") && (
                      <>
                        <div className="OAS-label-div-trans OAS-label-bank-width">
                          <div className="h-[26px] w-[70px] rounded-[56px] bg-[#f9f9f9] flex justify-center items-center">
                            <img
                              className="w-[50px] h-[13px]"
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(statement.ifsc_code))}
                              alt="ICICI-Bank"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            border: "none",
                            display: "block",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("bank/bank name") && (
                      <>
                        <p
                          style={{ minWidth: "130px" }}
                          className="OAS-label-div-val"
                        >
                          {statement.bank_name}
                        </p>
                        <div
                          style={{
                            display:
                              additionalDetailCol.length > 1 ? "block" : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("ifcscode") && (
                      <>
                        <p className="OAS-label-div-ref">
                          {statement.ifsc_code}
                        </p>
                        <div
                          style={{
                            display: [
                              "transactiondateandtime",
                              "valuedate",
                              "accno",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("accno") && (
                      <>
                        <p
                          onClick={() => {
                            navigate(
                              `/accounts/owner/owner_account_details/${statement.owner_account_id}`
                            );
                          }}
                          className="OAS-label-div-trans OAS-label-account-width ownerredirect"
                        >
                          {formatAccountNumber(
                            statement.owner_bank_account_number
                          )}
                        </p>
                        <div
                          style={{
                            display: [
                              "transactiondateandtime",
                              "valuedate",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("transactiondateandtime") && (
                      <>
                        <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                          {formatDateandTime(
                            new Date(statement.transaction_date),
                            true
                          )}
                        </p>
                        <div
                          style={{
                            display: [
                              "valuedate",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("valuedate") && (
                      <>
                        <p className="OAS-label-div-val">
                          {formatDateandTime(new Date(statement.value_date))}
                        </p>
                        <div
                          style={{
                            display: [
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}

                    {additionalDetailCol.includes("bankref") && (
                      <>
                        <p className="OAS-label-div-ref">
                          {statement.bank_reference}
                        </p>
                        <div
                          style={{
                            display: [
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("narration") && (
                      <>
                        <p className="OAS-label-div-narr">
                          {statement.narration}
                        </p>
                        <div
                          style={{
                            display: [
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("type") && (
                      <>
                        <div className="OAS-label-div-type">
                          {statement.credit_debit}
                        </div>
                        <div
                          style={{
                            display: [
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("amount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{ color: "#4ddd37" }}
                        >
                          ₹ <FormatAmount price={Number(statement.amount)} />
                        </div>
                        <div
                          style={{
                            display: [
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("creditamount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{ color: "#4ddd37" }}
                        >
                          {statement.credit_debit == "Credit" ? (
                            <>
                              ₹{" "}
                              <FormatAmount price={Number(statement.amount)} />
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div
                          style={{
                            display: [
                              "closingbalance",
                              "name",
                              "debitamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("debitamount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{ color: "#FF5860" }}
                        >
                          {statement.credit_debit == "Debit" ? (
                            <>
                              ₹{" "}
                              <FormatAmount price={Number(statement.amount)} />
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div
                          style={{
                            display: ["closingbalance", "name"].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("closingbalance") && (
                      <>
                        <p className="OAS-label-div-clamt">
                          ₹{" "}
                          <FormatAmount
                            price={Number(statement.closing_balance)}
                          />
                        </p>
                        <div
                          style={{
                            display: ["name"].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}

                    {additionalDetailCol.includes("name") && (
                      <p className="OAS-label-div-narr">{statement.name}</p>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col h-full items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="statement_noacc_found"
              />
              <p className="no-beneaccount-text2">No data found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnerAllStatement;
