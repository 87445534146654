import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import "./index.css";
import {
  setShowReloginModalFalse,
  setMultipleEntity,
} from "../../../redux/features/login/login";

const LogoutModel = ({ onClose, openLogoutModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (openLogoutModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openLogoutModal]);

  return (
    <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop bg-opacity-80 overflow-hidden z-[9999]">
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className={`h-auto flex flex-col px-5 bg-[#1D3A6D] rounded-[20px] ${
            isMobile ? "w-[330px] mx-auto" : "w-[560px]"
          }`}
        >
          <div className="flex flex-col gap-y-2.5 pt-5">
            <p className="text-xl text-[#FF7F33] font-sans font-semibold leading-[27.24px]">
              Logout
            </p>
            <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px]">
              Are you sure you want to logout ?
            </p>
          </div>
          <div className="flex flex-row-reverse items-center mt-10 mb-5">
            <div
              className="flex justify-center items-center w-20 h-8 bg-[#69B76F] rounded-[75px] cursor-pointer py-2 px-4"
              onClick={() => {
                dispatch(setMultipleEntity());
                dispatch(setShowReloginModalFalse());
                dispatch(logout());
                navigate("/login");
              }}
            >
              <p className="text-sm text-[#F9F9F9] font-sans font-semibold leading-[16.34px]">
                Confirm
              </p>
            </div>
            <div
              className="flex justify-center items-center w-[70px] h-8"
              onClick={() => {
                onClose();
              }}
            >
              <p className="text-sm text-[#F9F9F9] font-sans font-normal text-center leading-[16.34px] underline decoration-solid	cursor-pointer">
                Cancel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModel;
