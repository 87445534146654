import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../../redux/features/login/login";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import {
  internalTxnMultiRejectApproval,
  internalTxnReject,
  resetITMultiApprovalError,
  resetITMultiRejectApprovalLoading,
} from "../../../../redux/features/internalTransaction/internalTransactionSingleApproval";
import Xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";

const InternalDeclinePopup = ({
  onClose,
  accountId,
  allAccounts,
  updateAllAccounts,
  status,
  sideDrawer,
  setIsSidebarOpen,
  preventMultiEnter,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [declineReason, setDeclineReason] = useState("");
  const services = useSelector((state) => state.refreshToken.services);
  const [showLoader, setShowLoader] = useState(false);
  const [showTransitionClass, setShowTransitionClass] = useState("");
  const [cancleButtonHide, SetCancleButtonHide] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [confirmButtonStyle, setConfirmButtonStyle] = useState({
    width: "100px",
    height: "42px",
    background: "transparent",
    boxShadow: "0px 3px 6px #00000029",
    border: "2px solid #CCCCCC",
    borderRadius: "20px",
    opacity: "0.55",
    cursor: "initial",
    color: "#000000",
  });
  const internalTxnRejectLoading = useSelector(
    (state) => state.InternalTxnSingleApproval
  );
  const internalTxnMultiRejectApprovalLoading = useSelector(
    (state) => state.InternalTxnSingleApproval
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setDeclineReason(value);

    const isEmpty = value.trim() === "";

    const inputBorderColor =
      !isEmpty && value.replace(/\s+/g, "").length >= 10
        ? "1px solid #4DDD37"
        : "1px solid rgb(0, 255, 245)";

    if (!isEmpty && value.replace(/\s+/g, "").length >= 10) {
      setConfirmButtonStyle({
        width: "100px",
        height: "42px",
        background: "#3CB371",
        boxShadow: "0px 3px 6px #00000029",
        border: "none",
        borderRadius: "20px",
        opacity: "1",
        cursor: "pointer",
        color: "#FFFFFF",
      });
    } else {
      setConfirmButtonStyle({
        width: "100px",
        height: "42px",
        background: "transparent",
        boxShadow: "0px 3px 6px #00000029",
        border: "2px solid #CCCCCC",
        borderRadius: "20px",
        opacity: "0.55",
        cursor: "initial",
        color: "#000000",
      });
    }
  };
  const handleBlur = () => {
    if (declineReason?.length === 0) {
      setIsFocus(false);
    }
  };
  useEffect(() => {
    if (!internalTxnRejectLoading && internalTxnRejectLoading !== null) {
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    }
  }, [internalTxnRejectLoading]);

  // const handleTxnMultiRejecting = () => {
  //   if (
  //     internalTxnMultiRejectApprovalLoading ||
  //     internalTxnMultiRejectApprovalLoading == null
  //   ) {
  //     RefreshToast();
  //   }
  //   setTimeout(() => {
  //     toast.dismiss();
  //   }, 2000);
  // };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const internalTxnDecline = async (id, AccountIdData) => {
    if (!sessionStorage.getItem("q2p_token")) {
      dispatch(checkUsertoken());
      setIsSidebarOpen(false);
      return;
    }

    const rejectToastId = RefreshToast("internalTxn");
    setShowLoader(true);
    setInputDisabled(true);
    SetCancleButtonHide(false);

    try {
      if (sideDrawer === "multiApproval") {
        const data = await dispatch(
          internalTxnMultiRejectApproval({ id, AccountIdData })
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (data?.payload?.status === 500) {
          toast.dismiss(rejectToastId);
          toast.error(data?.payload?.error, toast_position);
        } else if (data?.payload?.status === false) {
          toast.dismiss(rejectToastId);
          Navigate("/internal_transaction/multi_approval_transaction/error");
        } else {
          toast.dismiss(rejectToastId);
          Navigate("/internal_transaction/multi_approval_transaction/declined");
        }
      } else {
        const data = await dispatch(internalTxnReject({ id, AccountIdData }));
        await new Promise((resolve) => setTimeout(resolve, 2000));

        if (data?.payload?.status === 500) {
          toast.dismiss(rejectToastId);
          toast.error(data?.payload?.error, toast_position);
        } else if (data?.payload?.status === false) {
          toast.dismiss(rejectToastId);
          const error_msg =
            data?.payload?.errors?.base?.[0]?.errors?.[0] || "Unknown error";
          toast.error(error_msg, toast_position);
        } else {
          toast.dismiss(rejectToastId);
          toast.success("Transaction rejected successfully", toast_position);
          const updatedData = allAccounts.map((item) => {
            if (item?.txn_id === id) {
              return {
                ...item,
                lifecycle_status: "rejected",
                rejected: true,
                rejection_reason: AccountIdData,
              };
            }
            return item;
          });
          updateAllAccounts(updatedData);
        }
      }
    } catch (error) {
      toast.dismiss(rejectToastId);
      toast.error("Something went wrong. Please try again.", toast_position);
    } finally {
      setShowLoader(false);
      setInputDisabled(false);
      setDeclineReason("");
      onClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !inputDisabled) {
        if (declineReason.replace(/\s+/g, "").length >= 10) {
          if (status === "internalTxn" || status === "multiTransaction") {
            internalTxnDecline(accountId, declineReason);
          }
        }
      }

      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    inputDisabled,
    declineReason,
    accountId,
    status,
    preventMultiEnter,
    showLoader,
  ]);

  return (
    <>
      <div className="mainPopup">
        {(status === "multiTransaction" || status === "internalTxn") && (
          <div className="internalDeclinedPopup">
            <div className="msgReason">
              <h2>Rejected reason</h2>
              <p className={`${isMobile ? "break-words" : ""}`}>
                Please state the reason for rejecting the transaction
              </p>
            </div>
            <div
              className={`customInputfield ${isMobile ? "" : ""} ${
                declineReason.length >= 9 ? "valid" : ""
              }`}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "95%",
                  maxWidth: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>
            <div className={`${isMobile ? "px-[30px]" : "pl-7"}`}>
              <p className="w-[90%] h-[19px] font-sans text-[#DADADA] text-[14px] leading-[19px] flex-none order-1 break-words whitespace-normal">
                Note - The reason must consist of at least 10 characters.
              </p>
            </div>
            <div className="buttonContainer">
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={Xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div className="flex items-center gap-[20px]">
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    className={`${
                      declineReason.replace(/\s+/g, "").length < 10
                        ? "confirmBtn"
                        : "confirm-btn-enable"
                    }`}
                    onClick={() => {
                      if (
                        services?.find(
                          (service) =>
                            service === "ABeneficiaryTxn" || service === "Admin"
                        ) !== undefined
                      ) {
                        if (
                          status === "internalTxn" ||
                          status === "multiTransaction"
                        ) {
                          internalTxnDecline(accountId, declineReason);
                        }
                      } else {
                        Navigate("/feature_not_assigned", {
                          state: {
                            unavailableService: "ABeneficiaryTxn",
                          },
                        });
                      }
                    }}
                    disabled={
                      inputDisabled ||
                      declineReason.replace(/\s+/g, "").length < 10
                    }
                    style={{
                      cursor:
                        inputDisabled ||
                        declineReason.replace(/\s+/g, "").length < 10
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InternalDeclinePopup;
