import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { beneCategories } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import Validation from "../../../utils/validation";
import BeneIcon from "../../../assets/CategoryIcons/beneficiary_category_default.svg";
import skippedVerification from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import WhiteMail from "../../../assets/MailIcons/gmail_with_white_circle.svg";
import Mail from "../../../assets/MailIcons/email_green_verified.svg";
import GreenPhone from "../../../assets/ContactIcons/contact_green_verified.svg";
import whitePhone from "../../../assets/ContactIcons/contact_with_white_circle.svg";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import userIcon from "../../../assets/UserProfileIcons/user.svg";
import selected from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import not_selected from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import not_selectall from "../../../assets/CheckmarksIcons/select_all_with_white_outlined_circle.svg";
import UserIcon from "../../../assets/UserProfileIcons/user.svg";
import AlertImg from "../../../assets/AlertIcons/alert2.svg";

const BeneContactDetails = (props) => {
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [focusState, setFocusState] = useState({});
  const [selectedFields, setSelectedFields] = useState([]);
  const [accountNameValidation, setAccountNameValidation] = useState(true);
  const dispatch = useDispatch();
  const categories = props.categories;
  const contactFieldRef = useRef(null);
  const emailFieldRef = useRef(null);
  const accountNameFieldRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState("legalname");

  const handleRadioBtn = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "legalname") {
      handleUpdateLegalName();
    }
  };

  const {
    verifyPAN,
    beneLookData,
    beneLookLoading,
    verifyPANisLoading,
    beneLookError,
  } = useSelector((state) => state.verify);

  const allCategoryIds = useMemo(() => {
    return categories.map((category) => category.id);
  }, []);
  const isAllSelected = useMemo(() => {
    return selectedFields.length === allCategoryIds.length;
  }, [selectedFields, allCategoryIds]);

  const handleUpdateLegalName = () => {
    const beneficiaryData = verifyPAN?.pan_details?.full_name;
    props?.setPayload((prevPayload) => ({
      ...prevPayload,
      account_name: beneficiaryData,
    }));
    setAccountNameValidation(true);
  };

  const handleSelectAllChange = () => {
    const allCategoryIds = categories.map((category) => category.id);
    setSelectedFields((prevSelectedFields) => {
      const isAllSelected = prevSelectedFields.length === categories.length;
      setSelectAllChecked(!isAllSelected);
      return isAllSelected ? [] : allCategoryIds;
    });
  };

  const toggleSelection = (category) => {
    const categoryId = category.id;
    setSelectedFields((prevSelectedFields) => {
      let updatedSelectedFields;

      if (categoryId === "Select All") {
        const isAllSelected = prevSelectedFields.length === categories.length;
        setSelectAllChecked(!isAllSelected);
        updatedSelectedFields = isAllSelected
          ? []
          : categories.map((cat) => cat.id);
      } else {
        if (prevSelectedFields.includes(categoryId)) {
          updatedSelectedFields = prevSelectedFields.filter(
            (id) => id !== categoryId
          );
        } else {
          updatedSelectedFields = [...prevSelectedFields, categoryId];
        }
        setSelectAllChecked(updatedSelectedFields.length === categories.length);
      }

      return updatedSelectedFields;
    });
  };

  const handleCategorySelect = (selectedCategories) => {
    let selectedCategoryNames = [];

    if (selectedCategories.length > 0) {
      selectedCategoryNames = selectedCategories
        .map((categoryId) => {
          const category = categories.find(
            (category) => category.id === categoryId
          );
          return category ? category.name : null;
        })
        .filter((name) => name !== null);
    } else {
      selectedCategoryNames = categories.map((category) => category.name);
    }

    props.onSelectFieldsChange(selectedCategoryNames);
  };

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const modifyValue = (type, value) => {
    if (type === "gstin") {
      return value?.toUpperCase();
    }
    return value.trim();
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], toast_position);
    });
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);

    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name != "account_name") {
      value = modifyValue(name, value?.replace(/\s/g, ""));
    } else {
      value = value;
    }

    if (props.payload) {
      if (allowedValue(name, value)) {
        validateAndRecordError(name, value);
        const newObj = { ...props.payload, [name]: value };
        props?.setPayload(newObj);
      }
      if (
        props?.payload?.contact_no &&
        (props?.payload?.contact_no?.length === 0 ||
          props?.payload?.contact_no?.length < 10)
      ) {
        setContactNumberValidation(false);
      }
      if (props?.payload?.email_id && props?.payload?.email_id?.length === 0) {
        setEmailValidation(false);
      }
      if (name === "account_name") {
        if (value.length > 0) {
          if (
            value.toLowerCase() ==
            verifyPAN?.pan_details?.full_name.toLowerCase()
          ) {
            setSelectedValue("legalname");
          } else {
            setSelectedValue("customname");
          }
          setAccountNameValidation(true);
        } else {
          setAccountNameValidation(false);
        }
      }
    }
  };

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    const getBorderColor = () => {
      return value?.length > 0 ? (status ? "#4DDD37" : "#FF5860") : "#F9F9F9";
    };

    const getActiveBorderColor = () => {
      return focusState[name] && value?.length === 0
        ? "#31E5E5"
        : getBorderColor();
    };

    const getLabelColor = () => {
      if (status) return "#4DDD37";
      if (focusState[name] && value?.length === 0) return "#31E5E5";
      return value?.length > 0 && !status ? "#FF5860" : "#F9F9F9";
    };

    const borderColor = getBorderColor();
    const activeBorderColor = getActiveBorderColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
    };
    const isRequired =
      name === "contact_no" || name === "email_id" || name === "account_name";
    const labelWithAsterisk = isRequired ? `${label} *` : label;

    const handleKeyDown = (e, nextRef) => {
      if (e.key === "Tab") {
        e.preventDefault();
        nextRef.current.focus();
      }
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          inputRef={
            name === "email_id"
              ? emailFieldRef
              : name === "contact_no"
              ? contactFieldRef
              : name === "account_name"
              ? accountNameFieldRef
              : null
          }
          onKeyDown={(e) =>
            handleKeyDown(
              e,
              name === "email_id"
                ? contactFieldRef
                : name === "contact_no"
                ? emailFieldRef
                : name === "account_name"
                ? accountNameFieldRef
                : null
            )
          }
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: activeBorderColor,
            },
            "& label": {
              color: getLabelColor(),
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: borderColor,
              },
              "&.Mui-focused fieldset": {
                borderColor: activeBorderColor,
              },
            },
          }}
          inputProps={{
            style: {
              color: "#F9F9F9",
            },
            maxLength: { maxLength },
          }}
          InputLabelProps={{ style: { color: getLabelColor() } }}
          InputProps={{
            sx: {
              borderRadius: "10px",
            },
            startAdornment: name === "contact_no" && (
              <InputAdornment position="start">
                <div className="text-[#F9F9F9]">+91</div>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-4 h-4">
                  {name === "contact_no" && (
                    <>
                      {value?.length === 0 && (
                        <img
                          src={whitePhone}
                          alt="default-icon"
                          onClick={() => {
                            showErrors(name);
                          }}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {value?.length > 0 && !status && (
                        <img
                          src={skippedVerification}
                          alt="Skipped Verification Icon"
                          onClick={() => {
                            showErrors(name);
                          }}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {status && (
                        <img
                          src={GreenPhone}
                          alt="Green Check Icon"
                          className="w-4 h-4"
                        />
                      )}
                    </>
                  )}
                  {name === "email_id" && (
                    <>
                      {value?.length === 0 && (
                        <img
                          src={WhiteMail}
                          alt="White Email Icon"
                          className="w-4 h-4"
                        />
                      )}
                      {value?.length > 0 && !status && (
                        <img
                          src={skippedVerification}
                          alt="Skipped Verification Icon"
                          onClick={() => {
                            showErrors(name);
                          }}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {status && (
                        <img
                          src={Mail}
                          alt="Green Check Icon"
                          className="w-4 h-4"
                        />
                      )}
                    </>
                  )}
                  {name === "account_name" && (
                    <>
                      <img src={UserIcon} alt="user Icon" className="w-4 h-4" />
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
            maxLength: maxLength,
            required: isRequired,
          }}
          label={labelWithAsterisk}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={(e) => {
            if (!(name === "account_name" && selectedValue === "legalname")) {
              handleChange(e);
            }
          }}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  useMemo(() => {
    if (customErrors["contact_no"]) {
      setContactNumberValidation(false);
    }
    if (
      !customErrors["contact_no"] &&
      props.payload.contact_no?.length === 10
    ) {
      setContactNumberValidation(true);
    }
    if (customErrors["email_id"]) {
      setEmailValidation(false);
    }
    if (
      !customErrors["email_id"] &&
      props.payload.email_id?.length > 0 &&
      emailRegex.test(props.payload.email_id)
    ) {
      setEmailValidation(true);
    }
  }, [contactNumberValidation, emailValidation, customErrors, props.payload]);
  useEffect(() => {
    if (emailValidation && contactNumberValidation && accountNameValidation) {
      props.BenEnableNextButton(false);
      props.parentCreateBen({
        email: emailValidation,
        contact: contactNumberValidation,
        accountname: accountNameValidation,
      });
    } else if (props?.activeStep == 2) {
      props.parentCreateBen({
        email: emailValidation,
        contact: contactNumberValidation,
        accountname: accountNameValidation,
      });
      props.BenEnableNextButton(true);
    }
    handleCategorySelect(selectedFields);
  }, [
    selectedFields,
    categories,
    isAllSelected,
    emailValidation,
    contactNumberValidation,
    accountNameValidation,
    props?.activeStep,
  ]);
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, []);

  const handleDivBtn = (value) => {
    setSelectedValue(value);
    if (value === "legalname") {
      handleUpdateLegalName();
    }
  };

  return (
    <div className="beneContact_Main">
      {props?.showAlertCategories && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center bg-[#000000] justify-center z-[9998] bg-opacity-80">
          <div className="w-[560px] h-auto rounded-[20px] bg-[#1D3A6D] p-[20px] flex flex-col gap-[16px] mx-[10px]">
            <div className="flex flex-row gap-[15px]">
              <img
                src={AlertImg}
                alt="alert-icon"
                className="w-[30px] h-[30px]"
              />
              <span className="w-[215px] h-[27px] text-[#E74C3C] text-[20px] leading-[27.24px] font-semibold">
                It is unsafe to proceed
              </span>
            </div>
            <div className="flex flex-warp">
              <span className="text-[#F9F9F9] text-[16px] leading-[21.79px] font-normal">
                Assigning all categories to a single account is highly unsafe.
                It is recommended to limit the number of categories assigned to
                the account.
              </span>
            </div>
            <div className="flex flex-row align-center justify-end gap-[30px]">
              <button
                onClick={() => {
                  props?.setShowAlertCategories(false);
                }}
              >
                <span className="w-[38px] h-[16px] text-[#F9F9F9] text-[12px] leading-[16.34px] font-normal">
                  <u>Cancel</u>
                </span>
              </button>
              <button
                className="w-[79px] h-[32px] rounded-[75px] py-[8px] px-[16px] bg-[#69B76F] flex align-center"
                onClick={() => {
                  props?.handleNext();
                }}
              >
                <span className="w-[48px] h-[16px] text-[#F9F9F9] text-[12px] leading-[16.34px] font-semibold">
                  Proceed
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dividecontact-crt-flow">
        <div className="w-1/2 h-fit contactright">
          <div className="flex flex-col relative rounded-[31px] mb-[20px]">
            <div className="py-5 h-full bg-[#314C7F] flex flex-col rounded-[20px]">
              <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 pb-1">
                <div className="text-[#DADADA] font-semibold text-base leading-[21.79px] pb-[28px]">
                  Beneficiary's Name
                </div>
                <div>
                  {getInputElementDiv(
                    "account_name",
                    accountNameValidation,
                    "Account name",
                    props.payload.account_name,
                    12
                  )}
                  <div className="flex flex-row align-center mt-[15px] h-[60px] gap-[20px]">
                    <div
                      className={`flex flex-row align-center justify-center w-[206px] h-[60px] rounded-[10px] bg-[${
                        selectedValue === "legalname" ? "#F9F9F9" : "#244277"
                      }] mobile-account-name-div cursor-${
                        selectedValue === "legalname" ? "default" : "pointer"
                      }`}
                      onClick={() => {
                        handleDivBtn("legalname");
                      }}
                    >
                      <div className="mt-[10px] align-center justify-center">
                        <Radio
                          checked={selectedValue === "legalname"}
                          onChange={handleRadioBtn}
                          value="legalname"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          sx={{
                            color:
                              selectedValue === "legalname"
                                ? "#244277"
                                : "#F9F9F9",
                            "&.Mui-checked": {
                              color:
                                selectedValue === "legalname"
                                  ? "#244277"
                                  : "#F9F9F9",
                            },
                          }}
                        />
                      </div>
                      <div
                        className={`flex flex-col align-center justify-center gap-[5px]`}
                      >
                        <Tooltip
                          title={props?.payload?.legalName?.toLowerCase()}
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <span
                            className={`w-[140px] h-[19px] text-[14px] leading-[19.07px] text-[${
                              selectedValue === "legalname"
                                ? "#1D1D1D"
                                : "#F9F9F9"
                            }] font-semibold truncate mobile-account-name-span`}
                          >
                            {props?.payload?.legalName}
                          </span>
                        </Tooltip>
                        <span
                          className={`w-[131px] h-[16px] text-[12px] leading-[16.34px] text-[${
                            selectedValue === "legalname"
                              ? "#707070"
                              : "#F9F9F9"
                          }] font-normal truncate mobile-account-name-span`}
                        >
                          Autofill PAN legal name
                        </span>
                      </div>
                    </div>
                    <div
                      className={`w-[206px] h-[60px] rounded-[10px] bg-[${
                        selectedValue === "customname" ? "#F9F9F9" : "#244277"
                      }] pt-[10px] mobile-account-name-div cursor-${
                        selectedValue === "customname" ? "default" : "pointer"
                      }`}
                      onClick={() => {
                        handleDivBtn("customname");
                      }}
                    >
                      <Radio
                        checked={selectedValue === "customname"}
                        onChange={handleRadioBtn}
                        value="customname"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                        sx={{
                          color:
                            selectedValue === "customname"
                              ? "#244277"
                              : "#F9F9F9",
                          "&.Mui-checked": {
                            color:
                              selectedValue === "customname"
                                ? "#244277"
                                : "#F9F9F9",
                          },
                        }}
                      />
                      <span
                        className={`w-[161px] h-[19px] text-[14px] leading-[19.07px] text-[${
                          selectedValue === "customname" ? "#1D1D1D" : "#F9F9F9"
                        }] font-semibold truncate mobile-account-name-span`}
                      >
                        Custom name
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col relative rounded-[31px]">
            <div className="py-5 h-full bg-[#314C7F] flex flex-col rounded-[20px]">
              <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 pb-1">
                <div className="text-[#DADADA] font-semibold text-base leading-[21.79px] pb-[28px]">
                  {props?.title}
                </div>
                <div className="pb-[28px]">
                  {getInputElementDiv(
                    "contact_no",
                    contactNumberValidation,
                    "Contact number",
                    props.payload.contact_no,
                    12
                  )}
                </div>
                <div>
                  {getInputElementDiv(
                    "email_id",
                    emailValidation,
                    "Email ID",
                    props.payload.email_id?.replace(/\s/g, ""),
                    20
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dividecontact-crt-flow">
        <div className="w-1/2 contactright">
          <div className="flex flex-col">
            <div className="bg-[#314C7F] flex flex-row justify-between py-4 pb-0 px-8 rounded-t-2xl 4xl:rounded-t-large items-center">
              <p className="text-base leading-[21.79px] font-semibold text-[#DADADA]">
                Assign Beneficiary Categories
              </p>
              <div className="rounded-bl-[20px] rounded-tr-[18px] bg-[#3A5686] flex items-center justify-around mt-[-16px] mr-[-32px] w-36 h-12 dividecontact-crt-select-all">
                <FormControlLabel
                  className="text-white"
                  control={
                    <Checkbox
                      icon={
                        <img
                          className="w-4 h-4"
                          src={not_selectall}
                          alt="not-selected"
                        />
                      }
                      checkedIcon={
                        <img
                          className="w-4 h-4"
                          src={selected}
                          alt="selected"
                        />
                      }
                      className="radioBT"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label={
                    <div className="font-normal text-base leading-[21.79px] text-[#F9F9F9] select-all-font-size">
                      Select All
                    </div>
                  }
                  sx={{
                    textAlign: "center",
                    marginLeft: "8px",
                    letterSpacing: "0px",
                    color: "#dddddd",
                    ".MuiFormControlLabel-label": {
                      font: "normal normal 600 16px Open Sans",
                    },
                  }}
                  labelPlacement="end"
                />
              </div>
            </div>
            <p className="py-[10px] pb-0 px-8 bg-[#314C7F] text-[#F9F9F9] font-normal text-xs leading-[16.34px]">
              Selecting "Select all" or proceeding without selection implies
              that these categories, and any future ones added, will be assigned
              to this user.
            </p>
          </div>
          <div className="rounded-b-4xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
            <div
              className="bg-[#314C7F] py-5 h-full flex flex-col rounded-b-2xl contact-crt-mobile-view"
              style={{ height: "318px" }}
            >
              <div className="w-full flex flex-col px-8 gap-4 overflow-auto h-72 custom-scroll-container">
                <div className="text-justify flex flex-col gap-6">
                  {Array.isArray(categories) &&
                    categories.map((category, index) =>
                      category?.active === "Y" ? (
                        <Tooltip
                          key={index}
                          title={category.name}
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <Button
                            key={category.id}
                            size="small"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: selectedFields.includes(
                                category.id
                              )
                                ? "#4A6D4B"
                                : "#506994",
                              borderRadius: "10px",
                              color: "#dadada",
                              height: "50px",
                              padding: "0 14px",
                              fontSize: "16px",
                              transition: "background-color 0.3s",
                            }}
                            onClick={() => toggleSelection(category)}
                            onMouseEnter={(e) => {
                              if (!selectedFields.includes(category.id)) {
                                e.currentTarget.style.backgroundColor =
                                  "#667CA3";
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (!selectedFields.includes(category.id)) {
                                e.currentTarget.style.backgroundColor =
                                  "#506994";
                              }
                            }}
                          >
                            <p className="w-[15%] bg-white cateEditParent">
                              <img
                                className="w-9 h-9 cateEditImage"
                                src={
                                  category?.name == "Vendor30" ||
                                  category?.name == "Vendor40"
                                    ? BeneIcon
                                    : category.logo_url
                                    ? category.logo_url
                                    : BeneIcon
                                }
                                alt="bene"
                              />
                            </p>
                            <span
                              className="pr-5 pl-4 categoryName"
                              style={{ textTransform: "capitalize" }}
                            >
                              {category.name.toLowerCase()}
                            </span>

                            <div style={{ marginLeft: "auto" }}>
                              <Checkbox
                                icon={
                                  <img
                                    className="w-4 h-4"
                                    src={not_selected}
                                    alt=""
                                  />
                                }
                                checkedIcon={
                                  <img
                                    className="w-4 h-4"
                                    src={selected}
                                    alt=""
                                  />
                                }
                                className="radioBT cursor-pointer"
                                value={category.id}
                                name="categoryCheckbox"
                                inputProps={{ "aria-label": "A" }}
                                checked={selectedFields.includes(category.id)}
                                onChange={() => toggleSelection(category)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </Button>
                        </Tooltip>
                      ) : null
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneContactDetails;
