import React, { useState, useEffect } from "react";
import "./index.css";
import successImage from "../../../../assets/Graphics/Success page graphic.svg";
import createTransaction from "../../../../assets/GeneralIcons/initiate_internal_transaction.svg";
import viewDetails from "../../../../assets/ViewIcons/view_details.svg";
import dashboard from "../../../../assets/GeneralIcons/dashboard.svg";
import ContactNumber from "../../../../assets/ContactIcons/contact_number.svg";
import Email from "../../../../assets/MailIcons/Mail.svg";
import { beneSuccessDetail } from "../../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip, tooltipClasses } from "@mui/material";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";

const BeneEditSucess = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, successType } = useParams();
  const successDetail = useSelector(
    (state) => state.beneCreateAccount.beneSuccessData
  );
  const isSuccessErrorData = useSelector(
    (state) => state.beneCreateAccount.isSuccessErrorData
  );

  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    id: "",
    name: "",
    bank_name: "",
    bank_acc_no: "",
    ifsc_code: "",
    category: "",
    pan: "",
    bank_account_holder_full_name: "",
  });

  useEffect(() => {
    if (isSuccessErrorData == "Request failed with status code 404") {
      navigate("*");
    }
  }, [isSuccessErrorData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneSuccessDetail(id));
  }, [dispatch, id]);

  const viewDetailsHandler = () => {
    if (props?.internalAccount === "internalAccount") {
      navigate(`/accounts/internal_account/details/${id}`);
    } else {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    }
  };
  const dashboardHandler = () => {
    if (props?.internalAccount === "internalAccount") {
      navigate(`/accounts/internal_account/dashboard`);
    } else {
      navigate("/accounts/beneficiary/dashboard");
    }
  };

  const createTxn = () => {
    if (props?.internalAccount === "internalAccount") {
      navigate("/transaction/internal_transaction/create", {
        state: currentAccountDetails,
      });
    } else {
      navigate("/transaction/beneficiary_transaction/create", {
        state: currentAccountDetails,
      });
    }
  };

  const beneficiaryAccount = successDetail?.beneficiary_accounts[0] || {};

  useEffect(() => {
    if (beneficiaryAccount) {
      const { id, name, bank_name, bank_acc_no, ifsc_code, category, pan_no } =
        beneficiaryAccount;
      setCurrentAccountDetails({
        id,
        name,
        bank_name,
        bank_acc_no,
        ifsc_code,
        category,
        pan: pan_no,
        bank_account_holder_full_name: name,
      });
    }
  }, [beneficiaryAccount]);

  const getMessage = () => {
    if (successType === "contact_edit_success") {
      return "Contact details updated successfully!";
    }
    if (successType === "category_edit_success") {
      return (
        <div>
          Beneficiary categories <br /> updated successfully!
        </div>
      );
    }
    return "";
  };

  return (
    <div className="min-h-screen bg-[#457549]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 mt-[66px] pt-[112px]">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
              <div className="p-2 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">{getMessage()}</p>
                </div>
              </div>
            </div>
          </div>
          <>
            <div className="flex-1 mt-[66px] py-[35px]">
              <div
                className={`mx-5 font-semibold text-[#F9F9F9] flex flex-row justify-between px-5 rounded-t-[20px] items-center bg-[#55985C]`}
              >
                <p className="text-center m-auto pb-[15px] font-open-sans mt-4 text-[16px]">
                  Account Details
                </p>
              </div>
              <div className="flex flex-col mx-5 bg-white rounded-b-[20px] items-center justify-between">
                <div className="max-w-[471px] max-h-[390px] overflow-y-auto pb-2.5">
                  <div className="flex flex-col font-bold px-10">
                    <div className="text-base text-[#000000] w-full text-center py-[13px]">
                      <div
                        className="w-96"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: " nowrap",
                          textTransform: "capitalize",
                        }}
                      >
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                              textTransform: "capitalize",
                            },
                          }}
                          title={beneficiaryAccount?.name}
                          placement="bottom-start"
                        >
                          {beneficiaryAccount?.name
                            ? beneficiaryAccount?.name?.toLowerCase()
                            : ""}
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="w-full content-center">
                    <div className="mx-0 dashedLine"></div>
                  </div>
                  <div className="w-full px-5">
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                          title={beneficiaryAccount.category}
                          placement="bottom-start"
                        >
                          <div className="font-normal text-base capitalize">
                            <div
                              className="w-96"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textAlign: "start",
                              }}
                            >
                              {typeof beneficiaryAccount.category === "string"
                                ? beneficiaryAccount.category
                                    .split(",")
                                    .map((category) => category.trim())
                                    .join(" , ")
                                : beneficiaryAccount.category}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        Assigned categories
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        {beneficiaryAccount?.pan_no}
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        PAN number
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        {formatAccountNumber(beneficiaryAccount?.bank_acc_no)}
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        Account number
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <div className="text-base text-black antialiased">
                          {beneficiaryAccount?.ifsc_code}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          IFSC code
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                        <img
                          src={require("../../../../assets/IfscLogo/" +
                            IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                          alt="bank"
                        />
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <div className="text-base text-black antialiased">
                          +91&nbsp;{beneficiaryAccount?.contact_no}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          Contact number
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                        <img src={ContactNumber} alt="Contact Number" />
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-5/6">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={beneficiaryAccount?.email_id}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <div
                            className="text-base font-medium text-black antialiased"
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {beneficiaryAccount?.email_id}
                          </div>
                        </Tooltip>

                        <div className="text-gray-500 text-xs antialiased">
                          Email ID
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-1/6 h-5 mt-3">
                        <img src={Email} alt="Email" />
                      </div>
                    </div>
                    {beneficiaryAccount?.gstin_detail && (
                      <>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount.gstin_detail}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              GST
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{ height: "135px" }}
                  className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                >
                  <div
                    className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                    onClick={createTxn}
                  >
                    <div className="w-3/6 flex justify-center">
                      <img
                        width={"40px"}
                        height={"40px"}
                        src={createTransaction}
                        alt=""
                        srcSet=""
                      />
                    </div>
                    <p className="text-xs">
                      Create
                      <br />
                      transaction
                    </p>
                  </div>
                  <div
                    className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                    onClick={viewDetailsHandler}
                  >
                    <div className="w-3/6 flex justify-center">
                      <img
                        width={"40px"}
                        height={"40px"}
                        src={viewDetails}
                        alt=""
                        srcSet=""
                      />
                    </div>
                    <p className="text-xs">
                      View
                      <br />
                      details
                    </p>
                  </div>
                  <div
                    className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                    onClick={dashboardHandler}
                  >
                    <div className="w-3/6 flex justify-center">
                      <img
                        width={"40px"}
                        height={"40px"}
                        src={dashboard}
                        alt=""
                        srcSet=""
                      />
                    </div>
                    <p className="text-xs">
                      Go to <br /> dashboard
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default BeneEditSucess;
