import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../../components/LandingPage/Footer";
import FinancialManagement from "../../../../assets/landingPage/FinancialManagement.svg";
import Consolidation from "../../../../assets/landingPage/Consolidation.svg";
import BirthOfXpentra from "../../../../assets/landingPage/BirthOfXpentra.svg";
import FinancialOperations from "../../../../assets/landingPage/FinancialOperations.svg";
import VisionBegins from "../../../../assets/landingPage/VisionBegins.svg";
import ExpandFunctionality from "../../../../assets/landingPage/ExpandingFunctionality.svg";
import EnhanceConnectivity from "../../../../assets/landingPage/EnhancingConnectivity.svg";
import ReliableVerifications from "../../../../assets/landingPage/ReliableVerifications.svg";
import NACHCollections from "../../../../assets/landingPage/NACHCollections.svg";
import SecureManagement from "../../../../assets/landingPage/SecureManagement.svg";
import OptimisingBusiness from "../../../../assets/landingPage/OptimisingBusiness.svg";
import BharatConnect from "../../../../assets/landingPage/BharatConnect.svg";
import SpecialisedSolutions from "../../../../assets/landingPage/SpecialisedSolutions.svg";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TeamPicture from "../../../../assets/landingPage/TeamPicture.jpg";
import designElement1 from "../../../../assets/landingPage/designElement1.svg";
import designElement2 from "../../../../assets/landingPage/designElement2.svg";
import designElement3 from "../../../../assets/landingPage/designElement3.svg";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const containerRef = useRef(null);
  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  const handleCardScroll = () => {
    if (containerRef.current) {
      const cards = containerRef.current.children;
      const containerScrollLeft = containerRef.current.scrollLeft;
      let closestIndex = 0;
      let closestDistance = Infinity;

      for (let i = 0; i < cards.length; i++) {
        const card = cards[i];
        const cardOffsetLeft = card.offsetLeft - containerScrollLeft;
        const distance = Math.abs(cardOffsetLeft);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = i;
        }
      }
      setCurrentIndex(closestIndex);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;
    const element = containerRef.current;
    element.addEventListener("scroll", handleCardScroll);
    return () => {
      element.removeEventListener("scroll", handleCardScroll);
    };
  }, [containerRef.current]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div
            style={{
              overflowY: mobilestatus && "hidden",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
            className="bg-[#031f4f]"
          >
            <div className="text-[#f9f9f9] bg-[#102C5D] p-[30px] mt-[60px]">
              <div
                className="bg-[#102C5D] rounded-[15px] mx-auto flex flex-col w-full gap-[20px]"
                style={{ maxWidth: "1024px" }}
              >
                <div className="flex flex-col gap-5">
                  <div className="text-lg font-semibold">About Xpentra</div>
                  <div className="bg-grey">
                    <img
                      className="rounded-[10px]"
                      src={TeamPicture}
                      alt="TeamPicture"
                    />
                  </div>
                  <div>
                    <div className="text-[12px] mb-2.5">
                      Xpentra is an innovative fintech venture founded by the
                      Rangnekar family, who bring a legacy of industry success,
                      drawing on their significant contributions to
                      Quest2Travel-a corporate travel management company
                      acquired by the NASDAQ-listed MakeMyTrip in 2018.
                    </div>
                    <div className="text-[12px] mb-2.5">
                      With over 2 decades of experience and taking Quest2travel
                      to greater heights, the founders have now ventured into
                      the Fintech Space with an understanding of the
                      complexities of the industry.
                    </div>
                    <div className="text-[12px] mb-[10px]">
                      Founded in 2018, Xpentra is built to deliver a
                      comprehensive banking solution for enterprises, offering
                      superior transaction and account visibility and
                      streamlining complex financial processes like payments,
                      collections, reporting, and reconciliation.
                    </div>
                    <div className="text-[12px]">
                      Xpentra prioritizes usability and protection, featuring
                      automated transactions, With Xpentra, businesses can
                      reduce operational errors, providing CFOs and CXOs with a
                      powerful enabler that eliminates time consuming
                      administrative tasks and allowing leaders to focus on
                      strategic initiatives and high-impact decision making.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-[#f9f9f9] py-[20px] px-[30px] bg-[#031F4F]">
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[16px] font-semibold text-[#f9f9f9] pb-[20px]">
                  Our <span className="text-[#31E5E5]">journey</span>
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l"
                    style={{ height: "75%", top: "37px", left: "25px" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[20px]">
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={FinancialManagement}
                          alt="FinancialManagement"
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          Identifying gaps in corporate financial management
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • At Quest2Pay, we noticed that corporate cards only
                          worked for vendors with POS terminals or payment
                          gateways.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • We introduced virtual cards to enable payments for
                          vendors without card acceptance infrastructure.
                        </div>
                        <div className="text-[12px]">
                          • Despite this, businesses with multiple bank accounts
                          struggled with fragmented systems and lacked
                          visibility over their finances.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={Consolidation}
                          alt="Consolidation"
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          The need for consolidation
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Businesses required a unified platform to manage
                          multiple bank accounts and financial activities.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • They needed real-time visibility, control, and
                          optimisation across all accounts.
                        </div>
                        <div className="text-[12px]">
                          • This realisation laid the foundation for Xpentra.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={BirthOfXpentra}
                          alt="BirthOfXpentra"
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          The birth of Xpentra
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Xpentra was developed to provide a single portal for
                          managing bank accounts and streamlining financial
                          operations.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • It offers a bird’s-eye view of financial activities,
                          enabling smarter and faster decisions.
                        </div>
                        <div className="text-[12px]">
                          • Our mission: To empower businesses with better
                          control, real-time insights, and solutions for modern
                          financial challenges.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={FinancialOperations}
                          alt="FinancialOperations"
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          Xpentra today: Empowering financial operations
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Xpentra offers real-time transaction visibility,
                          multi-bank integration, and advanced analytics.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • The platform is designed for scalability,
                          flexibility, and security, catering to modern business
                          needs.
                        </div>
                        <div className="text-[12px]">
                          • It helps businesses optimise resources, reduce
                          inefficiencies, and drive growth.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-[#f9f9f9] py-[20px] px-[30px]"
              style={{
                background:
                  "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[16px] font-semibold text-[#f9f9f9] pb-[20px]">
                  <span className="text-[#31E5E5]">Our evolution:</span> Key
                  feature additions over the years
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l h-[84%] xs:h-[84%] top-[25px] md:top-[30px]"
                    style={{ left: "25px" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[20px]">
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={VisionBegins}
                          alt="VisionBegins"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2016</span> – The
                          vision begins
                        </div>
                        <div className="text-[12px]">
                          • Xpentra was conceptualised to address the growing
                          need for an all-in-one financial management platform.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={ExpandFunctionality}
                          alt="ExpandFunctionality"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2017</span> –
                          Expanding functionality
                        </div>
                        <div className="text-[12px]">
                          • Added refunds and reimbursements features for
                          smoother financial operations.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={EnhanceConnectivity}
                          alt="EnhanceConnectivity"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2018</span> –
                          Enhancing connectivity
                        </div>
                        <div className="text-[12px] mb-1">
                          • Integrated API connectivity for seamless data
                          exchange with third-party platforms.
                        </div>
                        <div className="text-[12px]">
                          • Introduced virtual accounts for more efficient
                          transaction management.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={ReliableVerifications}
                          alt="ReliableVerifications"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2019</span> –
                          Reliable verifications
                        </div>
                        <div className="text-[12px]">
                          • Introduced real-time verifications to ensure secure
                          and accurate transactions.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={NACHCollections}
                          alt="NACHCollections"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2020</span> - NACH
                          collections
                        </div>
                        <div className="text-[12px]">
                          • Added functionality for NACH collections,
                          simplifying automated payment collections.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={SecureManagement}
                          alt="SecureManagement"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2021</span> – Secure
                          management
                        </div>
                        <div className="text-[12px]">
                          • Introduced easy management and addition of
                          beneficiaries to streamline payments.
                        </div>
                        <div className="text-[12px]">
                          • Added real-time balances and statement view for
                          faster financial insights.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={OptimisingBusiness}
                          alt="OptimisingBusiness"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2022</span> –
                          Optimising for business
                        </div>
                        <div className="text-[12px]">
                          • Introduced a customisable authorisation matrix for
                          secure, flexible user permissions.
                        </div>
                        <div className="text-[12px]">
                          • Added transaction categories to help businesses
                          manage and classify financial activities more
                          effectively.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={BharatConnect}
                          alt="BharatConnect"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2023</span> –
                          Expanding with Bharat Connect
                        </div>
                        <div className="text-[12px]">
                          • Integrated with the Bharat Bill Payment System (now
                          Bharat Connect) to offer broader payment solutions.
                        </div>
                        <div className="text-[12px]">
                          • Launched clean-up of bank narration, improving
                          financial clarity for businesses.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={SpecialisedSolutions}
                          alt="SpecialisedSolutions"
                          className="w-[50px] h-[50px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2024</span> –
                          Specialised solutions
                        </div>
                        <div className="text-[12px]">
                          • Delivered BFSI-specific solutions, tailoring our
                          platform for the banking, financial services, and
                          insurance industries.
                        </div>
                        <div className="text-[12px]">
                          • Added GST + TDS payment functionality, simplifying
                          tax-related payments.
                        </div>
                        <div className="text-[12px]">
                          • Began offering our end-to-end customisable
                          reconciliation solution, enhancing accuracy and
                          reducing manual effort.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        ) : (
          <div
            style={{
              overflow: !webstatus ? "" : "hidden",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div className="w-full">
              <div className="flex relative pt-[60px] bg-[#102C5D]">
                <div className="h-[492px] max-w-[1025px] w-screen flex justify-between gap-5 mx-auto relative">
                  <div className="my-auto">
                    <p className="text-[32px] text-[#F9F9F9] font-semibold mb-[26px]">
                      About Xpentra
                    </p>
                    <div className="w-[467px] h-[287px]">
                      <img
                        className="rounded-[8px]"
                        src={TeamPicture}
                        alt="TeamPicture"
                      />
                    </div>
                  </div>
                  <div className="w-[537px] flex flex-col justify-center text-[#F9F9F9] text-[14px]">
                    <p className="">
                      Xpentra is an innovative fintech venture founded by the
                      Rangnekar family, who bring a legacy of industry success,
                      drawing on their significant contributions to
                      Quest2Travel-a corporate travel management company
                      acquired by the NASDAQ-listed MakeMyTrip in 2018.
                    </p>
                    <br />
                    <p>
                      With over 2 decades of experience and taking Quest2travel
                      to greater heights, the founders have now ventured into
                      the Fintech Space with an understanding of the
                      complexities of the industry.
                    </p>
                    <br />
                    <p>
                      Founded in 2018, Xpentra is built to deliver a
                      comprehensive banking solution for enterprises, offering
                      superior transaction and account visibility and
                      streamlining complex financial processes like payments,
                      collections, reporting, and reconciliation.
                    </p>
                    <br />
                    <p>
                      Xpentra prioritizes usability and protection, featuring
                      automated transactions, With Xpentra, businesses can
                      reduce operational errors, providing CFOs and CXOs with a
                      powerful enabler that eliminates time consuming
                      administrative tasks and allowing leaders to focus on
                      strategic initiatives and high-impact decision making.
                    </p>
                  </div>

                  <div className="absolute h-[62px] w-[65px] ml-[150px] mt-[30px]">
                    <img src={designElement3} alt="" />
                  </div>
                  <div className="absolute h-[62px] w-[65px] ml-[50px] mt-[395px]">
                    <img src={designElement3} alt="" />
                  </div>
                </div>

                <div className="absolute h-[100px] w-[218px] left-[-60px] top-[25%]">
                  <img src={designElement1} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[50px] mt-[75px]">
                  <img src={designElement3} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[0] mt-[225px] rotate-90">
                  <img src={designElement2} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[25px] mt-[375px]">
                  <img src={designElement2} alt="" />
                </div>
              </div>
            </div>

            <div className="bg-[#031F4F] w-full">
              <div className="text-[#f9f9f9]">
                <div
                  className="py-[30px] mx-auto"
                  style={{ maxWidth: "1024px" }}
                >
                  <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                    Our <span className="text-[#31E5E5]">journey</span>
                  </div>
                  <div className="relative" style={{ width: "100%" }}>
                    <div
                      className="border-[#31E5E5] border-b relative top-[40px]"
                      style={{ width: "80%", marginLeft: "40px" }}
                    ></div>
                    <div className="flex flex-row justify-between gap-[50px]">
                      <div>
                        <div>
                          <img
                            src={FinancialManagement}
                            alt="FinancialManagement"
                            className="w-[80px] h-[80px] mb-5 relative z-10"
                          />
                        </div>
                        <div>
                          <div className="title-section font-semibold text-sm mb-2.5">
                            Identifying gaps in corporate financial management
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • At Quest2Pay, we noticed that corporate cards only
                            worked for vendors with POS terminals or payment
                            gateways.
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • We introduced virtual cards to enable payments for
                            vendors without card acceptance infrastructure.
                          </div>
                          <div className="text-[10px]">
                            • Despite this, businesses with multiple bank
                            accounts struggled with fragmented systems and
                            lacked visibility over their finances.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src={Consolidation}
                            alt="Consolidation"
                            className="w-[80px] h-[80px] mb-5 relative z-10"
                          />
                        </div>
                        <div>
                          <div className="title-section font-semibold text-sm mb-2.5">
                            The need for
                            <br /> consolidation
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • Businesses required a unified platform to manage
                            multiple bank accounts and financial activities.
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • They needed real-time visibility, control, and
                            optimisation across all accounts.
                          </div>
                          <div className="text-[10px]">
                            • This realisation laid the foundation for Xpentra.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src={BirthOfXpentra}
                            alt="BirthOfXpentra"
                            className="w-[80px] h-[80px] mb-5 relative z-10"
                          />
                        </div>
                        <div>
                          <div className="title-section font-semibold text-sm mb-2.5">
                            The birth of
                            <br /> Xpentra
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • Xpentra was developed to provide a single portal
                            for managing bank accounts and streamlining
                            financial operations.
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • It offers a bird’s-eye view of financial
                            activities, enabling smarter and faster decisions.
                          </div>
                          <div className="text-[10px]">
                            • Our mission: To empower businesses with better
                            control, real-time insights, and solutions for
                            modern financial challenges.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src={FinancialOperations}
                            alt="FinancialOperations"
                            className="w-[80px] h-[80px] mb-5 relative z-10"
                          />
                        </div>
                        <div>
                          <div className="title-section font-semibold text-sm mb-2.5">
                            Xpentra today: Empowering financial operations
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • Xpentra offers real-time transaction visibility,
                            multi-bank integration, and advanced analytics.
                          </div>
                          <div className="text-[10px] mb-2.5">
                            • The platform is designed for scalability,
                            flexibility, and security, catering to modern
                            business needs.
                          </div>
                          <div className="text-[10px]">
                            • It helps businesses optimise resources, reduce
                            inefficiencies, and drive growth.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-[#f9f9f9] py-[20px]"
              style={{
                background:
                  "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
              }}
            >
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                  <span className="text-[#31E5E5]">Our evolution:</span> Key
                  feature additions over the years
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l left-1/2"
                    style={{ height: "95%" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[40px]">
                    <div className="flex flex-row items-center justify-between">
                      <div className="w-[45%] text-sm mb-1"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={VisionBegins}
                          alt="VisionBegins"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2016</span> – The
                          vision begins
                        </div>
                        <div className="text-[10px]">
                          • Xpentra was conceptualised to address the growing
                          need for an all-in-one financial management platform.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2017</span> –
                          Expanding functionality
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • Added refunds and reimbursements features for
                          smoother financial operations.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={ExpandFunctionality}
                          alt="ExpandFunctionality"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={EnhanceConnectivity}
                          alt="EnhanceConnectivity"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2018</span> –
                          Enhancing connectivity
                        </div>
                        <div className="text-[10px] mb-1">
                          • Integrated API connectivity for seamless data
                          exchange with third-party platforms.
                        </div>
                        <div className="text-[10px]">
                          • Introduced virtual accounts for more efficient
                          transaction management.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2019</span> –
                          Reliable verifications
                        </div>
                        <div className="text-[10px]">
                          • Introduced real-time verifications to ensure secure
                          and accurate transactions.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={ReliableVerifications}
                          alt="ReliableVerifications"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={NACHCollections}
                          alt="NACHCollections"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2020</span> - NACH
                          collections
                        </div>
                        <div className="text-[10px]">
                          • Added functionality for NACH collections,
                          simplifying automated payment collections.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2021</span> – Secure
                          management
                        </div>
                        <div className="text-[10px]">
                          • Introduced easy management and addition of
                          beneficiaries to streamline payments.
                        </div>
                        <div className="text-[10px]">
                          • Added real-time balances and statement view for
                          faster financial insights.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={SecureManagement}
                          alt="SecureManagement"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={OptimisingBusiness}
                          alt="OptimisingBusiness"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2022</span> –
                          Optimising for business
                        </div>
                        <div className="text-[10px]">
                          • Introduced a customisable authorisation matrix for
                          secure, flexible user permissions.
                        </div>
                        <div className="text-[10px]">
                          • Added transaction categories to help businesses
                          manage and classify financial activities more
                          effectively.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2023</span> –
                          Expanding with Bharat Connect
                        </div>
                        <div className="text-[10px]">
                          • Integrated with the Bharat Bill Payment System (now
                          Bharat Connect) to offer broader payment solutions.
                        </div>
                        <div className="text-[10px]">
                          • Launched clean-up of bank narration, improving
                          financial clarity for businesses.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={BharatConnect}
                          alt="BharatConnect"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={SpecialisedSolutions}
                          alt="SpecialisedSolutions"
                          className="w-[80px] h-[80px] relative z-10 rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2024</span> –
                          Specialised solutions
                        </div>
                        <div className="text-[10px]">
                          • Delivered BFSI-specific solutions, tailoring our
                          platform for the banking, financial services, and
                          insurance industries.
                        </div>
                        <div className="text-[10px]">
                          • Added GST + TDS payment functionality, simplifying
                          tax-related payments.
                        </div>
                        <div className="text-[10px]">
                          • Began offering our end-to-end customisable
                          reconciliation solution, enhancing accuracy and
                          reducing manual effort.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
