import React from 'react'

export const ConvertBefiDate = (timestampInSeconds) => {
  if (!timestampInSeconds || typeof timestampInSeconds !== 'number') return "";

  const milliseconds = timestampInSeconds * 1000; // Convert seconds to milliseconds

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dateObj = new Date(milliseconds);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();

  // Add suffix to day
  let daySuffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  } else {
    daySuffix = <sup style={{ fontSize: "12px", top: "0px", bottom: "12px" }} className='suptext'>th</sup>;
  }

  const formattedDate = (
    <div>
      {/* {day}{<sup className='suptextwithtext' >{daySuffix}</sup>} {months[monthIndex]} {year} */}
      {day} {months[monthIndex]} {year}

    </div>
  );

  return formattedDate;





}
