import { Tooltip, tooltipClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";

const Datacard = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect hook to listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update state on resize
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once

  const numberTransformation = (number) => {
    if (number > 99) {
      return "99+";
    } else if (number < 10 && number > 0) {
      return "0" + Number(number);
    } else if (number <= 0) {
      return "00";
    } else {
      return number;
    }
  };
  const colorProp = props.color;
  const numberProp = numberTransformation(props.number);
  const textProp = props.text;
  const imgProp = props.imgName;
  // const pathProp = props.path;
  const handleDatacardClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.handleStatus) {
      props.handleStatus("approved");
    }
  };

  return (
    <div
      className={`owner-datacard ${windowWidth <= 800 && "isMob"}  ${
        numberProp !== "00" ? "cursor-pointer hover:bg-custom-cardHover" : ""
      } ${numberProp === "00" ? "opacity-40 cursor-default" : ""}`}
      onClick={numberProp !== "00" ? handleDatacardClick : undefined}
      style={{
        opacity: numberProp === "00" ? "0.5" : "1",
        height: "",
      }}
    >
      <div className="colorpage" style={{ backgroundColor: colorProp }}></div>

      <div className="cardpage pt-2">
        {imgProp ? (
          <div className="image-container">
            <img
              className="image"
              src={require(`../../assets/TransactionDashboardIcons/${imgProp}`)}
              alt=""
            />
          </div>
        ) : (
          <div
            className="word1"
            style={{ color: colorProp, letterSpacing: "2px" }}
          >
            {numberProp}
          </div>
        )}
        <Tooltip
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                },
              },
            },
          }}
          title={textProp}
        >
          <div
            style={{
              lineHeight:
                props.type === "internal-dsb" && windowWidth <= 800 && "17px",
            }}
            className="mt-[2px] antialiased break-words text-sm text-[#F9F9F9] capitalize overflow-hidden line-clamp-2"
          >
            {textProp}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Datacard;
