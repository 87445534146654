import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Footer from "../../../../components/LandingPage/Footer";
import CheckMark from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";

const Pricing = () => {
  const pricingData = [
    { label: "User Access", icon1: true, icon2: true },
    { label: "All-in-One Dashboard", icon1: true, icon2: true },
    { label: "Real-Time Balances", icon1: true, icon2: true },
    { label: "Customisable Filters", icon1: true, icon2: true },
    { label: "Statement Access", icon1: true, icon2: true },
    { label: "Debits/Credits Tracking", icon1: true, icon2: true },
    { label: "Balance Deltas", icon1: true, icon2: true },
    { label: "Scheduled Balance Updates", icon1: true, icon2: true },
    { label: "Multi-Format Downloads", icon1: true, icon2: true },
    { label: "Transaction Capabilities", icon1: false, icon2: true },
    { label: "Customisable Authorisation Matrix", icon1: false, icon2: true },
    { label: "User/Grade Approval Setup", icon1: false, icon2: true },
    { label: "Transaction Notifications", icon1: false, icon2: true },
    { label: "Advanced Analytics", icon1: false, icon2: true },
    { label: "Custom Reconciliation Solutions", icon1: false, icon2: true },
    { label: "ERP Integration", icon1: false, icon2: true },
    { label: "Utility Payments", icon1: false, icon2: true },
    { label: "BBPS Integration", icon1: false, icon2: true },
    { label: "Treasury Modules", icon1: false, icon2: true },
  ];

  const contactSales = [
    {
      title: "Xpentra Core Pricing",
      description:
        "Includes every feature that is listed in Xpentra core pricing.",
    },
    {
      title: "Transaction Capabilities",
      description:
        "Perform transactions directly within Xpentra for seamless account management.",
    },
    {
      title: "Customisable Authorisation Matrix",
      description:
        "Set up a tailored approval process, ensuring transactions are secure and authorised at the appropriate levels.",
    },
    {
      title: "User/Grade Approval Setup",
      description:
        "Assign approvals by user role or grade, creating a structured, secure workflow.",
    },
    {
      title: "Transaction Notifications",
      description:
        "Receive real-time alerts for all incoming and outgoing transactions.",
    },
    {
      title: "Advanced Analytics",
      description:
        "Access in-depth analytical tools to track trends, forecast cash flows, and support strategic decision-making.",
    },
    {
      title: "Custom Reconciliation Solutions",
      description:
        "Implement customised reconciliation features to meet specific operational needs.",
    },
    {
      title: "ERP Integration",
      description:
        "Seamlessly connect Xpentra with your ERP system for streamlined data flow and enhanced efficiency.",
    },
    {
      title: "Utility Payments",
      description:
        "Manage and process utility payments directly within the platform.",
    },
    {
      title: "BBPS Integration",
      description:
        "Centralise bill payments through integration with the Bharat Bill Payment System (BBPS).",
    },
    {
      title: "Treasury Modules",
      description:
        "Access specialised treasury tools to manage cash flow, investments, and risk with precision.",
    },
  ];

  const bookDemo = [
    {
      title: "All-in-One Dashboard",
      description:
        "Access all account information, balances, and statements in one centralised view.",
    },
    {
      title: "Real-Time Balances",
      description:
        "Refresh all balances at once with a single click or automatically for instant updates.",
    },
    {
      title: "Customisable Filters",
      description:
        "Sample views include Split view, Fund type, Bank type, and Account type, with many more available.",
    },
    {
      title: "Statement Access",
      description:
        "View and download statements, track debits/credits, and monitor balance changes.",
    },
    {
      title: "Multi-Format Downloads",
      description: "Export statements in formats like PDF, CSV, and Excel.",
    },
    {
      title: "Scheduled Balance Updates",
      description: "Automated updates at regular intervals.",
    },
  ];

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [isContactExpanded, setIsContactExpanded] = useState(false);
  const [isDemoExpanded, setIsDemoExpanded] = useState(false);
  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  const toggleContactExpand = () => {
    setIsContactExpanded(!isContactExpanded);
  };

  const toggleDemoExpand = () => {
    setIsDemoExpanded(!isDemoExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: mobilestatus && "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <div className="bg-[#031F4F] w-full mt-[60px]">
                <div className="flex flex-col text-[#f9f9f9] pt-[30px] pl-6 max-w-[1024px] text-start">
                  <h2 className="text-base font-semibold">
                    Transparent <span className="text-[#31E5E5]">pricing</span>
                    &nbsp;for scalable solutions
                  </h2>
                </div>

                <div className="flex flex-col justify-center">
                  <div className="bg-[#244277] mx-5 mt-5 mb-6 p-5 rounded-xl text-[#f9f9f9]">
                    <div className="text-center">
                      <h3 className="text-sm mb-2">Xpentra Core Pricing</h3>
                      <p className="text-sm font-semibold mb-1">
                        <span className="text-xl text-[#4DDD37]">₹3,000</span> /
                        account / month*
                      </p>
                      <p className="text-xs text-[#DADADA] mb-5">
                        Includes up to 10 users.
                      </p>
                    </div>
                    <button
                      className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 font-semibold w-full py-2.5 text-xs rounded-md mb-4"
                      onClick={() => navigate("/book_demo")}
                    >
                      Click here to request a demo
                    </button>
                    <ul className="space-y-[15px] text-sm mb-6">
                      {bookDemo.map((item, index) => (
                        <li className="flex items-start" key={index}>
                          <img
                            src={CheckMark}
                            className="w-[18px] h-[18px] mr-[10px] mt-0.5"
                            alt="CheckMark"
                          />
                          <span>
                            <strong>{item.title}</strong>
                            <br />
                            <span className="text-xs text-[#DADADA]">
                              {item.description}
                            </span>
                          </span>
                        </li>
                      ))}
                    </ul>
                    <p className="text-xs text-left border-t-2 border-[#03204F] pt-2.5 text-[#dadada]">
                      *Note that this does not include integration charges
                      levied by the bank or any other parties.
                    </p>
                  </div>

                  <div className="bg-[#244277] mx-5 mb-5 p-5 rounded-xl text-[#f9f9f9]">
                    <div className="text-center">
                      <h3 className="text-sm">Xpentra Enterprise</h3>
                      <p className="text-xl font-semibold my-2.5">
                        Custom pricing
                      </p>
                      <p className="text-xs text-[#dadada] mb-5">
                        For tailored pricing based on your needs.
                      </p>
                    </div>
                    <button
                      className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 font-semibold w-full py-2.5 text-xs rounded-md mb-4"
                      onClick={() => navigate("/contactus")}
                    >
                      Contact sales
                    </button>
                    <ul className="space-y-4 text-sm">
                      {contactSales.map((contact, index) => (
                        <li className="flex items-start" key={index}>
                          <img
                            src={CheckMark}
                            className="w-[18px] h-[18px] mr-[10px] mt-0.5"
                            alt="CheckMark"
                          />
                          <span>
                            <strong>{contact.title}</strong>
                            <br />
                            <span className="text-xs text-[#DADADA]">
                              {contact.description}
                            </span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="text-start bg-[#102C5D]">
                  <h3 className="text-md text-[#f9f9f9] font-semibold pl-6 pt-5">
                    Xpentra Pricing Comparison
                  </h3>
                  <div className="flex justify-center gap-4 mx-5 max-w-[1024px] m-5">
                    <div className="w-[328px] h-[120px] bg-[#244277] rounded-[15px] shadow-md flex items-center justify-center text-[#f9f9f9] text-sm p-3 font-semibold">
                      Feature
                    </div>
                    <div className="w-full max-w-[328px] h-[120px] bg-[#244277] rounded-[15px] shadow-md flex flex-col items-center justify-center text-[#f9f9f9] text-xs p-4 sm:max-w-[300px] md:max-w-[328px] lg:max-w-[328px]">
                      <div className="text-center text-xs">Xpentra Core</div>
                      <div className="text-center text-xs">Pricing</div>
                      <div className="font-semibold text-sm pt-2 text-center">
                        <span className="text-[#4DDD37]">₹3,000</span>
                        <span> / account</span>
                        <br />
                        <span> / month*</span>
                      </div>
                    </div>

                    <div className="w-[328px] h-[120px] bg-[#244277] rounded-[15px] shadow-md flex flex-col items-center justify-center text-[#f9f9f9] text-xs p-3">
                      <div>Xpentra</div>
                      <div>Enterprise</div>
                      <p>&nbsp;</p>
                      <div className="font-semibold text-sm">
                        <p>Custom</p>
                        <p>pricing</p>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1024px] mt-5 pb-5 mx-5">
                    {pricingData.map((item, index) => (
                      <div
                        key={index}
                        className={`flex py-3 border-b-[1px] border-[#244277]`}
                      >
                        <div className="w-[328px] pl-[10px]">
                          <p className="text-xs font-semibold text-[#f9f9f9] text-left">
                            {item.label}
                          </p>
                        </div>

                        <div className="w-[328px] flex items-center justify-center">
                          {item.icon1 && (
                            <img
                              src={CheckMark}
                              className="w-[18px] h-[18px]"
                              alt="CheckMark"
                            />
                          )}
                        </div>

                        <div className="w-[328px] flex items-center justify-center ml-[15px]">
                          {item.icon2 && (
                            <img
                              src={CheckMark}
                              className="w-[18px] h-[18px]"
                              alt="CheckMark"
                            />
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="text-xs text-[#f9f9f9] my-5">
                      Additional 65 more features with Xpentra Enterprise
                      (contact sales for more information.)
                    </div>
                    <div className="text-xs text-[#dadada]">
                      *Note that this does not include integration charges
                      levied by the bank or any other parties.
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                overflow: webstatus ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <>
                <div
                  className="bg-[#031F4F] w-full"
                  style={{ paddingTop: "60px" }}
                >
                  <div className="flex flex-col text-[#f9f9f9] py-[30px] px-30 mx-auto max-w-[1024px]">
                    <div className="text-[28px] font-semibold text-[#f9f9f9] text-center">
                      <p>
                        Transparent&nbsp;
                        <span className="text-[#31E5E5]">pricing</span>
                        &nbsp;for scalable solutions
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-center gap-8">
                    <div
                      className={`bg-[#244277] w-[340px] rounded-2xl shadow-lg text-[#f9f9f9] ${
                        isDemoExpanded ? "h-[1170px]" : "h-[675px]"
                      } overflow-hidden`}
                      style={{
                        transition: "0.2s ease-in",
                      }}
                    >
                      <div className="pt-5 px-5">
                        <div className="text-center">
                          <h3 className="text-sm"> Xpentra Core Pricing</h3>
                          <p className="text-sm font-semibold text-[#F9F9F9] my-2.5">
                            <span className="text-xl text-[#4DDD37]">
                              ₹3,000&nbsp;
                            </span>
                            / account / month*
                          </p>
                          <p className="text-xs mb-5 text-[#dadada]">
                            Includes up to 10 users.
                          </p>
                        </div>
                        <button
                          className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 w-full text-[#F9F9F9] text-sm hover:font-semibold py-2.5 rounded-md mb-4"
                          onClick={() => navigate("/book_demo")}
                        >
                          Click here to request a demo
                        </button>
                        <div
                          className={`overflow-hidden ${
                            !isDemoExpanded && "max-h-[385px]"
                          }`}
                        >
                          <ul className="space-y-[15px] text-sm mb-6">
                            {bookDemo.map((item, index) => (
                              <li className="flex items-start" key={index}>
                                <img
                                  src={CheckMark}
                                  className="w-[18px] h-[18px] mr-[10px] mt-0.5"
                                  alt="CheckMark"
                                />
                                <span>
                                  <strong>{item.title}</strong>
                                  <br />
                                  <span className="text-xs text-[#DADADA]">
                                    {item.description}
                                  </span>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div
                          className={`pt-2.5 transition-all duration-300 ${
                            isDemoExpanded ? "mt-[420px]" : ""
                          }`}
                        >
                          <p className="text-[12px] text-[#DADADA] text-left border-t-2 border-solid border-[#03204F] pt-2.5">
                            *Note that this does not include integration charges
                            levied by the bank or any other parties.
                          </p>
                        </div>
                      </div>

                      <button
                        className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 w-full text-[#F9F9F9] text-sm hover:font-semibold py-2.5 rounded-b-2xl mt-[13px]"
                        onClick={toggleDemoExpand}
                      >
                        {isDemoExpanded ? "View Less" : "View More"}
                      </button>
                    </div>
                    <div
                      className={`bg-[#244277] w-[340px] rounded-2xl shadow-lg text-[#f9f9f9] ${
                        isContactExpanded ? "h-[1170px]" : "h-[675px]"
                      } overflow-hidden `}
                      style={{
                        transition: "0.2s ease-in",
                      }}
                    >
                      <div className="pt-5 px-5">
                        <div className="text-center">
                          <h3 className="text-sm">Xpentra Enterprise</h3>
                          <p className="text-xl font-semibold text-[#F9F9F9] my-2.5">
                            Custom pricing
                          </p>
                          <p className="text-xs mb-5 text-[#dadada]">
                            For tailored pricing based on your needs.
                          </p>
                        </div>
                        <button
                          className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 w-full text-[#F9F9F9] text-sm hover:font-semibold py-2.5 rounded-md mb-4"
                          onClick={() => navigate("/contactus")}
                        >
                          Contact sales
                        </button>

                        <div
                          className={`overflow-hidden transition-all duration-300 ${
                            isContactExpanded ? "" : "max-h-[385px]"
                          }`}
                        >
                          <ul className="space-y-4 text-sm">
                            {contactSales.map((contact, index) => (
                              <li className="flex items-start" key={index}>
                                <img
                                  src={CheckMark}
                                  className="w-[18px] h-[18px] mr-[10px] mt-0.5"
                                  alt="CheckMark"
                                />
                                <span>
                                  <strong>{contact.title}</strong>
                                  <br />
                                  <span className="text-xs text-[#DADADA]">
                                    {contact.description}
                                  </span>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <button
                        className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 bottom-0 w-full text-[#F9F9F9] text-sm hover:font-semibold py-2.5 rounded-b-2xl mt-[70px]"
                        onClick={toggleContactExpand}
                      >
                        {isContactExpanded ? "View Less" : "View More"}
                      </button>
                    </div>
                  </div>
                  <div className="bg-[#102C5D]">
                    <p className="text-2xl text-[#f9f9f9] font-semibold text-center my-8 pt-8">
                      Xpentra Pricing Comparison
                    </p>
                    <div className="flex space-x-4 mx-auto max-w-[1024px]">
                      <div className="w-[328px] h-[99px] bg-[#244277] rounded-[15px] shadow-md flex items-center justify-center">
                        <p className="text-[#f9f9f9] text-xl font-semibold">
                          Feature
                        </p>
                      </div>
                      <div className="w-[328px] h-[99px] bg-[#244277] rounded-[15px] shadow-md flex flex-col items-center justify-center">
                        <p className="text-[#f9f9f9] text-sm">
                          Xpentra Core Pricing
                        </p>
                        <span className="text-sm font-semibold text-[#F9F9F9] mt-2.5">
                          <span className="text-xl text-[#4DDD37]">₹3,000</span>{" "}
                          / account / month*
                        </span>
                      </div>
                      <div className="w-[328px] h-[99px] bg-[#244277] rounded-[15px] shadow-md flex flex-col items-center justify-center">
                        <p className="text-[#f9f9f9] text-sm ">
                          Xpentra Enterprise
                        </p>
                        <p className="text-[#f9f9f9] font-semibold text-xl mt-2.5">
                          Custom pricing
                        </p>
                      </div>
                    </div>

                    <div className="max-w-[1024px] mx-auto space-y-2 h-auto mt-5 pb-[30px] bg-[#102C5D]">
                      <div className="">
                        {pricingData.map((item, index) => (
                          <div
                            key={index}
                            className={`flex border-b-[1px] border-[#244277]
                            `}
                          >
                            <div className="w-[324px] flex items-left justify-left ml-5 my-3">
                              <p className="text-sm font-semibold text-[#f9f9f9] ">
                                {item.label}
                              </p>
                            </div>

                            <div className="w-[324px] flex items-center justify-center">
                              {item.icon1 && (
                                <img
                                  src={CheckMark}
                                  className="w-[18px] h-[18px]"
                                  alt="CheckMark"
                                />
                              )}
                            </div>

                            <div className="w-[355px] flex items-center justify-center">
                              {item.icon2 && (
                                <img
                                  src={CheckMark}
                                  className="w-[18px] h-[18px]"
                                  alt="CheckMark"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        <div className="flex  text-xs justify-center text-center mt-2">
                          <div className="w-[324px] flex items-center justify-center"></div>{" "}
                          <div className="w-[324px] flex items-center justify-center text-[#dadada]">
                            *Note that this does not include integration
                            <br /> charges levied by the bank or any other
                            parties.
                          </div>
                          <div className="w-[324px] flex items-center justify-center text-[#f9f9f9]">
                            Additional 65 more features.
                            <br /> (contact sales for more information).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Pricing;
