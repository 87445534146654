import React from "react";
import "./index.css";
import { useRef } from "react";
import BeneAccViewAll from "../../../../containers/Accounts/BeneficiaryAccount/BeneAccViewAll";

const BeneTxnAccountModal = ({
  onClose,
  onAccountSelect,
  type,
  associateAccount,
  panNumber,
  IFSC,
  accountNumber,
  setOpenInternalAssociate,
  setOpenBeneAssociate,
  beneAccountId,
  BeneTxnAssocAcct,
  setAssociateModalOpen,
  accountType,
  flow,
  setTxnPayLoad,
  txnPayLoad,
  setIsNeft,
  setIsImps,
  setIsRtgs,
}) => {
  const handleButtonClick = (dataItem) => {
    if (type === "viewAll") {
      onAccountSelect(
        dataItem.name.trim(),
        dataItem.bank_acc_no.trim(),
        dataItem.ifsc_code.trim(),
        dataItem.id
      );
    } else {
      onAccountSelect(
        dataItem.name.trim(),
        dataItem.bank_acc_no.trim(),
        dataItem.ifsc_code.trim(),
        dataItem.bank_name,
        dataItem.category,
        dataItem.pan_no,
        dataItem.id
      );

      if (BeneTxnAssocAcct) {
        setAssociateModalOpen(false);
      }
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998] AccviewallMobile"
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div className="main_pop_div">
        <BeneAccViewAll
          typepop={"popup"}
          onClosepop={onClose}
          handleButtonClick={handleButtonClick}
          associateAccount={associateAccount}
          associatepanNumber={panNumber}
          associateIFSC={IFSC}
          associateaccountNumber={accountNumber}
          setOpenInternalAssociate={setOpenInternalAssociate}
          setOpenBeneAssociate={setOpenBeneAssociate}
          beneAccountId={beneAccountId}
          BeneTxnAssocAcct={BeneTxnAssocAcct}
          accountType={accountType}
          setAssociateModalOpen={setAssociateModalOpen}
          flow={flow}
          setTxnPayLoad={setTxnPayLoad}
          txnPayLoad={txnPayLoad}
          setIsNeft={setIsNeft}
          setIsImps={setIsImps}
          setIsRtgs={setIsRtgs}
        />
      </div>
    </>
  );
};

export default BeneTxnAccountModal;
