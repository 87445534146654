import React, { useState, useEffect } from "react";
import NavXpentraLogo from "../../../assets/landingPage/NavXpentraLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import NavXpentraLogoMob from "../../../assets/landingPage/NavXpentraLogoMob.svg";
import HamBurgerMobile from "../../../assets/landingPage/HamBurgerMobile.svg";
import CloseWithGreyCircle from "../../../assets/landingPage/CloseWithGreyCircle.svg";
import ArrowRightWhite from "../../../assets/landingPage/HoverArrowRight.svg";
import { useDispatch } from "react-redux";
import {
  mobilelandingpagestate,
  weblandingpagestate,
} from "../../../redux/features/landingpagestate/landingpagestate";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isOpen, setIsOpen] = useState(false);
  const [isDdlOpen, setIsDdlOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const toggleDropdown = () => setIsDdlOpen((prev) => !prev);
  const toggleAboutUsDropdown = () => setIsAboutUsOpen((prev) => !prev);
  const isActive = (route) => location.pathname === route;

  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);
  const [isAboutPopOpen, setIsAboutPopOpen] = useState(false);
  const [isPopOpen, setIsPopOpen] = useState(false);

  useEffect(() => {
    dispatch(weblandingpagestate(isPopOpen || isAboutPopOpen ? true : false));
  }, [isPopOpen, isAboutPopOpen]);

  useEffect(() => {
    dispatch(mobilelandingpagestate(isDarkSectionActive));
  }, [isDarkSectionActive]);

  const closePopoverIfActive = (route) => {
    if (isActive(route)) {
      setIsPopOpen(false);
      setIsAboutPopOpen(false);
    } else {
      navigate(route);
    }
  };

  const closePopoverAboutIfActive = (route) => {
    if (isActive(route)) {
      setIsAboutPopOpen(false);
      setIsPopOpen(false);
    } else {
      navigate(route);
    }
  };
  useEffect(() => {
    if (isPopOpen) {
      setIsPopOpen(false);
    }
    if (isAboutPopOpen) {
      setIsAboutPopOpen(false);
    }
  }, [location]);

  const toggleHeight = () => {
    setIsOpen(!isOpen);
    setIsDarkSectionActive(!isOpen);
  };

  const handleNavigate = (type) => {
    if (type === "home") {
      navigate("/");
    } else if (type === "bank statement") {
      navigate("/bank_statement");
    } else if (type === "view all balances") {
      navigate("/view_all_balances");
    } else if (type === "about us") {
      navigate("/aboutus");
    } else if (type === "advisors") {
      navigate("/advisors");
    } else if (type === "pricing") {
      navigate("/pricing");
    } else if (type === "contact us") {
      navigate("/contactus");
    }
    toggleHeight();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div>
        {isMobile ? (
          <div>
            <div
              className="fixed top-0 z-50"
              style={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="bg-[#3A5686] h-[60px] w-screen flex justify-between px-[20px] py-[14px]">
                <div
                  className="my-auto cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <img src={NavXpentraLogoMob} alt="NavXpentraLogoMob" />
                </div>
                <div className="flex gap-2.5">
                  <div
                    className="rounded-[5px] w-[91px] h-[32px] py-[8px] px-[30px] text-[12px] bg-[#f9f9f9] text-[#1D3A6D] hover:bg-[#667CA3] hover:text-[#F9F9F9] hover:font-semibold"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </div>
                  <div className="my-auto">
                    <img
                      src={HamBurgerMobile}
                      alt="HamBurgerMobile"
                      onClick={() => toggleHeight()}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`bg-custom-backdrop opacity-80 z-40 fixed top-0 left-0 w-screen h-screen ${
                isOpen ? "block" : "hidden"
              }`}
              onClick={() => toggleHeight()}
            ></div>

            <div
              className={`fixed bottom-0 w-screen z-50 transition-all duration-300 ${
                isOpen ? "h-auto" : "h-0"
              }`}
            >
              <div className="h-[80px]" onClick={() => toggleHeight()}>
                <img
                  className="mx-auto"
                  src={CloseWithGreyCircle}
                  alt="CloseWithGreyCircle"
                />
              </div>
              <div className="bg-[#244277] rounded-t-[20px] py-[30px]">
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => handleNavigate("home")}
                >
                  Home
                </div>
                <div>
                  <div
                    className={`py-[16px] px-[30px] text-[12px] font-semibold text-[#F9F9F9] flex justify-between cursor-pointer ${
                      isActive("/bank_statement") ||
                      isActive("/view_all_balances")
                        ? "text-[#31E5E5]"
                        : "text-[#F9F9F9]"
                    }`}
                    onClick={toggleDropdown}
                  >
                    Product
                    <span>
                      <img
                        className={`h-[16px] transition-transform duration-300 ${
                          isDdlOpen ? "-rotate-90" : "rotate-90"
                        }`}
                        src={ArrowRightWhite}
                        alt="ArrowRightWhite"
                      />
                    </span>
                  </div>

                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      isDdlOpen
                        ? "max-h-[200px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="px-[30px]">
                      <div className="px-[20px]">
                        <div className="border-l-[2px] h-[64px] flex flex-col gap-[20px] pl-[10px]">
                          <div
                            className={`font-semibold text-[#F9F9F9] text-[12px] ${
                              isActive("/bank_statement")
                                ? "text-[#31E5E5]"
                                : "text-[#F9F9F9]"
                            }`}
                            onClick={() => handleNavigate("bank statement")}
                          >
                            Bank Statements
                          </div>
                          <div
                            className={`font-semibold text-[#F9F9F9] text-[12px] ${
                              isActive("/view_all_balances")
                                ? "text-[#31E5E5]"
                                : "text-[#F9F9F9]"
                            }`}
                            onClick={() => handleNavigate("view all balances")}
                          >
                            View All Balances
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`py-[16px] px-[30px] text-[12px] font-semibold text-[#F9F9F9] flex justify-between cursor-pointer ${
                    isActive("/aboutus") || isActive("/advisors")
                      ? "text-[#31E5E5]"
                      : "text-[#F9F9F9]"
                  }`}
                  onClick={toggleAboutUsDropdown}
                >
                  About Us
                  <span>
                    <img
                      className={`h-[16px] transition-transform duration-300 ${
                        isAboutUsOpen ? "-rotate-90" : "rotate-90"
                      }`}
                      src={ArrowRightWhite}
                      alt="ArrowRightWhite"
                    />
                  </span>
                </div>

                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    isAboutUsOpen
                      ? "max-h-[200px] opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="px-[30px]">
                    <div className="px-[20px]">
                      <div className="border-l-[2px] h-[64px] flex flex-col gap-[20px] pl-[10px]">
                        <div
                          className={`font-semibold text-[#F9F9F9] text-[12px] ${
                            isActive("/aboutus")
                              ? "text-[#31E5E5]"
                              : "text-[#F9F9F9]"
                          }`}
                          onClick={() => handleNavigate("about us")}
                        >
                          About Xpentra
                        </div>
                        <div
                          className={`font-semibold text-[#F9F9F9] text-[12px] ${
                            isActive("/advisors")
                              ? "text-[#31E5E5]"
                              : "text-[#F9F9F9]"
                          }`}
                          onClick={() => handleNavigate("advisors")}
                        >
                          Management
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/aboutus") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => handleNavigate("about us")}
                >
                  About Us
                </div> */}
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/pricing") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => handleNavigate("pricing")}
                >
                  Pricing
                </div>
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/contactus") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => handleNavigate("contact us")}
                >
                  Contact Us
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-[#244277] fixed top-0 w-screen h-[60px] z-50"
            style={{
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div className="flex justify-between py-[15px] w-full max-w-[1024px] mx-auto">
              <div onClick={() => navigate("/")}>
                <img
                  src={NavXpentraLogo}
                  alt="NavXpentraLogo"
                  className="cursor-pointer"
                />
              </div>
              <div className="flex gap-4 text-[#F9F9F9] text-[12px]">
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/")
                      ? "bg-[#3A5686]"
                      : "hover:bg-[#FFFFFF] hover:bg-opacity-20"
                  }`}
                  onClick={() => navigate("/")}
                >
                  Home
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer flex items-center gap-[5px] ${
                    isActive("/bank_statement") ||
                    isActive("/view_all_balances")
                      ? "bg-[#3A5686]"
                      : "hover:bg-[#516B93]"
                  }`}
                  onClick={() => {
                    setIsPopOpen(!isPopOpen);
                    if (isAboutPopOpen) setIsAboutPopOpen(false);
                  }}
                >
                  Product
                  <span>
                    <img
                      className={`h-[12px] my-auto transition-all duration-800 mt-0.5 ${
                        isPopOpen ? "-rotate-90" : "rotate-90"
                      }`}
                      src={ArrowRightWhite}
                      alt="ArrowRightWhite"
                    />
                  </span>
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer flex items-center gap-[5px] ${
                    isActive("/aboutus") || isActive("/advisors")
                      ? "bg-[#3A5686]"
                      : "hover:bg-[#516B93]"
                  }`}
                  onClick={() => {
                    setIsAboutPopOpen(!isAboutPopOpen);
                    if (isPopOpen) setIsPopOpen(false);
                  }}
                >
                  About Us
                  <span>
                    <img
                      className={`h-[12px] my-auto transition-all duration-800 mt-0.5 ${
                        isAboutPopOpen ? "-rotate-90" : "rotate-90"
                      }`}
                      src={ArrowRightWhite}
                      alt="ArrowRightWhite"
                    />
                  </span>
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/pricing")
                      ? "bg-[#3A5686]"
                      : "hover:bg-[#FFFFFF] hover:bg-opacity-20"
                  }`}
                  onClick={() => navigate("/pricing")}
                >
                  Pricing
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/contactus")
                      ? "bg-[#3A5686]"
                      : "hover:bg-[#FFFFFF] hover:bg-opacity-20"
                  }`}
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </div>
              </div>
              <div
                className="justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer bg-[#f9f9f9] text-[#1D3A6D] hover:bg-[#667CA3] hover:text-[#F9F9F9] text-[12px]"
                onClick={() => navigate("/login")}
              >
                Login
              </div>
            </div>
            <div
              className={`${
                isPopOpen ? "h-[278px]" : "h-0"
              } transition-all duration-300 relative`}
            >
              <div
                className={`absolute top-[-18px] left-0 h-screen w-screen bg-custom-backdrop transition-opacity duration-300 ${
                  isPopOpen
                    ? "opacity-80 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
                }`}
                onClick={() => {
                  setIsPopOpen(false);
                  setIsAboutPopOpen(false);
                }}
              ></div>
              <div
                className={`max-w-[1024px] mx-auto mt-[20px] pt-[5px] flex relative transition-all duration-300 overflow-hidden ${
                  isPopOpen ? "h-[278px]" : "h-0"
                }`}
              >
                <div className="bg-[#1D3A6D] w-1/2 rounded-l-[10px] p-[30px] text-[#F9F9F9] flex flex-col gap-[5px]">
                  <div className="text-[16px] font-semibold">
                    Unified Financial Insights
                  </div>
                  <div className="text-[14px]">
                    Xpentra is a technology-driven platform designed to provide
                    CFOs, COOs, and other CXOs with a unified interface that
                    aggregates essential financial data and insights. By
                    consolidating information across banking functions, Xpentra
                    enables faster, more informed decision-making. With
                    real-time data, customizable reporting, and seamless bank
                    integrations, Xpentra offers a comprehensive view that
                    simplifies complex workflows and enhances operational
                    control-empowering leadership to drive strategy with greater
                    accuracy and agility.
                  </div>
                </div>

                <div className="bg-[#244277] w-1/2 rounded-r-[10px] p-[30px] text-[#F9F9F9] flex flex-col gap-[20px]">
                  <div className="text-[16px] font-semibold">
                    Product Features
                  </div>

                  <div className="flex gap-[20px] h-[63px]">
                    <div
                      className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 rounded-[10px] w-1/2 p-[10px] pl-[15px] flex justify-between cursor-pointer"
                      onClick={() => closePopoverIfActive("/bank_statement")}
                    >
                      <div className="">
                        <div className="text-[12px] text-[#F9F9F9]">
                          Bank Statements
                        </div>
                        <div className="text-[8px] text-[#DADADA] w-[149px]">
                          Bank statements: Comprehensive financial insights in
                          one place
                        </div>
                      </div>
                      <div className="flex">
                        <div className="h-[15px] w-[15px] rounded-[246px] bg-[#244277] flex items-center justify-center">
                          <img
                            className="h-[8px] ml-[2px]"
                            src={ArrowRightWhite}
                            onClick={() =>
                              closePopoverIfActive("/bank_statement")
                            }
                            alt="ArrowRightWhite"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 rounded-[10px] w-1/2 p-[10px] pl-[15px] flex justify-between cursor-pointer"
                      onClick={() => closePopoverIfActive("/view_all_balances")}
                    >
                      <div className="">
                        <div className="text-[12px] text-[#F9F9F9]">
                          View All Balances
                        </div>
                        <div className="text-[8px] text-[#DADADA] w-[149px]">
                          View all balances: Simplifying financial oversight
                        </div>
                      </div>

                      <div className="flex">
                        <div className="h-[15px] w-[15px] rounded-[246px] bg-[#244277] flex items-center justify-center">
                          <img
                            className="h-[8px] ml-[2px]"
                            src={ArrowRightWhite}
                            onClick={() =>
                              closePopoverIfActive("/view_all_balances")
                            }
                            alt="ArrowRightWhite"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  isAboutPopOpen ? "h-[165px]" : "h-0"
                } transition-all duration-300 relative`}
              >
                <div
                  className={`absolute top-[-23px] mx-auto h-screen w-screen bg-custom-backdrop transition-opacity duration-300 ${
                    isAboutPopOpen
                      ? "opacity-80 pointer-events-auto"
                      : "opacity-0 pointer-events-none"
                  }`}
                  onClick={() => {
                    setIsAboutPopOpen(false);
                    setIsPopOpen(false);
                  }}
                ></div>
                <div
                  className={`max-w-[1024px] mx-auto flex relative transition-all duration-300 overflow-hidden ${
                    isAboutPopOpen ? "h-[165px]" : "h-0"
                  }`}
                >
                  <div className="bg-[#244277] mx-auto rounded-[10px] p-[30px] text-[#F9F9F9] flex flex-col gap-[20px] w-[532px]">
                    <div className="text-[16px] font-semibold">About Us</div>

                    <div className="flex gap-[20px] h-[63px]">
                      <div
                        className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 rounded-[10px] w-full p-[10px] pl-[15px] flex justify-between cursor-pointer"
                        onClick={() => closePopoverAboutIfActive("/aboutus")}
                      >
                        <div className="cursor-pointer">
                          <div className="text-[12px] text-[#F9F9F9]">
                            About Xpentra
                          </div>
                          <div className="text-[8px] text-[#DADADA] w-[149px]">
                            Get to know Xpentra, our story, and the vision
                            behind our innovative solutions.
                          </div>
                        </div>
                        <div className="flex">
                          <div className="h-[15px] w-[15px] rounded-[246px] bg-[#244277] flex items-center justify-center">
                            <img
                              className="h-[8px] ml-[2px]"
                              src={ArrowRightWhite}
                              onClick={() =>
                                closePopoverAboutIfActive("/aboutus")
                              }
                              alt="ArrowRightWhite"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="bg-[#3A5686] hover:bg-[#FFFFFF] hover:bg-opacity-20 rounded-[10px] w-full p-[10px] pl-[15px] flex justify-between cursor-pointer"
                        onClick={() => closePopoverAboutIfActive("/advisors")}
                      >
                        <div className="">
                          <div className="text-[12px] text-[#F9F9F9]">
                            Management
                          </div>
                          <div className="text-[8px] text-[#DADADA] w-[149px]">
                            Meet the team driving Xpentra’s success and shaping
                            its future.
                          </div>
                        </div>
                        <div className="flex">
                          <div className="h-[15px] w-[15px] rounded-[246px] bg-[#244277] flex items-center justify-center">
                            <img
                              className="h-[8px] ml-[2px]"
                              src={ArrowRightWhite}
                              onClick={() =>
                                closePopoverAboutIfActive("/advisors")
                              }
                              alt="ArrowRightWhite"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
