import React, { useEffect, useState } from "react";
import "./index.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Datacard from "../../../../components/Datacard";
import arrowright from "../../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import OwnerAccountImg from "../../../../assets/HomeDashboardIcons/OwnerAccountsWithBlueCircle.svg";
import OwnerDashboardAccountList from "../../../../components/Accounts/OwnerAccounts/DashboardAccountList";
import arrowup from "../../../../assets/ArrowsIcons/arrow_up_white_filled.svg";
import arrowdown from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import {
  ownerDashboard,
  ownerListViewAllAcc,
  resetpage,
  resetToggle,
  setshowsuccestoast,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import ConnectedBankOwnerCard from "../../../../components/Accounts/OwnerAccounts/ConnectedBankOwnerCard";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import view_statement_icon from "../../../../assets/ViewIcons/view_statement.svg";
import { toast_position } from "../../../../redux/features/Settings/Authorization";

function OwnerDashboard() {
  const [dashboardOwnersData, setDashboardOwnersData] = useState([]);
  const [ownerAccounts, setOwnerAccounts] = useState([]);
  const [ownerDashboardDataLoading, setOwnerDashboardDataLoading] =
    useState(true);
  const [connectedDashboardDataLoading, setConnectedDashboardDataLoading] =
    useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isBalanceSummaryOpen, setIsBalanceSummaryOpen] = useState(true);
  const dispatch = useDispatch();
  const [connectedBankingTotal, setConnectedBankingTotal] = useState("");

  const [connectedBankingOwnerAccounts, setConnectedBankingOwnerAccounts] =
    useState([]);
  const { ownerAccBalanceLoading, showSuccessToast, ownerAccBalanceError } =
    useSelector((state) => state.ownerAllVerification);

  useEffect(() => {
    if (!ownerAccBalanceLoading) {
      setTimeout(() => {
        toast.dismiss();
        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  if (ownerAccBalanceLoading) {
    RefreshBalanceToast();
  }

  useEffect(() => {
    const getDashboardData = async () => {
      if (currentPage === 1) {
        setOwnerDashboardDataLoading(true);
      }
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerDashboard({ page: 1, page_size: 50 })
        );

        setDashboardOwnersData(response?.payload);
        setOwnerAccounts(response?.payload?.owner_accounts);

        if (
          response?.payload?.response?.data?.errors?.user_unauthorized?.length >
          0
        ) {
          navigate("/feature_not_assigned");
        } else {
          console.log("No user unauthorized error.");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setOwnerDashboardDataLoading(false);
      }
    };

    getDashboardData();
  }, []);

  useEffect(() => {
    const getConnectedBankingData = async () => {
      if (currentPage === 1) {
        setConnectedDashboardDataLoading(true);
      }
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerListViewAllAcc({
            page: 1,
            page_size: 50,
            connected_banking: "Y",
          })
        );

        const newData = response?.payload?.owner_accounts || [];

        setConnectedBankingOwnerAccounts((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setConnectedBankingTotal(response?.payload?.total_balance);
      } catch (error) {
        console.error(error);
      } finally {
        setConnectedDashboardDataLoading(false);
      }
    };

    getConnectedBankingData();
  }, [dispatch, currentPage]);

  const getOwnerCardDetails = (accountType, accountAccount) => {
    let accountTypeName;
    let status;
    let color;

    switch (accountType) {
      case "connected_banking_count":
        accountTypeName = `Active ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "Active";
        color = "#4DDD37";
        break;

      case "non_connected_banking_count":
        accountTypeName = `Inactive ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "Inactive";
        color = "#FF5860";
        break;

      default:
        accountTypeName = "Unknown";
        status = "unknown";
        color = "#000000";
        break;
    }
    return { accountTypeName, status, color };
  };

  const getMoreOwners = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleDatacardClick = (approvalStatus) => {
    dispatch(resetToggle());
    dispatch(resetpage());
    const routeMap = {
      Active: "/accounts/owner/view_all",
      Inactive: "/accounts/owner/view_all",
      approved: "/accounts/owner/view_all",
      pending: "/accounts/owner/view_all",
      denied: "/accounts/owner/view_all",
      blocked: "/accounts/owner/view_all",
    };

    navigate(routeMap[approvalStatus], {
      state: { approvalStatus },
    });
  };

  const order = ["connected_banking_count", "non_connected_banking_count"];

  const handleClickViewStatement = () => {
    toast.info("Currently in development!", toast_position);
  };

  return (
    <div className="owner-dashboard">
      <div className="onwerdshbnav bg-[#3a5686] h-[60px] w-full py-[10px] px-[20px] flex gap-x-3">
        <div className="h-[40px] w-[40px]">
          <img src={OwnerAccountImg} alt="" />
        </div>
        <div className="flex flex-col">
          <p className="text-[16px] font-semibold text-[#f9f9f9]">Owner</p>
          <p className="text-[12px] font-semibold text-[#c9c9c9]">Accounts</p>
        </div>
      </div>
      <div className="ownerNavMenu">
        {ownerAccounts?.length > 0 ? (
          <div className="owner-gridLayout">
            {Object.entries(dashboardOwnersData?.summary)
              .filter(
                ([key]) =>
                  key === "connected_banking_count" ||
                  key === "non_connected_banking_count"
              )
              ?.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
              ?.map(([accountType, accountCount]) => {
                const { accountTypeName, status, color } = getOwnerCardDetails(
                  accountType,
                  accountCount
                );

                return (
                  <Box
                    key={accountType}
                    sx={{
                      opacity: "0.9",
                      height: "111px",
                      cursor: "pointer",
                    }}
                  >
                    <Datacard
                      color={color}
                      onClick={() => handleDatacardClick(status)}
                      text={accountTypeName}
                      number={accountCount}
                    />
                  </Box>
                );
              })}
          </div>
        ) : (
          <div className="owner-gridLayout">
            <Box
              sx={{
                opacity: "0.9",
                height: "111px",
                cursor: "pointer",
              }}
            >
              <Datacard
                color={"#4DDD37"}
                onClick={() => handleDatacardClick("wire transfer activated")}
                text={"Active account"}
                number={"0"}
              />
            </Box>
          </div>
        )}
      </div>

      <div className="ownerMain h-full">
        <div className="ownerSection">
          <div className="ownerContent">
            {ownerAccounts?.length > 0 && (
              <div className="owner-recentSection">
                <div className="recenttext">Recent</div>
                <div
                  className="view-all hover:bg-custom-cardHover"
                  onClick={async () => {
                    await dispatch(resetpage());
                    await dispatch(resetToggle());
                    navigate("/accounts/owner/view_all");
                  }}
                >
                  <p>View All</p>
                  <img src={arrowright} alt="arrow right" />
                </div>
              </div>
            )}
            <div
              className="ownerGridLaySection"
              style={{
                height: ownerDashboardDataLoading && "50vh",
              }}
            >
              {ownerDashboardDataLoading ? (
                <div className="owner-dsb-loader">
                  <img src={dataLoading} alt="" />
                </div>
              ) : ownerAccounts?.length > 0 ? (
                <div
                  className="owner-gridcontainer"
                  style={{
                    position: "relative",
                  }}
                >
                  {ownerAccounts?.slice(0, 10)?.map((owner) => (
                    <OwnerDashboardAccountList
                      key={owner?.id}
                      accountNumber={owner?.bank_acc_no}
                      connected_banking={owner?.connected_banking}
                      bankName={owner?.ifsc_code}
                      status={owner?.approval_status}
                      ownerId={owner?.id}
                      balance={owner?.owner_account_balance}
                      lastFetchedAt={
                        owner?.owner_account_balance_last_fetched_at_in_words
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="ownerNotFound">
                  <img width={"100%"} src={noaccount} alt="NoDataImg" />

                  <Typography
                    className="pt-1"
                    sx={{ color: "#FFFFFF", textAlign: "center" }}
                  >
                    No accounts found. <br />
                    All owner accounts will appear here.
                  </Typography>
                </div>
              )}
            </div>
          </div>

          <div className="ownerAcountRightSec">
            <Box
              className="owner_dash_responsive"
              sx={{
                boxShadow: "5px 5px 11px #00000033",
                width: "308px",
                marginLeft: "22px",
                marginTop: "30px",
                opacity: 1,
                height: "40px",
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "#3A5686",
              }}
            >
              <div
                className="viewStatement_div hover:bg-custom-cardHover"
                onClick={() => {
                  if (window.innerWidth > 800) {
                    dispatch(resetToggle());
                    dispatch(resetpage());
                    navigate("/accounts/owner/consolidated_statement");
                  } else {
                    toast.info("Feature comming soon", toast_position);
                  }
                }}
              >
                <img src={view_statement_icon} alt="view_statement_icon" />
                <p>View consolidated statement</p>
              </div>
            </Box>

            {ownerAccounts?.length > 0 && (
              <Box
                className="owner_dash_responsive"
                sx={{
                  boxShadow: "5px 5px 11px #00000033",
                  width: "308px",
                  marginLeft: "22px",
                  marginTop: "30px",
                  marginBottom: "20px",
                  opacity: 1,
                  borderRadius: "22px",
                  justifyContent: "center",
                  height: isBalanceSummaryOpen ? "" : "37px",
                }}
              >
                <div className="ownerQuickActionSec">
                  <div
                    className="balance-summary-head hover:bg-custom-cardHover"
                    style={{
                      borderRadius: !isBalanceSummaryOpen && "22px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsBalanceSummaryOpen((prev) => !prev)}
                  >
                    <p>Balance Summary</p>
                    <div className="arrow-icon">
                      <img
                        src={isBalanceSummaryOpen ? arrowup : arrowdown}
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className={`balance-summary-container-${
                      isBalanceSummaryOpen ? "open" : "close"
                    }`}
                  >
                    <div className="balance-summary-amount">
                      <span>
                        Total balance{" "}
                        {formatTotalBalance(connectedBankingTotal).prefix ===
                        "crores"
                          ? "in crores"
                          : ""}{" "}
                        :{" "}
                      </span>
                      <p>
                        ₹{" "}
                        {formatTotalBalance(connectedBankingTotal || 0).amount}{" "}
                      </p>
                    </div>
                    <div>
                      <div
                        id="infinityScrollOwnersView"
                        className="balance-cards-div"
                        style={{
                          pointerEvents: !isBalanceSummaryOpen && "none",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          {connectedDashboardDataLoading ? (
                            <div className=" flex justify-center items-center">
                              <img
                                src={dataLoading}
                                alt="Loading"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          ) : (
                            <>
                              <InfiniteScroll
                                style={{ width: "inherit" }}
                                dataLength={ownerAccounts?.length}
                                next={getMoreOwners}
                                hasMore={
                                  currentPage <
                                  Math.ceil(
                                    dashboardOwnersData?.total_count / 50
                                  )
                                }
                                loader={
                                  <div className="loader-container m-auto justify-center items-center flex">
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={dataLoading}
                                      alt=""
                                    />
                                  </div>
                                }
                                scrollableTarget="infinityScrollOwnersView"
                                scrollThreshold={0.8}
                              >
                                {connectedBankingOwnerAccounts?.map((owner) => (
                                  <ConnectedBankOwnerCard
                                    key={owner?.id}
                                    owner={owner}
                                    ownerAccounts={ownerAccounts}
                                    setOwnerAccounts={setOwnerAccounts}
                                    ownerAccBalanceLoading={
                                      ownerAccBalanceLoading
                                    }
                                    ownerAccBalanceError={ownerAccBalanceError}
                                    updateTotalBalance={
                                      setConnectedBankingTotal
                                    }
                                    total_balance={connectedBankingTotal}
                                    isBalanceSummaryOpen={isBalanceSummaryOpen}
                                    connectedBankingOwnerAccounts={
                                      connectedBankingOwnerAccounts
                                    }
                                    setConnectedBankingOwnerAccounts={
                                      setConnectedBankingOwnerAccounts
                                    }
                                  />
                                ))}
                              </InfiniteScroll>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwnerDashboard;
