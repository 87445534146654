import "./StepperTxn.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { internalTransactionCreate } from "../../../redux/features/internalTransaction/internalTransactionCreate";
import { checkUsertoken } from "../../../redux/features/login/login";
import { resetViewAllAcc } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useState } from "react";

const StepperTxn = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  direction,
  payload,
  isConfidential,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const services = useSelector((state) => state.refreshToken.services);
  const [disabled, setDisabled] = useState(false);
  const handleCreateBiller = async () => {
    setDisabled(true);
    let internalData = {
      amount: payload?.amount,
      owner_account_id: payload?.ownerId,
      beneficiary_account_id: payload?.beneAccountId,
      payment_method: payload?.settleType?.toUpperCase(),
      category: payload?.selectedCategory?.name,
      confidential_transaction: isConfidential,
    };

    if (payload?.mergeCategory?.length !== 0) {
      internalData.additional_data = {
        data: payload?.mergeCategory?.map((fil) => {
          if (
            fil?.value === "Not Given" ||
            fil?.value === "" ||
            (fil.data_type === "quantity" && fil.value <= 0)
          ) {
            const { value, ...rest } = fil;
            return rest;
          } else {
            return fil;
          }
        }),
      };
    }

    dispatch(checkUsertoken());
    dispatch(internalTransactionCreate({ services, navigate, internalData }));
    dispatch(resetViewAllAcc());
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  return (
    <div className="internal-stepper-main">
      <Button
        disabled={activeStep === 0}
        className={`custom_create ${activeStep === 0 ? "inactive" : "active"}`}
        style={{
          backgroundColor: "#506994",
          color: "#FFFFFF",
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        sx={{ borderRadius: 0 }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="internal-stepperline_Div_biller">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={`stepDetails ${
                  activeStep !== index ||
                  (activeStep === 0 && direction === null)
                    ? ""
                    : "internal-growing-element"
                }`}
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={`internal-main_Line ${
                  activeStep !== index ||
                  (activeStep === 0 && direction === null)
                    ? ""
                    : "internal-growing-element"
                }`}
                style={{
                  width: activeStep === index ? "360px" : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 2 ? (
        <Button
          className={`create_Button ${
            activeStep === 3 ? "active" : "inactive"
          }`}
          onClick={handleCreateBiller}
          sx={{ borderRadius: 0 }}
          disabled={disabled}
        >
          Create
        </Button>
      ) : (
        <Button
          disabled={!nextButtonEnabled}
          className={`custom_create_next ${
            !nextButtonEnabled ? "inactive" : "active"
          }`}
          style={{
            backgroundColor: !nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: !nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          sx={{ borderRadius: 0 }}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default StepperTxn;
