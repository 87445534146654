import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  achivedAccountPan,
  unachivedAccountPan,
  beneLookUp,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { toast } from "react-toastify";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneTxnViewAllAcc } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import RefreshToast from "../../../components/_utils/RefreshToast";
const BeneficiaryPanModal = ({
  close,
  id,
  type,
  setAccountType,
  message,
  note,
  panNumber,
  setIsArchived,
  setShutViewAll,
  setShowAction,
  accountType,
  setRejectTxnPopup,
  message2,
  accountId,
}) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [isMobileView1, setIsMobileView1] = useState(window.innerWidth <= 400);
  const {
    unachivedLoadingPan,
    achivedLoadingPan,
    unachivedDataPan,
    achivedDataPan,
  } = useSelector((state) => state.verify);
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      setIsMobileView1(window.innerWidth <= 400);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handlePan = async () => {
    if (type === "unarchived") {
      RefreshToast("unarchive");
      dispatch(checkUsertoken());
      dispatch(unachivedAccountPan({ id }));
      setIsArchived(false);
    } else if (type === "archived") {
      RefreshToast("archive");
      dispatch(checkUsertoken());
      dispatch(achivedAccountPan({ id }));
    }
    setAccountType({ type: "", id: "" });
    setShutViewAll(false);
  };

  useEffect(() => {
    if (!achivedLoadingPan) {
      if (achivedDataPan?.status === true) {
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account archived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (achivedDataPan?.status === false) {
        toast.error(achivedDataPan?.errors?.base[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [achivedDataPan]);

  useEffect(() => {
    if (!unachivedLoadingPan) {
      if (unachivedDataPan?.status === true) {
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account unarchived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (unachivedDataPan?.status === false) {
        toast.error(unachivedDataPan?.errors?.base[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [unachivedDataPan]);

  const handleConfirm = async () => {
    setIsButtonDisabled(true);
    await handlePan();
    close(false);
    setShowAction(false);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const handleRejectTransactionChecker = async () => {
    setShowLoader(true);
    let queryParams = {
      page_no: 1,
      page_item_count: 50,
      lifecycle_status: "pending",
      beneficiary_account_id: id,
    };
    dispatch(checkUsertoken());
    const response = await dispatch(beneTxnViewAllAcc(queryParams));
    const data = response?.payload?.transactions;
    if (data?.length > 0 && response?.payload?.status && type === "archived") {
      setRejectTxnPopup(true);
      close(false);
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      <div
        className="fixed w-screen top-0 left-0 h-screen z-[9998] "
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-[70%] flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl"
          style={{
            width: isMobileView1 ? "300px" : "400px",
            maxWidth: "600px",
          }}
        >
          <div className="mx-4">
            <h6 className="text-custom-orange pb-1 md:font-bold">{message2}</h6>
            <div className="flex items-center pb-2"></div>
            <div className="mb-3">
              <span
                className="text-white text-sm"
                style={{ wordWrap: "break-word" }}
              >
                {message}
              </span>
              <p
                style={{
                  color: "white",
                  fontSize: "10px",
                  marginTop: "1rem",
                  color: "#C9C9C9",
                  opacity: "1",
                }}
              >
                Note - {note}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                margin: "0px 50px",
                width: "100%",
              }}
              className="px-4 pt-4 m-4"
            >
              {!showLoader && (
                <Button
                  onClick={() => {
                    close(false);
                  }}
                  sx={{
                    borderColor: "none",
                    color: "#fff",
                    textTransform: "capitalize",
                    textDecoration: "underline",
                  }}
                >
                  Cancel
                </Button>
              )}
              {showLoader ? (
                <>
                  <button className="expentra-Loader-class">
                    <img src={xpentraloader} alt="Loading..." />
                  </button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isButtonDisabled}
                    sx={{
                      borderRadius: "20px",
                      color: "#fff !important",
                      boxShadow: "0px 3px 6px #00000033",
                      textTransform: "capitalize",
                      backgroundColor: "#69B76F",
                      border: "1px solid transparent",
                      "&:hover": {
                        backgroundColor: "#69B76F",
                      },
                      marginRight: "30px",
                      width: "110px",
                    }}
                    onClick={() => handleRejectTransactionChecker()}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BeneficiaryPanModal;
