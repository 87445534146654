import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import addbutton from "../../../assets/AddIcons/add_button_white.svg";
import arrowright from "../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import SendPayment from "../../../assets/AdditionalEmailIcons/send_payment_advices_gradient.svg";
import SendTxnInitial from "../../../assets/AdditionalEmailIcons/send_transaction_initiation_emails_gradient.svg";
import bill from "../../../assets/GeneralIcons/send_mis_report_gradient.svg";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import NoDataImg from "../../../assets/Graphics/No transaction found.svg";
import vendorImage from "../../../assets/CategoryIcons/merchant.svg";
import { useNavigate } from "react-router-dom";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import PrevIcon from "../../../assets/ArrowsIcons/arrow_left_white_circle.svg";
import NextIcon from "../../../assets/ArrowsIcons/arrow_right_white_circle.svg";
import { BeneTxnDashBoard } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import Datacard from "../../../components/Datacard";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import BeneficiaryImg from "../../../assets/beneficiary_with_white_circle.svg";
import arrowFilledDown from "../../../assets/arrow_down_white_filled.svg";
import hamburgerMenu from "../../../assets/hamburger_menu.svg";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const BeneTxnDashboard = () => {
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [currentDot, setcurrentDot] = useState(1);
  const [disablePrev, setDisablePrev] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isServiceAvl, setisServiceAvl] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { BeneTxnDashBoardData, BeneTxnDashBoardLoading } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const services = useSelector((state) => state.refreshToken.services);
  useEffect(() => {
    async function getData() {
      dispatch(checkUsertoken());
      await dispatch(BeneTxnDashBoard({ page: 1, page_size: 50 }));
    }
    getData();
  }, [dispatch]);

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleDatacardClick = (approvalStatus, distributionStatus) => {
    const routeMap = {
      approved: "/transaction/beneficiary_transaction/view_all",
      pending: "/transaction/beneficiary_transaction/view_all",
      require:
        "/transaction/beneficiary_transaction/transaction_single_approval",
      rejected: "/transaction/beneficiary_transaction/view_all",
      settled: "/transaction/beneficiary_transaction/view_all",
      cancelled: "/transaction/beneficiary_transaction/view_all",
      failed: "/transaction/beneficiary_transaction/view_all",
      abandoned: "/transaction/beneficiary_transaction/view_all",
      queued: "/transaction/beneficiary_transaction/view_all",
      distribution_initiated: "/transaction/beneficiary_transaction/view_all",
      distribution_complete: "/transaction/beneficiary_transaction/view_all",
      awaiting_funds: "/transaction/beneficiary_transaction/view_all",
      created: "/transaction/beneficiary_transaction/view_all",
    };

    const isDistributionStatus = distributionStatus
      .toLowerCase()
      .includes("distribution");

    const DistributionStatus = isDistributionStatus ? true : false;
    navigate(routeMap[approvalStatus], {
      state: { approvalStatus, DistributionDashBoard: DistributionStatus },
    });
  };
  // console.log()
  const getHeight = () => {
    if (currentDot === 1) return "270px";
    if (currentDot === 2) return "380px";
    if (currentDot === 3) return "150px";
    return "auto";
  };
  // console.log()
  const firstTenAccounts = BeneTxnDashBoardData?.transactions?.slice(0, 8);
  const beneTxnNext = () => {
    if (BeneTxnDashBoardData?.transactions.length === 0) {
      if (currentDot < 2) {
        setcurrentDot(currentDot + 1);
        setShowSecondContent(!showSecondContent);
        setDisablePrev(false);
      }
    } else {
      if (hasNonZeroDistribution) {
        if (currentDot < 2) {
          setcurrentDot(currentDot + 1);
          setShowSecondContent(!showSecondContent);
          setDisablePrev(false);
        }
      } else if (currentDot < 3) {
        setcurrentDot(currentDot + 1);
        setShowSecondContent(!showSecondContent);
        // setDisableNext(true);
        setDisablePrev(false);
      }
    }
  };

  const beneTxnPrev = () => {
    if (currentDot > 1) {
      setcurrentDot(currentDot - 1);
      setShowSecondContent(!showSecondContent);
      // setDisablePrev(true);
      setDisableNext(false);
    }
  };

  const getColorClass = (approvalStatus) => {
    switch (approvalStatus) {
      case "pending":
        return "pendingColor";
      case "rejected":
        return "rejectedColor";
      case "approved":
        return "approvedColor";
      case "queued":
        return "queuedColor";
      case "settled":
        return "settledColor";
      case "abandoned":
        return "abandonedColor";
      case "cancelled":
        return "cancelledColor";
      case "denied":
        return "deniedColor";
      default:
        return "leftPrice";
    }
  };

  useEffect(() => {
    if (services?.includes("CBeneficiaryTxn") || services?.includes("Admin")) {
      setisServiceAvl(false);
    } else if (services?.includes("ABeneficiaryTxn")) {
      setisServiceAvl(true);
    }
  }, []);

  const accountTypesSummary =
    BeneTxnDashBoardData && BeneTxnDashBoardData?.summary
      ? Object.entries(BeneTxnDashBoardData.summary)
      : [];

  const transactionData = accountTypesSummary.filter(
    ([key]) => !key.includes("distribution")
  );

  const distributionData = accountTypesSummary.filter(([key]) =>
    key.includes("distribution")
  );

  const hasNonZeroDistribution = distributionData.some(
    ([key, value]) => value === 0
  );

  const order = [
    "approved_transaction_count",
    "actionable_transaction_count",
    "pending_transaction_count",
    "rejected_transaction_count",
    "abandoned_transaction_count",
    "distribution_complete_distribution_transaction_count",
    "created_distribution_transaction_count",
    "approved_distribution_transaction_count",
    "distribution_initiated_distribution_transaction_count",
    "awaiting_funds_distribution_transaction_count",
    "rejected_distribution_transaction_count",
    "failed_distribution_transaction_count",
    "abandoned_distribution_transaction_count",
    // "cancelled_transaction_count",
    // "denied_transaction_count",
    // "blocked_transaction_count",
  ];

  let firstFiveDataCards;
  let SecondFiveDataCards;
  let ThirdFiveDataCards;

  if (isMobileView) {
    if (accountTypesSummary?.length > 6) {
      firstFiveDataCards = transactionData;
      if (hasNonZeroDistribution) {
        SecondFiveDataCards = distributionData.slice(0, 6);
      } else {
        SecondFiveDataCards = distributionData.slice(0, 6);
        ThirdFiveDataCards = distributionData.slice(6);
      }
    } else {
      firstFiveDataCards = accountTypesSummary;
    }
  } else {
    if (accountTypesSummary?.length > 7) {
      firstFiveDataCards = transactionData;
      if (hasNonZeroDistribution) {
        SecondFiveDataCards = distributionData.slice(0, 8);
      } else {
        SecondFiveDataCards = distributionData.slice(0, 7);
        ThirdFiveDataCards = distributionData.slice(7);
      }
    } else {
      firstFiveDataCards = accountTypesSummary;
    }
  }

  const getTransactionDataCardsDetails = (accountType, accountAccount) => {
    let accountTypeName;
    let status;
    let color;
    let distributionStatus;
    switch (accountType) {
      case "approved_transaction_count":
        accountTypeName = "Approved";
        distributionStatus = "approved_transaction_count";
        status = "approved";
        color = "#B196FA";
        break;
      case "actionable_transaction_count":
        accountTypeName = "Require Approval";
        status = "require";
        distributionStatus = "actionable_transaction_count";
        color = "#31E5E5";
        break;
      case "pending_transaction_count":
        accountTypeName = "Pending";
        status = "pending";
        color = "#31E5E5";
        distributionStatus = "pending_transaction_count";
        break;
      case "rejected_transaction_count":
        accountTypeName = "Rejected";
        status = "rejected";
        color = "#FF5860";
        distributionStatus = "rejected_transaction_count";
        break;
      case "abandoned_transaction_count":
        accountTypeName = "Abandoned";
        status = "abandoned";
        distributionStatus = "abandoned_transaction_count";
        color = "#B4B4B4";
        break;
      case "distribution_complete_distribution_transaction_count":
        accountTypeName = "Distribution Complete";
        status = "distribution_complete";
        distributionStatus =
          "distribution_complete_distribution_transaction_count";
        color = "#4DDD37";
        break;
      case "created_distribution_transaction_count":
        accountTypeName = "Distribution Created";
        distributionStatus = "created_distribution_transaction_count";
        status = "created";
        color = "#FBC02D";
        break;
      case "approved_distribution_transaction_count":
        accountTypeName = `Distribution Approved`;
        status = "approved";
        color = "#FBC02D";
        distributionStatus = "approved_distribution_transaction_count";
        break;
      case "distribution_initiated_distribution_transaction_count":
        accountTypeName = "Distribution initiated";
        status = "distribution_initiated";
        color = "#FBC02D";
        distributionStatus =
          "distribution_initiated_distribution_transaction_count";
        break;
      case "awaiting_funds_distribution_transaction_count":
        accountTypeName = "Awaiting funds";
        status = "awaiting_funds";
        distributionStatus = "awaiting_funds_distribution_transaction_count";
        color = "#FBC02D";
        break;
      case "rejected_distribution_transaction_count":
        accountTypeName = `Distribution rejected`;
        status = "rejected";
        color = "#FF5860";
        distributionStatus = "rejected_distribution_transaction_count";
        break;
      case "failed_distribution_transaction_count":
        accountTypeName = `Distribution failed`;
        status = "failed";
        color = "#FF5860";
        distributionStatus = "failed_distribution_transaction_count";
        break;

      case "abandoned_distribution_transaction_count":
        accountTypeName = "Distribution Abandoned";
        status = "abandoned";
        color = "#B4B4B4";
        distributionStatus = "abandoned_distribution_transaction_count";
        break;

      default:
        accountTypeName = "Unknown";
        status = "unknown";
        distributionStatus = "Unknown";
        color = "#FBC02D";
        break;
    }

    return { accountTypeName, status, color, distributionStatus };
  };

  const showDataCardDetailsFirstDiv = (cards) => {
    return cards

      ?.filter(([accountType, accountCount]) => {
        const excludeTypes = [
          // "approved_transaction_count",
          "cancelled_transaction_count",
          "blocked_transaction_count",
        ];

        if (excludeTypes.includes(accountType)) {
          return false;
        }

        if (
          accountCount <= 0 &&
          accountType !== "pending_transaction_count" &&
          accountType !== "actionable_transaction_count"
        ) {
          return false;
        }

        // Service-based filtering
        if (
          (services?.includes("ABeneficiaryTxn") &&
            services?.includes("CBeneficiaryTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }

        if (
          services?.includes("ABeneficiaryTxn") &&
          !services?.includes("CBeneficiaryTxn")
        ) {
          // return accountType !== "pending_transaction_count";
          return true;
        }

        if (
          services?.includes("CBeneficiaryTxn") &&
          !services?.includes("ABeneficiaryTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }
        return true;
      })
      ?.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))

      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            // sx={{
            //   opacity: "0.9",
            //   height: "111px",
            // }}
          >
            <Datacard
              color={color}
              onClick={() => handleDatacardClick(status, distributionStatus)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };
  const showDataCardDetailsSecondDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        // if (
        //   (accountCount <= 0 &&
        //     accountType !== "pending_transaction_count" &&
        //     accountType !== "actionable_transaction_count") ||
        //   accountType === "approved_transaction_count"
        // ) {
        //   return false;
        // }
        if (
          accountCount <= 0 &&
          accountType !== "distribution_complete_distribution_transaction_count"
        ) {
          return false;
        }
        if (
          (services?.includes("ABeneficiaryTxn") &&
            services?.includes("CBeneficiaryTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          services?.includes("ABeneficiaryTxn") &&
          !services?.includes("CBeneficiaryTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CBeneficiaryTxn") &&
          !services?.includes("ABeneficiaryTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        return accountType;
      })
      .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            // sx={{
            //   opacity: "0.9",
            //   height: "111px",
            // }}
          >
            <Datacard
              color={color}
              onClick={() => handleDatacardClick(status, distributionStatus)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };
  const showDataCardDetailsThirdDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        if (
          (accountCount <= 0 &&
            accountType !== "pending_transaction_count" &&
            accountType !== "actionable_transaction_count") ||
          accountType === "approved_transaction_count"
        ) {
          return false;
        }
        if (
          (services?.includes("ABeneficiaryTxn") &&
            services?.includes("CBeneficiaryTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          services?.includes("ABeneficiaryTxn") &&
          !services?.includes("CBeneficiaryTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CBeneficiaryTxn") &&
          !services?.includes("ABeneficiaryTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        return accountType;
      })
      .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            // sx={{
            //   opacity: "0.9",
            //   height: "111px",
            // }}
          >
            <Datacard
              color={color}
              onClick={() => handleDatacardClick(status, distributionStatus)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };

  return (
    <div
      style={
        {
          // marginTop: "60px", position: "absolute", top: "-10px"s
        }
      }
      className="BeneTxn_DashBoardMainParent"
    >
      <div className="BeneTxn_Dashboard_mobile_header">
        <div className="TxnMobHeaderCont">
          <div className="bt-header-left-mobile">
            <img src={BeneficiaryImg} alt="BeneficiaryImg" />
            <div className="bt-header-left-mContent">
              <div className="beneText-dashboard-mob">Beneficiary</div>
              <div className="beneTransText-dashboard-mob">Transactions</div>
            </div>
          </div>
        </div>
      </div>
      <div className="BeneTxn-mobile-create-tran">
        <div
          className="BeneTxn-mobile-create-tranCont cursor-pointer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
          }}
          onClick={() => {
            if (
              services?.find((service) => service === "CBeneficiaryTxn") !==
              undefined
            ) {
              navigate("/transaction/beneficiary_transaction/create");
            } else {
              navigate("/feature_not_assigned", {
                state: {
                  unavailableService: "CBeneficiaryTxn",
                },
              });
            }
          }}
        >
          <img
            src={addbutton}
            alt="addbutton"
            onClick={() => {
              // services?.includes("CBeneficiaryTxn") ||
              // services?.includes("Admin")
              //   ? toast.info("Feature coming soon!", toast_position)
              //   : navigate("/feature_not_assigned", {
              //       state: {
              //         unavailableService: "CBeneficiaryTxn",
              //       },
              //     });
            }}
          />
          <div className="BeneTxn_mob_tranText">Create transaction</div>
        </div>
      </div>

      <div className="BeneTxn_NavMenu">
        {BeneTxnDashBoardData?.transactions?.length > 0 ? (
          <>
            <div
              className="BeneTxn_GridLayout"
              style={{
                height: isMobileView ? getHeight() : "9rem",
              }}
            >
              <div
                className={`transition-effect ${
                  currentDot === 1 ? "showFirstContent" : "hideFirstContent"
                } `}
              >
                {showDataCardDetailsFirstDiv(firstFiveDataCards)}
              </div>

              <div
                className={`transition-effect ${
                  currentDot === 2 ? "showSecondContent" : "hideSecondContent"
                }`}
              >
                {showDataCardDetailsSecondDiv(SecondFiveDataCards)}
              </div>
              <div
                className={`transition-effect ${
                  currentDot === 3 ? "showThirdContent" : "hideThirdContent"
                }`}
              >
                {showDataCardDetailsThirdDiv(ThirdFiveDataCards)}
              </div>
            </div>

            <div className="BeneTxn_CaraouselBar" style={{}}>
              <div>
                <p className="mx-auto  h-[19px] font-semibold text-[14px] leading-[19px] text-[#FFFF]">
                  {currentDot === 1
                    ? "Approval Statuses"
                    : "Distribution Statuses"}
                </p>
              </div>
              <div className="BeneTxn_CarouselDotbar">
                <div
                  className="BeneTxn_FirstCaraoDot"
                  onClick={disablePrev ? null : beneTxnPrev}
                  style={{
                    width: currentDot === 1 ? "20px" : "12px",
                    opacity: currentDot === 1 ? "1" : "0.65",
                    transition: "width 0.4s ease, opacity 0.4s ease",
                    pointerEvents: isMobileView === false ? "none" : "auto",
                  }}
                ></div>
                <div
                  className="BeneTxn_SecondCaraoDot"
                  onClick={disableNext ? null : beneTxnNext}
                  style={{
                    width: currentDot === 2 ? "20px" : "12px",
                    opacity: currentDot === 2 ? "1" : "0.65",
                    transition: "width 0.4s ease, opacity 0.4s ease",
                    pointerEvents: isMobileView === false ? "none" : "auto",
                  }}
                ></div>
                {!hasNonZeroDistribution && (
                  <div
                    className="BeneTxn_ThirdCaraoDot"
                    onClick={disableNext ? null : beneTxnNext}
                    style={{
                      width: currentDot === 3 ? "20px" : "12px",
                      opacity: currentDot === 3 ? "1" : "0.65",
                      transition: "width 0.4s ease, opacity 0.4s ease",
                      pointerEvents: isMobileView === false ? "none" : "auto",
                    }}
                  ></div>
                )}
              </div>
              <div className="BeneTxn_NextAndPrevIcon">
                <div
                  className="BeneTxn_CarouPrevIcon"
                  style={{
                    cursor:
                      currentDot === 3 || currentDot === 2
                        ? "pointer"
                        : "default",
                  }}
                  onClick={disablePrev ? null : beneTxnPrev}
                >
                  <img
                    src={PrevIcon}
                    alt=""
                    style={{
                      opacity:
                        currentDot === 3 || currentDot === 2 ? "1" : "0.5",
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>
                <div
                  className="BeneTxn_CarouNextIcon"
                  style={{
                    cursor:
                      hasNonZeroDistribution && currentDot === 2
                        ? "default"
                        : currentDot === 1 || currentDot === 2
                        ? "pointer"
                        : "default",
                  }}
                  onClick={disableNext ? null : beneTxnNext}
                >
                  <img
                    src={NextIcon}
                    alt=""
                    style={{
                      opacity:
                        hasNonZeroDistribution && currentDot === 2
                          ? "0.5"
                          : currentDot === 1 || currentDot === 2
                          ? "1"
                          : "0.5",
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="beneTxngridlayout">
            <Box
              sx={{
                opacity: "0.9",
                height: "111px",
                cursor: "pointer",
              }}
            >
              <Datacard
                color={
                  (services?.includes("ABeneficiaryTxn") &&
                    services?.includes("CBeneficiaryTxn")) ||
                  services?.includes("Admin") ||
                  (services?.includes("ABeneficiaryTxn") &&
                    !services?.includes("CBeneficiaryTxn"))
                    ? "#31E5E5"
                    : "#FBC02D"
                }
                onClick={() => handleDatacardClick("pending")}
                text={
                  (services?.includes("ABeneficiaryTxn") &&
                    services?.includes("CBeneficiaryTxn")) ||
                  services?.includes("Admin") ||
                  (services?.includes("ABeneficiaryTxn") &&
                    !services?.includes("CBeneficiaryTxn"))
                    ? "Require Approval"
                    : "Pending"
                }
                number={"0"}
              />
            </Box>
            <Box
              sx={{
                opacity: "0.9",
                height: "111px",
                cursor: "pointer",
              }}
            >
              <Datacard
                color={"#4DDD37"}
                onClick={() => handleDatacardClick("approved")}
                text={"Approved"}
                number={"0"}
              />
            </Box>
          </div>
        )}
      </div>

      <div className="BeneTxn_NavMenuMob">
        {BeneTxnDashBoardData?.transactions?.length > 0 ? (
          <>
            <div
              className="BeneTxn_GridLayoutMob"
              // style={{
              //   height: isMobileView ? getHeight() : "238px",
              // }}
            >
              <div
                className={`transition-effect ${
                  currentDot === 1 ? "showFirstContent" : "hideFirstContent"
                } `}
              >
                {showDataCardDetailsFirstDiv(firstFiveDataCards)}
              </div>

              <div
                className={`transition-effect ${
                  currentDot === 2 ? "showSecondContent" : "hideSecondContent"
                }`}
              >
                {showDataCardDetailsSecondDiv(SecondFiveDataCards)}
              </div>
              <div
                className={`transition-effect ${
                  currentDot === 3 ? "showThirdContent" : "hideThirdContent"
                }`}
              >
                {showDataCardDetailsThirdDiv(ThirdFiveDataCards)}
              </div>
            </div>

            <div className="BeneTxn_CaraouselBar" style={{}}>
              <div>
                <p className="mx-auto  h-[19px] font-semibold text-[14px] leading-[19px] text-[#FFFF]">
                  {currentDot === 1
                    ? "Approval Statuses"
                    : "Distribution Statuses"}
                </p>
              </div>
              <div className="BeneTxn_CarouselDotbar">
                <div
                  className="BeneTxn_FirstCaraoDot"
                  onClick={disablePrev ? null : beneTxnPrev}
                  style={{
                    width: currentDot === 1 ? "20px" : "12px",
                    opacity: currentDot === 1 ? "1" : "0.65",
                    transition: "width 0.4s ease, opacity 0.4s ease",
                    pointerEvents: isMobileView === false ? "none" : "auto",
                  }}
                ></div>
                <div
                  className="BeneTxn_SecondCaraoDot"
                  onClick={disableNext ? null : beneTxnNext}
                  style={{
                    width: currentDot === 2 ? "20px" : "12px",
                    opacity: currentDot === 2 ? "1" : "0.65",
                    transition: "width 0.4s ease, opacity 0.4s ease",
                    pointerEvents: isMobileView === false ? "none" : "auto",
                  }}
                ></div>
                {!hasNonZeroDistribution && (
                  <div
                    className="BeneTxn_ThirdCaraoDot"
                    onClick={disableNext ? null : beneTxnNext}
                    style={{
                      width: currentDot === 3 ? "20px" : "12px",
                      opacity: currentDot === 3 ? "1" : "0.65",
                      transition: "width 0.4s ease, opacity 0.4s ease",
                      pointerEvents: isMobileView === false ? "none" : "auto",
                    }}
                  ></div>
                )}
              </div>
              <div className="BeneTxn_NextAndPrevIcon">
                <div
                  className="BeneTxn_CarouPrevIcon"
                  style={{
                    cursor:
                      currentDot === 3 || currentDot === 2
                        ? "pointer"
                        : "default",
                  }}
                  onClick={disablePrev ? null : beneTxnPrev}
                >
                  <img
                    src={PrevIcon}
                    alt=""
                    style={{
                      opacity:
                        currentDot === 3 || currentDot === 2 ? "1" : "0.5",
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>
                <div
                  className="BeneTxn_CarouNextIcon"
                  style={{
                    cursor:
                      hasNonZeroDistribution && currentDot === 2
                        ? "default"
                        : currentDot === 1 || currentDot === 2
                        ? "pointer"
                        : "default",
                  }}
                  onClick={disableNext ? null : beneTxnNext}
                >
                  <img
                    src={NextIcon}
                    alt=""
                    style={{
                      opacity:
                        hasNonZeroDistribution && currentDot === 2
                          ? "0.5"
                          : currentDot === 1 || currentDot === 2
                          ? "1"
                          : "0.5",
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="beneTxngridlayout">
            <Box
              sx={{
                opacity: "0.9",
                height: "111px",
                cursor: "pointer",
              }}
            >
              <Datacard
                color={
                  (services?.includes("ABeneficiaryTxn") &&
                    services?.includes("CBeneficiaryTxn")) ||
                  services?.includes("Admin") ||
                  (services?.includes("ABeneficiaryTxn") &&
                    !services?.includes("CBeneficiaryTxn"))
                    ? "#31E5E5"
                    : "#FBC02D"
                }
                onClick={() => handleDatacardClick("pending")}
                text={
                  (services?.includes("ABeneficiaryTxn") &&
                    services?.includes("CBeneficiaryTxn")) ||
                  services?.includes("Admin") ||
                  (services?.includes("ABeneficiaryTxn") &&
                    !services?.includes("CBeneficiaryTxn"))
                    ? "Require Approval"
                    : "Pending"
                }
                number={"0"}
              />
            </Box>
            <Box
              sx={{
                opacity: "0.9",
                height: "111px",
                cursor: "pointer",
              }}
            >
              <Datacard
                color={"#4DDD37"}
                onClick={() => handleDatacardClick("approved")}
                text={"Approved"}
                number={"0"}
              />
            </Box>
          </div>
        )}
      </div>

      <div className="BeneTxn_AllSection h-full">
        <div className="BeneTxn_TxnSection">
          {BeneTxnDashBoardLoading ? (
            <>
              <div
                className="loader-container expentraloaderwithDashBoard"
                id="beneTnxXpentraLoaderWeb"
              >
                <img src={xpentraloader} alt="Loading..." />
              </div>
            </>
          ) : BeneTxnDashBoardData?.transactions?.length ? (
            <div className="BeneTxn_ContentPage">
              <div className="BeneTxn_RecentPage">
                <div className="BeneTxn_RecentText">Recent</div>
                <div
                  className="BeneTxn_ViewallPage hover:bg-custom-cardHover"
                  onClick={() =>
                    navigate("/transaction/beneficiary_transaction/view_all", {
                      state: { approvalStatus: "all" },
                    })
                  }
                >
                  <p>View All</p>
                  <img src={arrowright} alt="" />
                </div>
              </div>

              <div className="BeneTxn_BenGridLaySection">
                <div
                  className="BeneTxn-GridContainer"
                  style={{ position: "relative" }}
                >
                  {firstTenAccounts?.map((account, index) => (
                    <div
                      className="BeneTxn_GridItemContent cursor-pointer hover:bg-custom-cardHover"
                      key={index}
                      onClick={() =>
                        navigate(
                          `/transaction/beneficiary_transaction/details/${account.txn_id}`
                          // {
                          //   state: totalPages,
                          // }
                        )
                      }
                    >
                      <div className="BeneTxn_ItemContent">
                        <div className="BeneTxn_TxtLeftSection">
                          <div className="BeneTxn_PriceSection">
                            <p
                              className={`leftPrice ${getColorClass(
                                account.lifecycle_status
                              )}`}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "17.2px",
                                  paddingRight: "5px",
                                }}
                              >
                                ₹
                              </span>

                              <span>
                                {<FormatAmount price={account.amount} />}
                              </span>
                            </p>
                          </div>
                          <div className="BeneTxn_CompanyName">
                            <p
                              className={`lelfP ${getColorClass(
                                account.approval_status
                              )}`}
                            >
                              {account.beneficiary_account_name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </p>
                          </div>
                        </div>
                        <div className="BeneTxn_ImageRightSection">
                          <div className="BeneTxn_CategoriesSection">
                            <img src={vendorImage} alt="" />
                          </div>
                          <div className="BeneTxn_DividerBtwnCatnBank"></div>
                          <div className="BeneTxn_BankImagesSection">
                            <img
                              src={require("../../../assets/IfscLogo/" +
                                IfscCodeMapper(
                                  account?.beneficiary_account_ifsc_code
                                ))}
                              alt="bank"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="beneTxnDashNotFound">
              <img width={"100%"} src={NoDataImg} alt="NoDataImg" />

              <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
                No transactions found <br />
                All beneficiary transactions will appear here.
              </Typography>
            </div>
          )}

          <div className="BeneTxn_AcountRightSec">
            <Box
              className="bene-box-mobile-hide"
              sx={{
                boxShadow: "5px 5px 11px #00000033",
                width: "308px",
                cursor: "default",
                marginLeft: "22px",
                // marginTop: "30px",
                opacity: 1,
                // height: "65px",
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "#3A5686",
              }}
            >
              <div
                className={`createAc ${
                  services?.includes("CBeneficiaryTxn") ||
                  services?.includes("Admin")
                    ? "hover:bg-custom-cardHover"
                    : ""
                }`}
                style={{
                  opacity:
                    services?.includes("CBeneficiaryTxn") ||
                    services?.includes("Admin")
                      ? "1"
                      : "0.5",
                  cursor:
                    services?.includes("CBeneficiaryTxn") ||
                    services?.includes("Admin")
                      ? "cursor"
                      : isServiceAvl && "not-allowed",
                }}
                onClick={() => {
                  if (
                    services?.find(
                      (service) => service === "CBeneficiaryTxn"
                    ) !== undefined
                  ) {
                    navigate("/transaction/beneficiary_transaction/create");
                  } else {
                    navigate("/feature_not_assigned", {
                      state: {
                        unavailableService: "CBeneficiaryTxn",
                      },
                    });
                  }
                }}
              >
                <img src={addbutton} alt="img add" />
                <p className="BeneTxn_CreateTxn">Create Transaction</p>
              </div>
            </Box>

            <Box
              className="bene-box-mobile"
              sx={{
                boxShadow: "5px 5px 11px #00000033",
                width: "308px",
                marginLeft: "22px",
                marginTop: "30px",
                overflow: "hidden",
                paddingBottom: "3%",
                height: "fit-content",
                opacity: 1,
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "#3A5686",
              }}
            >
              <div className="BeneTxn_QuickActionSec">
                <div className="BeneTxn_LeftBenfContent">
                  <p>Quick actions</p>
                </div>
                <div className="h-full">
                  <div className="BeneTxn_RightBenfContent">
                    <div className="BeneTxn_leftSidecontent">
                      <img src={bill} alt="" />
                      <p>Send MIS report</p>
                    </div>
                    <div className="BeneTxn_RightSideContent">
                      <img src={arrowright} alt="" />
                    </div>
                  </div>

                  <div className="BeneTxnRightDivider"></div>

                  <div className="BeneTxn_RightBenfContent">
                    <div className="BeneTxn_leftSidecPaymentAdvice">
                      <img src={SendPayment} alt="" />
                      <p>Send payment advices</p>
                    </div>
                    <div className="BeneTxn_RightSideContent">
                      <img src={arrowright} alt="" />
                    </div>
                  </div>

                  <div className="BeneTxnRightDivider"></div>

                  <div className="BeneTxn_RightBenfContent">
                    <div className="BeneTxn_LeftSideSendTxn">
                      <img src={SendTxnInitial} alt="" />
                      <p>Send transaction initiation emails</p>
                    </div>
                    <div className="BeneTxn_RightSideContent">
                      <img src={arrowright} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnDashboard;
