import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import ArrowWhiteFilled from "../../../assets/OwnerAccount/arrowdown5.png";
import CheckMarkWhite from "../../../assets/CheckmarksIcons/check_mark_white.svg";

const TimePicker = ({
  handleTimeConfirm,
  hourShow,
  minuteShow,
  meridiemShow,
  setMinuteShow,
  setMeridiemShow,
  setHourShow,
  isTimeSelected,
  setShowDatePicker,
  setShowDatePickerCal,
  data_type,
  fieldname,
  value,
  handleClearTime,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(null);

  const numbers = [...Array(12).keys()].map((i) => i + 1); // [1, 2, ..., 12]
  const numbers1 = [...Array(60).keys()]; // [1, 2, ..., 60]
  const section1Ref = useRef(null); // Reference to the first section
  const section2Ref = useRef(null); // Reference to the first section
  const section3Ref = useRef(null); // Reference to the first section
  const [section1Width, setSection1Width] = useState(0); // State for section1's width
  const [section2Width, setSection2Width] = useState(0); // State for section1's width
  const [section3Width, setSection3Width] = useState(0); // State for section1's width

  const toggleHeight = () => {
    if (isExpanded) {
      setIsDropdownOpen(null);
    }
    setIsExpanded(!isExpanded);
  };

  const toggleDropdown = (dropdownId) => {
    setIsDropdownOpen((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const expandedDropdownHeight = {
    dropdown1: isDropdownOpen === "dropdown1" ? 160 : 0,
    dropdown2: isDropdownOpen === "dropdown2" ? 160 : 0,
    dropdown3: isDropdownOpen === "dropdown3" ? 65 : 0,
  };

  const totalExpandedHeight =
    120 +
    expandedDropdownHeight.dropdown1 +
    expandedDropdownHeight.dropdown2 +
    expandedDropdownHeight.dropdown3;

  useEffect(() => {
    const updateWidths = () => {
      if (section1Ref.current) {
        const width1 = section1Ref.current.getBoundingClientRect().width;
        const width2 = section2Ref.current.getBoundingClientRect().width;
        const width3 = section3Ref.current.getBoundingClientRect().width;

        setSection1Width(width1);
        setSection2Width(width2);
        setSection3Width(width3);
      }
    };
    updateWidths();

    window.addEventListener("resize", updateWidths);

    return () => {
      window.removeEventListener("resize", updateWidths);
    };
  }, []);

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="w-full relative rounded-[20px] flex flex-col overflow-visible"
      ref={dropdownRef}
      onClick={() => {
        setShowDatePicker(false);
        setShowDatePickerCal(false);
      }}
    >
      <div
        onClick={toggleHeight}
        style={{
          borderRadius: "10px",
          borderBottomRightRadius: isExpanded ? "" : "10px",
          borderBottomLeftRadius: isExpanded ? "" : "10px",
          borderTop: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
          borderRight: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
          borderLeft: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
        }}
        className={`bg-[#667CA3] px-[18px] py-2.5 flex justify-between cursor-pointer z-10`}
      >
        <div className="text-[#DADADA]">{value}</div>
        <div>
          <img
            className="w-[14px] h-[14px] mt-[5px]"
            style={{
              transform: isExpanded ? "rotate(180deg)" : "",
              transition: ".5s",
            }}
            src={ArrowWhiteFilled}
            alt="ArrowWhiteFilled"
          />
        </div>
      </div>
      <div
        className={`w-full bg-[#667CA3] relative z-[9] overflow-hidden`}
        style={{
          height: isExpanded ? `${totalExpandedHeight}px` : "0px",
          transition: "height 0.3s ease",
          borderTopRightRadius: !isExpanded ? "10px" : "",
          borderTopLeftRadius: !isExpanded ? "10px" : "",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottom: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
          borderRight: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
          borderLeft: isExpanded ? "rgb(226, 88, 105) solid 1px" : "",
        }}
      >
        <div>
          <div className="border-t-[1px] border-t-[#3A5686] mx-5"></div>
          <div className="relative py-[12px] px-[18px] text-[#F9F9F9] text-[14px] flex justify-center gap-1 items-center">
            <div
              ref={section1Ref}
              className="flex-[28] bg-[#3A5686] p-2.5 text-[16px] flex justify-between items-center relative"
              style={{
                borderRadius:
                  isDropdownOpen === "dropdown1" ? "10px 10px 0px 0px" : "10px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown("dropdown1");
              }}
            >
              <div className="flex justify-between items-center w-full md:px-[5px]">
                <div>{hourShow}</div>
                <div>
                  <img
                    className="w-[14px] h-[14px]"
                    style={{
                      transform:
                        isDropdownOpen === "dropdown1" ? "rotate(180deg)" : "",
                      transition: ".5s",
                    }}
                    src={ArrowWhiteFilled}
                    alt="Arrow Icon"
                  />
                </div>
              </div>

              {/* Dropdown menu */}
              {isDropdownOpen === "dropdown1" && (
                <div
                  className={`bg-[#3A5686] p-2.5 pt-0 absolute top-full left-0 z-10 flex flex-col gap-2 h-[160px] overflow-y-scroll`}
                  style={{
                    width: `${section1Width.toFixed(2)}px`,
                    borderRadius:
                      isDropdownOpen === "dropdown1"
                        ? "0px 0px 10px 10px"
                        : "10px",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {numbers.map((num) => (
                    <div
                      className={`cursor-pointer hover:bg-[#68789F] rounded-[5px] md:px-[5px] flex items-center justify-between ${
                        hourShow === num.toString().padStart(2, "0")
                          ? "bg-[#1D3A6D] hover:bg-[#172E57]"
                          : ""
                      }`}
                      key={num}
                      onClick={(e) => {
                        e.stopPropagation();
                        setHourShow(num.toString().padStart(2, "0"));
                        setIsDropdownOpen(null);
                      }}
                    >
                      <span>{num.toString().padStart(2, "0")}</span>
                      {hourShow === num.toString().padStart(2, "0") && (
                        <img
                          src={CheckMarkWhite}
                          alt="Selected"
                          className="w-4 h-4"
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex-[1]">:</div>

            <div
              ref={section2Ref}
              className="flex-[28] bg-[#3A5686] p-2.5 text-[16px] flex justify-between items-center relative"
              style={{
                borderRadius:
                  isDropdownOpen === "dropdown2" ? "10px 10px 0px 0px" : "10px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown("dropdown2");
              }}
            >
              <div className="flex justify-between items-center w-full md:px-[5px]">
                <div>{minuteShow}</div>
                <div>
                  <img
                    className="w-[14px] h-[14px]"
                    style={{
                      transform:
                        isDropdownOpen === "dropdown2" ? "rotate(180deg)" : "",
                      transition: ".5s",
                    }}
                    src={ArrowWhiteFilled}
                    alt="Arrow Icon"
                  />
                </div>
              </div>

              {/* Dropdown menu */}
              {isDropdownOpen === "dropdown2" && (
                <div
                  className={`bg-[#3A5686] p-2.5 pt-0 absolute top-full left-0 flex-[28] z-10 flex flex-col gap-2 h-[160px] overflow-y-scroll`}
                  style={{
                    width: `${section2Width.toFixed(2)}px`,
                    borderRadius:
                      isDropdownOpen === "dropdown2"
                        ? "0px 0px 10px 10px"
                        : "10px",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {numbers1.map((num) => (
                    <div
                      className={`hover:bg-[#68789F] rounded-[5px] md:px-[5px] cursor-pointer flex items-center justify-between ${
                        minuteShow === num.toString().padStart(2, "0")
                          ? "bg-[#1D3A6D] hover:bg-[#172E57]"
                          : ""
                      }`}
                      key={num}
                      onClick={(e) => {
                        e.stopPropagation();
                        setMinuteShow(num.toString().padStart(2, "0"));
                        setIsDropdownOpen(null);
                      }}
                    >
                      <span>{num.toString().padStart(2, "0")}</span>
                      {minuteShow === num.toString().padStart(2, "0") && (
                        <img
                          src={CheckMarkWhite}
                          alt="Selected"
                          className="w-4 h-4"
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex-[1]">:</div>

            <div
              ref={section3Ref}
              className="flex-[28] bg-[#3A5686] p-2.5 text-[16px] flex justify-between items-center relative"
              style={{
                borderRadius:
                  isDropdownOpen === "dropdown3" ? "10px 10px 0px 0px" : "10px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown("dropdown3");
              }}
            >
              <div className="flex justify-between items-center w-full md:px-[5px]">
                <div>{meridiemShow}</div>
                <div>
                  <img
                    className="w-[14px] h-[14px]"
                    style={{
                      transform:
                        isDropdownOpen === "dropdown3" ? "rotate(180deg)" : "",
                      transition: ".5s",
                    }}
                    src={ArrowWhiteFilled}
                    alt="Arrow Icon"
                  />
                </div>
              </div>

              {/* Dropdown menu */}
              {isDropdownOpen === "dropdown3" && (
                <div
                  className="bg-[#3A5686] p-2.5 pt-0 absolute top-[100%] left-0 flex-[28] z-10 flex flex-col gap-[10px]"
                  style={{
                    width: `${section3Width.toFixed(2)}px`,
                    borderRadius: isDropdownOpen ? "0px 0px 10px 10px" : "10px",
                  }}
                >
                  {["AM", "PM"].map((meridiem) => {
                    const isSelected = meridiemShow === meridiem;

                    return (
                      <div
                        key={meridiem}
                        className={`cursor-pointer hover:bg-[#68789F] rounded-[5px] md:px-[5px] flex items-center justify-between ${
                          isSelected ? "bg-[#1D3A6D] hover:bg-[#172E57]" : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setMeridiemShow(meridiem);
                          setIsDropdownOpen(null);
                        }}
                      >
                        <span>{meridiem}</span>
                        {isSelected && (
                          <img
                            src={CheckMarkWhite}
                            alt="Selected"
                            className="w-4 h-4"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full flex justify-center gap-5 z-50 px-[18px] pb-[15px] text-[#F9F9F9] text-[14px]">
          <div
            className="border border-[#F9F9F9] rounded-[20px] flex-1 py-[6px] text-center bg-[#667CA3]"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClearTime(fieldname);
            }}
          >
            Clear
          </div>
          <div
            className="border border-[#707070] rounded-[20px] flex-1 py-[6px] text-center"
            style={{
              cursor: "pointer",
              pointerEvents: isTimeSelected ? "auto" : "none",
              backgroundColor: isTimeSelected ? "#F05A6A" : "#707070",
            }}
            onClick={() => {
              handleTimeConfirm(fieldname, data_type);
              setIsExpanded(false);
            }}
          >
            Set Time
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
