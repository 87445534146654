import React, { useEffect, useState } from "react";
import successImage from "../../../../assets/Graphics/Success page graphic.svg";
import createTransaction from "../../../../assets/GeneralIcons/initiate_internal_transaction.svg";
import viewDetails from "../../../../assets/ViewIcons/view_details.svg";
import dashboard from "../../../../assets/GeneralIcons/dashboard.svg";
import ContactNumber from "../../../../assets/ContactIcons/contact_number.svg";
import Email from "../../../../assets/MailIcons/Mail.svg";
import {
  beneSuccessDetail,
  resetEditBankBeneficiary,
} from "../../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip, tooltipClasses } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import {
  beneLookUp,
  verifyThisPAN,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import {
  beneVerifyThisBank,
  beneBankLookUp,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";

function BeneBankEditSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [previousAssociated, setPreviousAssociated] = useState(false);

  const { beneLookData } = useSelector((state) => state.verify);

  const isSuccessErrorData = useSelector(
    (state) => state.beneCreateAccount.isSuccessErrorData
  );
  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    id: "",
    name: "",
    bank_name: "",
    bank_acc_no: "",
    ifsc_code: "",
    category: "",
    pan: "",
  });

  useEffect(() => {
    if (isSuccessErrorData == "Request failed with status code 404") {
      navigate("*");
    }
  }, [isSuccessErrorData]);

  useEffect(() => {
    const panNumber = beneLookData?.beneficiary_accounts[0]?.pan_no;
    if (panNumber) {
      alert("Hit");
      dispatch(checkUsertoken());
      dispatch(verifyThisPAN({ pan_number: panNumber, navigate: navigate }));
    }
  }, [beneLookData, dispatch]);

  useEffect(() => {
    const bankNumber = beneLookData?.beneficiary_accounts[0]?.bank_acc_no;
    const ifscCode = beneLookData?.beneficiary_accounts[0]?.ifsc_code;
    if (bankNumber) {
      dispatch(checkUsertoken());
      dispatch(
        beneVerifyThisBank({
          bank_account: bankNumber,
          ifsc_code: ifscCode,
          navigate: navigate,
        })
      );
    }
  }, [benebankLookData, dispatch]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneSuccessDetail(id));
  }, [dispatch, id]);

  const successDetail = useSelector(
    (state) => state.beneCreateAccount.beneSuccessData
  );

  const beneficiaryAccount = successDetail?.beneficiary_accounts[0] || {};

  useEffect(() => {
    if (beneficiaryAccount) {
      const { id, name, bank_name, bank_acc_no, ifsc_code, category, pan_no } =
        beneficiaryAccount;
      setCurrentAccountDetails({
        id,
        name,
        bank_name,
        bank_acc_no,
        ifsc_code,
        category,
        pan: pan_no,
      });
    }
  }, [beneficiaryAccount]);

  const viewDetailsHandler = () => {
    navigate(`/accounts/beneficiary_account/details/${id}`);
  };

  const dashboardHandler = () => {
    navigate("/accounts/beneficiary/dashboard");
  };

  const createTxn = () => {
    navigate("/transaction/beneficiary_transaction/create", {
      state: currentAccountDetails,
    });
  };

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending" && val?.id !== id;
  });

  let haveBankPending = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending" && val?.id !== id;
  });

  let haveBankApproved = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  const { isSuccessLoading } = useSelector((state) => state.beneCreateAccount);

  useEffect(() => {
    if (!isSuccessLoading && successDetail) {
      dispatch(checkUsertoken());
      dispatch(
        beneLookUp({
          pan_number: successDetail?.beneficiary_accounts[0]?.pan_no,
        })
      );
    }
  }, [successDetail]);

  useEffect(() => {
    if (!isSuccessLoading && successDetail) {
      dispatch(checkUsertoken());
      dispatch(
        beneBankLookUp({
          bank_account_no: successDetail?.beneficiary_accounts[0]?.bank_acc_no,
          ifsc: successDetail?.beneficiary_accounts[0]?.ifsc_code,
        })
      );
    }
  }, [successDetail]);

  const bgColorClass =
    currentTab === 0 ? "bg-[#55985C]" : 1 ? "bg-[#4C8452]" : "bg-[#4C8452]";
  const capitalizeFirstLine = (text) => {
    if (!text) return "";
    const lines = text.split("\n");
    if (lines.length > 0) {
      lines[0] = lines[0]?.charAt(0)?.toUpperCase() + lines[0].slice(1);
    }
    return lines.join("\n");
  };

  return (
    <div className="min-h-screen bg-[#457549]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 mt-[66px] pt-[112px]">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
              <div className="p-2 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">
                    Old account archived and new account added to the list of
                    active accounts successfully!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <>
            {previousAssociated == false &&
            (haveBankApproved != undefined || haveBankPending != undefined) &&
            (haveApproved != undefined || havePending != undefined) ? (
              <div className="flex-1 mt-[66px] py-[35px]">
                <div
                  className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0 rounded-t-[18px] items-center ${bgColorClass}`}
                >
                  <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "10px",
                        color: "#F9F9F9",
                      },
                      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                        color: "#F9F9F9",
                      },
                      "& .MuiTabs-flexContainer": {
                        position: "relative",
                      },
                      "& .MuiTab-root": {
                        position: "relative",
                        zIndex: 1,
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          height: "33px",
                          width: "1px",
                          backgroundColor: "#F9F9F9",
                          top: 13.5,
                          right: 0,
                        },
                        "&:last-child::after": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Tab
                      label={
                        <span
                          style={{
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: currentTab === 0 ? 600 : 400,
                          }}
                        >
                          Account Details
                        </span>
                      }
                      sx={{
                        color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                        width: "237px",
                        textTransform: "none",
                        height: "60px",
                        borderTopLeftRadius: "20px",
                        backgroundColor:
                          currentTab === 0 ? "#55985C" : "#4C8452",
                      }}
                    />

                    <Tab
                      label={
                        <span
                          style={{
                            whiteSpace: "pre-line",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: currentTab === 1 ? 600 : 400,
                          }}
                        >
                          Accounts Associated{"\n"}to PAN
                        </span>
                      }
                      sx={{
                        color: currentTab === 1 ? "#F9F9F9" : "#F9F9F9",
                        width: "241px",
                        textTransform: "none",
                        height: "60px",
                        borderTopRightRadius: "20px",
                        paddingTop: "13px",
                        backgroundColor:
                          currentTab === 1 ? "#55985C" : "#4C8452",
                      }}
                    />
                  </Tabs>
                </div>

                {currentTab === 0 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-[20px] items-center justify-between"
                    style={{ height: "495px" }}
                  >
                    <div className="max-w-[471px] max-h-[390px] overflow-y-auto">
                      <div className="flex flex-col font-bold px-10">
                        <div className="text-base text-[#000000] w-full text-center py-[13px]">
                          <div
                            className="w-96"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: " nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                  textTransform: "capitalize",
                                },
                              }}
                              title={beneficiaryAccount?.name}
                              placement="bottom-start"
                            >
                              {beneficiaryAccount?.name
                                ? beneficiaryAccount?.name?.toLowerCase()
                                : ""}
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="w-full content-center">
                        <div className="mx-0 dashedLine"></div>
                      </div>
                      <div className="w-full px-5">
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={beneficiaryAccount.category}
                              placement="bottom-start"
                            >
                              <div className="font-normal text-base capitalize">
                                <div
                                  className="w-96 pt-2.5"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {typeof beneficiaryAccount.category ===
                                  "string"
                                    ? beneficiaryAccount.category
                                        .split(",")
                                        .map((category) => category.trim())
                                        .join(" , ")
                                    : beneficiaryAccount.category}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Assigned categories
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {beneficiaryAccount?.pan_no}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            PAN number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {formatAccountNumber(
                              beneficiaryAccount?.bank_acc_no
                            )}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Account number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount?.ifsc_code}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              IFSC code
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                              alt="bank"
                            />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              +91&nbsp;{beneficiaryAccount?.contact_no}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Contact number
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={ContactNumber} alt="Contact Number" />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={beneficiaryAccount?.email_id}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                className="text-base font-medium text-black antialiased"
                                style={{
                                  width: "50%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {beneficiaryAccount?.email_id}
                              </div>
                            </Tooltip>

                            <div className="text-gray-500 text-xs antialiased">
                              Email ID
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={Email} alt="Email" />
                          </div>
                        </div>
                        {beneficiaryAccount?.gstin_detail && (
                          <>
                            <div className="simpleLine bg-[#A0A0A0]"></div>
                            <div className="flex flex-row justify-between py-0.5">
                              <div className="flex flex-col space-y-1 text-left w-4/6">
                                <div className="text-base text-black antialiased">
                                  {beneficiaryAccount.gstin_detail}
                                </div>
                                <div className="text-gray-500 text-xs antialiased">
                                  GST
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : currentTab === 1 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-4xl justify-between"
                    style={{ height: "495px" }}
                  >
                    {haveApproved || havePending ? (
                      <div className="max-w-[471px] max-h-[380px] overflow-y-auto pb-2.5">
                        {haveApproved && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-5 py-0.5">
                              Approved accounts
                            </p>
                            <div className="flex flex-col w-full px-5">
                              {beneLookData?.beneficiary_accounts.map(
                                (val, index, array) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  Bank account number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4">
                                              <img
                                                src={require("../../../../assets/IfscLogo/" +
                                                  IfscCodeMapper(
                                                    val?.ifsc_code
                                                  ))}
                                                alt="bank"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          </>
                        )}
                        {havePending && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-5 py-0.5">
                              Pending accounts
                            </p>
                            <div className="flex flex-col w-full px-5 mt-3">
                              {beneLookData?.beneficiary_accounts
                                .filter(
                                  (val) =>
                                    val?.approval_status === "pending" &&
                                    val?.id !== id
                                )
                                .map((val, index, array) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  Bank account number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4">
                                              <img
                                                src={require("../../../../assets/IfscLogo/" +
                                                  IfscCodeMapper(
                                                    val?.ifsc_code
                                                  ))}
                                                alt="bank"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {array.filter(
                                          (acc) =>
                                            acc.approval_status === "pending"
                                        ).length -
                                          1 !==
                                          index && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] flex flex-row justify-center">
                          <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-4xl justify-between"
                    style={{ height: "495px" }}
                  >
                    {haveBankApproved || haveBankPending ? (
                      <div className="max-w-[471px] max-h-[380px]  overflow-y-auto">
                        {haveBankApproved && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-5 py-0.5">
                              Approved accounts
                            </p>
                            <div className="flex flex-col w-full px-5">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, index, array) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val.name}
                                              placement="bottom-start"
                                            >
                                              {val.name
                                                ? val?.name?.toLowerCase()
                                                : ""}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {val.pan_no}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  PAN number
                                                </span>
                                              </div>
                                            </div>{" "}
                                            <div className="flex flex-row justify-end w-2/6 h-4"></div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          </>
                        )}
                        {haveBankPending && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-5 py-0.5">
                              Pending accounts
                            </p>
                            <div className="flex flex-col w-full px-5 mt-3">
                              {benebankLookData?.beneficiary_accounts
                                .filter(
                                  (val) =>
                                    val?.approval_status === "pending" &&
                                    val?.id !== id
                                )
                                .map((val, index, array) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {val.pan_no}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  PAN number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4"></div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <>
                                            <div className="simpleLine bg-[#A0A0A0]"></div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] flex flex-row justify-center">
                          <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : previousAssociated == false &&
              (haveBankApproved != undefined || haveBankPending != undefined) &&
              !(haveApproved != undefined || havePending != undefined) ? (
              <div className="flex-1 mt-[66px] py-[35px]">
                <div
                  className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0 rounded-t-[18px] items-center ${bgColorClass}`}
                >
                  <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "10px",
                        color: "#F9F9F9",
                      },
                      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                        color: "#F9F9F9",
                      },
                      "& .MuiTabs-flexContainer": {
                        position: "relative",
                      },
                      "& .MuiTab-root": {
                        position: "relative",
                        zIndex: 1,
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          height: "33px",
                          width: "1px",
                          backgroundColor: "#F9F9F9",
                          top: 13.5,
                          right: 0,
                        },
                        "&:last-child::after": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Tab
                      label={<span>Account Details</span>}
                      sx={{
                        color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                        width: "236px",

                        textTransform: "none",
                        height: "60px",
                        borderTopLeftRadius: "20px",
                        backgroundColor:
                          currentTab === 0 ? "#55985C" : "#4C8452",
                        paddingLeft: "13px",
                      }}
                    />
                    <Tab
                      label={
                        <span>
                          Accounts <br /> Associated{"\n"}to Bank
                        </span>
                      }
                      sx={{
                        color: currentTab === 1 ? "#F9F9F9" : "#F9F9F9",
                        width: "236px",
                        textTransform: "none",
                        height: "60px",
                        borderTopRightRadius: "20px",
                        paddingTop: "13px",

                        backgroundColor:
                          currentTab === 1 ? "#55985C" : "#4C8452",
                      }}
                    />
                  </Tabs>
                </div>

                {currentTab === 0 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-[20px] items-center justify-between"
                    style={{ height: "495px" }}
                  >
                    <div className="max-w-[471px] max-h-[390px] overflow-y-auto">
                      <div className="flex flex-col font-bold px-10">
                        <div className="text-base text-[#000000] w-full text-center py-[13px]">
                          <div
                            className="w-96"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: " nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                  textTransform: "capitalize",
                                },
                              }}
                              title={beneficiaryAccount?.name}
                              placement="bottom-start"
                            >
                              {beneficiaryAccount?.name
                                ? beneficiaryAccount?.name?.toLowerCase()
                                : ""}
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="w-full content-center">
                        <div className="mx-0 dashedLine"></div>
                      </div>
                      <div className="w-full px-5">
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={beneficiaryAccount.category}
                              placement="bottom-start"
                            >
                              <div className="font-normal text-base capitalize">
                                <div
                                  className="w-96 pt-2.5"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {typeof beneficiaryAccount.category ===
                                  "string"
                                    ? beneficiaryAccount.category
                                        .split(",")
                                        .map((category) => category.trim())
                                        .join(" , ")
                                    : beneficiaryAccount.category}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Assigned categories
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {beneficiaryAccount?.pan_no}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            PAN number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {formatAccountNumber(
                              beneficiaryAccount?.bank_acc_no
                            )}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Account number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount?.ifsc_code}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              IFSC code
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                              alt="bank"
                            />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              +91&nbsp;{beneficiaryAccount?.contact_no}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Contact number
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={ContactNumber} alt="Contact Number" />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={beneficiaryAccount?.email_id}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                className="text-base font-medium text-black antialiased"
                                style={{
                                  width: "50%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {beneficiaryAccount?.email_id}
                              </div>
                            </Tooltip>

                            <div className="text-gray-500 text-xs antialiased">
                              Email ID
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={Email} alt="Email" />
                          </div>
                        </div>
                        {beneficiaryAccount?.gstin_detail && (
                          <>
                            <div className="simpleLine bg-[#A0A0A0]"></div>
                            <div className="flex flex-row justify-between py-0.5">
                              <div className="flex flex-col space-y-1 text-left w-4/6">
                                <div className="text-base text-black antialiased">
                                  {beneficiaryAccount.gstin_detail}
                                </div>
                                <div className="text-gray-500 text-xs antialiased">
                                  GST
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : currentTab === 1 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-4xl justify-between"
                    style={{ height: "495px" }}
                  >
                    {haveBankApproved || haveBankPending ? (
                      <div className="max-w-[471px] max-h-[380px]  overflow-y-auto">
                        {haveBankApproved && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-5 py-0.5">
                              Approved accounts
                            </p>
                            <div className="flex flex-col w-full px-5">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, index, array) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val.name}
                                              placement="bottom-start"
                                            >
                                              {val.name
                                                ? val?.name?.toLowerCase()
                                                : ""}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {val.pan_no}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  PAN number
                                                </span>
                                              </div>
                                            </div>{" "}
                                            <div className="flex flex-row justify-end w-2/6 h-4"></div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          </>
                        )}
                        {haveBankPending && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-5 py-0.5">
                              Pending accounts
                            </p>
                            <div className="flex flex-col w-full px-5 mt-3">
                              {benebankLookData?.beneficiary_accounts
                                .filter(
                                  (val) =>
                                    val?.approval_status === "pending" &&
                                    val?.id !== id
                                )
                                .map((val, index, array) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {val.pan_no}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  PAN number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4"></div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <>
                                            <div className="simpleLine bg-[#A0A0A0]"></div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] flex flex-row justify-center">
                          <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : previousAssociated == false &&
              (haveApproved != undefined || havePending != undefined) &&
              !(
                haveBankApproved != undefined || haveBankPending != undefined
              ) ? (
              <div className="flex-1 mt-[66px] py-[35px]">
                <div
                  className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0 rounded-t-[18px] items-center ${bgColorClass}`}
                >
                  <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "10px",
                        color: "#F9F9F9",
                      },
                      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                        color: "#F9F9F9",
                      },
                      "& .MuiTabs-flexContainer": {
                        position: "relative",
                      },
                      "& .MuiTab-root": {
                        position: "relative",
                        zIndex: 1,
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          height: "33px",
                          width: "1px",
                          backgroundColor: "#F9F9F9",
                          top: 13.5,
                          right: 0,
                        },
                        "&:last-child::after": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Tab
                      label={<span>Account Details</span>}
                      sx={{
                        color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                        width: "236px",

                        textTransform: "none",
                        height: "60px",
                        borderTopLeftRadius: "20px",
                        backgroundColor:
                          currentTab === 0 ? "#55985C" : "#4C8452",
                        paddingLeft: "40px",
                      }}
                    />
                    <Tab
                      label={<span>Accounts Associated{"\n"}to PAN</span>}
                      sx={{
                        color: currentTab === 1 ? "#F9F9F9" : "#F9F9F9",
                        width: "236px",
                        textTransform: "none",
                        height: "60px",
                        borderTopRightRadius: "20px",
                        paddingTop: "13px",

                        backgroundColor:
                          currentTab === 1 ? "#55985C" : "#4C8452",
                      }}
                    />
                  </Tabs>
                </div>
                {currentTab === 0 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-[20px] items-center justify-between"
                    style={{ height: "495px" }}
                  >
                    <div className="max-w-[471px] max-h-[390px] overflow-y-auto">
                      <div className="flex flex-col font-bold px-10">
                        <div className="text-base text-[#000000] w-full text-center py-[13px]">
                          <div
                            className="w-96"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: " nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                  textTransform: "capitalize",
                                },
                              }}
                              title={beneficiaryAccount?.name}
                              placement="bottom-start"
                            >
                              {beneficiaryAccount?.name
                                ? beneficiaryAccount?.name?.toLowerCase()
                                : ""}
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="w-full content-center">
                        <div className="mx-0 dashedLine"></div>
                      </div>
                      <div className="w-full px-5">
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={beneficiaryAccount.category}
                              placement="bottom-start"
                            >
                              <div className="font-normal text-base capitalize">
                                <div
                                  className="w-96 pt-2.5"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {typeof beneficiaryAccount.category ===
                                  "string"
                                    ? beneficiaryAccount.category
                                        .split(",")
                                        .map((category) => category.trim())
                                        .join(" , ")
                                    : beneficiaryAccount.category}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Assigned categories
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {beneficiaryAccount?.pan_no}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            PAN number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {formatAccountNumber(
                              beneficiaryAccount?.bank_acc_no
                            )}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Account number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount?.ifsc_code}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              IFSC code
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                              alt="bank"
                            />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              +91&nbsp;{beneficiaryAccount?.contact_no}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Contact number
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={ContactNumber} alt="Contact Number" />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={beneficiaryAccount?.email_id}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                className="text-base font-medium text-black antialiased"
                                style={{
                                  width: "50%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {beneficiaryAccount?.email_id}
                              </div>
                            </Tooltip>

                            <div className="text-gray-500 text-xs antialiased">
                              Email ID
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={Email} alt="Email" />
                          </div>
                        </div>
                        {beneficiaryAccount?.gstin_detail && (
                          <>
                            <div className="simpleLine bg-[#A0A0A0]"></div>
                            <div className="flex flex-row justify-between py-0.5">
                              <div className="flex flex-col space-y-1 text-left w-4/6">
                                <div className="text-base text-black antialiased">
                                  {beneficiaryAccount.gstin_detail}
                                </div>
                                <div className="text-gray-500 text-xs antialiased">
                                  GST
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : currentTab === 1 ? (
                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-4xl justify-between"
                    style={{ height: "495px" }}
                  >
                    {haveApproved || havePending ? (
                      <div className="max-w-[471px] max-h-[380px] overflow-y-auto pb-2.5">
                        {haveApproved && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-5 py-0.5">
                              Approved accounts
                            </p>
                            <div className="flex flex-col w-full px-5">
                              {beneLookData?.beneficiary_accounts.map(
                                (val, index, array) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  Bank account number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4">
                                              <img
                                                src={require("../../../../assets/IfscLogo/" +
                                                  IfscCodeMapper(
                                                    val?.ifsc_code
                                                  ))}
                                                alt="bank"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {index !== array.length - 1 && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          </>
                        )}
                        {havePending && (
                          <>
                            <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-5 py-0.5">
                              Pending accounts
                            </p>
                            <div className="flex flex-col w-full px-5 mt-3">
                              {beneLookData?.beneficiary_accounts
                                .filter(
                                  (val) =>
                                    val?.approval_status === "pending" &&
                                    val?.id !== id
                                )
                                .map((val, index, array) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="group flex flex-col drop-shadow-xl overflow-hidden"
                                        >
                                          <div
                                            className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  textTransform: "capitalize",
                                                },
                                              }}
                                              title={val?.name}
                                              placement="bottom-start"
                                            >
                                              {val?.name?.toLowerCase()}
                                            </Tooltip>
                                          </div>
                                          <div className="flex flex-row justify-between">
                                            <div className="flex flex-col space-y-1 text-left">
                                              <div className="text-base text-black antialiased">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <br />
                                                <span className="text-xs text-[#828282]">
                                                  Bank account number
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-end w-2/6 h-4">
                                              <img
                                                src={require("../../../../assets/IfscLogo/" +
                                                  IfscCodeMapper(
                                                    val?.ifsc_code
                                                  ))}
                                                alt="bank"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {array.filter(
                                          (acc) =>
                                            acc.approval_status === "pending"
                                        ).length -
                                          1 !==
                                          index && (
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        )}
                                      </>
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] flex flex-row justify-center">
                          <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="flex-1 mt-[66px] py-[35px]">
                <div
                  className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0  rounded-t-[20px] items-center ${bgColorClass}`}
                >
                  <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "10px",
                        color: "#F9F9F9",
                      },
                      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                        color: "#F9F9F9",
                      },
                      "& .MuiTabs-flexContainer": {
                        position: "relative",
                      },
                      "& .MuiTab-root": {
                        position: "relative",
                        zIndex: 1,
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          height: "33px",
                          width: "1px",
                          backgroundColor: "#F9F9F9",
                          top: 13.5,
                          right: 0,
                        },
                        "&:last-child::after": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Tab
                      label="Account Details"
                      sx={{
                        color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                        width: "472px",
                        textTransform: "none",
                        height: "60px",
                        borderTopLeftRadius: "20px",
                        backgroundColor:
                          currentTab === 0 ? "#55985C" : "#4C8452",
                        paddingLeft: "120px",
                      }}
                    />
                  </Tabs>
                </div>

                <div
                  className=" flex flex-col mx-5 bg-white rounded-b-[20px] items-center justify-between"
                  style={{ height: "495px" }}
                >
                  <div
                    className="w-full flex flex-col gap-2 items-center justify-center overflow-auto h-80"
                    style={{ height: "360px" }}
                  >
                    <div
                      className={`flex flex-col font-bold ${
                        beneficiaryAccount?.gstin_detail
                          ? "pt-[32%]"
                          : "pt-[20%]"
                      } px-7`}
                    >
                      <div className="text-base text-[#000000] w-full text-center p-[5px]">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                          title={beneficiaryAccount.name}
                          placement="bottom-start"
                        >
                          <div
                            className="w-96"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: " nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {beneficiaryAccount?.name
                              ? beneficiaryAccount?.name?.toLowerCase()
                              : ""}
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="w-full content-center">
                      <div className="mx-0 dashedLine"></div>
                    </div>
                    <div className="w-full px-5">
                      <div className="flex flex-col space-y-1 text-left">
                        <div className="text-base text-black antialiased">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            arrow
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                            title={beneficiaryAccount.category}
                            placement="bottom-start"
                          >
                            <div className="font-normal text-base capitalize">
                              <div
                                className="w-96 pt-2.5"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  textAlign: "start",
                                }}
                              >
                                {typeof beneficiaryAccount.category === "string"
                                  ? beneficiaryAccount.category
                                      .split(",")
                                      .map((category) => category.trim())
                                      .join(" , ")
                                  : beneficiaryAccount.category}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          Assigned categories
                        </div>
                      </div>
                      <div className="simpleLine bg-[#A0A0A0]"></div>
                      <div className="flex flex-col space-y-1 text-left">
                        <div className="text-base text-black antialiased">
                          {beneficiaryAccount?.pan_no}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          PAN number
                        </div>
                      </div>
                      <div className="simpleLine bg-[#A0A0A0]"></div>
                      <div className="flex flex-col space-y-1 text-left">
                        <div className="text-base text-black antialiased">
                          {formatAccountNumber(beneficiaryAccount?.bank_acc_no)}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          Account number
                        </div>
                      </div>
                      <div className="simpleLine bg-[#A0A0A0]"></div>
                      <div className="flex flex-row justify-between py-0.5">
                        <div className="flex flex-col space-y-1 text-left w-4/6">
                          <div className="text-base text-black antialiased">
                            {beneficiaryAccount?.ifsc_code}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            IFSC code
                          </div>
                        </div>
                        <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                          <img
                            src={require("../../../../assets/IfscLogo/" +
                              IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                            alt="bank"
                          />
                        </div>
                      </div>
                      <div className="simpleLine bg-[#A0A0A0]"></div>
                      <div className="flex flex-row justify-between py-0.5">
                        <div className="flex flex-col space-y-1 text-left w-4/6">
                          <div className="text-base text-black antialiased">
                            +91&nbsp;{beneficiaryAccount?.contact_no}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Contact number
                          </div>
                        </div>
                        <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                          <img src={ContactNumber} alt="Contact Number" />
                        </div>
                      </div>
                      <div className="simpleLine bg-[#A0A0A0]"></div>
                      <div className="flex flex-row justify-between py-0.5">
                        <div className="flex flex-col space-y-1 text-left w-4/6">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={beneficiaryAccount?.email_id}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <div
                              className="text-base font-medium text-black antialiased"
                              style={{
                                width: "50%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {beneficiaryAccount?.email_id}
                            </div>
                          </Tooltip>

                          <div className="text-gray-500 text-xs antialiased">
                            Email ID
                          </div>
                        </div>
                        <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                          <img src={Email} alt="Email" />
                        </div>
                      </div>
                      {beneficiaryAccount?.gstin_detail && (
                        <>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-row justify-between py-0.5">
                            <div className="flex flex-col space-y-1 text-left w-4/6">
                              <div className="text-base text-black antialiased">
                                {beneficiaryAccount.gstin_detail}
                              </div>
                              <div className="text-gray-500 text-xs antialiased">
                                GST
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ height: "135px" }}
                    className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[20px] bg-[#55985C] flex justify-evenly items-center gap-0"
                  >
                    <div
                      className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                      onClick={createTxn}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={createTransaction}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Create
                        <br />
                        transaction
                      </p>
                    </div>
                    <div
                      className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                      onClick={viewDetailsHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={viewDetails}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        View
                        <br />
                        details
                      </p>
                    </div>
                    <div
                      className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                      onClick={dashboardHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={dashboard}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Go to <br /> dashboard
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default BeneBankEditSuccess;
