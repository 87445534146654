import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import groupIcon from "../../../src/assets/Graphics/MyProfile.svg";
import ProfileIcon from "../../../src/assets/UserProfileIcons/ProfileIcon.svg";
import ChangePassword from "../../../src/assets/PasswordIcons/change-password – 1.svg";
import Role from "../../../src/assets/GeneralIcons/edit_roles.svg";
import Services from "../../../src/assets/GeneralIcons/edit_services.svg";
import Editprofile from "../../../src/assets/EditIcons/edit_with_white_circle.svg";
import Logout from "../../../src/assets/LogoutIcons/logout.svg";
import Authorizeddevices from "../../../src/assets/GeneralIcons/authorized_devices_with_white_circle.svg";
import close from "../../assets/CloseIcons/close.svg";
import EditPopUp from "../../components/MyProfile/EditPop-up";
import LogoutModel from "../../components/MyProfile/LogoutModel";
import LeftIcon from "../../assets/ArrowsIcons/arrow_left_white.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import {
  resetEmail,
  resetMobNum,
  resetAliasName,
} from "../../redux/features/users";
import { setHideBreadCrumb } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";

import { useDispatch, useSelector } from "react-redux";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLogoutModelOpen, setIsLogoutModelOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const refreshData = useSelector((state) => state.refreshToken.data);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const updateRefreshData = (newData) => {
    setUpdatedData(newData);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const closeProfile = () => {
    navigate("/home");
  };

  const handleLogout = () => {
    setIsLogoutModelOpen(true);
  };

  const closeLogoutModel = () => {
    setIsLogoutModelOpen(false);
  };

  const closeModal = () => {
    dispatch(checkUsertoken());
    dispatch(resetAliasName());
    dispatch(resetEmail());
    dispatch(resetMobNum());
    setIsEditModalOpen(false);
  };
  const handleToastClick = () => {
    toast.info("New Feature Coming Soon", toast_position);
  };

  return (
    <>
      <div className="main-container-profile">
        {isLogoutModelOpen && (
          <LogoutModel
            onClose={() => closeLogoutModel()}
            isLogoutModelOpen={isLogoutModelOpen}
          />
        )}
        {isEditModalOpen && (
          <EditPopUp
            onClose={closeModal}
            refreshData={refreshData}
            updateRefreshData={updateRefreshData}
            isEditModalOpen={isEditModalOpen}
          />
        )}

        {isMobileView && (
          <div className="upperDiv_responsive ">
            <div className="right-side-responsive pb-[20px] h-auto lg:h-[281px] ">
              <div>
                <div className=" flex items-center pb-3">
                  <img
                    onClick={closeProfile}
                    className="w-[35px] h-[35px] pl-4 cursor-pointer"
                    src={LeftIcon}
                  />
                  <p className=" text-[#ffffff] pl-5"> Profile</p>
                </div>
                <div className=" border border-b-1 opacity-50 w-auto border-[#1d3a6d] "></div>
                <div className="profile-icon pl-6 flex items-center gap-3 pb-2 pt-3">
                  <img src={ProfileIcon} />
                  <div className="w-[230px] ">
                    <Tooltip
                      arrow
                      placement="bottom"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "preventOverflow",
                            options: {
                              boundary: "viewport",
                            },
                          },
                        ],
                        style: {
                          maxWidth: "none",
                        },
                      }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                              color: "#FFFFFF",
                              fontSize: "14px",
                              padding: "8px",
                              borderRadius: "4px",
                            },
                            [`& .${tooltipClasses.arrow}`]: {
                              color: (theme) => theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      enterTouchDelay={0}
                      title={`${refreshData?.employees?.first_name} ${refreshData?.employees?.last_name}`}
                    >
                      <h1 className="font-semibold text-lg text-[#ffffff] truncate">{`${refreshData?.employees?.first_name} ${refreshData?.employees?.last_name}`}</h1>
                    </Tooltip>
                    <p className="horizontal-Line_responsive">Full name</p>
                  </div>
                </div>
                <div className=" border border-b-1 w-auto opacity-50 border-[#1d3a6d] "></div>

                <div className="Profile-title lg:w-full flex flex-col ">
                  <div className="upperContent flex flex-col lg:flex">
                    <div>
                      <h1>{refreshData?.employees?.user_id}</h1>
                      <p>User ID</p>
                    </div>
                    <div className=" border border-b-1 mt-3 opacity-50 border-[#1d3a6d] "></div>
                    <div className=" flex flex-col pt-3 lg:flex lg:pt-0">
                      <h1>+91 {refreshData?.employees?.mob_no}</h1>
                      <p>Contact number</p>
                    </div>
                  </div>
                  <div className=" border border-b-1 mt-3 opacity-50 border-[#1d3a6d] "></div>

                  <div className="middleContent flex flex-col lg:flex">
                    <div className="firstHalf overflow-hidden whitespace-nowrap overflow-ellipsis">
                      <span>
                        {refreshData?.employees?.alias_name
                          ? refreshData?.employees?.alias_name
                          : "Not Given"}
                      </span>
                      <p>Alias</p>

                      <div className=" border border-b-1 mt-3 opacity-50 border-[#1d3a6d] "></div>
                    </div>
                    <div className=" pt-3  lg:pt-0">
                      <h1>{refreshData?.employees?.grade}</h1>
                      <p>Grade</p>
                    </div>
                    <div className=" border border-b-1 mt-3 opacity-50 border-[#1d3a6d] "></div>
                  </div>

                  <div className="lastContent">
                    <Tooltip
                      sx={{ zIndex: "2147483647 !important" }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      enterTouchDelay={0}
                      title={refreshData?.employees?.email}
                    >
                      <p
                        style={{
                          color: "white",
                          paddingRight: "10px",
                        }}
                        id="email-id-firstLetter"
                        className="truncate w-[290px]"
                      >
                        {refreshData?.employees?.email}
                      </p>
                    </Tooltip>
                    <p>Email ID</p>
                  </div>
                </div>
              </div>
            </div>
            {!isMobileView && (
              <div className="left-side ">
                <img src={groupIcon} />
              </div>
            )}
          </div>
        )}

        {!isMobileView && (
          <div className="upperDiv">
            <div className="right-side">
              <div className="profile-icon flex">
                <img src={ProfileIcon} />
                <div className="Profile-title w-full">
                  <Tooltip
                    sx={{ zIndex: "2147483647 !important" }}
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                          zIndex: "2147483647 !important",
                        },
                      },
                    }}
                    title={`${refreshData?.employees?.first_name} ${refreshData?.employees?.last_name}`}
                  >
                    <h1 className="font-semibold text-lg truncate">{`${refreshData?.employees?.first_name} ${refreshData?.employees?.last_name}`}</h1>
                  </Tooltip>
                  <p className="horizontal-Line">Full name</p>
                  <div className="upperContent">
                    <div>
                      <h1>{refreshData?.employees?.user_id}</h1>
                      <p>User ID</p>
                    </div>
                    <div>
                      <h1>+91 {refreshData?.employees?.mob_no}</h1>
                      <p>Contact number</p>
                    </div>
                  </div>
                  <div className="middleContent flex">
                    <div className="firstHalf overflow-hidden whitespace-nowrap overflow-ellipsis">
                      <Tooltip
                        sx={{ zIndex: "2147483647 !important" }}
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title={
                          refreshData?.employees?.alias_name
                            ? refreshData?.employees?.alias_name
                            : "Not Given"
                        }
                      >
                        <span>
                          {refreshData?.employees?.alias_name
                            ? refreshData?.employees?.alias_name
                            : "Not Given"}
                        </span>
                      </Tooltip>
                    </div>
                    <div>
                      <h1>{refreshData?.employees?.grade}</h1>
                    </div>
                  </div>
                  <div className="secondHalf">
                    <p>Alias</p>
                    <p>Grade</p>
                  </div>
                  <div className="lastContent">
                    <Tooltip
                      sx={{ zIndex: "2147483647 !important" }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title={refreshData?.employees?.email}
                    >
                      <p
                        style={{
                          color: "white",
                        }}
                        id="email-id-firstLetter"
                        className="truncate"
                      >
                        {refreshData?.employees?.email}
                      </p>
                    </Tooltip>
                    <p>Email ID</p>
                  </div>
                </div>
              </div>
            </div>
            {!isMobileView && (
              <div className="left-side ">
                <img src={groupIcon} />
              </div>
            )}
          </div>
        )}

        <div className="bottomDiv flex flex-col lg:flex-row ">
          <div className="verifield mb-[30px] mx-3 lg:w-[328px]    ">
            <div className="ver-detail    ">
              <h1>Verified Details</h1>
              <div className="detail-css ">
                <span>{refreshData?.entity?.entity_pan}</span>
                <p>PAN number</p>
              </div>
              <div className="legalName w-[280px] md:w-[400px] lg:w-[328px]">
                <Tooltip
                  sx={{ zIndex: "2147483647 !important" }}
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title={refreshData?.entity?.entity_name}
                >
                  <span className="truncate">
                    {refreshData?.entity?.entity_name}
                  </span>
                </Tooltip>
                <p>Legal name</p>
              </div>
              <div className="GstNum">
                <span>Not Given</span>
                <p>GST number</p>
              </div>
            </div>
          </div>
          <div className="verifield mb-[30px] mx-3 lg:w-[328px]  ">
            <div className="ver-detail ">
              <h1>Company Details</h1>
              <div className="detail-css">
                <span>Not Given</span>
                <p>Company name </p>
              </div>

              <div className="legalName lg:w-[328px] ">
                <span>Not Given</span>
                <p>CIN number</p>
              </div>
              <div className="GstNum">
                <span>Not Given</span>
                <p>DIN number</p>
              </div>
            </div>
          </div>

          {isMobileView && (
            <div className="verifield_responsive mb-[30px] mx-3   ">
              <div className="profileAction_responsive  lg:w-[328px] ">
                <h1>Quick Actions</h1>
                <div className="UpperSection_responsive flex py-[5px]  ">
                  <div
                    className="changePassword   hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={ChangePassword} />
                    <p>
                      Change <br /> password
                    </p>
                  </div>
                  <div
                    className="Logout hover:bg-custom-cardHover"
                    onClick={handleLogout}
                  >
                    <img src={Logout} />
                    <p>Logout</p>
                  </div>
                  <div
                    className="EditProfile hover:bg-custom-cardHover"
                    onClick={handleEdit}
                  >
                    <img src={Editprofile} />
                    <p>
                      Edit <br /> profile
                    </p>
                  </div>
                </div>
                <div
                  className={`LowerSection ${
                    isMobileView ? "pb-[10px]" : "pb-[10px]"
                  }  flex`}
                >
                  <div
                    className="changePassword hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Role} />
                    <p>Role</p>
                  </div>
                  <div
                    className="Logout hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Services} />
                    <p>Services</p>
                  </div>
                  <div
                    className="EditProfile hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Authorizeddevices} />
                    <p>
                      Authorized <br />
                      devices
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isMobileView && (
            <div className="verifield mb-[30px] mx-3   ">
              <div className="profileAction lg:w-[328px] ">
                <h1>Quick Actions</h1>
                <div className="UpperSection_responsive flex py-[5px]  ">
                  <div
                    className="changePassword   hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={ChangePassword} />
                    <p>
                      Change <br /> password
                    </p>
                  </div>
                  <div
                    className="Logout hover:bg-custom-cardHover"
                    onClick={handleLogout}
                  >
                    <img src={Logout} />
                    <p>Logout</p>
                  </div>
                  <div
                    className="EditProfile hover:bg-custom-cardHover"
                    onClick={handleEdit}
                  >
                    <img src={Editprofile} />
                    <p>
                      Edit <br /> profile
                    </p>
                  </div>
                </div>
                <div
                  className={`LowerSection ${
                    isMobileView ? "pb-[10px]" : "pb-[10px]"
                  }  flex`}
                >
                  <div
                    className="changePassword hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Role} />
                    <p>Role</p>
                  </div>
                  <div
                    className="Logout hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Services} />
                    <p>Services</p>
                  </div>
                  <div
                    className="EditProfile hover:bg-custom-cardHover"
                    onClick={handleToastClick}
                  >
                    <img src={Authorizeddevices} />
                    <p>
                      Authorized <br />
                      devices
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyProfile;
