import React, { useEffect, useState } from "react";
import "./index.css";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../../utils/validation";
import { resetAliasName, updateEmail } from "../../../redux/features/users";
import { updateMobNo } from "../../../redux/features/users";
import { updateAlias } from "../../../redux/features/users";
import { checkUsertoken } from "../../../redux/features/login/login";
import checkMark from "../../../assets/CreateTransactionIcons/checkmark.png";
import invalid from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import inputLoader from "../../../assets/Beneficiary Icons/inputloder.gif";
import { getRefreshTokenNew } from "../../../redux/features/refreshToken/refreshToken";
import { getSpecificUser } from "../../../redux/features/Settings/Management/Users";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { set } from "lodash";

const EditPopUp = ({
  refreshData,
  onClose,
  updateRefreshData,
  isEditModalOpen,
  id,
}) => {
  const dispatch = useDispatch();
  const [isblur, setisblur] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(true);
  const [nameValidation, setNameValidation] = useState(true);
  const [emailValidation, setEmailValidation] = useState(true);
  const [aliasName, setAliasName] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [focusState, setFocusState] = useState({});
  const [aliasUpdateDisabled, setAliasUpdateDisabled] = useState(true);
  const [emailUpdateDisabled, setEmailUpdateDisabled] = useState(true);
  const [mobNoUpdateDisabled, setMobNoUpdateDisabled] = useState(true);

  const [aliasEdited, setAliasEdited] = useState(false);
  const [emailEdited, setEmailEdited] = useState(false);
  const [mobNoEdited, setMobNoEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isEmailSuccess, setIsEmailSuccess] = useState(false);
  const aliasIsLoading = useSelector((state) => state.user.aliasIsLoading);
  const aliasError = useSelector((state) => state.user.aliasError);
  const aliasData = useSelector((state) => state.user.aliasData);

  const emailIsLoading = useSelector((state) => state.user.emailIsLoading);
  const emailError = useSelector((state) => state.user.emailError);
  const emailData = useSelector((state) => state.user.emailData);
  const [isMobNoLoading, setIsMobNoLoading] = useState(false);
  const [isMobNoError, setIsMobNoError] = useState(false);
  const [isMobNoSuccess, setIsMobNoSuccess] = useState(false);
  const mobNoIsLoading = useSelector((state) => state.user.mobNoIsLoading);
  const mobNoError = useSelector((state) => state.user.mobNoError);
  const mobNoData = useSelector((state) => state.user.mobNoData);

  const [customErrors, setCustomErrors] = useState({});

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const aliaNameRegex = /^[a-zA-Z0-9-_]+$/;

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  useEffect(() => {
    if (refreshData?.employees) {
      setAliasName(refreshData.employees.alias_name);
      setEmail(refreshData.employees.email);
      setMobNo(refreshData.employees.mob_no);
      setMobNoUpdateDisabled(true);
      setEmailUpdateDisabled(true);
      setAliasUpdateDisabled(true);
    }
  }, [refreshData]);
  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isEditModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const updateMob = async () => {
    setIsMobNoLoading(true);
    setIsMobNoError(false);
    setIsMobNoSuccess(false);

    try {
      await dispatch(checkUsertoken());

      const response = await dispatch(
        updateMobNo({
          id: refreshData?.employees?.id,
          mob_no: mobNo,
        })
      );

      if (response?.payload?.status === true) {
        await dispatch(getSpecificUser(id));

        updateRefreshData({
          ...refreshData,
          employees: { ...refreshData.employees, mob_no: mobNo },
        });

        setIsMobNoLoading(false);
        setIsMobNoSuccess(true);
      } else {
        setIsMobNoLoading(false);
        setIsMobNoError(true);
      }
    } catch (error) {
      setIsMobNoLoading(false);
      setIsMobNoError(true);
    } finally {
      setMobNoUpdateDisabled(true);
    }
  };

  const updateEmailId = async () => {
    setIsEmailLoading(true);
    setIsEmailError(false);
    setIsEmailSuccess(false);
    setEmailEdited(false);

    try {
      await dispatch(checkUsertoken());

      const response = await dispatch(
        updateEmail({
          id: refreshData?.employees?.id,
          email: email,
        })
      );

      if (response?.payload?.status === true) {
        await dispatch(checkUsertoken());
        await dispatch(getSpecificUser(id));

        updateRefreshData({
          ...refreshData,
          employees: { ...refreshData.employees, email: email },
        });

        setIsEmailLoading(false);
        setIsEmailSuccess(true);
        // setEmailEdited(true);
      } else {
        setIsEmailLoading(false);
        setIsEmailError(true);
        setIsEmailSuccess(false);
      }
    } catch (error) {
      setIsEmailLoading(false);
      setIsEmailError(true);
      setIsEmailSuccess(false);
    } finally {
      setEmailUpdateDisabled(true);
    }
  };

  const startEditing = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setHasError(false);
    setAliasUpdateDisabled(false);
  };

  const updateAliasName = async () => {
    setIsLoading(true);
    setHasError(false);
    setIsSuccess(false);
    setAliasUpdateDisabled(false);

    try {
      await dispatch(checkUsertoken());

      const response = await dispatch(
        updateAlias({
          id: refreshData?.employees?.id,
          alias_name: aliasName,
        })
      );

      if (response?.payload?.status === true) {
        await dispatch(checkUsertoken());
        await dispatch(getSpecificUser(id));

        updateRefreshData({
          ...refreshData,
          employees: { ...refreshData.employees, alias_name: aliasName },
        });

        setIsLoading(false);
        setIsSuccess(true);
      } else {
        setIsLoading(false);
        setHasError(true);
        setIsSuccess(false);
      }
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      setIsSuccess(false);
    } finally {
      setAliasUpdateDisabled(true);
    }
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation?.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation?.validateContactNum(name, value, errors);
    if (name === "alias_name")
      errors = Validation?.validateName(name, value, errors);
    setCustomErrors(errors);
  };

  useEffect(() => {
    if (mobNo.length <= 9) {
      setMobNoUpdateDisabled(true);
    } else if (mobNo !== refreshData?.employees?.mob_no) {
      setMobNoUpdateDisabled(false);
    } else {
      setMobNoUpdateDisabled(true);
    }
  }, [mobNo, refreshData]);

  useEffect(() => {
    if (aliasName === refreshData?.employees?.alias_name) {
      setAliasUpdateDisabled(true);
      setNameValidation(true);
    } else if (aliasName?.length <= 3) {
      setAliasUpdateDisabled(true);
      setNameValidation(false);
    } else if (hasError) {
      setNameValidation(false);
    }
  }, [aliasName, hasError, refreshData]);

  useEffect(() => {
    if (email === refreshData?.employees?.email) {
      setEmailUpdateDisabled(true);
    }
  }, [email, refreshData]);

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g1, g2) {
        return g1?.toUpperCase() + g2?.toLowerCase();
      });
    }
    return value.trim();
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation?.allowedContactNum(value);
    if (name === "alias_name") return Validation?.allowedName(value);
    if (name === "email_id") return Validation?.allowedEmail(value);
    return true;
  };

  const handleUpdate = () => {
    dispatch(getRefreshTokenNew());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let val = value?.replaceAll(" ", "");
    const newValue = modifyValue(name, val);

    if (allowedValue(name, val)) {
      validateAndRecordError(name, newValue);

      // Handle contact number
      if (name === "contact_no") {
        setMobNo(newValue);

        // Reset specific states for contact number
        setIsMobNoSuccess(false);
        setIsMobNoError(false);
        setIsMobNoLoading(false);

        // Reset button to default state
        setMobNoUpdateDisabled(false);

        if (newValue?.length === 0 || newValue?.length < 10) {
          setContactNumberValidation(false);
          setMobNoUpdateDisabled(true);
        } else {
          setContactNumberValidation(true);
        }
      }

      // Handle email
      if (name === "email_id") {
        setEmail(newValue);

        setIsEmailSuccess(false);
        setIsEmailError(false);
        setIsEmailLoading(false);

        setEmailUpdateDisabled(false);

        if (newValue?.length === 0 || !emailRegex?.test(newValue)) {
          setEmailValidation(false);
          setEmailUpdateDisabled(true);
        } else if (!emailRegex.test(value)) {
          setEmailValidation(true);
        } else {
          setEmailValidation(true);
        }
      }

      if (name === "alias_name") {
        const regex = /^[a-zA-Z0-9-_]+$/;
        let sanitizedValue = newValue;

        if (!regex.test(newValue)) {
          sanitizedValue = newValue.replace(/[^a-zA-Z0-9-_]/g, "");
        }

        setAliasName(sanitizedValue);

        setIsSuccess(false);
        setHasError(false);
        setIsLoading(false);

        setAliasUpdateDisabled(false);

        if (!aliaNameRegex.test(sanitizedValue)) {
          setNameValidation(false);
          setAliasUpdateDisabled(true);
        } else {
          if (
            sanitizedValue !== refreshData?.employees?.alias_name ||
            aliasData
          ) {
            setAliasUpdateDisabled(sanitizedValue.length <= 2);
          } else {
            setAliasUpdateDisabled(true);
          }
          setNameValidation(true);
        }
      }
    }
  };

  const getAliasInputElementDiv = (name, status, label, value, maxLength) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };
    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    const handlePaste = (e) => {
      e.preventDefault();
    };

    return (
      <div className="flex flex-row items-center">
        <TextField
          className={`w-full antialiased text-xl ${
            isMobile ? "w-auto" : "w-[405px]"
          }`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "405px",
            borderRadius: "10px",
            height: "35px",
            "@media (max-width: 1024px)": {
              width: "264px",
            },
            "@media (max-width: 400px)": {
              width: "216px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& label": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
                borderRadius: "10px !important",
                padding: "0px 0px 0px 8px !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: {
              color: "#F9F9F9",
              borderRadius: "10px !important",
              padding: "7.5px 14px !important",
            },
            maxLength: maxLength,
            onPaste: handlePaste,
            disabled: aliasEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getContactInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="flex flex-row items-center self-center  text-center gap-4">
        <TextField
          className={`antialiased text-xl ${isMobile ? "w-auto" : "w-[405px]"}`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "405px",
            height: "35px",
            borderRadius: "10px",
            "@media (max-width: 1024px)": {
              width: "264px",
            },
            "@media (max-width: 400px)": {
              width: "216px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& label": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
                borderRadius: "10px !important",

                padding: "0px 0px 0px 8px !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: {
              color: "#f9f9f9",
              borderRadius: "10px",
            },
            maxLength: maxLength,
            disabled: mobNoEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            startAdornment: name === "contact_no" && (
              <InputAdornment position="start">
                <div className="text-[#f9f9f9]">+91</div>
              </InputAdornment>
            ),
          }}
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getEmailInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="flex flex-row items-center self-center text-center gap-4">
        <TextField
          className={`antialiased text-xl ${isMobile ? "w-auto" : "w-[405px]"}`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "405px",
            height: "35px",
            borderRadius: "10px",
            "@media (max-width: 1024px)": {
              width: "264px",
            },
            "@media (max-width: 400px)": {
              width: "216px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& label": {
              color: color,
              fontSize: "12px !important",
              fontWeight: "400",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
                borderRadius: "10px !important",
                padding: "0px 0px 0px 8px !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: {
              color: "#F9F9F9",
              borderRadius: "10px",
              padding: "7.5px 14px !important",
            },
            maxLength: maxLength,
            disabled: emailEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={(e) => {
            if (e.target.value != " ") {
              handleChange(e);
            }
          }}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className="fixed w-screen top-0 left-0 h-screen z-[9999]  bg-black bg-opacity-80 overflow-hidden">
          <div
            className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <div
              className="h-fit w-70% lg:w-[565px]  flex flex-col bg-black gap-6 rounded-2xl"
              style={{
                width: "auto",
                height: "260px",
                backgroundColor: "#102C5D",
                padding: "20px",
              }}
            >
              <div className="flex">
                <h6
                  className=" text-custom-orange  w-[94%] pb-1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Edit Profile
                </h6>
                <IconButton
                  onClick={() => {
                    onClose();
                    handleUpdate();
                  }}
                  style={{
                    marginTop: "4px",
                    backgroundColor: "#031F4F",
                    borderRadius: "5px",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <Close className="text-white hover:bg-[#667CA3] hover:rounded-[5px] hover:w-[30px] hover:h-[30px]" />
                </IconButton>
              </div>
              <div className="flex  ">
                {getAliasInputElementDiv(
                  "alias_name",
                  nameValidation,
                  "Alias",
                  aliasName,
                  50
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!aliasUpdateDisabled) {
                      startEditing();
                      updateAliasName();
                    }
                  }}
                  disabled={aliasUpdateDisabled}
                  style={{
                    backgroundColor: "#102C5D",
                    cursor: aliasUpdateDisabled ? "default" : "pointer",
                  }}
                >
                  {isLoading && (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  )}

                  {isSuccess && !hasError && !isLoading && (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  )}

                  {hasError && !isLoading && (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  )}

                  {!isLoading && !isSuccess && !hasError && (
                    <div
                      className={`${
                        aliasUpdateDisabled ? "defaultButton" : "UpdateButton"
                      }`}
                    >
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex ">
                {getEmailInputElementDiv(
                  "email_id",
                  emailValidation,
                  "Email ID",
                  email,
                  50
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!emailUpdateDisabled) {
                      updateEmailId();
                    }
                  }}
                  disabled={emailUpdateDisabled}
                  style={{
                    backgroundColor: "#102C5D",
                    cursor: emailUpdateDisabled ? "default" : "pointer",
                  }}
                >
                  {isEmailLoading && (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  )}

                  {isEmailSuccess && !isEmailError && !isEmailLoading && (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  )}

                  {isEmailError && !isEmailLoading && (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  )}

                  {!isEmailLoading && !isEmailSuccess && !isEmailError && (
                    <div
                      className={`${
                        emailUpdateDisabled ? "defaultButton" : "UpdateButton"
                      }`}
                    >
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex ">
                {getContactInputElementDiv(
                  "contact_no",
                  contactNumberValidation,
                  "Contact number",
                  mobNo,
                  10
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!mobNoUpdateDisabled) {
                      updateMob();
                    }
                  }}
                  disabled={mobNoUpdateDisabled}
                  style={{
                    backgroundColor: "#102C5D",
                    cursor: mobNoUpdateDisabled ? "default" : "pointer",
                  }}
                >
                  {isMobNoLoading && (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  )}

                  {isMobNoSuccess && !isMobNoError && !isMobNoLoading && (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  )}

                  {isMobNoError && !isMobNoLoading && (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  )}

                  {!isMobNoLoading && !isMobNoSuccess && !isMobNoError && (
                    <div
                      className={`${
                        mobNoUpdateDisabled ? "defaultButton" : "UpdateButton"
                      }`}
                    >
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <>
          <div
            className="fixed  w-screen top-0 left-0 h-screen z-[9999] bg-black bg-opacity-80 overflow-hidden"
            style={{
              overflowY: isEditModalOpen ? "hidden" : "scroll",
            }}
          >
            <div
              className="flex justify-center relative items-center  m-auto top-1/2 left-1/2  z-[9999] p-3"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              <div
                className="flex flex-col gap-6 bg-black rounded-2xl"
                style={{
                  padding: "20px 15px",
                  backgroundColor: "#102C5D",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <div className="flex justify-between">
                  <h6
                    className={`text-custom-orange ${
                      isMobile ? "w-[100px] whitespace-nowrap" : "w-[94%]"
                    } pb-1 font-[600]  w-full lg:w-[94%]`}
                  >
                    Edit Profile
                  </h6>
                  <IconButton
                    onClick={() => {
                      onClose();
                      handleUpdate();
                    }}
                    className=" "
                    style={{
                      marginTop: "4px",
                      backgroundColor: "#031F4F",
                      borderRadius: "5px",
                      height: "22px",
                      width: "22px",
                    }}
                  >
                    <Close className="text-white hover:bg-[#667CA3] hover:rounded-lg" />
                  </IconButton>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getAliasInputElementDiv(
                    "alias_name",
                    nameValidation,
                    "Alias",
                    aliasName,
                    50
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!aliasUpdateDisabled) {
                        updateAliasName();
                      }
                    }}
                    disabled={aliasUpdateDisabled}
                    style={{
                      backgroundColor: "#102C5D",

                      cursor: aliasUpdateDisabled ? "default" : "pointer",
                    }}
                  >
                    {isLoading && (
                      <div className="loadingImg">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    )}

                    {isSuccess && !hasError && !isLoading && (
                      <div className="successImg">
                        <img src={checkMark} alt="Success" />
                      </div>
                    )}

                    {hasError && !isLoading && (
                      <div className="failImg">
                        <img src={invalid} alt="Error" />
                      </div>
                    )}

                    {!isLoading && !isSuccess && !hasError && (
                      <div
                        className={`${
                          aliasUpdateDisabled ? "defaultButton" : "UpdateButton"
                        }`}
                      >
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getEmailInputElementDiv(
                    "email_id",
                    emailValidation,
                    "Email-ID",
                    email,
                    50
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!emailUpdateDisabled) {
                        updateEmailId();
                      }
                    }}
                    disabled={emailUpdateDisabled}
                    style={{
                      backgroundColor: "#102C5D",
                      cursor: emailUpdateDisabled ? "default" : "pointer",
                    }}
                  >
                    {isEmailLoading && (
                      <div className="loadingImg">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    )}

                    {isEmailSuccess && !isEmailError && !isEmailLoading && (
                      <div className="successImg">
                        <img src={checkMark} alt="Success" />
                      </div>
                    )}

                    {isEmailError && !isEmailLoading && (
                      <div className="failImg">
                        <img src={invalid} alt="Error" />
                      </div>
                    )}

                    {!isEmailLoading && !isEmailSuccess && !isEmailError && (
                      <div
                        className={`${
                          emailUpdateDisabled ? "defaultButton" : "UpdateButton"
                        }`}
                      >
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getContactInputElementDiv(
                    "contact_no",
                    contactNumberValidation,
                    "Contact number",
                    mobNo,
                    10
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!mobNoUpdateDisabled) {
                        updateMob();
                      }
                    }}
                    disabled={mobNoUpdateDisabled}
                    style={{
                      backgroundColor: "#102C5D",
                      cursor: mobNoUpdateDisabled ? "default" : "pointer",
                    }}
                  >
                    {isMobNoLoading && (
                      <div className="loadingImg">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    )}

                    {isMobNoSuccess && !isMobNoError && !isMobNoLoading && (
                      <div className="successImg">
                        <img src={checkMark} alt="Success" />
                      </div>
                    )}

                    {isMobNoError && !isMobNoLoading && (
                      <div className="failImg">
                        <img src={invalid} alt="Error" />
                      </div>
                    )}

                    {!isMobNoLoading && !isMobNoSuccess && !isMobNoError && (
                      <div
                        className={`${
                          mobNoUpdateDisabled ? "defaultButton" : "UpdateButton"
                        }`}
                      >
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditPopUp;
