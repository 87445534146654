import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import arrowIcon from "../../../assets/TransactionDashboardIcons/arrow_next.png";
import { useSelector } from "react-redux";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";

const MenuItemCard = ({ icon, label }) => {
  const services_list = useSelector((state) => state.refreshToken.services);
  const [showExpandArrow, setShowExpandArrow] = useState(false);

  const isEnabled = checkIfEnable(ServiceIDByLabel, label, services_list);

  return (
    <Box
      onMouseLeave={() => setShowExpandArrow(false)}
      onMouseEnter={() => setShowExpandArrow(true)}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "20px",
        flexGrow: 1,
        backgroundColor: isEnabled ? "#FFFFFF" : "#F8F8F8",
        "&:hover": isEnabled && {
          backgroundColor: "#F0F0F0",
          cursor: "pointer",
        },
        width: "340px",
        height: "76px",
        color: "#202020",
        opacity: isEnabled ? "1" : "0.5",
        textAlign: "left",
        fontSize: "16px",
        pointerEvents: isEnabled ? "auto" : "none",
      }}
    >
      <Grid container alignItems="center" className="justify-around">
        <Grid item xs={2} display="flex" justifyContent="center">
          {icon && (
            <img
              width={"50px"}
              height={"50px"}
              src={require("../../../assets/TransactionDashboardIcons/" + icon)}
              alt=""
            />
          )}
        </Grid>
        <Grid item xs={7} display="flex" justifyContent="left" className="pl-3">
          {label}
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          {showExpandArrow && (
            <img
              style={{
                width: "24px",
                height: "24px",
                background: "#C95E81",
                borderRadius: "12px",
                opacity: 1,
                marginRight: "18px",
              }}
              src={arrowIcon}
              alt="arrowIcon"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MenuItemCard;
