import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.css";

const BenePanEditModal = ({
  message2,
  message,
  id,
  close,
  internalAccount,
}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (internalAccount === "internalAccount") {
      navigate(`/accounts/internal_account/details/${id}`);
    } else {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    }
  };

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-black opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-70% flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl"
          style={{ width: "560px", maxWidth: "600px" }}
        >
          <div className="mx-4">
            <h6 className="text-custom-orange pb-1 md:font-bold">{message2}</h6>
            <div className="flex items-center pb-2"></div>
            <div className="mb-3">
              <span
                className="text-white text-sm"
                style={{ wordWrap: "break-word" }}
              >
                {message}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                margin: "0px 50px",
                width: "100%",
              }}
              className="px-4 pt-4 m-4"
            >
              <Button
                onClick={() => {
                  close(false);
                }}
                sx={{
                  borderColor: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: "20px",
                  color: "#fff !important",
                  boxShadow: "0px 3px 6px #00000033",
                  textTransform: "capitalize",
                  backgroundColor: "#69B76F",
                  border: "1px solid transparent",
                  "&:hover": {
                    backgroundColor: "#69B76F",
                  },
                  marginRight: "30px",
                  width: "110px",
                }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenePanEditModal;
