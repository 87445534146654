import React, { useState, useEffect, useRef } from "react";
import arrow_down_red from "../../../../src/assets/ArrowsIcons/arrow_down_white_filled.svg";
import allChecker from "../../../../src/assets/CheckmarksIcons/check_mark_white.svg"; // Import the checkmark icon
import "./index.css";

export const ActionDrpDown = ({
  ddlValue,
  optionalLabel,
  isFieldRequired,
  fieldname,
  handleInputChange,
  data_type,
  type,
  setShowDatePicker,
  value,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const handleSelect = (val, fieldName, data_type) => {
    if (type === "checkbox") {
      const currentSelections = value === "Select" ? [] : value;

      let store = currentSelections.includes(val)
        ? currentSelections.filter((item) => item !== val)
        : [...currentSelections, val];

      handleInputChange({
        target: {
          name: fieldName,
          value: store?.length === 0 ? "" : store,
          data_typeChan: data_type,
        },
      });
    } else {
      setdropdownOpen(false);
      handleInputChange({
        target: {
          name: fieldName,
          value: data_type === "year" ? val?.toString() : val,
          data_typeChan: data_type,
        },
      });
    }
  };

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setdropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-[100%] mt-[5px]" ref={dropdownRef}>
      <div
        className="headerText"
        id="uploadId"
        style={{
          color:
            value === "Select" || value === "" || value?.length === 0
              ? "#F9F9F9"
              : value === "Not Given"
              ? "#F9F9F9"
              : "#F9F9F9",
        }}
      >
        {`${fieldname}${isFieldRequired ? "*" : ""}${optionalLabel}`}
      </div>
      <div
        className={`selectDrp shadow-lg ${dropdownOpen ? "open" : ""}`}
        onClick={() => {
          setShowDatePicker(false);
          setdropdownOpen(!dropdownOpen);
        }}
      >
        <div className="selectDrp_select">
          {type === "checkbox" ? (
            <span
              style={{
                color: value === "Select" ? "#DADADA" : "#f9f9f9",
                textTransform: "capitalize !important",
              }}
            >
              {value === "Select" ? "Select" : `${value?.length} selected`}
            </span>
          ) : (
            <span
              style={{
                color: value === "Select" ? "#DADADA" : "#f9f9f9",
              }}
            >
              {value === "Not Given" ? "Select" : value}
            </span>
          )}

          <img
            src={arrow_down_red}
            alt="down"
            style={{
              transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        <div
          className="selectDrp_scroll_line"
          style={{ display: dropdownOpen ? "block" : "none" }}
        ></div>

        {dropdownOpen && (
          <div className="scroll_content shadow-lg cursor-pointer">
            {type ? (
              type === "checkbox" ? (
                <ul>
                  {ddlValue.map((val, index) => (
                    <li
                      key={`rd${index}`}
                      className={`category-wrapper checkbox ${
                        value.includes(val) ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(val, fieldname, data_type);
                      }}
                    >
                      <input
                        type={type}
                        name={`val${index}`}
                        checked={value.includes(val)}
                        onChange={() => handleSelect(val, fieldname, data_type)}
                        required={isFieldRequired}
                      />
                      <label>{val}</label>
                      {value.includes(val) && (
                        <img
                          src={allChecker}
                          alt="Checkmark"
                          className="all-txn-status-icon_acc"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {ddlValue.map((val, index) => (
                    <li
                      key={`rd${index}`}
                      className={`category-wrapper ${
                        value === val ? "selected" : ""
                      }`}
                      onClick={() => handleSelect(val, fieldname, data_type)}
                    >
                      <label>{val}</label>
                      {value === val && (
                        <img
                          src={allChecker}
                          alt="Checkmark"
                          className="all-txn-status-icon_acc"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              )
            ) : (
              <>
                {/* {!isFieldRequired && (
                  <div
                    key={"none"}
                    className="category-wrapper"
                    onClick={() => handleSelect("", fieldname, data_type)}
                  >
                    {"none"}
                  </div>
                )} */}
                {ddlValue.map((val, index) => (
                  <div
                    key={`rd${index}`}
                    className={`category-wrapper ${
                      value === val ? "selected" : ""
                    }`}
                    onClick={() => handleSelect(val, fieldname, data_type)}
                  >
                    {val}
                    {value === val && (
                      <img
                        src={allChecker}
                        alt="Checkmark"
                        className="all-txn-status-icon_acc"
                      />
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionDrpDown;
