import React, { useEffect, useState } from "react";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";
import refresh_balance from "../../../../assets/RejectIcons/refresh_balance.svg";
import arrowRight from "../../../../assets/ArrowsIcons/arrow_right_white.svg";
import arrowRightWhite from "../../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import {
  verifyThisPANGet,
  selectedPan,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneVerifyThisGST } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import formatGSTDate from "../../../../utils/formatGSTDate";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import gst_verification_blue from "../../../../assets/Settings/Verification/gst_verification_blue.svg";
import whiteLoader from "../../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import { toast, Bounce } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";

const SettingGSTDetails = ({ setGstNotFound, hideSearch }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z0-9]{1}$/;

  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  const toast_position_fectching = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "229px",
      height: "49px",
      borderRadius: "10px",
      color: "#F9F9F9",
      fontSize: "14px",
    },
  };

  const handleUpdateDetails = async () => {
    const paragraphStyle = {
      width: "161px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#F9F9F9",
      Opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };
    const imageStyle = {
      width: "20px",
      height: "20px",
      Opacity: 1,
    };
    toast(
      <div style={divStyle} className="approving-toast-beneTxn">
        <img src={dataLoading} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Fetching details</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: window.innerWidth > 600 ? "229px" : window.innerWidth + "px",
          height: "49px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    const response = await dispatch(
      beneVerifyThisGST({
        search_string: beneVerifyGST?.gstin_details?.gstin_no,
      })
    );

    setTimeout(() => {
      if (response?.payload?.status === true) {
        toast.success("Updated successfully", toast_position_fectching);
      } else {
        toast.error("Failed to update", toast_position_fectching);
      }
    }, 2000);
  };

  return (
    <div className="gst-main-div">
      {loading && !beneVerifyGST ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <>
          <div
            className={`div-fixed-setting-gst border-t border-solid border-[#1D3A6D] ${
              hideSearch ? "pt-[6rem]" : ""
            }`}
          >
            <div className="profile-setting">
              <div className="flex flex-col w-full h-auto">
                <div className="flex flex-row justify-between h-[90px] border-b border-solid border-[#1D3A6D]">
                  <div className="flex flex-row w-full h-full">
                    <div className="flex flex-col w-full py-6 pr-5 gap-y-1">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={beneVerifyGST?.gstin_details?.business_name.toLowerCase()}
                        arrow
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <p className="w-[800px] text-base text-[#F9F9F9] capitalize font-sans font-normal leading-[21.79px] truncate">
                          {beneVerifyGST?.gstin_details?.business_name.toLowerCase()}
                        </p>
                      </Tooltip>
                      <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                        Company name
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div
                      className="flex flex-col justify-center items-center gap-y-1 w-[102px] bg-[#506994] cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                      onClick={
                        //   () => {
                        // dispatch(
                        //   beneVerifyThisGST({
                        //     search_string:
                        //       beneVerifyGST?.gstin_details?.gstin_no,
                        //   })
                        // );
                        //   }
                        handleUpdateDetails
                      }
                    >
                      <img
                        className="w-7 h-7"
                        src={refresh_balance}
                        alt="cin"
                      />
                      <p className="text-[10px] text-[#F9F9F9] text-center font-sans font-normal leading-[13.62px]">
                        Update fetched details
                      </p>
                    </div>
                    <div className="flex justify-center items-center w-[102px] bg-[#F9F9F9]">
                      <img
                        className="gst-recipient-img"
                        src={gst_verification_blue}
                        alt="document"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row h-[90px] border-b border-solid border-[#1D3A6D]">
                  <div
                    className="flex flex-col py-6 gap-y-1"
                    style={{ width: "273.33px" }}
                  >
                    <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px]">
                      {beneVerifyGST?.gstin_details?.gstin_no}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      GST no.
                    </p>
                  </div>
                  <div className="my-4 border border-solid border-[#1D3A6D]"></div>
                  <div
                    className="ml-5 py-5"
                    style={{
                      width: "273.33px",
                    }}
                  >
                    <div
                      className="p-1 w-[141px] cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-lg"
                      onClick={() => {
                        dispatch(checkUsertoken());
                        dispatch(
                          selectedPan(beneVerifyGST?.gstin_details?.pan_no)
                        );
                        dispatch(
                          verifyThisPANGet({
                            search_string: beneVerifyGST?.gstin_details?.pan_no,
                          })
                        );

                        navigate(`/settings/PAN_Verification`, {
                          state: beneVerifyGST?.gstin_details?.pan_no,
                        });
                      }}
                    >
                      <div className="flex flex-col gap-y-1">
                        <div className="flex flex-row">
                          <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px] underline">
                            {beneVerifyGST?.gstin_details?.pan_no}
                          </p>
                          <div className="grid place-content-center w-5 h-5 rounded-[3px] ml-3 bg-[#1D3A6D]">
                            <img
                              src={arrowRightWhite}
                              className="w-2.5 h-2.5"
                            />
                          </div>
                        </div>
                        <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                          PAN number
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="my-4 border border-solid border-[#1D3A6D]"></div>
                  <div
                    className="flex flex-col ml-5 py-6 gap-y-1"
                    style={{
                      width: "273.33px",
                    }}
                  >
                    <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px]">
                      {formatGSTDate(
                        beneVerifyGST?.gstin_details?.date_of_registration
                      )}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      Estd. on
                    </p>
                  </div>
                </div>
                <div className="flex flex-row h-[90px]">
                  <div
                    className="flex flex-col py-6 gap-y-1 flex-shrink-0 truncate"
                    // style={{
                    //   width: "273.33px",
                    // }}
                  >
                    <p className="text-base text-[#4DDD37] font-sans font-semibold leading-[21.79px] truncate w-[273.33px] pr-5">
                      {beneVerifyGST?.gstin_details?.status}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      Status
                    </p>
                  </div>
                  <div className="my-4 border border-solid border-[#1D3A6D]"></div>
                  <div
                    className="flex flex-col ml-5 py-6 pr-5 gap-y-1 flex-shrink-0"
                    style={{
                      width: "273.33px",
                    }}
                  >
                    <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px]">
                      {beneVerifyGST?.gstin_details?.constitution_of_business}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      Class of company
                    </p>
                  </div>
                  <div className="my-4 border border-solid border-[#1D3A6D]"></div>
                  <div
                    className="flex flex-col ml-5 py-6 gap-y-1"
                    // style={{
                    //   width: "273.33px",
                    // }}
                  >
                    <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px] truncate w-[430px] pr-5">
                      {beneVerifyGST?.gstin_details?.taxpayer_type}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      Taxpayer type
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-auto-setting-gst">
            <div className="mx-auto max-w-5xl">
              <div className="flex items-center w-16 h-7 rounded-[30px] px-2.5 py-1 mt-5 bg-[#F9F9F9]">
                <p className="text-xs text-[#373F4B] font-sans font-semibold leading-[16.34px]">
                  Address
                </p>
              </div>
            </div>

            <div className="mx-auto max-w-5xl">
              <div className="my-5 p-5 max-w-[1024px] rounded-[20px] bg-[#314D7E]">
                <div className="flex flex-row gap-x-10 justify-between">
                  <div className="flex flex-col">
                    <p className="text-sm text-[#DADADA] font-sans font-semibold leading-[19.07px] mb-2.5">
                      Corporate Address
                    </p>
                    <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px] capitalize mb-1">
                      {beneVerifyGST?.gstin_details?.address?.toLowerCase()}
                    </p>
                  </div>
                  <div>
                    <>
                      <GeoMapLeaflet
                        address={beneVerifyGST?.gstin_details?.address}
                        owner={"gstDetails"}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingGSTDetails;
