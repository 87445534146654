import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Link, useNavigate } from "react-router-dom";
import MenuItemCardmobile from "../../MenuItemCardmobile";
import checkIfEnable from "../../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../../services/ServiceIDByLabel";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useRef } from "react";
import "./index.css";
import { setCurrentMenu } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import CloseWithGreyCircle from "../../../../assets/landingPage/CloseWithGreyCircle.svg";

export default function MobileViewPopover(props) {
  const services_list = useSelector((state) => state.refreshToken.services);
  const elementRef = useRef(null);
  const setOpenMobilePopover = props.setOpenMobilePopover;
  const setArrowUpm = props.setArrowUp;
  const dispatch = useDispatch();

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  const handleTogglePopover = () => {
    setOpenMobilePopover(false);
    setArrowUpm(false);
  };
  const hidePopover = () => {
    setOpenMobilePopover(false);
  };
  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setOpenMobilePopover(open);
  };
  const [flowType, setFlowType] = useState(false);
  useEffect(() => {
    setFlowType(
      props.data[0].title.includes("Transactions") ? "Transactions" : "Accounts"
    );
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          hidePopover();
        }}
        className="overlay absolute "
      ></div>
      {["bottom"].map((anchor) => (
        <div
          ref={elementRef}
          className="popover-main-mobile relative pb-[30px]"
        >
          <img
            onClick={() => {
              hidePopover();
            }}
            className=" closeIcon absolute top-[-60px]"
            src={CloseWithGreyCircle}
            alt="CloseWithGreyCircle"
            style={{
              cursor: "pointer",
            }}
          />
          <div
            className="menudiv"
            style={{
              width: "95%",
              boxShadow: "none",
              margin: "auto",
              flexGrow: 1,
              height: "auto",
              cursor: "pointer",
            }}
          >
            <>
              {props.data.map((transaction, index) =>
                transaction.title !== "Admin Transactions" ? (
                  <>
                    <div className="mt-[35px] mb-[10px] ml-[10px] text-[#DADADA] text-[14px]">
                      {transaction.title.toUpperCase()}
                    </div>
                    <div>
                      {transaction.menuItems.map((menuItem, index) => (
                        <Link
                          to={`/${menuItem.route}`}
                          style={{
                            textDecoration: "none",
                            pointerEvents: checkIfEnable(
                              ServiceIDByLabel,
                              menuItem.label,
                              services_list
                            )
                              ? "auto"
                              : "none",
                            backgroundColor: checkIfEnable(
                              ServiceIDByLabel,
                              menuItem.label,
                              services_list
                            )
                              ? "#FFFFFF"
                              : "#F8F8F8",
                            "&:hover": checkIfEnable(
                              ServiceIDByLabel,
                              menuItem.label,
                              services_list
                            ) && {
                              backgroundColor: "#F0F0F0",
                              cursor: "pointer",
                            },
                            opacity: checkIfEnable(
                              ServiceIDByLabel,
                              menuItem.label,
                              services_list
                            )
                              ? "1"
                              : "0.5",
                          }}
                          onClick={() => {
                            dispatch(setCurrentMenu(menuItem.menu));
                            handleTogglePopover();
                          }}
                        >
                          {" "}
                          <MenuItemCardmobile
                            key={index}
                            icon={menuItem.icon}
                            labelm={menuItem.labelm}
                            labela={menuItem.labela}
                          />
                        </Link>
                      ))}
                    </div>
                    <div
                      key={index}
                      className="borderbottom"
                      style={{
                        width: "95%",
                        float: "left",
                        margin: "10px 10px 20px 10px",
                        display: index % 2 === 1 ? "none" : "block",
                      }}
                    ></div>
                    <span
                      key={index}
                      style={{
                        display: index % 2 !== 1 ? "none" : "block",
                        fontSize: "12px",
                        color: "#DADADA",
                        margin: "10px 10px 0px 10px",
                      }}
                    >
                      *If disabled - the feature is not available for your ID.
                    </span>
                  </>
                ) : null
              )}
            </>
          </div>
        </div>
      ))}
    </div>
  );
}
