import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import "../../assets/Landing/css/landingPage.css";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  setHideBreadCrumb,
  setCurrentMenu,
} from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";
import { CSSTransition } from "react-transition-group";
import { getHolidays } from "../../redux/features/date/date";
import { beneTxnViewAllAcc } from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import {
  beneViewAllAcc,
  resetViewAllAcc,
} from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { resetToggle as resetAccToggle } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import {
  ownerListViewAllAcc,
  setshowsuccestoast,
} from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import {
  getRefreshTokenNew,
  resetServices,
} from "../../redux/features/refreshToken/refreshToken";
import Entity from "../../assets/EntityIcons/Entities.svg";
import Calendar from "../../assets/HomeDashboardIcons/Calender.svg";
import Profile from "../../assets/UserProfileIcons/profile_icon_with_white_circle.svg";
import ChevronLeftWhite from "../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import ChevronRightWhite from "../../assets/ArrowsIcons/ChevronRightWhite.svg";
import TransactionHeader from "../../assets/HomeDashboardIcons/TransactionHeader.svg";
import BeneficiaryTransaction from "../../assets/HomeDashboardIcons/BeneficiaryTransactionWithBlueCircle.svg";
import BeneficiaryTransactionCreate from "../../assets/HomeDashboardIcons/PayWithBlueCircle.svg";
import Receive from "../../assets/HomeDashboardIcons/RecieveWithBlueCircle.svg";
import InternalTransaction from "../../assets/HomeDashboardIcons/InternalTransactionWithBlueCircle.svg";
import Approval from "../../assets/HomeDashboardIcons/ApprovalWithBlueCircle.svg";
import AccountHeader from "../../assets/HomeDashboardIcons/AccountHeader.svg";
import BeneficiaryAccount from "../../assets/HomeDashboardIcons/BeneficiaryAccountWithBlueCircle.svg";
import BeneficiaryAccountCreate from "../../assets/HomeDashboardIcons/CreateAccountWithBlueCircle.svg";
import HolidaysSidebarnew from "../../components/HomeDashboard/HolidaysSidebarnew";
import formatTotalBalance from "../../utils/formatTotalBalance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../assets/Beneficiary Icons/inputloder.gif";
import ConnectedBankOwnerCard from "../../components/Accounts/OwnerAccounts/ConnectedBankOwnerCard";
import MainDashboardAccountList from "../../components/Accounts/OwnerAccounts/MainDashboardAccountList";
import RefreshBalanceToast from "../../components/_utils/RefreshBalanceToast";
import xpentraLogom from "../../assets/newdashboardicon/xpentralogo_mobile.png";
import ThreeUpcomingHolidays from "../../components/HomeDashboard/ThreeUpcomingHolidays";
import HolidaysSidebar from "../../components/HomeDashboard/HolidaysSidebar";
import { internalTxnViewAll } from "../../redux/features/internalTransaction/internalTransactionViewAll";
import { ViewAllAccountInternal } from "../../redux/features/InternalAccount/ViewAllAccountInternal.js";

const HomeDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarState, setSidebarState] = useState(false);
  const contents = ["Entity details", "Upcoming bank holidays"];
  const contentsm = ["Transactions", "Accounts"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexm, setCurrentIndexm] = useState(0);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const loading = useSelector((state) => state.homeDashboard.loading);
  const services = useSelector((state) => state.refreshToken.services);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);
  const [isFirstDivVisiblem, setIsFirstDivVisiblem] = useState(true);
  const [isApproveDivVisible, setIsApproveDivVisible] = useState(false);
  const [isApproveDivVisibleAcc, setIsApproveDivVisibleAcc] = useState(false);
  const [isApproveDivVisiblem, setIsApproveDivVisiblem] = useState(false);
  const [isApproveDivVisibleAccm, setIsApproveDivVisibleAccm] = useState(false);
  const [dashboardOwnersData, setDashboardOwnersData] = useState([]);
  const [connectedBankingTotal, setConnectedBankingTotal] = useState("");
  const [ownerAccounts, setOwnerAccounts] = useState([]);
  const [ownerDataLoading, setOwnerDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBalanceSummaryOpen, setIsBalanceSummaryOpen] = useState(true);
  const [connectedBankingOwnerAccounts, setConnectedBankingOwnerAccounts] =
    useState([]);
  const { ownerAccBalanceLoading, showSuccessToast, ownerAccBalanceError } =
    useSelector((state) => state.ownerAllVerification);

  const { beneTxnViewAllAccData } = useSelector(
    (state) => state.beneTxnViewAccData
  );

  const { internalTxnViewAllAccData } = useSelector(
    (state) => state?.internalTxnViewall
  );

  const { internalAccViewAllData } = useSelector(
    (state) => state?.ViewAllAccountInternal
  );

  const contentRef = useRef(null);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.Height = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isOpen]);

  const { beneViewAllAccData, beneViewAllAccLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  useEffect(() => {
    async function fetchData() {
      try {
        let queryParams = {
          page_no: 1,
          page_item_count: 50,
        };

        dispatch(checkUsertoken());
        dispatch(beneTxnViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => { }, [beneTxnViewAllAccData]);

  useEffect(() => {
    async function fetchPendingAccountData() {
      try {
        let queryParams = {
          page_no: 1,
          page_size: 50,
          approval_status: ["pending", "approved"],
        };
        dispatch(checkUsertoken());
        dispatch(beneViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingAccountData();
  }, []);

  useEffect(() => {
    async function fetchPendingInternalTxnData() {
      try {
        let queryParams = {
          page_no: 1,
          page_item_count: 50,
        };
        dispatch(checkUsertoken());
        dispatch(internalTxnViewAll(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingInternalTxnData();
  }, []);

  useEffect(() => { }, [internalTxnViewAllAccData]);

  useEffect(() => {
    async function fetchPendingInternalAccData() {
      try {
        let queryParams = {
          page_no: 1,
          page_size: 50,
          type: "internalAccount",
        };
        dispatch(checkUsertoken());
        dispatch(ViewAllAccountInternal(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingInternalAccData();
  }, []);

  useEffect(() => { }, [internalAccViewAllData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getHolidays());
  }, []);

  const getMoreOwners = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    const getConnectedBankingData = async () => {
      if (currentPage === 1) {
        setOwnerDataLoading(true);
      }
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerListViewAllAcc({
            page_no: 1,
            page_size: 50,
            connected_banking: "Y",
          })
        );
        const newData = response?.payload?.owner_accounts || [];
        setConnectedBankingOwnerAccounts((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setConnectedBankingTotal(response?.payload?.total_balance);
        setOwnerAccounts(response?.payload?.owner_accounts);
        setOwnerDataLoading(false);
        setDashboardOwnersData(response?.payload);
      } catch (error) {
        console.error(error);
        setOwnerDataLoading(false);
      }
    };
    getConnectedBankingData();
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!ownerAccBalanceLoading) {
      setTimeout(() => {
        toast.dismiss();
        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  const txnApprovalReq = useSelector(
    (state) => state.homeDashboard.txnApprovalReq
  );

  const beneTxnNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, contents.length - 1)
    );
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnPrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnNextm = () => {
    setCurrentIndexm((prevIndex) =>
      Math.min(prevIndex + 1, contents.length - 1)
    );

    if (currentIndexm === 1 && isFirstDivVisiblem) {
      setShowSecondContent(true);
    } else {
      setShowSecondContent(!showSecondContent);
    }
  };

  const beneTxnPrevm = () => {
    setCurrentIndexm((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowSecondContent(!showSecondContent);
    setIsFirstDivVisiblem(true);
  };

  const [zIndex] = useState(1);

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };
  const showToastWeb = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  return (
    <div>
      <div className="bg-custom-appBack flex flex-col min-h-screen">
        <div className="dashboard-container-div">
          <div id="webview" className="row" style={{ overflow: "hidden" }}>
            <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_welcome">
                <img
                  src={Profile}
                  alt="Profile"
                  style={{ height: "50px", float: "left", margin: "10px" }}
                />
                <div className="dashboard_welcometext">
                  {refreshData?.employees ? (
                    <div className="m-[10px] ">
                      <span
                        className="font-semibold text-custom-heading truncate capitalize block max-w-[555px]"
                        style={{ fontSize: "18px" }}
                      >
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={`${refreshData.employees.first_name} ${refreshData.employees.last_name}`}
                          placement="bottom-start"
                        >
                          {`${refreshData.employees.first_name} ${refreshData.employees.last_name}`}
                        </Tooltip>
                      </span>
                      <span className="text-custom-subheading">
                        Welcome back
                      </span>
                    </div>
                  ) : null}
                </div>

                <br className="clearfix" />
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div style={{ width: "100%" }}>
                    <div className="dashboardcontentleft">
                      <img
                        src={TransactionHeader}
                        alt="TransactionHeader"
                        style={{
                          width: "20px",
                          float: "left",
                          margin: "0px 10px 0px 5px",
                        }}
                      />
                      <span className="text-custom-subheading">
                        Transactions
                      </span>
                    </div>
                    {/* <div
                      className={`dashboardcontentright ${!services?.find(
                        (service) => service === "VBeneficiaryTxn"
                      )
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-[#667CA3] hover:cursor-pointer"
                        }`}
                      style={{
                        cursor: !services?.find(
                          (service) => service === "VBeneficiaryTxn"
                        )
                          ? "default"
                          : "pointer",
                      }}
                      onClick={() => {
                        if (
                          services?.find(
                            (service) => service === "VBeneficiaryTxn"
                          )
                        ) {
                          navigate(
                            "/transaction/beneficiary_transaction/view_all",
                            {
                              state: { approvalStatus: "all" },
                            }
                          );
                        } else {
                          navigate("/feature_not_assigned", {
                            state: {
                              unavailableService: "VBeneficiaryTxn",
                            },
                          });
                        }
                      }}
                    >
                      View All
                      <img
                        src={ChevronRightWhite}
                        alt="ChevronRightWhite"
                        style={{
                          width: "12px",
                          float: "right",
                          marginTop: "3px",
                        }}
                      />
                    </div> */}
                  </div>
                </div>

                <div>
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div
                        className="Bene_View_All"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) => service === "VBeneficiaryTxn"
                            )
                          ) {
                            navigate(
                              "/transaction/beneficiary_transaction/view_all",
                              {
                                state: { approvalStatus: "all" },
                              }
                            );
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VBeneficiaryTxn",
                              },
                            });
                          }
                        }}
                      >
                        <div className="borderBottom">
                          {beneTxnViewAllAccData ? (
                            <div className="textcontent">
                              {(beneTxnViewAllAccData?.total_count !==
                                undefined &&
                                beneTxnViewAllAccData?.total_count !== null &&
                                beneTxnViewAllAccData?.total_count?.toLocaleString()) ??
                                0}
                            </div>
                          ) : (
                            <div className="textcontent">0</div>
                          )}

                          <div
                            className="textheader"
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "2em",
                              display: "flex",
                            }}
                          >
                            {beneTxnViewAllAccData?.total_count
                              ? beneTxnViewAllAccData?.total_count === 0 ||
                                beneTxnViewAllAccData?.total_count === 1
                                ? "Beneficiary transaction"
                                : "Beneficiary transactions"
                              : "Beneficiary transaction"}
                            <img
                              src={ChevronRightWhite}
                              alt="right-arrow"
                              style={{
                                width: "10px",
                                paddingLeft: "2px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="Internal_View_All"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) => service === "VBeneficiaryTxn"
                            )
                          ) {
                            navigate(
                              "/transaction/internal_transaction/view_all",
                              {
                                state: { approvalStatus: "all" },
                              }
                            );
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VBeneficiaryTxn",
                              },
                            });
                          }
                        }}
                      >
                        {internalTxnViewAllAccData ? (
                          <div className="textcontent">
                            {(internalTxnViewAllAccData?.total_count !==
                              undefined &&
                              internalTxnViewAllAccData?.total_count !== null &&
                              internalTxnViewAllAccData?.total_count?.toLocaleString()) ??
                              0}
                          </div>
                        ) : (
                          <div className="textcontent">0</div>
                        )}
                        <div
                          className="textheader"
                          style={{
                            marginTop: "0.5em",
                            marginBottom: "2em",
                            display: "flex",
                          }}
                        >
                          {internalTxnViewAllAccData?.total_count
                            ? internalTxnViewAllAccData?.total_count === 0 ||
                              internalTxnViewAllAccData?.total_count === 1
                              ? "Internal transaction"
                              : "Internal transactions"
                            : "Internal transaction"}
                          <img
                            src={ChevronRightWhite}
                            alt="right-arrow"
                            style={{
                              width: "10px",
                              paddingLeft: "2px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "table-cell", width: "75%" }}>
                      <div
                        className="table-container"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          height: "197px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        <CSSTransition>
                          <div
                            className={`table-row ${isApproveDivVisible
                              ? "slide-right-enter-done"
                              : "slide-right-exit-done"
                              }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond "
                                style={{
                                  position: "relative",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  className="bg-[#314D7E] hover:bg-[#60779d]"
                                  style={{
                                    position: "absolute",
                                    // backgroundColor: "#314D7E",
                                    fontSize: "12px",
                                    padding: "6px 10px",
                                    top: "0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: "10px",
                                    fontWeight: "600",
                                    width: "99px",
                                  }}
                                  onClick={() => {
                                    setIsApproveDivVisible(false);
                                  }}
                                >
                                  Approve
                                  <img
                                    src={ChevronLeftWhite}
                                    alt="ChevronLeftWhite"
                                    style={{
                                      width: "12px",
                                      float: "left",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                                <div className="borderright">
                                  <div
                                    className="textheader"
                                    style={{
                                      padding: "55px 20px",
                                      alignItems: "center",
                                    }}
                                  >
                                    Select the transaction type you want to
                                    approve.
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/transaction_single_approval"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "ABeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                        (service) =>
                                          service === "ABeneficiaryTxn"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={BeneficiaryTransaction}
                                      alt="BeneficiaryTransaction"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Beneficiary</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve the transactions for the fund
                                    transfer from owner to the beneficiary.
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/internal_transaction/single_approval_transaction"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "ABeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={InternalTransaction}
                                      alt="internal"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Internal</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve the transactions for the fund
                                    transfer betweeen owner accounts
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>

                        <CSSTransition>
                          <div
                            className={`table-row ${!isApproveDivVisible
                              ? "slide-left-enter-done-acc"
                              : "slide-left-exit-done"
                              } ${isFirstDivVisible
                                ? "slide-right-enter-done"
                                : "slide-right-exit-done"
                              }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond  hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    setIsFirstDivVisible(false);
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "CBeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                        (service) =>
                                          service === "CBeneficiaryTxn"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright ">
                                  <div>
                                    <img
                                      src={BeneficiaryTransactionCreate}
                                      alt="BeneficiaryTransactionCreate"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">pay</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 20px" }}
                                  >
                                    Create an outgoing transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "RCTxn"
                                    ) !== undefined
                                  ) {
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "RCTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                        (service) => service === "RCTxn"
                                      ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) => service === "RCTxn"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "RCTxn"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={Receive}
                                      alt="Receive"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Receive</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 14px" }}
                                  >
                                    Create an incoming transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    setIsApproveDivVisible(true);
                                    // navigate(
                                    //   "/transaction/beneficiary_transaction/transaction_single_approval"
                                    // );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "ABeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  // transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                        (service) =>
                                          service === "ABeneficiaryTxn"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "ABeneficiaryTxn"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={Approval}
                                      alt="Approval"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Approve</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve created transactions
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                        <CSSTransition>
                          <div
                            className={`table-row ${!isFirstDivVisible
                              ? "slide-left-enter-done-acc"
                              : "slide-left-exit-done"
                              }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond"
                                style={{
                                  position: "relative",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  className="bg-[#314D7E] hover:bg-[#60779d]"
                                  style={{
                                    position: "absolute",
                                    fontSize: "12px",
                                    padding: "6px 10px",
                                    fontWeight: "600",
                                    width: "71px",
                                    top: "0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: "10px",
                                  }}
                                  onClick={() => {
                                    setIsFirstDivVisible(true);
                                  }}
                                >
                                  Pay
                                  <img
                                    src={ChevronLeftWhite}
                                    alt="ChevronLeftWhite"
                                    style={{
                                      width: "12px",
                                      float: "left",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                                <div className="borderright">
                                  <div
                                    className="textheader"
                                    style={{ padding: "47px 20px" }}
                                  >
                                    Select the transaction type you want to
                                    create
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/create"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "CBeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                        (service) =>
                                          service === "CBeneficiaryTxn"
                                      ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={BeneficiaryTransaction}
                                      alt="BeneficiaryTransaction"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Beneficiary</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Select category and pay a beneficiary
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) !== undefined
                                  ) {
                                    setIsApproveDivVisible(true);
                                    dispatch(resetViewAllAcc());
                                    navigate(
                                      "/transaction/internal_transaction/create"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "CBeneficiaryTxn",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  // transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                        (service) =>
                                          service === "CBeneficiaryTxn"
                                      ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "CBeneficiaryTxn"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={InternalTransaction}
                                      alt="internal"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Internal</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Transfer funds from one owner account to
                                    another
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div className="dashboardcontentleft">
                    <img
                      src={AccountHeader}
                      alt="AccountHeader"
                      style={{
                        width: "20px",
                        float: "left",
                        margin: "0px 10px 0px 5px",
                      }}
                    />
                    Accounts
                  </div>
                  {/* <div
                    className={`dashboardcontentright ${!services?.find((service) => service === "BVAccount")
                      ? "cursor-not-allowed opacity-50"
                      : "hover:bg-[#667CA3] hover:cursor-pointer"
                      }`}
                    style={{
                      cursor: !services?.find(
                        (service) => service === "BVAccount"
                      )
                        ? "default"
                        : "pointer",
                    }}
                    onClick={async () => {
                      if (
                        services?.find((service) => service === "BVAccount")
                      ) {
                        await dispatch(resetAccToggle());
                        navigate("/accounts/beneficiary_account/view_all", {
                          state: { approvalStatus: "all" },
                        });
                      } else {
                        navigate("/feature_not_assigned", {
                          state: {
                            unavailableService: "BVAccount",
                          },
                        });
                      }
                    }}
                  >
                    View All
                    <img
                      src={ChevronRightWhite}
                      alt="ChevronRightWhite"
                      style={{
                        width: "12px",
                        float: "right",
                        marginTop: "3px",
                      }}
                    />
                  </div> */}
                </div>
                <div>
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div
                        className="Bene_View_All"
                        onClick={async () => {
                          if (
                            services?.find((service) => service === "BVAccount")
                          ) {
                            await dispatch(resetAccToggle());
                            navigate("/accounts/beneficiary_account/view_all", {
                              state: { approvalStatus: "all" },
                            });
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "BVAccount",
                              },
                            });
                          }
                        }}
                      >
                        <div className="borderBottom">
                          {beneViewAllAccData ? (
                            <div className="textcontent">
                              {beneViewAllAccData?.total_count.toLocaleString() ??
                                0}
                            </div>
                          ) : (
                            <div className="textcontent">0</div>
                          )}
                          <div
                            className="textheader"
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "2em",
                              display: "flex",
                            }}
                          >
                            {beneViewAllAccData?.total_count
                              ? beneViewAllAccData?.total_count === 0 ||
                                beneViewAllAccData?.total_count === 1
                                ? "Beneficiary  account"
                                : "Beneficiary  accounts"
                              : "Beneficiary  account"}
                            <img
                              src={ChevronRightWhite}
                              alt="right-arrow"
                              style={{
                                width: "10px",
                                paddingLeft: "2px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="Internal_View_All"
                        onClick={async () => {
                          if (
                            services?.find((service) => service === "BVAccount")
                          ) {
                            await dispatch(resetAccToggle());
                            navigate("/accounts/internal_account/view_all", {
                              state: { approvalStatus: "all" },
                            });
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "BVAccount",
                              },
                            });
                          }
                        }}
                      >
                        {internalAccViewAllData ? (
                          <div className="textcontent">
                            {internalAccViewAllData?.total_count ?? 0}
                          </div>
                        ) : (
                          <div className="textcontent">0</div>
                        )}

                        <div
                          className="textheader"
                          style={{
                            marginTop: "0.5em",
                            marginBottom: "2em",
                            display: "flex",
                          }}
                        >
                          {internalAccViewAllData?.total_count
                            ? internalAccViewAllData?.total_count ||
                              internalAccViewAllData?.total_count === 1
                              ? "Internal accounts"
                              : "Internal accounts"
                            : "Internal account"}
                          <img
                            src={ChevronRightWhite}
                            alt="right-arrow"
                            style={{
                              width: "10px",
                              paddingLeft: "2px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "table-cell", width: "75%" }}>
                      <div
                        className="table-container"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          height: "197px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        <CSSTransition>
                          <div
                            className={`table-row ${!isApproveDivVisibleAcc
                              ? "slide-left-enter-done"
                              : "slide-left-exit-done"
                              } ${isFirstDivVisible
                                ? "slide-right-enter-done"
                                : "slide-right-exit-done"
                              }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "BUAccount"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/accounts/beneficiary_account/create"
                                    );
                                    dispatch(setCurrentMenu("Accounts"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BUAccount",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                        (service) => service === "BUAccount"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "BUAccount"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "BUAccount"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={BeneficiaryAccountCreate}
                                      alt="BeneficiaryAccountCreate"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Create</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 20px" }}
                                  >
                                    Create a beneficiary account
                                  </div>
                                </div>
                              </div>
                            )}

                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) !== undefined
                                  ) {
                                    setIsApproveDivVisibleAcc(true);
                                    // navigate(
                                    //   "/transaction/beneficiary_transaction/transaction_single_approval"
                                    // );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BAAccount",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  // transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                        (service) => service === "BAAccount"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={Approval}
                                      alt="Approval"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Approve</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve created accounts
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              className="tranxcontentmainforthlast"
                            // style={{ cursor: "default", opacity: "0.5" }}
                            ></div>
                          </div>
                        </CSSTransition>
                        <CSSTransition>
                          <div
                            className={`table-row ${isApproveDivVisibleAcc
                              ? "slide-right-enter-done"
                              : "slide-right-exit-done"
                              }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond "
                                style={{
                                  position: "relative",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  className="bg-[#314D7E] hover:bg-[#60779d]"
                                  style={{
                                    position: "absolute",
                                    fontSize: "12px",
                                    padding: "6px 10px",
                                    top: "0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: "10px",
                                    fontWeight: "600",
                                    width: "99px",
                                  }}
                                  onClick={() => {
                                    setIsApproveDivVisibleAcc(false);
                                  }}
                                >
                                  Approve
                                  <img
                                    src={ChevronLeftWhite}
                                    alt="ChevronLeftWhite"
                                    style={{
                                      width: "12px",
                                      float: "left",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                                <div className="borderright">
                                  <div
                                    className="textheader"
                                    style={{
                                      padding: "55px 20px",
                                      alignItems: "center",
                                    }}
                                  >
                                    Select the Account type you want to approve.
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird hover:bg-[#60779d]"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/accounts/beneficiary_account/approval_required"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BAAccount",
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                        (service) => service === "BAAccount"
                                      ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={BeneficiaryTransaction}
                                      alt="BeneficiaryTransaction"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Beneficiary</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve the accounts to initiate
                                    transactions.
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) => service === "BAAccount"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/accounts/internal_account/approval_required"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    dispatch(resetServices());
                                    dispatch(getRefreshTokenNew());
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BAAccount",
                                      },
                                    });
                                  }
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={InternalTransaction}
                                      alt="internal"
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Internal</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve the accounts to initiate internal
                                    fund transfer.
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_summery">
                <div className="dashboard_hedder">
                  <div className="Caraouseltab">
                    <div
                      className="CarouselDottabl"
                      onClick={beneTxnPrev}
                      disabled={currentIndex === 0}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={Entity}
                          alt=""
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[0]}
                      </div>
                    </div>
                    <div
                      className="CarouselDottabr"
                      onClick={beneTxnNext}
                      disabled={currentIndex === 1}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={Calendar}
                          alt="Calendar"
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[1]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerscroll">
                  <div
                    className={`${!showSecondContent
                      ? "showFirstContentd"
                      : "hideFirstContentd"
                      }`}
                  >
                    {currentIndex === 0 && (
                      <>
                        <div className="dashboard_contenthedder_ent">
                          <div className="textcontent">
                            <div
                              style={{
                                padding: "15px 0px 0px 0px",
                                fontSize: "20px",
                                color: "#4DDD37",
                              }}
                            >
                              ₹
                              {connectedBankingTotal !== undefined
                                ? formatTotalBalance(connectedBankingTotal).amount
                                : "00.00"}
                              {connectedBankingTotal !== undefined &&
                                formatTotalBalance(connectedBankingTotal)
                                  .prefix == "crores"}
                              <span
                                style={{ fontSize: "12px", color: "#DADADA" }}
                              >
                                &nbsp;in&nbsp;
                              </span>
                              {dashboardOwnersData?.total_count}&nbsp;
                              <span
                                style={{ fontSize: "12px", color: "#DADADA" }}
                              >
                                {dashboardOwnersData?.total_count === 0 ||
                                  dashboardOwnersData?.total_count === 1
                                  ? "account"
                                  : "accounts"}
                              </span>
                            </div>
                            <div
                              style={{
                                padding: "0px 0px 15px 0px",
                                fontSize: "12px",
                                color: "#DADADA",
                                fontWeight: "normal"
                              }}
                            >
                              Total balance&nbsp;
                              {formatTotalBalance(connectedBankingTotal).prefix ==
                                "crores"
                                ? "in crores"
                                : ""}
                            </div>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={refreshData?.entity?.entity_name}
                              placement="bottom-start"
                            >
                              <div className="textcontent" style={{
                                fontWeight: "normal",
                                paddingBottom: "15px"
                              }}>
                                {refreshData?.entity ? (
                                  <span>
                                    {refreshData.entity.entity_name.toLowerCase()}
                                  </span>
                                ) : null}
                              </div>
                            </Tooltip>
                          </div>


                        </div>
                        <div className="Caraouselcontent">
                          <div className="Caraouselcontentleft">
                            Balances Summary
                          </div>
                          <div
                            className={`Caraouselcontentright ${services?.find(
                              (service) => service === "VAccount"
                            ) === undefined
                              ? "opacity-50"
                              : "hover:bg-[#667CA3] hover:cursor-pointer"
                              }`}
                            style={{
                              cursor:
                                services?.find(
                                  (service) => service === "VAccount"
                                ) === undefined
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "VAccount"
                                ) !== undefined
                              ) {
                                navigate("/accounts/owner/view_all");
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "VAccount",
                                  },
                                });
                              }
                            }}
                          >
                            View All
                            <img
                              src={ChevronRightWhite}
                              alt="ChevronRightWhite"
                              style={{
                                width: "12px",
                                float: "right",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="Caraouselcontentmain">
                          {ownerDataLoading ? (
                            <div className="flex justify-center items-center">
                              <img
                                src={Loader}
                                alt="Loading"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          ) : ownerAccounts?.length === 0 ? (
                            <div className="flex items-center">
                              <p className="text-[12px] text-[#F9F9F9] pl-2">
                                No owner account found
                              </p>
                            </div>
                          ) : (
                            <>
                              <InfiniteScroll
                                style={{ width: "inherit" }}
                                dataLength={ownerAccounts?.length || 0}
                                next={getMoreOwners}
                                hasMore={
                                  currentPage <
                                  Math.ceil(
                                    dashboardOwnersData?.total_count / 50
                                  )
                                }
                                loader={
                                  <div className="loader-container m-auto justify-center items-center flex">
                                    <img src={Loader} alt="" />
                                  </div>
                                }
                                scrollableTarget="infinityScrollOwnersView"
                                scrollThreshold={0.8}
                              >
                                {connectedBankingOwnerAccounts?.map((owner) => (
                                  <ConnectedBankOwnerCard
                                    key={owner?.id}
                                    owner={owner}
                                    ownerAccounts={ownerAccounts}
                                    setOwnerAccounts={setOwnerAccounts}
                                    ownerAccBalanceLoading={
                                      ownerAccBalanceLoading
                                    }
                                    ownerAccBalanceError={ownerAccBalanceError}
                                    updateTotalBalance={
                                      setConnectedBankingTotal
                                    }
                                    total_balance={connectedBankingTotal}
                                    isBalanceSummaryOpen={isBalanceSummaryOpen}
                                    connectedBankingOwnerAccounts={
                                      connectedBankingOwnerAccounts
                                    }
                                    setConnectedBankingOwnerAccounts={
                                      setConnectedBankingOwnerAccounts
                                    }
                                  />
                                ))}
                              </InfiniteScroll>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`${showSecondContent
                      ? "showSecondContentd"
                      : "hideSecondContentd"
                      }`}
                  >
                    {currentIndex === 1 && (
                      <>
                        <div className="dashboard_contenthedder"></div>
                        <div className="Caraouseldatemain">
                          <HolidaysSidebarnew />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="mobileview"
            className="row bodymaindiv"
            style={{
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_header_div">
                <div className="dashboard_logo_div">
                  <img
                    className="cursor-pointer"
                    width="150px"
                    src={xpentraLogom}
                    alt="xpentraLogo"
                  />
                </div>
              </div>
              <div className="mt-[60px]">
                <div className="dashboard_header_main">
                  <div className="dashboard_header_left">
                    <div
                      style={{
                        padding: "15px 0px 0px 0px",
                        fontSize: "25px",
                        color: "#4DDD37",
                        fontWeight: "bold"
                      }}
                    >
                      ₹
                      {connectedBankingTotal !== undefined
                        ? formatTotalBalance(connectedBankingTotal).amount
                        : "00.00"}
                      {connectedBankingTotal !== undefined &&
                        formatTotalBalance(connectedBankingTotal).prefix ==
                        "crores"}
                      <span style={{ fontSize: "16px", color: "#DADADA" }}>
                        &nbsp;in&nbsp;
                      </span>
                      {dashboardOwnersData?.total_count}&nbsp;
                      <span style={{ fontSize: "16px", color: "#DADADA" }}>
                        {dashboardOwnersData?.total_count === 0 ||
                          dashboardOwnersData?.total_count === 1
                          ? "account"
                          : "accounts"}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: "0px 0px 15px 0px",
                        fontSize: "12px",
                        color: "#DADADA",
                      }}
                    >
                      Total balance&nbsp;
                      {formatTotalBalance(connectedBankingTotal).prefix ==
                        "crores"
                        ? "in crores"
                        : ""}
                    </div>
                    <div >
                      <div className="textcontent">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                          title={refreshData?.entity?.entity_name}
                          placement="bottom-start"
                        >
                          <span>{refreshData?.entity?.entity_name}</span>
                        </Tooltip>
                      </div>


                    </div>
                  </div>
                  <div className="dashboard_header_right"></div>
                  <br className="clearfix" />
                </div>
                <div className="accordion" style={{ border: "none" }}>
                  <div className="accordion-title" onClick={toggleAccordion}>
                    <span>Balances Summary</span>
                    <div
                      className={`accordion-arrow ${isOpen ? "open" : ""}`}
                    />
                  </div>
                  <div
                    ref={contentRef}
                    className={`accordionDashboard-content ${isOpen ? "open" : ""
                      }`}
                  >
                    <div>
                      <div
                        className={`Carouselcontentmainm ${isOpen ? "open" : ""
                          }`}
                      >
                        {ownerDataLoading ? (
                          <div className="flex justify-center items-center">
                            <img
                              src={Loader}
                              alt="Loading"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        ) : ownerAccounts?.length === 0 ? (
                          <div className="flex items-start">
                            <p className="text-sm text-[#F9F9F9] pl-4 pt-4">
                              No owner account found
                            </p>
                          </div>
                        ) : (
                          <InfiniteScroll
                            style={{
                              width: "inherit",
                              padding: "10px",
                              zIndex: "99",
                              position: "relative",
                            }}
                            dataLength={ownerAccounts?.length || 0}
                            next={getMoreOwners}
                            hasMore={
                              currentPage <
                              Math.ceil(dashboardOwnersData?.total_count / 50)
                            }
                            loader={
                              <div className="loader-container m-auto justify-center items-center flex">
                                <img src={Loader} alt="" />
                              </div>
                            }
                            scrollableTarget="infinityScrollOwnersView"
                            scrollThreshold={0.8}
                          >
                            {connectedBankingOwnerAccounts?.map((owner) => (
                              <MainDashboardAccountList
                                key={owner?.id}
                                owner={owner}
                                ownerAccounts={ownerAccounts}
                                setOwnerAccounts={setOwnerAccounts}
                                ownerAccBalanceLoading={ownerAccBalanceLoading}
                                ownerAccBalanceError={ownerAccBalanceError}
                                updateTotalBalance={setConnectedBankingTotal}
                                bankName={owner?.ifsc_code}
                                total_balance={connectedBankingTotal}
                                isBalanceSummaryOpen={isBalanceSummaryOpen}
                                connectedBankingOwnerAccounts={
                                  connectedBankingOwnerAccounts
                                }
                                setConnectedBankingOwnerAccounts={
                                  setConnectedBankingOwnerAccounts
                                }
                                lastFetchedAt={
                                  owner?.owner_account_balance_last_fetched_at_in_words
                                }
                              />
                            ))}
                          </InfiniteScroll>
                        )}
                      </div>
                    </div>

                    {isOpen && (
                      <div className="h-[56px] bg-[#1d3a6d] w-screen border-[1px solid red] fixed bottom-[66px] z-[9]"></div>
                    )}

                    {isOpen && (
                      <div className="flex flex-col fixed bottom-0 w-screen z-[99]">
                        <div
                          onClick={() => {
                            if (
                              services?.find(
                                (service) => service === "VAccount"
                              ) !== undefined
                            ) {
                              navigate("/accounts/owner/view_all");
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "VAccount",
                                },
                              });
                            }
                            // showToast();
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "66px",
                            textAlign: "center",
                            color:
                              services?.find(
                                (service) => service === "VAccount"
                              ) !== undefined
                                ? "#FFFFFF"
                                : "black",
                            backgroundColor:
                              services?.find(
                                (service) => service === "VAccount"
                              ) !== undefined
                                ? "#5FA564"
                                : "#FFFFFF",
                            padding: "20px",
                            cursor:
                              services?.find(
                                (service) => service === "VAccount"
                              ) !== undefined
                                ? "pointer"
                                : "not-allowed",
                          }}
                        >
                          View All
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="dashboard_content_div"
                style={{
                  // marginTop: "35px",
                  display: isOpen ? "none" : "block",
                }}
              >
                <div className="dashboard_summerym">
                  <div className="dashboard_hedderm">
                    <div className="Caraouseltab">
                      <div
                        className={`CarouselDottablm ${currentIndexm === 0 ? "activetab" : ""
                          }`}
                        disabled={
                          currentIndexm === 0 &&
                          isFirstDivVisiblem &&
                          !isApproveDivVisiblem
                        }
                        onClick={() => {
                          if (
                            currentIndexm === 1 &&
                            isFirstDivVisiblem &&
                            !isApproveDivVisiblem
                          ) {
                            beneTxnPrevm();
                          } else if (
                            currentIndexm === 1 &&
                            !isFirstDivVisiblem &&
                            isApproveDivVisiblem
                          ) {
                            beneTxnPrevm();
                            setIsFirstDivVisiblem(false);
                            setIsApproveDivVisiblem(true);
                          } else {
                            setIsFirstDivVisiblem(true);
                            setIsApproveDivVisiblem(false);
                          }
                        }}
                      >
                        <div
                          style={{ width: "max-content", margin: "0px auto" }}
                        >
                          {isFirstDivVisiblem && !isApproveDivVisiblem && (
                            <div style={{ cursor: "pointer" }}>
                              <img
                                src={TransactionHeader}
                                alt="TransactionHeader"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              {contentsm[0]}
                            </div>
                          )}
                          {!isFirstDivVisiblem && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsFirstDivVisiblem(true);
                              }}
                            >
                              <img
                                src={ChevronLeftWhite}
                                alt="ChevronLeftWhite"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              Pay transaction
                            </div>
                          )}
                          {isApproveDivVisiblem && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsApproveDivVisiblem(false);
                              }}
                            >
                              <img
                                src={ChevronLeftWhite}
                                alt="ChevronLeftWhite"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              Approve transaction
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`CarouselDottabrm ${currentIndexm === 1 ? "activetab" : ""
                          }`}
                        onClick={() => {
                          beneTxnNextm();
                          setIsFirstDivVisiblem(true);
                          setIsApproveDivVisibleAccm(false);
                        }}
                      // disabled={
                      //   currentIndexm === 1 &&
                      //   isFirstDivVisiblem &&
                      //   !isApproveDivVisibleAccm
                      // }
                      // disabled={currentIndexm === 1}
                      >
                        <div
                          style={{ width: "max-content", margin: "0px auto" }}
                        >
                          {!isFirstDivVisiblem && !isApproveDivVisibleAccm && (
                            <div>
                              <img
                                src={AccountHeader}
                                alt="AccountHeader"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              {contentsm[1]}
                            </div>
                          )}
                          {isFirstDivVisiblem && !isApproveDivVisibleAccm && (
                            <div style={{ cursor: "pointer" }}>
                              <img
                                src={AccountHeader}
                                alt="TransactionHeader"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              {contentsm[1]}
                            </div>
                          )}
                          {isApproveDivVisibleAccm && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsApproveDivVisibleAccm(false);
                                // setIsFirstDivVisiblem(true);
                              }}
                            >
                              <img
                                src={ChevronLeftWhite}
                                alt="ChevronLeftWhite"
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              Approve account
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerscrollm">
                    <div
                      className={`${!showSecondContent
                        ? "showFirstContentdm"
                        : "hideFirstContentdm"
                        }`}
                    >
                      {currentIndexm === 0 && (
                        <>
                          <div className="dashboard_tranxcontentmainmm">
                            {/* <div className="tranxcontentmainfirstm">
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {beneTxnViewAllAccData ? (
                                  <div className="textcontent">
                                    {(beneTxnViewAllAccData?.total_count !==
                                      undefined &&
                                      beneTxnViewAllAccData?.total_count !==
                                      null &&
                                      beneTxnViewAllAccData?.total_count?.toLocaleString()) ??
                                      0}
                                  </div>
                                ) : (
                                  <div className="textcontent">0</div>
                                )}
                                <div className="textheader">
                                  {beneTxnViewAllAccData?.total_count
                                    ? beneTxnViewAllAccData?.total_count ===
                                      0 ||
                                      beneTxnViewAllAccData?.total_count === 1
                                      ? "Beneficiary transaction"
                                      : "Beneficiary transactions"
                                    : "Beneficiary transaction"}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                {internalTxnViewAllAccData ? (
                                  <div className="textcontent">
                                    {(internalTxnViewAllAccData?.total_count !==
                                      undefined &&
                                      internalTxnViewAllAccData?.total_count !==
                                      null &&
                                      internalTxnViewAllAccData?.total_count?.toLocaleString()) ??
                                      0}
                                  </div>
                                ) : (
                                  <div className="textcontent">0</div>
                                )}
                                <div className="textheader">
                                  {internalTxnViewAllAccData?.total_count
                                    ? internalTxnViewAllAccData?.total_count ===
                                      0 ||
                                      internalTxnViewAllAccData?.total_count ===
                                      1
                                      ? "Internal transaction"
                                      : "Internal transactions"
                                    : "Internal transaction"}
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div> */}
                            <div
                              className="table-containerm"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <CSSTransition>
                                <div
                                  className={`table-rowm ${isFirstDivVisiblem
                                    ? "slide-right-enter-done"
                                    : "slide-right-exit-done"
                                    }                                  
                                    ${!isApproveDivVisiblem
                                      ? "slide-right-enter-done"
                                      : "slide-right-exit-done"
                                    }`}
                                >
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainsecondm flex items-center"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          setIsFirstDivVisiblem(false);
                                          // showToast();
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "CBeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Pay"
                                            ? services?.find(
                                              (service) =>
                                                service === "CBeneficiaryTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={BeneficiaryTransactionCreate}
                                            alt="BeneficiaryTransactionCreate"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">pay</div>
                                        <div className="textheader">
                                          Create an outgoing transaction
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainthirdm flex items-center"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) => service === "RCTxn"
                                          ) !== undefined
                                        ) {
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService: "RCTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                              (service) => service === "RCTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) => service === "RCTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                              0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) => service === "RCTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={Receive}
                                            alt="Receive"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Receive
                                        </div>
                                        <div className="textheader">
                                          Create an incoming transaction
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainforthm flex items-center cursor-pointer"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          setIsApproveDivVisiblem(true);

                                          // showToast();
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                          dispatch(resetServices());
                                          dispatch(getRefreshTokenNew());
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "ABeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                              (service) =>
                                                service === "ABeneficiaryTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                              0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={Approval}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Approve
                                        </div>
                                        <div className="textheader">
                                          Approve created transactions
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                </div>
                              </CSSTransition>
                              <CSSTransition>
                                <div
                                  className={`table-row ${!isFirstDivVisiblem
                                    ? "slide-left-enter-done"
                                    : "slide-left-exit-done"
                                    }`}
                                >
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainthirdm flex items-center"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          // showToast();
                                          navigate(
                                            "/transaction/beneficiary_transaction/create"
                                          );
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                          dispatch(resetServices());
                                          dispatch(getRefreshTokenNew());
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "CBeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                              (service) =>
                                                service === "CBeneficiaryTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                              0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={BeneficiaryTransaction}
                                            alt="BeneficiaryTransaction"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Beneficiary
                                        </div>
                                        <div className="textheader">
                                          Select category and pay a beneficiary
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainforthm flex items-center cursor-pointer"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          // showToast();
                                          navigate(
                                            "/transaction/beneficiary_transaction/create"
                                          );
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                          dispatch(resetServices());
                                          dispatch(getRefreshTokenNew());
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "CBeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                              (service) =>
                                                service === "CBeneficiaryTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                              0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={InternalTransaction}
                                            alt="InternalTransaction"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Internal
                                        </div>
                                        <div className="textheader">
                                          Transfer funds from one owner account
                                          to another
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div className="px-[60px] text-center py-[45px] text-[#DADADA] text-[16px]">
                                      Select the transaction type you want to
                                      create
                                    </div>
                                  )}
                                </div>
                              </CSSTransition>

                              <CSSTransition>
                                <div
                                  className={`table-row ${isApproveDivVisiblem
                                    ? "slide-left-enter-done"
                                    : "slide-left-exit-done"
                                    }`}
                                >
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainthirdm flex items-center"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          navigate(
                                            "/transaction/beneficiary_transaction/transaction_single_approval"
                                          );
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "ABeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                              (service) =>
                                                service === "ABeneficiaryTxn"
                                            ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                              0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                              ?.pending_transaction_count ===
                                              0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={BeneficiaryTransaction}
                                            alt="BeneficiaryTransaction"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Beneficiary
                                        </div>
                                        <div className="textheader">
                                          Approve the transactions for the fund
                                          transfer from owner to the
                                          beneficiary.
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainforthm flex items-center cursor-pointer"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn"
                                          ) !== undefined
                                        ) {
                                          navigate(
                                            "/internal_transaction/single_approval_transaction"
                                          );
                                        } else {
                                          navigate("/feature_not_assigned", {
                                            state: {
                                              unavailableService:
                                                "ABeneficiaryTxn",
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={InternalTransaction}
                                            alt="InternalTransaction"
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Internal
                                        </div>
                                        <div className="textheader">
                                          Approve the transactions for the fund
                                          transfer betweeen owner accounts
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div className="px-[60px] text-center py-[45px] text-[#DADADA] text-[16px]">
                                      Select the transaction type you want to
                                      approve
                                    </div>
                                  )}
                                </div>
                              </CSSTransition>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="containerscrollm">
                      <div
                        className={`${showSecondContent
                          ? "showSecondContentdm"
                          : "hideSecondContentdm"
                          }`}
                      >
                        {currentIndexm === 1 && (
                          <>
                            <div className="dashboard_tranxcontentmainmm">
                              {/* <div className="tranxcontentmainfirstm">
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {beneViewAllAccData ? (
                                  <div className="textcontent">
                                    {beneViewAllAccData?.summary
                                      ?.pending_accounts_count ?? 0}
                                  </div>
                                ) : (
                                  <div className="textcontent">0</div>
                                )}
                                <div className="textheader">
                                  {beneViewAllAccData?.summary
                                    ?.pending_accounts_count
                                    ? beneViewAllAccData?.summary
                                      ?.pending_accounts_count === 0 ||
                                      beneViewAllAccData?.summary
                                        ?.pending_accounts_count === 1
                                      ? "Pending account"
                                      : "Pending accounts"
                                    : "Pending account"}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                {beneViewAllAccData ? (
                                  <div className="textcontent">
                                    {beneViewAllAccData?.summary
                                      ?.approved_accounts_count ?? 0}
                                  </div>
                                ) : (
                                  <div className="textcontent">0</div>
                                )}
                                <div className="textheader">
                                  {beneViewAllAccData?.summary
                                    ?.approved_accounts_count
                                    ? beneViewAllAccData?.summary
                                      ?.approved_accounts_count === 0 ||
                                      beneViewAllAccData?.summary
                                        ?.approved_accounts_count === 1
                                      ? "Approved account"
                                      : "Approved accounts"
                                    : "Approved account"}
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div> */}

                              <div
                                className="table-containerm"
                                style={{
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <CSSTransition>
                                  <div
                                    className={`table-rowm ${isFirstDivVisiblem
                                      ? "slide-right-enter-done"
                                      : "slide-right-exit-done"
                                      } ${!isApproveDivVisibleAccm
                                        ? "slide-right-enter-done"
                                        : "slide-right-exit-done"
                                      }`}
                                  >
                                    {!loading && (
                                      <div
                                        className="tranxcontentmainsecondm flex items-center"
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BUAccount"
                                            ) !== undefined
                                          ) {
                                            navigate(
                                              "/accounts/beneficiary_account/create"
                                            );
                                            dispatch(
                                              setCurrentMenu("Accounts")
                                            );
                                            dispatch(resetServices());
                                            dispatch(getRefreshTokenNew());
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BUAccount",
                                              },
                                            });
                                          }
                                        }}
                                        style={{
                                          transition: "all 0.6s ease-in-out",
                                          transform:
                                            hover === "Pay"
                                              ? services?.find(
                                                (service) =>
                                                  service === "BUAccount"
                                              ) !== undefined
                                                ? "translateY(-50px)"
                                                : "translateY(0px)"
                                              : "translateY(0px)",
                                          opacity:
                                            services?.find(
                                              (service) =>
                                                service === "BUAccount"
                                            ) === undefined
                                              ? "0.5"
                                              : "1",
                                          cursor:
                                            services?.find(
                                              (service) =>
                                                service === "BUAccount"
                                            ) === undefined
                                              ? "not-allowed"
                                              : "pointer",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "15%",
                                            float: "left",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={BeneficiaryAccountCreate}
                                              alt="BeneficiaryAccountCreate"
                                              style={{
                                                width: "60px",
                                                margin: "5px auto 5px auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "85%",
                                            float: "left",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          <div className="textcontent">
                                            Create
                                          </div>
                                          <div className="textheader">
                                            Create a beneficiary account
                                          </div>
                                        </div>
                                        <br className="clearfix" />
                                      </div>
                                    )}
                                    {!loading && (
                                      <div
                                        className="tranxcontentmainthirdm flex items-center"
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount"
                                            ) !== undefined
                                          ) {
                                            setIsApproveDivVisibleAccm(true);

                                            // showToast();
                                            dispatch(
                                              setCurrentMenu("Transactions")
                                            );
                                            dispatch(resetServices());
                                            dispatch(getRefreshTokenNew());
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BAAccount",
                                              },
                                            });
                                          }
                                        }}
                                        style={{
                                          transition: "all 0.6s ease-in-out",
                                          transform:
                                            hover === "Approval required"
                                              ? services?.find(
                                                (service) =>
                                                  service === "BAAccount"
                                              ) !== undefined
                                                ? "translateY(-50px)"
                                                : "translateY(0px)"
                                              : "translateY(0px)",
                                          opacity:
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount"
                                            ) === undefined
                                              ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                undefined ||
                                                txnApprovalReq?.summary
                                                  ?.pending_transaction_count ===
                                                0
                                                ? "0.5"
                                                : "0.5"
                                              : "1",
                                          cursor:
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount"
                                            ) === undefined
                                              ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0 || undefined
                                                ? "not-allowed"
                                                : "not-allowed"
                                              : "pointer",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "15%",
                                            float: "left",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={Approval}
                                              alt=""
                                              style={{
                                                width: "60px",
                                                margin: "5px auto 5px auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "85%",
                                            float: "left",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          <div className="textcontent">
                                            Approve
                                          </div>
                                          <div className="textheader">
                                            Approve created accounts
                                          </div>
                                        </div>
                                        <br className="clearfix" />
                                      </div>
                                    )}
                                  </div>
                                </CSSTransition>
                                <CSSTransition>
                                  <div
                                    className={`table-row ${isApproveDivVisibleAccm
                                      ? "slide-left-enter-done"
                                      : "slide-left-exit-done"
                                      }`}
                                  >
                                    {!loading && (
                                      <div
                                        className="tranxcontentmainthirdm flex items-center cursor-pointer"
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount"
                                            ) !== undefined
                                          ) {
                                            navigate(
                                              "/accounts/beneficiary_account/approval_required"
                                            );
                                            dispatch(
                                              setCurrentMenu("Transactions")
                                            );
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BAAccount",
                                              },
                                            });
                                          }
                                        }}
                                      // style={{
                                      //   transition: "all 0.6s ease-in-out",
                                      //   transform:
                                      //     hover === "Approval required"
                                      //       ? services?.find(
                                      //         (service) =>
                                      //           service === "ABeneficiaryTxn"
                                      //       ) !== undefined
                                      //         ? "translateY(-50px)"
                                      //         : "translateY(0px)"
                                      //       : "translateY(0px)",
                                      //   opacity:
                                      //     services?.find(
                                      //       (service) =>
                                      //         service === "ABeneficiaryTxn"
                                      //     ) === undefined
                                      //       ? txnApprovalReq?.summary
                                      //         ?.pending_transaction_count ===
                                      //         undefined ||
                                      //         txnApprovalReq?.summary
                                      //           ?.pending_transaction_count ===
                                      //         0
                                      //         ? "0.5"
                                      //         : "0.5"
                                      //       : "1",
                                      //   cursor:
                                      //     services?.find(
                                      //       (service) =>
                                      //         service === "ABeneficiaryTxn"
                                      //     ) === undefined
                                      //       ? txnApprovalReq?.summary
                                      //         ?.pending_transaction_count ===
                                      //         0 || undefined
                                      //         ? "not-allowed"
                                      //         : "not-allowed"
                                      //       : "pointer",
                                      // }}
                                      >
                                        <div
                                          style={{
                                            width: "15%",
                                            float: "left",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={BeneficiaryTransaction}
                                              alt="BeneficiaryTransaction"
                                              style={{
                                                width: "60px",
                                                margin: "5px auto 5px auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "85%",
                                            float: "left",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          <div className="textcontent">
                                            Beneficiary
                                          </div>
                                          <div className="textheader">
                                            Approve the accounts to initiate
                                            transactions.
                                          </div>
                                        </div>
                                        <br className="clearfix" />
                                      </div>
                                    )}
                                    {!loading && (
                                      <div
                                        className="tranxcontentmainforthm flex items-center cursor-pointer"
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount"
                                            ) !== undefined
                                          ) {
                                            navigate(
                                              "/accounts/internal_account/approval_required"
                                            );
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BAAccount",
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "15%",
                                            float: "left",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={InternalTransaction}
                                              alt="InternalTransaction"
                                              style={{
                                                width: "60px",
                                                margin: "5px auto 5px auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "85%",
                                            float: "left",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          <div className="textcontent">
                                            Internal
                                          </div>
                                          <div className="textheader">
                                            Approve the accounts to initiate
                                            internal fund transfer.
                                          </div>
                                        </div>
                                        <br className="clearfix" />
                                      </div>
                                    )}
                                    {!loading && (
                                      <div className="px-[60px] text-center py-[45px] text-[#DADADA] text-[16px]">
                                        Select the account type you want to
                                        approve
                                      </div>
                                    )}
                                  </div>
                                </CSSTransition>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <br className="clearfix" />
                </div>
              </div>
              <div className={`${isOpen ? "hidden" : "block"}`}>
                <ThreeUpcomingHolidays setSidebarState={setSidebarState} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HolidaysSidebar state={sidebarState} setState={setSidebarState} />
    </div>
  );
};

export default HomeDashboard;
