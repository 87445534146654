import React, { useEffect, useState } from "react";
import "../../containers/MyProfileDetails/index.css";
import edit_with_white_circle from "../../assets/EditIcons/edit_with_white_circle.svg";
import logout from "../../assets/LogoutIcons/logout.svg";
import authorized_devices_with_white_circle from "../../assets/GeneralIcons/authorized_devices_with_white_circle.svg";
import auto_generate_password from "../../assets/GeneralIcons/auto_generate_password.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkUsertoken } from "../../redux/features/login/login";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { toast } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import arrowRight from "../../assets/ArrowsIcons/ChevronRightWhite.svg";
import {
  resetAliasName,
  resetEmail,
  resetMobNum,
} from "../../redux/features/users";
import EditPopUp from "../../components/MyProfile/EditPop-up";
import arrow_right_blue_box from "../../assets/ArrowsIcons/arrow_left_blue_box.svg";
import { getSpecificUser } from "../../redux/features/Settings/Management/Users";
import dataLoading from "../../assets/LoaderIcons/Loader.gif";
import GeoMapLeaflet from "../../components/_utils/GeoMapLeaflet";
import LogoutModel from "../../components/MyProfile/LogoutModel";
import formatDateViewAll from "../../utils/formatDateViewAll";
import { beneVerifyThisPAN } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import arrow_left_white from "../../assets/ArrowsIcons/arrow_left_white.svg";
function MyProfileDetails() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTab, SetselectedTab] = useState("User details");
  const [updatedData, setUpdatedData] = useState({});
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const { specificUserData, specificUserLoading, specificUserError } =
    useSelector((state) => state.users);
  const id = refreshData?.employees?.id;

  useEffect(() => {
    if (id) {
      dispatch(getSpecificUser(id));
      checkUsertoken();
    }
  }, [id]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const clickButton = (e) => {
    SetselectedTab(e.target.innerText.trim());
  };

  useEffect(() => {
    dispatch(checkUsertoken());
  }, []);
  const closeModal = () => {
    dispatch(checkUsertoken());
    dispatch(resetAliasName());
    dispatch(resetEmail());
    dispatch(resetMobNum());
    setIsEditModalOpen(false);
  };
  const handleToastClick = () => {
    toast.info("New Feature Coming Soon", toast_position);
  };
  const updateRefreshData = (newData) => {
    setUpdatedData(newData);
  };
  const handleEdit = () => {
    setIsEditModalOpen(true);
  };
  const openLogout = () => {
    setOpenLogoutModal(true);
  };

  const closeLogout = () => {
    setOpenLogoutModal(false);
  };

  const handlePANDetails = async (pan) => {
    if (pan?.length === 10) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisPAN({ search_string: pan })
      );
      if (response?.payload?.status === true) {
        // navigate(`/accounts/beneficiary_account/details/GST_details/${gstin}`);
        navigate(`/settings/PAN_Verification`, { state: pan });
      } else if (response?.payload?.status === false) {
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };
  const gotHome = () => {
    navigate("/home");
  };
  return (
    <>
      <div
        className="MyProfile-main"
        style={{
          height: "100vh",
          overflowX: "hidden",
          overflowY: openLogoutModal || isEditModalOpen ? "hidden" : "auto",
        }}
      >
        {openLogoutModal && (
          <LogoutModel
            onClose={() => closeLogout()}
            openLogoutModal={openLogoutModal}
          />
        )}
        <div className=" MyProfile-main-fixed">
          {isEditModalOpen && (
            <EditPopUp
              id={id}
              onClose={closeModal}
              refreshData={refreshData}
              updateRefreshData={updateRefreshData}
              isEditModalOpen={isEditModalOpen}
            />
          )}
        </div>

        {!specificUserData ? (
          <div className=" m-auto mt-[400px] justify-center items-center flex">
            <img className=" w-[40px] h-[40px] " src={dataLoading} alt="" />
          </div>
        ) : (
          <>
            {isMobileView && (
              <>
                <div className=" h-[60px] w-full p-[10px] fixed z-10 flex items-center bg-[#3A5686] ">
                  <img
                    src={arrow_left_white}
                    className=" ml-[10px] w-[16px] h-[16px] cursor-pointer "
                    onClick={gotHome}
                  />
                  <div className=" flex flex-col ml-5">
                    <p className=" text-[16px] font-[400px] font-sans text-[#ffffff]">
                      {" "}
                      Profile
                    </p>
                    {/* <p className=" text-[12px] font-sans font-[600px] text-[#dadada]">
                      Account details
                    </p> */}
                  </div>
                </div>
                <div className="content-page-Responsive ">
                  <div className="container-Responsive">
                    <div className="acc-section-responsive">
                      <div className="major-div-responsive">
                        <div className="individual-profile-detail-responsive">
                          <div className="individual_profile_acc_responsive">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={`${specificUserData?.employees?.[0]?.first_name} ${specificUserData?.employees?.[0]?.last_name}`}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize w-[320px] truncate block overflow-ellipsis">
                                {`${specificUserData?.employees?.[0]?.first_name} ${specificUserData?.employees?.[0]?.last_name}`}
                              </p>
                            </Tooltip>

                            <div className="acc-number-label mt-1 text-xs">
                              Full name
                            </div>
                          </div>

                          <div className=" flex flex-col w-full ">
                            <div className="individual_profile_acc_responsive">
                              <p className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize w-[320px] truncate block overflow-ellipsis">
                                {specificUserData?.employees?.[0]?.user_id}
                              </p>

                              <div className="acc-number-label mt-1 text-xs">
                                User ID
                              </div>
                            </div>
                            <div className="individual_profile_acc_responsive">
                              <Tooltip
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "viewport",
                                      },
                                    },
                                  ],
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={` ${
                                  specificUserData?.employees?.[0]?.alias_name
                                    ? specificUserData?.employees?.[0]
                                        ?.alias_name
                                    : "Not Given"
                                }`}
                              >
                                <p className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize w-[320px] truncate block overflow-ellipsis">
                                  {specificUserData?.employees?.[0]?.alias_name
                                    ? specificUserData?.employees?.[0]
                                        ?.alias_name
                                    : "Not Given"}
                                </p>
                              </Tooltip>
                              <div className="acc-number-label mt-1 text-xs">
                                Alias
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bank-details-Responsive">
                          <div className="profile-grade-details-responsive px-[20px] py-[15px]">
                            <div className=" flex flex-col">
                              <span className="accno-response">
                                {specificUserData?.employees?.[0]?.grade}
                              </span>
                              <div className="acc-number-label text-xs">
                                Grade
                              </div>
                            </div>

                            <div className="flex flex-col justify-center gap-[2px]">
                              <div
                                className="text-center h-10 flex flex-col justify-center"
                                style={{
                                  borderLeft: "1px solid #102C5D",
                                }}
                              >
                                <div className="flex justify-center text-[#4DDD37] font-semibold text-[16px] pl-[15px]">
                                  Active
                                </div>

                                <div className="flex justify-center pl-[15px]">
                                  <div className="text-[#dadada] text-[12px] font-normal">
                                    Status
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="profile-email-div-main-Resposive px-[20px] py-[15px] ">
                            <div>
                              <Tooltip
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "viewport",
                                      },
                                    },
                                  ],
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={` ${specificUserData?.employees?.[0]?.email_id}`}
                              >
                                <div className="ifsc-response">
                                  {` ${specificUserData?.employees?.[0]?.email_id}`}
                                </div>
                              </Tooltip>
                              <div className="ifsc-code-element">Email ID</div>
                            </div>
                          </div>
                        </div>

                        <div className="profile-detail-main-responsive">
                          <div className="text-[#F9F9F9] text-[16px] font-normal">
                            +91 {specificUserData?.employees?.[0]?.mob_no}
                          </div>
                          <div className="text-[#DADADA] text-[12px] font-normal mt-[2px]">
                            Contact number
                          </div>
                        </div>
                        <div className="h-[20px] bg-[#1D3A6D]"> </div>
                        <div className="h-[49px] text-[14px] w-full bg-[#314D7E] text-left text-[#f9f9f9] font-semibold px-[15px] py-[15px]">
                          Quick Actions
                        </div>
                        <div className=" h-[96px] w-auto flex">
                          <div
                            style={{ border: "none", width: "34.3%" }}
                            className="recipient-profile-box2 flex-grow hover:bg-[#667CA3]"
                          >
                            <Box
                              className="text-center flex flex-col justify-center gap-1 px-[20px] py-[15px] cursor-pointer"
                              onClick={handleEdit}
                            >
                              <div className="flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    className=" w-[30px] h-[30px] "
                                    src={edit_with_white_circle}
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[#DADADA] font-normal text-[12px] h-8/12 w-20 self-center antialiased truncate...">
                                  Edit <br /> profile
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0", height: "96px" }}
                            className="profile-detail-border-div"
                          ></div>{" "}
                          <div
                            style={{ border: "none", width: "34.3%" }}
                            className="recipient-profile-box2 flex-grow hover:bg-[#667CA3] cursor-pointer"
                          >
                            <Box
                              className="text-center flex flex-col justify-center gap-1 px-[20px] py-[15px]"
                              onClick={openLogout}
                            >
                              <div className="flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    className=" w-[30px] h-[30px] "
                                    src={logout}
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[#DADADA] font-normal text-[12px] h-8/12 w-20 self-center antialiased truncate...">
                                  Logout
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0", height: "96px" }}
                            className="profile-detail-border-div"
                          ></div>{" "}
                          <div
                            style={{ border: "none" }}
                            className="recipient-profile-box2 flex-grow"
                          >
                            <Box
                              className={`text-center flex flex-col justify-center px-[20px] py-[15px] ${
                                services?.find(
                                  (service) => service === "Sup_VDevice"
                                ) !== undefined
                                  ? "hover:bg-[#667CA3] cursor-pointer"
                                  : "opacity-50 cursor-default"
                              }`}
                              onClick={() => {
                                if (
                                  services?.find(
                                    (service) => service === "Sup_VDevice"
                                  ) !== undefined
                                ) {
                                  navigate("/settings/Devices");
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "Sup_VDevice",
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    className=" w-[30px] h-[30px] "
                                    src={authorized_devices_with_white_circle}
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box>
                                  <div className="text-[#DADADA] font-normal text-[12px] h-8/12 self-center antialiased truncate... ">
                                    Authorized <br /> devices
                                  </div>
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                        <div
                          className=" h-[96px] w-auto flex"
                          style={{
                            borderTop: "1px solid #1D3A6D",
                          }}
                        >
                          <div className="recipient-profile-box2 flex-grow cursor-pointer hover:bg-[#667CA3]">
                            <Box
                              className="text-center h-full flex flex-col justify-center gap-1 px-[15px] py-[20px]"
                              onClick={handleToastClick}
                            >
                              <div className="flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    className=" w-[30px] h-[30px] "
                                    src={auto_generate_password}
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[#DADADA] font-normal text-[12px] h-8/12 self-center antialiased truncate...">
                                  Generate new <br /> password
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-details-div-section-responsive ">
                    <div className="profile-details-section-responsive">
                      <div className="Porfilebuttonsforspecificdetails-responsive no-scrollbar">
                        <button
                          className={`Details_profile_button_responsive 
                           ${
                             selectedTab === "User details" && "active1"
                               ? "bg-[#f9f9f9]"
                               : "hover:bg-[#A3A3A3]"
                           }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "User details" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#F9F9F9]"
                            } `}
                          >
                            User details
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive ${
                            selectedTab === "CIN details" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "CIN details" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            CIN details
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive  ${
                            selectedTab === "DIN details" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "DIN details" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            DIN details
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive ${
                            selectedTab === "Roles" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "Roles" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            Roles
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive ${
                            selectedTab === "Features" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "Features" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            Features
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive ${
                            selectedTab === "Services" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "Services" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            Services
                          </span>
                        </button>
                        <button
                          className={`CINButton-responsive ${
                            selectedTab === "Assigned tags" && "active1"
                              ? "bg-[#f9f9f9]"
                              : "hover:bg-[#A3A3A3]"
                          }`}
                          onClick={clickButton}
                        >
                          <span
                            className={`text-[12px] text-center font-sans font-normal ${
                              selectedTab === "Assigned tags" && "active1"
                                ? "text-[#373F4B]"
                                : "text-[#f9f9f9]"
                            }`}
                          >
                            Assigned tags
                          </span>
                        </button>
                      </div>
                      {selectedTab === "User details" && (
                        <div className=" bank-card-row-responsive  ">
                          <div className="entity-detail-card-responsive  w-1/4 ">
                            <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                              Company Details
                            </h4>

                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                {refreshData?.entity?.entity_id
                                  ? refreshData?.entity?.entity_id
                                  : "Not Given"}
                              </div>
                              <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                Entity ID
                              </div>
                            </div>
                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize">
                                {refreshData?.entity?.entity_name
                                  ? refreshData?.entity?.entity_name.toLowerCase()
                                  : "Not Given"}
                              </div>
                              <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                Entity name
                              </div>
                            </div>
                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                {refreshData?.entity?.entity_pan
                                  ? refreshData?.entity?.entity_pan
                                  : "Not Given"}
                              </div>
                              <div className="text-[#DADADA] font-normal text-[12px]">
                                Entity PAN number
                              </div>
                            </div>
                          </div>

                          <div className="support-detail-card-responsive  w-1/4 mb-[30px]">
                            <div className="w-50 h-50">
                              <h4 className="mb-[10px] text-[#dadada] font-semibold text-[16px] font-[600px] font-sans">
                                PAN Details
                              </h4>
                              <div className="mt-[5px]">
                                <div
                                  className={`${
                                    specificUserData?.pan_details?.pan
                                      ? services?.find(
                                          (service) => service === "VPAN"
                                        )
                                        ? "hover:bg-[#667CA3] rounded-lg  py-1 cursor-pointer"
                                        : "hover:bg-[#667CA3] rounded-lg cursor-default py-1"
                                      : "hover:bg-[#667CA3] rounded-lg cursor-default py-1"
                                  }`}
                                  onClick={
                                    specificUserData?.pan_details?.pan
                                      ? () => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "VPAN" ||
                                                service === "Admin"
                                            ) !== undefined &&
                                            specificUserData?.pan_details?.pan
                                          ) {
                                            handlePANDetails(
                                              specificUserData?.pan_details?.pan
                                            );
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "VPAN",
                                              },
                                            });
                                          }
                                        }
                                      : null
                                  }
                                >
                                  <div className="flex">
                                    <div
                                      style={{ flex: "90%" }}
                                      className="text-[14px] font-normal text-[#f9f9f9]"
                                    >
                                      {specificUserData?.pan_details?.pan
                                        ? specificUserData?.pan_details?.pan
                                        : "Not Given"}
                                    </div>

                                    {specificUserData?.pan_details?.pan && (
                                      <div
                                        style={{ flex: "10%" }}
                                        className={`grid place-content-center w-3 h-4.4 bg-[#1D3A6D] rounded-md ${
                                          services?.includes("VPAN")
                                            ? "cursor-pointer"
                                            : "cursor-not-allowed"
                                        }`}
                                      >
                                        <button>
                                          <img
                                            src={arrowRight}
                                            alt="document"
                                            className="w-3 h-3"
                                          />
                                        </button>
                                      </div>
                                    )}
                                  </div>

                                  <div className="text-[#DADADA] font-normal text-[12px]">
                                    PAN number
                                  </div>
                                </div>
                              </div>

                              <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                <div className="data-div capitalize">
                                  {specificUserData?.pan_details?.full_name
                                    ? specificUserData?.pan_details?.full_name.toLowerCase()
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Full name
                                </div>
                              </div>

                              <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                <div className="data-div capitalize">
                                  {specificUserData?.pan_details?.created_on
                                    ? formatDateViewAll(
                                        specificUserData?.pan_details
                                          ?.created_on,
                                        true,
                                        true
                                      )
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Created on
                                </div>
                              </div>
                              <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                <div className="data-div capitalize">
                                  {specificUserData?.pan_details
                                    ?.last_updated_on
                                    ? formatDateViewAll(
                                        specificUserData?.pan_details
                                          ?.last_updated_on,
                                        true,
                                        true
                                      )
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Last updated on
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="entity-detail-card-responsive  w-1/4 ">
                            <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                              Origin Timeline
                            </h4>

                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] capitalize">
                                {specificUserData?.employees?.[0]
                                  ?.created_by_name
                                  ? specificUserData?.employees?.[0]?.created_by_name.toLowerCase()
                                  : "Not Given"}
                              </div>
                              <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                Created by
                              </div>
                            </div>
                            <div className="w-50 h-50 acc-type-section-div">
                              <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize">
                                {specificUserData?.employees?.[0]?.created_on
                                  ? formatDateViewAll(
                                      specificUserData?.employees?.[0]
                                        ?.created_on,
                                      true,
                                      true
                                    )
                                  : "Not Given"}
                              </div>
                              <div className="text-[#DADADA] font-normal text-[12px]">
                                Created on
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedTab === "CIN details" &&
                        (specificUserData?.cin_details ? (
                          <div className=" bank-card-row-responsive">
                            <div className="entity-detail-card-responsive  w-1/4 ">
                              <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Company Details
                              </h4>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                  43783884389385
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  CIN number
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize">
                                  Subash steel and manufacturing company Pvt.
                                  Ltd.
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Company name
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Private
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Class of company
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Active
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Company status
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Unlisted
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]  border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Listed status
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  27 Feb 2014
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Estd. on
                                </div>
                              </div>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  +91 90854 78899
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Contact number
                                </div>
                              </div>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Ashishgupta@gmail.com
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Email ID
                                </div>
                              </div>
                            </div>
                            <div className="entity-detail-card-responsive  w-1/4 ">
                              <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Annual Compliance Status
                              </h4>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                  17 Apr 2023
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Last AGM date
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize">
                                  31 Jan 2024
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Last BS date
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Capital Details
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  ₹ 83,00,000,00
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  Paid up capital
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Authorized capital
                                </div>
                              </div>
                            </div>

                            <div className="support-detail-card-responsive  w-1/4 mb-[30px]">
                              <div className="w-50 h-50">
                                <h4 className="mb-[10px] text-[#dadada] font-semibold text-[16px] font-[600px] font-sans">
                                  PAN Details
                                </h4>
                                <div className="mt-[5px]">
                                  <div
                                    className={`${
                                      specificUserData?.pan_details?.pan
                                        ? services?.find(
                                            (service) => service === "VPAN"
                                          )
                                          ? "hover:bg-[#667CA3] rounded-lg  py-1 cursor-pointer"
                                          : "hover:bg-[#667CA3] rounded-lg cursor-default py-1"
                                        : "hover:bg-[#667CA3] rounded-lg cursor-default py-1"
                                    }`}
                                    onClick={
                                      specificUserData?.pan_details?.pan
                                        ? () => {
                                            if (
                                              services?.find(
                                                (service) =>
                                                  service === "VPAN" ||
                                                  service === "Admin"
                                              ) !== undefined &&
                                              specificUserData?.pan_details?.pan
                                            ) {
                                              handlePANDetails(
                                                specificUserData?.pan_details
                                                  ?.pan
                                              );
                                            } else {
                                              navigate(
                                                "/feature_not_assigned",
                                                {
                                                  state: {
                                                    unavailableService: "VPAN",
                                                  },
                                                }
                                              );
                                            }
                                          }
                                        : null
                                    }
                                  >
                                    <div className="flex">
                                      <div
                                        style={{ flex: "90%" }}
                                        className="text-[14px] font-normal text-[#f9f9f9]"
                                      >
                                        {specificUserData?.pan_details?.pan
                                          ? specificUserData?.pan_details?.pan
                                          : "Not Given"}
                                      </div>

                                      {specificUserData?.pan_details?.pan && (
                                        <div
                                          style={{ flex: "10%" }}
                                          className={`grid place-content-center w-3 h-4.4 bg-[#1D3A6D] rounded-md ${
                                            services?.includes("VPAN")
                                              ? "cursor-pointer"
                                              : "cursor-not-allowed"
                                          }`}
                                        >
                                          <button>
                                            <img
                                              src={arrowRight}
                                              alt="document"
                                              className="w-3 h-3"
                                            />
                                          </button>
                                        </div>
                                      )}
                                    </div>

                                    <div className="text-[#DADADA] font-normal text-[12px]">
                                      PAN number
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                  <div className="data-div capitalize">
                                    {specificUserData?.pan_details?.full_name
                                      ? specificUserData?.pan_details?.full_name.toLowerCase()
                                      : "Not Given"}
                                  </div>
                                  <div className="text-[#DADADA] font-normal text-[12px]">
                                    Full name
                                  </div>
                                </div>

                                <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                  <div className="data-div capitalize">
                                    {specificUserData?.pan_details?.created_on
                                      ? formatDateViewAll(
                                          specificUserData?.pan_details
                                            ?.created_on,
                                          true,
                                          true
                                        )
                                      : "Not Given"}
                                  </div>
                                  <div className="text-[#DADADA] font-normal text-[12px]">
                                    Created on
                                  </div>
                                </div>
                                <div className="mt-[8px] border-t-[1px] border-solid border-[#1D3A6D] pt-[10px]">
                                  <div className="data-div capitalize">
                                    {specificUserData?.pan_details
                                      ?.last_updated_on
                                      ? formatDateViewAll(
                                          specificUserData?.pan_details
                                            ?.last_updated_on,
                                          true,
                                          true
                                        )
                                      : "Not Given"}
                                  </div>
                                  <div className="text-[#DADADA] font-normal text-[12px]">
                                    Last updated on
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="entity-detail-card-responsive  w-1/4 ">
                              <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Address Details
                              </h4>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                  {specificUserData?.din_details
                                    ?.present_address
                                    ? specificUserData?.din_details
                                        ?.present_address
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] pb-[10px]">
                                  Permanent address
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize w-auto">
                                  <GeoMapLeaflet
                                    address={
                                      specificUserData?.din_details
                                        ?.present_address
                                    }
                                    owner="user_profile_din"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" bank-card-row-responsive  ">
                            <div className="entity-detail-card-responsive m-[20px]  w-1/4 ">
                              <span className="  text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                CIN details not available
                              </span>
                            </div>
                          </div>
                        ))}
                      {selectedTab === "DIN details" &&
                        (specificUserData?.din_details ? (
                          <div className=" bank-card-row-responsive">
                            <div className="entity-detail-card-responsive  w-1/4 ">
                              <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Company Details
                              </h4>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                  {specificUserData?.din_details?.din_number
                                    ? specificUserData?.din_details?.din_number
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  DIN number
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize">
                                  {specificUserData?.din_details?.full_name
                                    ? specificUserData?.din_details?.full_name
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Full name
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details?.father_name
                                    ? specificUserData?.din_details?.father_name
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Father’s name
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details?.dob
                                    ? formatDateViewAll(
                                        specificUserData?.din_details?.dob,
                                        undefined,
                                        undefined
                                      )
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Date of birth
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details?.nationality
                                    ? specificUserData?.din_details?.nationality
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]  border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Nationality
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details?.pan_number
                                    ? specificUserData?.din_details?.pan_number
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  PAN number
                                </div>
                              </div>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details?.email
                                    ? specificUserData?.din_details?.email
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px] border-b-[1px] border-solid border-[#1D3A6D] pb-[10px]">
                                  Email ID
                                </div>
                              </div>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px]">
                                  {specificUserData?.din_details
                                    ?.last_updated_on
                                    ? formatDateViewAll(
                                        specificUserData?.din_details
                                          ?.last_updated_on,
                                        true,
                                        true
                                      )
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]">
                                  Last updated on
                                </div>
                              </div>
                            </div>
                            <div className="entity-detail-card-responsive  w-1/4 ">
                              <h4 className="  text-[16px] font-[600px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Address Details
                              </h4>

                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px]">
                                  {specificUserData?.din_details
                                    ?.present_address
                                    ? specificUserData?.din_details
                                        ?.present_address
                                    : "Not Given"}
                                </div>
                                <div className="text-[#DADADA] font-normal text-[12px]  pb-[10px]">
                                  Permanent address
                                </div>
                              </div>
                              <div className="w-50 h-50 acc-type-section-div">
                                <div className="text-[#F9F9F9] font-normal text-[14px] pb-[2px] pt-[10px] capitalize w-auto">
                                  <GeoMapLeaflet
                                    address={
                                      specificUserData?.din_details
                                        ?.present_address
                                    }
                                    owner="user_profile_din"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" bank-card-row-responsive  ">
                            <div className="entity-detail-card-responsive m-[20px]  w-1/4 ">
                              <span className="  text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                DIN details not available
                              </span>
                            </div>
                          </div>
                        ))}

                      {selectedTab === "Roles" && (
                        <div className="bank-card-row-responsive">
                          {specificUserData?.user_roles?.length === 0 ? (
                            <div className="entity-detail-card-responsive m-[20px] w-1/4">
                              <span className="text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Roles Not Available
                              </span>
                            </div>
                          ) : (
                            specificUserData?.user_roles?.map((roles, i) => (
                              <div className="entity-detail-card-responsive w-1/4">
                                <div
                                  key={i}
                                  className="w-50 h-50 acc-type-section-div"
                                >
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={roles?.role_name}
                                  >
                                    <div className="text-[#F9F9F9] font-semibold text-[14px] pb-[10px]">
                                      <h4>{roles?.role_name}</h4>
                                    </div>
                                  </Tooltip>
                                  <div className="">
                                    <p className="text-[#f9f9f9] font-normal text-[14px]">
                                      {roles?.role_desc}
                                    </p>
                                    <h6 className="text-[#DADADA] font-normal text-[12px]">
                                      Description
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}

                      {selectedTab === "Features" && (
                        <div className="bank-card-row-responsive">
                          {specificUserData?.user_features?.length === 0 ? (
                            <div className="entity-detail-card-responsive m-[20px] w-1/4">
                              <span className="text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Features Not Available
                              </span>
                            </div>
                          ) : (
                            specificUserData?.user_features?.map((roles, i) => (
                              <div className="entity-detail-card-responsive m-[20px] w-1/4">
                                <div
                                  key={i}
                                  className="w-50 h-50 acc-type-section-div"
                                >
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={roles?.feature_name}
                                  >
                                    <div className="text-[#F9F9F9] font-semibold text-[14px] pb-[10px]">
                                      <h4>{roles?.feature_name}</h4>
                                    </div>
                                  </Tooltip>
                                  <div className="">
                                    <p className="text-[#f9f9f9] font-normal text-[14px]">
                                      {roles?.feature_description}
                                    </p>
                                    <h6 className="text-[#dadada] font-normal text-[12px]">
                                      Description
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}

                      {selectedTab === "Services" && (
                        <div className="bank-card-row-responsive">
                          {specificUserData?.user_services?.length === 0 ? (
                            <div className="entity-detail-card-responsive m-[20px] w-1/4">
                              <span className="text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                Services Not Available
                              </span>
                            </div>
                          ) : (
                            specificUserData?.user_services?.map((roles, i) => (
                              <div className="entity-detail-card-responsive m-[20px] w-1/4">
                                <div
                                  key={i}
                                  className="w-50 h-50 acc-type-section-div"
                                >
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={roles?.service_name}
                                  >
                                    <div className="text-[#F9F9F9] font-semibold text-[14px] pb-[10px]">
                                      <h4>{roles?.service_name}</h4>
                                    </div>
                                  </Tooltip>
                                  <div className="">
                                    <p className="text-[#f9f9f9] font-normal text-[14px]">
                                      {roles?.service_desc}
                                    </p>
                                    <h6 className="text-[#dadada] font-normal text-[12px]">
                                      Description
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}

                      {selectedTab === "Assigned tags" && (
                        <div className="bank-card-row-responsive">
                          <div className="assigned_tag_div">
                            {specificUserData?.employees?.[0]?.account_tags
                              ?.length > 0 ? (
                              specificUserData.employees[0].account_tags.map(
                                (itm, indx) => (
                                  <span className="bg-[#506994] rounded-[17px] px-[10px] py-[5px] h-auto font-[400px] text-[14px] leading-[19.07px] text-[#DADADA] gap-[10px]">
                                    {itm}
                                  </span>
                                )
                              )
                            ) : (
                              <div className="noassignedtags-card-responsive w-1/4">
                                <span className="text-[14px] font-[400px] font-sans mb-[10px] text-[#dadada] font-semibold">
                                  No tags assigned to this account.
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {!isMobileView && (
              <>
                <div className="MyPfroile_Upper_Container">
                  <div className="MyProfile-Container w-full max-w-5xl m-auto">
                    <div className="w-full max-w-5xl m-auto h-[30%] bg-[#3a5686]  ">
                      <div className=" My-Profile-details1 mt-[25px] flex border-b-2 border-[#1D3A6D]">
                        <div className=" w-[275px] my-[15px]   border-r-2 border-[#1D3A6D]">
                          <div className=" my-[10px]">
                            <p className="h-[22px] mb-[4px] font-sans font-[400px] text-[16px] text-[#f9f9f9] pr-[5px]">
                              <Tooltip
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "viewport",
                                      },
                                    },
                                  ],
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={`${specificUserData?.employees?.[0]?.first_name} ${specificUserData?.employees?.[0]?.last_name}`}
                              >
                                <h1 className="font-[400px] text-[16px] text-[#f9f9f9] truncate">{`${specificUserData?.employees?.[0]?.first_name} ${specificUserData?.employees?.[0]?.last_name}`}</h1>
                              </Tooltip>
                              {/* {refreshData?.employees?.first_name} */}
                            </p>
                            <p className="w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              Full name
                            </p>
                          </div>
                        </div>
                        <div className=" w-[280px] my-[15px] border-r-2 border-[#1D3A6D] pl-[20px]">
                          <div className=" my-[10px] ">
                            <p className=" w-[207px] h-[22px] mb-[4px]  font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                              {" "}
                              {specificUserData?.employees?.[0]?.user_id}
                            </p>
                            <p className=" w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              User ID
                            </p>
                          </div>
                        </div>
                        <div className=" w-[280px]   pl-[20px]">
                          <div className=" my-[25px] ">
                            <p className=" w-[218px] mb-[4px]  h-[22px] font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                              {" "}
                              <Tooltip
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "viewport",
                                      },
                                    },
                                  ],
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={` ${
                                  specificUserData?.employees?.[0]?.alias_name
                                    ? specificUserData?.employees?.[0]
                                        ?.alias_name
                                    : "Not Given"
                                }`}
                              >
                                <h1 className="font-[400px] text-[16px] text-[#f9f9f9] truncate">{`${specificUserData?.employees?.[0]?.alias_name} `}</h1>
                              </Tooltip>
                            </p>
                            <p className="w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              Alias
                            </p>
                          </div>
                        </div>
                        <div className=" flex cursor-pointer w-[204px] h-[90] bg-[#506994] ">
                          <div className="hover:bg-[#667CA3] w-1/2  ">
                            <div className="  my-4  " onClick={handleEdit}>
                              <div className=" px-[36px] flex items-center mb-[2px] ">
                                <img
                                  className=" w-[30px] h-[30px] "
                                  src={edit_with_white_circle}
                                />
                              </div>
                              <p className=" w-[85px] h-[28px] text-[10px] flex text-center pl-[12px] pr-[10px] ml-[23px] text-[#f9f9f9]">
                                Edit <br /> profile
                              </p>
                            </div>
                          </div>

                          <div className=" border border-r-1 my-4 border-[#1D3A6D]"></div>

                          <div
                            className=" w-1/2 hover:bg-[#667CA3]"
                            onClick={openLogout}
                          >
                            <div className=" my-4">
                              <div className=" px-[36px] flex items-center  mb-[2px]">
                                <img
                                  className=" w-[30px] h-[30px] "
                                  src={logout}
                                />
                              </div>

                              <p className=" w-[85px] h-[28px] text-[10px] text-center px-[10px] pr-[14px] ml-[10px] text-[#f9f9f9]">
                                Logout
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" My-Profile-details1 flex border-b-2 border-[#1D3A6D]">
                        <div className=" w-[281px] my-[15px]   border-r-2 border-[#1D3A6D]">
                          <div className="my-[10px]">
                            <p className=" w-[207px] h-[22px] mb-[4px]  font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                              {" "}
                              +91 {specificUserData?.employees?.[0]?.mob_no}
                            </p>
                            <p className=" h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              Contact number
                            </p>
                          </div>
                        </div>
                        <div className=" w-[563px] px-[20px]">
                          <div className="my-[25px]">
                            <p className=" w-full max-w-[500px] h-[22px] mb-[4px]  font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                              {" "}
                              <Tooltip
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "viewport",
                                      },
                                    },
                                  ],
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={` ${specificUserData?.employees?.[0]?.email_id}`}
                              >
                                <h1 className="font-[400px] text-[16px] text-[#f9f9f9] truncate">{`${specificUserData?.employees?.[0]?.email_id} `}</h1>
                              </Tooltip>
                            </p>
                            <p className=" w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              Email ID
                            </p>
                          </div>
                        </div>{" "}
                        <div className=" flex cursor-pointer w-[204px] h-[90] bg-[#506994]">
                          <div
                            className={`${
                              services?.find(
                                (service) => service === "Sup_VDevice"
                              ) !== undefined
                                ? "hover:bg-[#667CA3]"
                                : "opacity-50 cursor-default"
                            } flex justify-center w-1/2`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "Sup_VDevice"
                                ) !== undefined
                              ) {
                                navigate("/settings/Devices");
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "Sup_VDevice",
                                  },
                                });
                              }
                            }}
                          >
                            <div className="  my-4">
                              <div className=" px-[36px] flex items-center mb-[2px] ">
                                <img
                                  className=" w-[30px] h-[30px] "
                                  src={authorized_devices_with_white_circle}
                                />
                              </div>

                              <p className=" w-[85px] h-[28px] text-[10px] flex text-center px-[10px] ml-[15px] text-[#f9f9f9]">
                                Authorized <br /> devices
                              </p>
                            </div>
                          </div>

                          <div className=" border border-r-1 my-4 border-[#1D3A6D]"></div>

                          <div
                            className=" w-1/2 opacity-30  cursor-default "
                            onClick={handleToastClick}
                          >
                            <div className=" px-[36px]  flex items-center mt-[18px] mb-[2px]">
                              <img
                                className=" w-[30px] h-[30px] "
                                src={auto_generate_password}
                              />
                            </div>

                            <p className=" w-[200px] h-[28px] text-[10px] flex text-center px-[10px] ml-[10px] text-[#f9f9f9]">
                              Generate new <br /> password
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=" My-Profile-details1 flex ">
                        <div className=" w-[272px] my-[15px]  border-r-2 border-[#1D3A6D]">
                          <div className=" my-[10px] ">
                            <p className=" w-[207px] h-[22px] mb-[4px]  font-sans font-[400px] text-[16px] text-[#4DDD37] font-semibold">
                              {" "}
                              Active
                            </p>
                            <p className="w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                              Status
                            </p>
                          </div>
                        </div>
                        <div className=" w-[280px] my-[25px]   pl-[20px]">
                          <p className=" w-[207px] h-[22px] mb-[4px]  font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                            {" "}
                            {specificUserData?.employees?.[0]?.grade}
                          </p>
                          <p className=" w-[55px] h-[16px] font-sans font-[400px] text-[12px] text-[#dadada]">
                            Grade
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-[#1D3A6D] h-auto ">
                  <div className=" mt-7 max-w-[1024px] flex m-auto">
                    <div>
                      <button
                        className={`Myprofile-detail-button  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "User details" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          User details
                        </p>
                      </button>
                    </div>
                    <div>
                      <button
                        className={`Myprofile-detail-button ml-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "CIN details" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          CIN details
                        </p>
                      </button>
                    </div>
                    <div>
                      <button
                        className={`Myprofile-detail-button ml-[20px] mr-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "DIN details" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          DIN details
                        </p>
                      </button>
                    </div>
                    <div className=" border border-r-1 border-[#ffff]"></div>
                    <div>
                      <button
                        className={`Myprofile-detail-button ml-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "Roles" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          Roles
                        </p>
                      </button>
                    </div>
                    <div>
                      <button
                        className={`Myprofile-detail-button ml-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "Features" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          Features
                        </p>
                      </button>
                    </div>
                    <div>
                      <button
                        className={`Myprofile-detail-button ml-[20px] mr-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "Services" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          Services
                        </p>
                      </button>
                    </div>

                    <div className=" border border-r-1 border-[#ffff]"></div>
                    <div>
                      <button
                        className={`Myprofile-detail-button w-[120px] ml-[20px]  ${"hover:bg-[#A3A3A3]"} ${
                          selectedTab === "Assigned tags" && "active1"
                        }`}
                        onClick={clickButton}
                      >
                        <p className=" font-sans font-[400px] text-[12px]">
                          {" "}
                          Assigned tags
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className=" max-w-[1024px] m-auto">
                    {selectedTab === "CIN details" &&
                      (specificUserData?.cin_details ? (
                        <>
                          <div className="  max-w-[1024px] m-auto   ">
                            <div className="flex">
                              <div className=" flex flex-col">
                                <div className="w-auto h-auto p-[20px] rounded-[20px] bg-[#314D7E] mt-[20px]">
                                  <p className=" w-[255px] h-[19px] font-[600px] text-[14px] text-[#dadada]">
                                    Company Details
                                  </p>
                                  <div className=" flex ">
                                    <div className=" mt-[20px] mr-[30px]">
                                      <div className=" mb-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px] font-sans w-[288px]">
                                          43783884389385
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          CIN number
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px] h-[38px] ">
                                          Subash steel and manufacturing company
                                          Pvt. Ltd.
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Company name
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px]  ">
                                          private
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Class of company
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px]  ">
                                          Active
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Company status
                                        </p>
                                      </div>
                                    </div>
                                    <div className=" border border-b-1 mt-[20px] h-[243px] border-[#1D3A6D]"></div>
                                    <div className=" mt-[20px] ml-[30px]">
                                      <div className=" mb-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px] font-sans w-[288px]">
                                          Unlisted
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Listed status
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px]  ">
                                          27 Feb 2014
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Estd. on
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px]  ">
                                          +91 90854 78899
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Contact number
                                        </p>
                                      </div>

                                      <div className=" border border-b-1 w-[288px] border-[#1D3A6D]"></div>

                                      <div className=" my-[10px]">
                                        <p className=" text-[#f9f9f9] font-[400px] text-[14px]  font-sans w-[288px]  ">
                                          Ashishgupta@gmail.com
                                        </p>
                                        <p className=" text-[#dadada] font-[400px] text-[12px] font-sans w-[288px]">
                                          Email ID
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" flex mt-[20px]">
                                  <div className=" w-[328px] h-[176px] bg-[#314D7E] p-[20px] rounded-[20px]">
                                    <div>
                                      <p className=" font-sans font-[600px] text-[14px] text-[#dadada]">
                                        Annual Compliance Status
                                      </p>
                                    </div>
                                    <div className=" mt-[20px]">
                                      <p className=" font-sans font-[400px] text-[14px] text-[#f9f9f9]">
                                        17 Apr 2023
                                      </p>
                                      <p className=" font-sans font-[400px] text-[12px] text-[#dadada]">
                                        Last AGM date
                                      </p>
                                    </div>

                                    <div className=" border border-b-1 my-[10px] w-[288px] border-[#1D3A6D]"></div>

                                    <div className="">
                                      <p className=" font-sans font-[400px] text-[14px] text-[#f9f9f9]">
                                        31 Jan 2024
                                      </p>
                                      <p className=" font-sans font-[400px] text-[12px] text-[#dadada]">
                                        Last BS date
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" w-[328px] ml-[20px] h-[176px] p-[20px] rounded-[20px] bg-[#314D7E]">
                                    <div>
                                      <p className=" font-sans font-[600px] text-[14px] text-[#dadada]">
                                        Capital Details
                                      </p>
                                    </div>
                                    <div className=" mt-[20px]">
                                      <p className=" font-sans font-[400px] text-[14px] text-[#f9f9f9]">
                                        ₹ 83,00,000,00
                                      </p>
                                      <p className=" font-sans font-[400px] text-[12px] text-[#dadada]">
                                        Paid up capital
                                      </p>
                                    </div>

                                    <div className=" border border-b-1 my-[10px] w-[288px] border-[#1D3A6D]"></div>

                                    <div className="">
                                      <p className=" font-sans font-[400px] text-[14px] text-[#f9f9f9]">
                                        ₹ 56,00,000,00
                                      </p>
                                      <p className=" font-sans font-[400px] text-[12px] text-[#dadada]">
                                        Authorized capital
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="w-[328px] h-[523px] ml-[20px] p-[20px] rounded-[20px] bg-[#314D7E] mt-[20px]">
                                <div className=" ">
                                  <p className="  font-sans font-[600px] text-[14px] text-[#dadada]">
                                    {" "}
                                    Address Details
                                  </p>
                                  <p className="  font-sans font-[400px] mt-[10px] text-[12px] text-[#ffff]">
                                    A-703, Dev City, P C N T D A Moshi, Spine
                                    Street, Girgaon, Mumbai- 40078 A-703, Dev
                                    City, P C N T D A Moshi, Spine Street,
                                    Girgaon, Mumbai- 40079
                                  </p>
                                  <p className="  font-sans font-[400px] text-[12px] text-[#dadada]">
                                    Permanent address
                                  </p>
                                </div>
                                <div className="MyProfile_cin_address_slide_right">
                                  <GeoMapLeaflet
                                    address=" A-703, Dev City, P C N T D A Moshi, Spine Street,
                                   Girgaon, Mumbai- 40078 A-703, Dev City, P C N T D
                                   A Moshi, Spine Street, Girgaon, Mumbai- 40079"
                                    owner="MyProfile_cin_details"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" w-[1024px] overflow-y-hidden h-[402px] p-[20px] bg-[#314D7E] my-[20px] rounded-[20px]">
                              <div>
                                <p className=" font-sans font-[600px] text-[14px] text-[#dadada]">
                                  Directors
                                </p>
                              </div>
                              <div className=" grid grid-cols-4 max-h-[330px] overflow-y-auto mt-[21px]">
                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] h-auto p-[20px] mb-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                {/* <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                            <div className=" flex items-center">
                              <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                Deep karla
                              </p>
                              <img
                                className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                src={arrow_right_blue_box}
                              />
                            </div>

                            <div className=" mt-[10px]">
                              <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                45476589780890
                              </p>
                              <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                DIN number
                              </p>
                            </div>
                          </div>

                          <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                            <div className=" flex items-center">
                              <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                Deep karla
                              </p>
                              <img
                                className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                src={arrow_right_blue_box}
                              />
                            </div>

                            <div className=" mt-[10px]">
                              <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                45476589780890
                              </p>
                              <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                DIN number
                              </p>
                            </div>
                          </div>

                          <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                            <div className=" flex items-center">
                              <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                Deep karla
                              </p>
                              <img
                                className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                src={arrow_right_blue_box}
                              />
                            </div>

                            <div className=" mt-[10px]">
                              <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                45476589780890
                              </p>
                              <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                DIN number
                              </p>
                            </div>
                          </div>

                          <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                            <div className=" flex items-center">
                              <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                Deep karla
                              </p>
                              <img
                                className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                src={arrow_right_blue_box}
                              />
                            </div>

                            <div className=" mt-[10px]">
                              <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                45476589780890
                              </p>
                              <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                DIN number
                              </p>
                            </div>
                          </div>

                          <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                            <div className=" flex items-center">
                              <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                Deep karla
                              </p>
                              <img
                                className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                src={arrow_right_blue_box}
                              />
                            </div>

                            <div className=" mt-[10px]">
                              <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                45476589780890
                              </p>
                              <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                DIN number
                              </p>
                            </div>
                          </div> */}

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>

                                <div className=" w-[231px] bg-[#506994] mb-[20px] h-auto p-[20px] rounded-[10px]">
                                  <div className=" flex items-center">
                                    <p className="font-sans font-[600px] text-[16px] text-[#f9f9f9]">
                                      Deep karla
                                    </p>
                                    <img
                                      className=" ml-[85px] w-[20px] rounded-[5px] h-[20px]"
                                      src={arrow_right_blue_box}
                                    />
                                  </div>

                                  <div className=" mt-[10px]">
                                    <p className="font-sans font-[600px] text-[14px] text-[#f9f9f9]">
                                      45476589780890
                                    </p>
                                    <p className="font-sans font-[600px] text-[12px] text-[#dadada]">
                                      DIN number
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="w-[1024px] h-[59px] bg-[#314D7E] rounded-[20px] my-5 flex items-center">
                          <span className="w-full h-[19px] font-[400px] text-[14px] leading-[19.07px] text-[#DADADA] ml-[20px]">
                            CIN details not available
                          </span>
                        </div>
                      ))}

                    {selectedTab === "Roles" && (
                      <div className="profile_roles_main">
                        {specificUserData?.user_roles?.length === 0 ? (
                          <h4 className="roles_not_available">
                            Roles Not Available
                          </h4>
                        ) : (
                          specificUserData?.user_roles?.map((roles, i) => {
                            return (
                              <div className="profile_roles_child" key={i}>
                                <Tooltip
                                  key={i}
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={roles?.role_name}
                                >
                                  <h4>{roles?.role_name} </h4>
                                </Tooltip>
                                <div className="profile_roles_des">
                                  <p>{roles?.role_desc}</p>
                                  <h6>Description</h6>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                    {selectedTab === "Features" && (
                      <div className="profile_roles_main">
                        {specificUserData?.user_features?.length === 0 ? (
                          <h4 className="roles_not_available">
                            Features Not Available
                          </h4>
                        ) : (
                          specificUserData?.user_features?.map((roles, i) => {
                            return (
                              <div className="profile_roles_child" key={i}>
                                <Tooltip
                                  key={i}
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={roles?.feature_name}
                                >
                                  <h4>{roles?.feature_name} </h4>
                                </Tooltip>
                                <div className="profile_roles_des">
                                  <p>{roles?.feature_description}</p>
                                  <h6>Description</h6>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}

                    {selectedTab === "Services" && (
                      <div className="profile_roles_main">
                        {specificUserData?.user_services?.length === 0 ? (
                          <h4 className="roles_not_available">
                            Services Not Available
                          </h4>
                        ) : (
                          specificUserData?.user_services?.map((roles, i) => {
                            return (
                              <div className="profile_roles_child" key={i}>
                                <Tooltip
                                  key={i}
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={roles?.service_name}
                                >
                                  <h4>{roles?.service_name} </h4>
                                </Tooltip>
                                <div className="profile_roles_des">
                                  <p>{roles?.service_desc}</p>
                                  <h6>Description</h6>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}

                    {selectedTab === "Assigned tags" &&
                      (specificUserData?.employees?.[0]?.account_tags ? (
                        <>
                          <div className="w-[1024px] h-auto p-[20px] bg-[#314D7E] rounded-[20px] my-5 flex items-center gap-[10px]">
                            {specificUserData?.employees?.[0]?.account_tags?.map(
                              (itm, indx) => {
                                return (
                                  <>
                                    <span className="w-auto bg-[#506994] rounded-[17px]  px-[10px] py-[5px] h-auto font-[400px] text-[14px] leading-[19.07px] text-[#DADADA] ">
                                      {itm}
                                    </span>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="w-[1024px] h-[59px] bg-[#314D7E] rounded-[20px] my-5 flex items-center">
                          <span className="w-full h-[19px] font-[400px] text-[14px] leading-[19.07px] text-[#DADADA] ml-[20px]">
                            No tags assigned to this account.
                          </span>
                        </div>
                      ))}

                    {selectedTab === "User details" && (
                      <div className="flex justify-between my-5 m-auto">
                        <section className="w-[328px] bg-[#314D7E] rounded-[20px] self-start">
                          <article className="m-[20px]">
                            <span className="w-[288px] h-[19px] font-semibold text-[14px] leading-[19.07px] text-[#DADADA]">
                              Company Details
                            </span>
                            <div className="my-[8px] h-[0.2px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                {refreshData?.entity?.entity_id
                                  ? refreshData?.entity?.entity_id
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Entity ID
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[2px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9] capitalize">
                                {refreshData?.entity?.entity_name
                                  ? refreshData?.entity?.entity_name.toLowerCase()
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Entity name
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                {refreshData?.entity?.entity_pan
                                  ? refreshData?.entity?.entity_pan
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Entity PAN number
                              </span>
                            </div>
                          </article>
                        </section>
                        <section className="w-[328px] bg-[#314D7E] rounded-[20px] self-start">
                          <article className="m-[20px]">
                            <span className="w-[288px] h-[19px] font-semibold text-[14px] leading-[19.07px] text-[#DADADA]">
                              PAN Details
                            </span>
                            <div className="my-[8px] h-[0.2px]"></div>
                            <div
                              className={`flex flex-col gap-y-2 ${
                                specificUserData?.pan_details?.pan
                                  ? services?.find(
                                      (service) => service === "VPAN"
                                    )
                                    ? "hover:bg-[#667CA3] rounded-lg -ml-[-0.38rem] py-1 pl-1 p-1 cursor-pointer"
                                    : "hover:bg-[#667CA3] rounded-lg -ml-[-0.38rem] cursor-default py-1 p-1"
                                  : "hover:bg-[#667CA3] rounded-lg -ml-[-0.38rem] cursor-default py-1 p-1"
                              }`}
                              onClick={
                                specificUserData?.pan_details?.pan
                                  ? () => {
                                      if (
                                        services?.find(
                                          (service) =>
                                            service === "VPAN" ||
                                            service === "Admin"
                                        ) !== undefined &&
                                        specificUserData?.pan_details?.pan
                                      ) {
                                        handlePANDetails(
                                          specificUserData?.pan_details?.pan
                                        );
                                      } else {
                                        navigate("/feature_not_assigned", {
                                          state: {
                                            unavailableService: "VPAN",
                                          },
                                        });
                                      }
                                    }
                                  : null
                              }
                            >
                              <div className="flex">
                                <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                  {specificUserData?.pan_details?.pan
                                    ? specificUserData?.pan_details?.pan
                                    : "Not Given"}
                                </span>
                                {specificUserData?.pan_details?.pan && (
                                  <div
                                    style={{ flex: "10%" }}
                                    className={`grid place-content-center w-3 h-4.4 bg-[#1D3A6D] rounded-md ${
                                      services?.includes("VPAN")
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed"
                                    }`}
                                  >
                                    <button>
                                      <img
                                        src={arrowRight}
                                        alt="document"
                                        className="w-3 h-3"
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                PAN number
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[2px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9] capitalize">
                                {specificUserData?.pan_details?.full_name
                                  ? specificUserData?.pan_details?.full_name.toLowerCase()
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Full name
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                {specificUserData?.pan_details?.created_on
                                  ? formatDateViewAll(
                                      specificUserData?.pan_details?.created_on,
                                      true,
                                      true
                                    )
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Created on
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                {specificUserData?.pan_details?.last_updated_on
                                  ? formatDateViewAll(
                                      specificUserData?.pan_details
                                        ?.last_updated_on,
                                      true,
                                      true
                                    )
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Last updated on
                              </span>
                            </div>
                          </article>
                        </section>
                        <section className="w-[328px] bg-[#314D7E] rounded-[20px] self-start">
                          <article className="m-[20px]">
                            <span className="w-[288px] h-[19px] font-semibold text-[14px] leading-[19.07px] text-[#DADADA]">
                              Origin Timeline
                            </span>
                            <div className="my-[8px] h-[0.2px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9] capitalize">
                                {specificUserData?.employees?.[0]
                                  ?.created_by_name
                                  ? specificUserData?.employees?.[0]?.created_by_name.toLowerCase()
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Created by
                              </span>
                            </div>
                            <div className="bg-[#1D3A6D] h-[2px] my-[8px]"></div>
                            <div className="flex flex-col gap-y-2">
                              <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                {specificUserData?.employees?.[0]?.created_on
                                  ? formatDateViewAll(
                                      specificUserData?.employees?.[0]
                                        ?.created_on,
                                      true,
                                      true
                                    )
                                  : "Not Given"}
                              </span>
                              <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                Created on
                              </span>
                            </div>
                          </article>
                        </section>
                      </div>
                    )}
                    {selectedTab === "DIN details" && (
                      <>
                        {specificUserData?.din_details ? (
                          <div className="flex justify-between my-5 m-auto">
                            <section className="w-[676px] bg-[#314D7E] rounded-[20px] self-start">
                              <article className="m-[20px]">
                                <span className="w-[288px] h-[19px] font-semibold text-[14px] leading-[19.07px] text-[#DADADA]">
                                  Company Details
                                </span>
                                <div className="my-[8px] h-[0.2px]"></div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: "50%",
                                    }}
                                  >
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.din_number
                                          ? specificUserData?.din_details
                                              ?.din_number
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        DIN number
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.full_name
                                          ? specificUserData?.din_details
                                              ?.full_name
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Full name
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.father_name
                                          ? specificUserData?.din_details
                                              ?.father_name
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Father’s name
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details?.dob
                                          ? formatDateViewAll(
                                              specificUserData?.din_details
                                                ?.dob,
                                              undefined,
                                              undefined
                                            )
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Date of birth
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#1D3A6D",
                                      width: "2px",
                                      margin: "0 18px 0 18px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      flex: "50%",
                                    }}
                                  >
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.nationality
                                          ? specificUserData?.din_details
                                              ?.nationality
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Nationality
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.pan_number
                                          ? specificUserData?.din_details
                                              ?.pan_number
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        PAN number
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details?.email
                                          ? specificUserData?.din_details?.email
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Email ID
                                      </span>
                                    </div>
                                    <div className="bg-[#1D3A6D] h-[1.5px] my-[8px]"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                        {specificUserData?.din_details
                                          ?.last_updated_on
                                          ? formatDateViewAll(
                                              specificUserData?.din_details
                                                ?.last_updated_on,
                                              true,
                                              true
                                            )
                                          : "Not Given"}
                                      </span>
                                      <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                        Last updated on
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </section>
                            <section className="w-[328px] bg-[#314D7E] rounded-[20px] self-start">
                              <article className="m-[20px]">
                                <span className="w-[288px] h-[19px] font-semibold text-[14px] leading-[19.07px] text-[#DADADA]">
                                  Address Details
                                </span>
                                <div className="my-[6px] h-[0.2px]"></div>
                                <div className="flex flex-col gap-y-2">
                                  <span className="w-[288px] h-auto font-normal text-[14px] leading-[19.07px] text-[#F9F9F9]">
                                    {specificUserData?.din_details
                                      ?.present_address
                                      ? specificUserData?.din_details
                                          ?.present_address
                                      : "Not Given"}
                                  </span>
                                  <span className="w-[288px] h-[16px] font-normal text-[12px] leading-[16.34px] text-[#DADADA]">
                                    Permanent address
                                  </span>
                                </div>
                                <div className="h-[0.2px] my-[6px]"></div>
                                <div className="w-[288px] h-[110px] rounded-[16px]">
                                  <GeoMapLeaflet
                                    address={
                                      specificUserData?.din_details
                                        ?.present_address
                                    }
                                    owner="user_profile_din"
                                  />
                                </div>
                              </article>
                            </section>
                          </div>
                        ) : (
                          <div className="w-[1024px] h-[59px] bg-[#314D7E] rounded-[20px] my-5 flex items-center">
                            <span className="w-full h-[19px] text-[14px] leading-[19.07px] text-[#DADADA] ml-[20px]">
                              DIN details not available
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default MyProfileDetails;
