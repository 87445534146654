import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import noaccount from "../../../assets/No transaction found.svg";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import MyDeviceCard from "../../../components/MyDevices/MyDeviceCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { getAllDevices } from "../../../redux/features/devices/devices";
import arrow_left_white from "../../../assets/ArrowsIcons/arrow_left_white.svg";
function MyDevices() {
  const location = useLocation();
  const [allDevicesData, setAllDevicesData] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );
  const isDeviceDeleted = location.state
    ? location.state.isDeviceDeleted
    : false;

  const services = useSelector((state) => state.refreshToken.services);

  const navigate = useNavigate();

  useEffect(() => {
    if (isDeviceDeleted) {
      toast.success("Device has been deleted successfully", toast_position);
      navigate("/settings/Devices");
    }
  }, []);

  useEffect(() => {
    if (services && !services?.includes("Sup_VDevice")) {
      navigate("/feature_not_assigned");
    }

    const getLoggedInDevices = async () => {
      if (currentPage === 1) {
        setLoading(true);
      }

      try {
        if (services && services?.includes("Sup_VDevice")) {
          dispatch(checkUsertoken());
          const response = await dispatch(
            getAllDevices({ page_no: currentPage, page_size: pageSize })
          );
          const devicesData = response?.payload;
          setAllDevicesData(devicesData);

          const newData = response?.payload?.devices || [];
          setAllDevices((prevData) =>
            currentPage === 1 ? newData : [...prevData, ...newData]
          );
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      } catch (err) {
        console.error(err);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    };

    getLoggedInDevices();
  }, [dispatch, currentPage, pageSize, services]);

  const fetchMoreDevices = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 800);
  };
  window.addEventListener("resize", handleResize);

  const gotHome = () => {
    navigate(-1);
  };
  return (
    <div className="bg-custom-appBack flex flex-col h-screen">
      <div className="devices_div_container">
        <div className="devices-gradient w-[26%] 3xl:w-[32%] 4xl:w-[37%] 5.6xl:w-[41%]"></div>
        <div className="devices_div ">
          <div>
            <div className="device-count-header">
              {isMobileView ? (
                <img
                  src={arrow_left_white}
                  className=" ml-[10px] h-[25px] cursor-pointer "
                  onClick={gotHome}
                />
              ) : null}
              <div className="devices-count">
                {!loading ? (
                  <div className="loader-container w-[40px] h-[40px] m-auto justify-center items-center flex">
                    <img src={dataLoading} alt="Loading..." />
                  </div>
                ) : (
                  <>
                    <p>{allDevicesData.total_count?.toLocaleString() || 0}</p>
                    <p>Total devices</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="infinityScrollDevicesView">
        {loading ? (
          <div className="loader-container m-auto justify-center items-center flex">
            <img src={dataLoading} alt="Loading..." />
          </div>
        ) : allDevices?.length > 0 ? (
          <div className="scroll flex flex-col items-center justify-center w-full">
            <InfiniteScroll
              style={{ width: "inherit" }}
              dataLength={allDevices.length || []}
              next={fetchMoreDevices}
              hasMore={
                currentPage < Math.ceil(allDevicesData?.total_count / pageSize)
              }
              loader={
                <div className="loader-container m-auto justify-center items-center flex">
                  <img src={dataLoading} alt="" />
                </div>
              }
              scrollableTarget="infinityScrollDevicesView"
              scrollThreshold={0.3}
            >
              <MyDeviceCard data={allDevices} />
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center m-auto">
            <img
              src={noaccount}
              alt="No device found"
              className="devices-acc-no-acc-image"
            />
            <p className="devices-acc-no-devicesaccount-text">
              No devices found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyDevices;
