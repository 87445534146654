import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Box } from "@mui/material";
import { viewAccountDetail } from "../../redux/features/verifyPAN/verifyPAN";
import {
  beneTxnAccountDetail,
  resetBeneTxnMultiApprovalError,
  resetBeneTxnMultiDeniedApprovalData,
} from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import DeclinedPopup from "../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import ArrowPlay from "../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import NotSelect from "../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import Selected from "../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import formatDateViewAll from "../../utils/formatDateViewAll";
import BeneTxnSideDrawer from "../SideDrawer";
import { checkUsertoken } from "../../redux/features/login/login";
import { useNavigate } from "react-router-dom";
import BeneTxnDetailPagePopup from "../BeneTransaction/BeneTxnDetailPopup";
import BeneAccDetailPagePopup from "../Accounts/BeneAccDetailPagePopup";
import confidential_transactionImg from "../../assets/GeneralIcons/confidential_transaction.svg";

const BeneficiaryMultiApprovalTxn = ({
  filterOpen,
  data,
  setAllAccounts,
  setselectAllCard,
  selectedTransactions,
  setSelectedTransactions,
  setTotalAmount,
  setSelectedSendTransactions,
  selectedSendTransactions,
}) => {
  const navigate = useNavigate();
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState("");
  const [datas, setDatas] = useState([]);
  const [txnId, setTxnId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [widthCheck, setIswidthCheck] = useState(window.innerWidth <= 950);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);

  const dispatch = useDispatch();
  const multitxnApprovedata = data;
  const {
    beneTxnAccountData,
    BeneTxnMultiApprovalError,
    BeneTxnMultiDeniedApprovalError,
  } = useSelector((state) => state?.beneTxnViewAccData);

  useEffect(() => {
    dispatch(resetBeneTxnMultiApprovalError());
    dispatch(resetBeneTxnMultiDeniedApprovalData());
  }, [BeneTxnMultiApprovalError, BeneTxnMultiDeniedApprovalError]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };
  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };
  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };
  const closeBeneAccountDetailModal = () => {
    setisDrawerOpen(false);
  };
  useEffect(() => {
    setDatas({
      ...datas,
      owner_account_balance_last_fetched_at_in_words:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at_in_words,
      owner_account_balance:
        beneTxnAccountData?.transaction[0]?.owner_account_balance,
      owner_account_balance_last_fetched_at:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at,
    });
  }, [beneTxnAccountData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37"; //#4ddd37
      case "denied":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const SideDrawerOpen = async (id, txnId) => {
    SetBeneTxnDetailsId(id);
    setisDrawerOpen(!isDrawerOpen);
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleCardClick = (account) => {
    if (account.beneficiary_account_approval_status === "approved") {
      setselectAllCard(false);
      setSelectedTransactions((prevSelected) => {
        const cardAmount = Number(account.amount);
        const isSelected = prevSelected.includes(account.txn_id);

        if (isSelected) {
          setTotalAmount((prevAmount) => prevAmount - cardAmount);
          let data = selectedSendTransactions.filter((val) => {
            return val?.txn_id !== account.txn_id;
          });
          setSelectedSendTransactions(data);
          return prevSelected.filter((id) => id !== account.txn_id);
        } else {
          if (prevSelected.length < 20) {
            setTotalAmount((prevAmount) => prevAmount + cardAmount);
            setSelectedSendTransactions((prev) => [...prev, account]);
            return [...prevSelected, account.txn_id];
          } else {
            return prevSelected;
          }
        }
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
      setIswidthCheck(window.innerWidth <= 950);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      {isMobileResponsive ? (
        <div className="flex m-auto flex-col w-auto">
          {data?.map((account, index) => (
            <Box
              key={index}
              className={`px-4 rounded-[10px] shadow-[ -5px -5px 11px #71717133 ] box-border col-span-1 h-[110px] W-[330px] m-[10px] p-[10px] relative hover:opacity-80

          `}
              style={{
                backgroundColor:
                  account.beneficiary_account_approval_status === "pending"
                    ? "#00000099"
                    : selectedTransactions.includes(account.txn_id)
                    ? "#4A6D4B"
                    : "#3A5686",
                cursor:
                  filterOpen ||
                  account.beneficiary_account_approval_status === "pending"
                    ? "default"
                    : "pointer",
                opacity: filterOpen ? "0.1" : "",
              }}
              onClick={() => handleCardClick(account)}
            >
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                <div
                  className="multiApproval_viewall_name_divMob"
                  style={{
                    color: getStatusColor(
                      account.beneficiary_account_approval_status
                    ),
                  }}
                >
                  {account.beneficiary_account_approval_status === "pending" ? (
                    <span className="w-203 h-22 text-left font-normal text-[16px] opacity-[0.7]">
                      Account Approval Pending
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          display: "flex",
                          fontSize: "16px",
                          paddingRight: "5px",
                          paddingTop: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        ₹{" "}
                        {Number(account.amount)?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        <div className="flex flex-row gap-2">
                          {account?.confidential_transaction === "Y" ? (
                            <span>
                              <img
                                className="w-[16px] h-[16px]"
                                src={confidential_transactionImg}
                                alt="confidential"
                              />
                            </span>
                          ) : (
                            <></>
                          )}

                          <span className="w-[16px] h-[16px]">
                            {selectedTransactions.includes(account?.txn_id) ? (
                              <img className="" src={Selected} alt="" />
                            ) : (
                              <img className="" src={NotSelect} alt="" />
                            )}
                          </span>
                        </div>
                      </span>
                    </>
                  )}
                </div>

                <div
                  className="text-[#F9F9F9] text-[14px] mt-2 mb-3 w-[186px] overflow-hidden overflow-ellipsis text-ellipsis"
                  style={{
                    opacity:
                      account.beneficiary_account_approval_status === "pending"
                        ? "0.7"
                        : "1",
                    fontWeight:
                      account.beneficiary_account_approval_status === "pending"
                        ? "100"
                        : "600",
                  }}
                >
                  {account.beneficiary_account_name
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </div>
                <div className="text-[#DADADA] text-[10px] font-semibold">
                  {" "}
                  {account.created_on
                    ? formatDateViewAll(account.created_on, false, true)
                    : "Not given"}
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();

                  setDatas(account);

                  if (!sessionStorage.getItem("q2p_token")) {
                    setisDrawerOpen(false);
                    dispatch(checkUsertoken());
                    return;
                  }
                  if (
                    account.beneficiary_account_approval_status === "pending"
                  ) {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      "BeneficiaryTxnMultiApprovalPending",
                      account?.beneficiary_account_id,
                      account.txn_id
                    );
                  } else {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      "BeneficiaryTxnMultiApproval",
                      account?.beneficiary_account_id,
                      account.txn_id
                    );
                  }
                }}
                className={`bg-[#f9f9f9] rounded-tl-[10px] rounded-br-[10px] h-[24px] w-[99px] absolute bottom-0 right-0 mb-0 flex gap-1  justify-center`}
                style={{
                  width:
                    account.beneficiary_account_approval_status === "pending"
                      ? "115px"
                      : "99px",
                }}
              >
                <p
                  className=" text-[#1D3A6D] font-semibold text-[10px] whitespace-nowrap leading-[27px] opacity-100 "
                  onClick={() => {
                    account.beneficiary_account_approval_status === "pending"
                      ? navigate(
                          `/accounts/beneficiary_account/details/${account.beneficiary_account_id}`,
                          {
                            state: {
                              type: "txn_single_approval",
                            },
                          }
                        )
                      : navigate(
                          `/transaction/beneficiary_transaction/details/${account.txn_id}`,
                          {
                            state: {
                              type: "txn_single_approval",
                            },
                          }
                        );
                  }}
                  style={{
                    cursor:
                      account.beneficiary_account_approval_status ===
                        "pending" ||
                      account.beneficiary_account_approval_status === "approved"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {account.beneficiary_account_approval_status === "pending"
                    ? "Approve account"
                    : " More details"}
                </p>
                <div className="w-[9px]  pt-2">
                  <img src={ArrowPlay} alt="" />
                </div>
              </div>
            </Box>
          ))}
        </div>
      ) : (
        <div className="multiApprovalViewallCard">
          {showPopup && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom bg-opacity-80">
              <DeclinedPopup
                onClose={handleClosePopup}
                accountId={deniedId}
                allAccounts={multitxnApprovedata}
                updateAllAccounts={updateAllAccounts}
                status={"Transaction"}
              />
            </div>
          )}
          {showTxnDetailPop && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <BeneTxnDetailPagePopup
                closeBeneTxnDetailModal={closeBeneTxnDetailModal}
                bene_txn_id={txnId}
                type={"bene_txn_detail_modal"}
              />
            </div>
          )}
          {isDrawerOpen && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <BeneAccDetailPagePopup
                closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                bene_account_id={BeneTxnDetailsId}
                type={"bene_account_detail_modal"}
              />
            </div>
          )}
          {data?.map((account, index) => (
            <Box
              key={index}
              className={`px-4 multiSelectApprovalViewAllCard relative hover:opacity-80

          `}
              style={{
                backgroundColor:
                  account.beneficiary_account_approval_status === "pending"
                    ? "#1C212A"
                    : selectedTransactions.includes(account.txn_id)
                    ? "#4A6D4B"
                    : "#3a5686",
                cursor:
                  filterOpen ||
                  account.beneficiary_account_approval_status === "pending"
                    ? "default"
                    : "pointer",
                opacity: filterOpen ? "0.1" : "",
              }}
              onClick={() => handleCardClick(account)}
            >
              <div className="multiApproval_view_all_user">
                <div
                  className="multiApproval_viewall_name_div"
                  style={{
                    color: getStatusColor(
                      account.beneficiary_account_approval_status
                    ),
                  }}
                >
                  {account.beneficiary_account_approval_status === "pending" ? (
                    <span className="w-203 h-22 text-left font-normal text-[16px] opacity-[0.7]">
                      Account Approval Pending
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "17px",
                          paddingRight: "5px",
                        }}
                      >
                        ₹{" "}
                        {Number(account.amount)?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>

                      <div className="flex flex-row gap-2">
                        {account?.confidential_transaction === "Y" ? (
                          <span>
                            <img
                              className="w-[16px] h-[16px]"
                              src={confidential_transactionImg}
                              alt="confidential"
                            />
                          </span>
                        ) : (
                          <></>
                        )}

                        <span className="w-[16px] h-[16px]">
                          {selectedTransactions.includes(account?.txn_id) ? (
                            <img className="" src={Selected} alt="" />
                          ) : (
                            <img className="" src={NotSelect} alt="" />
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="multiApproval_viewall_bank_acc_div"
                  style={{
                    opacity:
                      account.beneficiary_account_approval_status === "pending"
                        ? "0.7"
                        : "1",
                    fontWeight:
                      account.beneficiary_account_approval_status === "pending"
                        ? "100"
                        : "600",
                  }}
                >
                  {account.beneficiary_account_name
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </div>
                <div className="multiApproval_viewall_acc_title">
                  {" "}
                  {account.created_on
                    ? formatDateViewAll(account.created_on, false, true)
                    : "Not given"}
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();

                  setDatas(account);

                  if (!sessionStorage.getItem("q2p_token")) {
                    setisDrawerOpen(false);
                    dispatch(checkUsertoken());
                    return;
                  }
                  if (
                    account.beneficiary_account_approval_status === "pending"
                  ) {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      // "BeneficiaryTxnMultiApprovalPending",
                      account?.beneficiary_account_id,
                      account.txn_id
                    );
                  } else {
                    setSideDrawerDisabled(false);
                    openBeneTxnDetailModal();
                    setTxnId(account.txn_id);
                  }
                }}
                className={`multiApproval_viewall_card_container absolute bottom-0 right-0 mb-0 flex flex-row items-center`}
                style={{
                  width:
                    account.beneficiary_account_approval_status === "pending"
                      ? "117px"
                      : "94px",
                }}
              >
                <p
                  className="text-[#1D3A6D] p-[5px] font-normal leading-[14px] text-[11px]"
                  style={{
                    cursor:
                      account.beneficiary_account_approval_status ===
                        "pending" ||
                      account.beneficiary_account_approval_status === "approved"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {account.beneficiary_account_approval_status === "pending"
                    ? "Approve account"
                    : " More details"}
                </p>
                <span className=" w-[9px]">
                  <img src={ArrowPlay} alt="" />
                </span>
              </div>
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default BeneficiaryMultiApprovalTxn;
