import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import BeneficiaryStepper from "../../../../components/BeneCreateAccount/BeneficiaryStepper";
import { useDispatch, useSelector } from "react-redux";
import BeneBankDetails from "../BeneBankDetails";
import BeneContactDetails from "../../../../components/BeneCreateAccount/BeneContactDetails";
import BeneConfirmAccount from "../../../../components/BeneCreateAccount/BeneConfirmAccount";
import {
  verifyThisPAN,
  beneLookUp,
  resetBeneData,
  resetPANVerification,
  setIsCreateAcc,
  resetIsCreateAcc,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { toast } from "react-toastify";
import { checkUsertoken } from "../../../../redux/features/login/login";
import CreateBeneAccPanDetails from "../BenePanCrtDetails";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";
import { useNavigate } from "react-router-dom";
import arrow_left_white from "../../../../assets/ArrowsIcons/arrow_left_white.svg";
import { set } from "lodash";
import { resetInternalPage } from "../../../../redux/features/internalTransaction/internalTransactionSingleApproval";
import { is } from "date-fns/locale";

const CreateBeneficiaryAccount = () => {
  const scrollIt = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nextButtonEnabled, BenEnableNextButton] = useState(true);
  const [newMessage, setNewMessage] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isLinkedAccount, setIsLinkedAccount] = useState(false);
  const [accountType, setAccountType] = useState({ type: "", id: "" });
  const [lookupNext, setLookupNext] = useState(false);
  const [bankAccountType, setBankAccountType] = useState({ type: "", id: "" });
  const [disableCreateNext, setDisableCreateNext] = useState(false);
  const [shutViewAll, setShutViewAll] = useState(false);
  const [isAchived, setIsArchived] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isDennied, setIsDennied] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [bankAccountName, setBankAccountName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [bankIsDennied, setBankIsDennied] = useState(false);
  const [bankIsPending, setBankIsPending] = useState(false);
  const [bankLookupNext, setBankLookupNext] = useState(false);
  const [bankIsAchived, setBankIsArchived] = useState(false);
  const [bankIsApproved, setBankIsApproved] = useState(false);
  const [bankSelectedItem, setBankSelectedItem] = useState(null);
  const [bankDisableCreateNext, setBankDisableCreateNext] = useState(false);
  const [nonArchive, setNonArchive] = useState(false);
  const [newBankMessage, setBankNewMessage] = useState(true);
  const [hideBankCreate, setBankHideCreate] = useState(false);
  const [hideBankBlock, setBankHideBlock] = useState(false);
  const [bankShutViewAll, setBankShutViewAll] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [verifiedPan, setVerifiedPan] = useState(null);
  const [showAction, setShowAction] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [hideCreate, setHideCreate] = useState(false);
  const [unachivedOpenPop, setUnachivedOpenPop] = useState(false);
  const [achivedOpenPop, setAchivedOpenPop] = useState(false);
  const [hideBlock, setHideBlock] = useState(false);
  const [bankUnachivedOpenPop, setBankUnachivedOpenPop] = useState(false);
  const [bankAchivedOpenPop, setBankAchivedOpenPop] = useState(false);
  const [contactBol, setContactBol] = useState({
    email: "",
    contact: "",
    accountname: "",
  });
  const [active, setActive] = useState(false);
  const [bankUserInfo, setBankUserInfo] = useState([]);
  const [showAccInfo, setShowAccInfo] = useState(false);
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);
  const [showRejectAllTxnReson, setShowRejectAllTxnReson] = useState(false);
  const [GSTStatus, setGSTStatus] = useState(null);
  const [payload, setPayload] = useState({
    bank_account: "",
    ifsc_code: "",
    contact_no: "",
    email_id: "",
    gstin: "",
    name: "",
    categories: "",
    panNumber: "",
    gst_org_name: "",
    account_name: "",
  });

  const bankStatus = useSelector(
    (state) => state.beneAllVerification.verifyBank
  );
  const iscreateAcc = useSelector((state) => state.verifyPAN?.isCreateAcc);
  console.log(iscreateAcc, "isCreateAcc");
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [pangstConfrimBtn, setPanGstConfrimBtn] = useState(false);
  const [showAlertCategories, setShowAlertCategories] = useState(false);

  const dataReceiveFromContact = (data) => {
    setContactBol(data);
  };

  const steps = [
    "Enter PAN details",
    "Enter bank details",
    "Enter contact details",
    "Confirm and create account",
  ];

  const {
    verifyPAN,
    beneLookData,
    beneLookLoading,
    verifyPANisLoading,
    beneLookError,
  } = useSelector((state) => state.verify);

  const { verifyBank, beneBankLookUp, verifyBankisLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );
  const { isCreateAcc } = useSelector((state) => state.verify);

  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  const { services, isLoading } = useSelector((state) => state.refreshToken);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const specialRegex = /^[a-zA-Z0-9 ]*$/;

  useEffect(() => {
    if (!verifyPANisLoading) {
      if (verifyPAN?.status === true) {
        setVerifiedPan(true);
        dispatch(checkUsertoken());
        dispatch(beneLookUp({ pan_number: payload.panNumber }));
        setPayload({
          ...payload,
          legalName: verifyPAN?.pan_details?.full_name,
          account_name: verifyPAN?.pan_details?.full_name,
        });
      } else if (
        verifyPAN?.status === false &&
        payload?.panNumber.length == 10
      ) {
        setVerifiedPan(false);
        toast.error(Object.values(verifyPAN?.errors)[0][0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [verifyPAN]);

  useEffect(() => {
    if (!verifyBankisLoading) {
      if (verifyBank?.status === true) {
        setBankAccountName(true);
        setBankAccountName(verifyBank?.verification_details[0]?.full_name);
      }
    }
  }, [verifyBank]);

  useEffect(() => {
    dispatch(resetPANVerification());
    setVerifiedPan(null);
    dispatch(checkUsertoken());
    dispatch(getRefreshTokenNew());
  }, []);

  useEffect(() => {
    if (!isLoading && services != null) {
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(checkUsertoken());
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [services, isLoading]);

  const isVerifiedPan = verifiedPan === true;
  const shouldLookupNext = lookupNext === true;
  const isBeneLookDataNull = beneLookData === null;

  const shouldEnableNextButtonFirstStepper = isBeneLookDataNull
    ? isVerifiedPan
    : isVerifiedPan && shouldLookupNext;

  const isBankStatusValid = bankStatus?.status === true;
  const isBankAccountValid = payload?.bank_account?.length > 0;
  const isIfscCodeValid = payload?.ifsc_code?.length === 11;
  const isVerifyBankValid = verifyBank?.status === true;

  const shouldEnableNextButtonSecondStepper =
    benebankLookData === null
      ? isBankStatusValid &&
        isBankAccountValid &&
        isIfscCodeValid &&
        isVerifyBankValid
      : isBankStatusValid &&
        isBankAccountValid &&
        isIfscCodeValid &&
        isVerifyBankValid &&
        bankLookupNext === true;

  const isContactValid = contactBol.contact === true;
  const isEmailValid = contactBol.email === true;
  const isAccountNameValid = contactBol.accountname === true;

  const shouldEnableNextButtonThirdStepper =
    isContactValid && isEmailValid && isAccountNameValid;

  useEffect(() => {
    if (activeStep === 0 && !beneLookLoading) {
      if (shouldEnableNextButtonFirstStepper) {
        BenEnableNextButton(false);
      } else {
        if (!isMobile) BenEnableNextButton(true);
        // isMobile ? setLookupNext(true) : setLookupNext(false);
      }
    } else if (activeStep === 1) {
      if (shouldEnableNextButtonSecondStepper && bankLookupNext) {
        BenEnableNextButton(false);
      } else {
        BenEnableNextButton(true);
        // isMobile ? setBankLookupNext(true) : setBankLookupNext(false);
      }
    } else if (activeStep === 2) {
      if (shouldEnableNextButtonThirdStepper) {
        BenEnableNextButton(false);
      } else {
        BenEnableNextButton(true);
      }
    }
  }, [
    activeStep,
    bankStatus?.status,
    contactBol?.contact,
    contactBol?.email,
    payload?.bank_account,
    payload?.ifsc_code,
    verifiedPan,
    verifyBank?.status,
    bankLookupNext,
    beneLookData,
    lookupNext,
    shouldEnableNextButtonFirstStepper,
    shouldEnableNextButtonSecondStepper,
    shouldEnableNextButtonThirdStepper,
    beneLookLoading,
  ]);

  const handleKeyDown = (event) => {
    if (event.target.tagName === "INPUT") {
      if (event.key === "Tab") {
        event.preventDefault();
      }
    } else if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Tab" ||
      event.key === " "
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleNext = () => {
    if (
      activeStep === 2 &&
      selectedFields?.length === categories?.length &&
      !showAlertCategories
    ) {
      setShowAlertCategories(true);
    } else {
      setButtonDisabled(true);
      setShowAlertCategories(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (scrollIt.current) {
        if (isMobile) {
          scrollIt.current.scrollLeft += window.innerWidth;
        } else {
          scrollIt.current.scrollLeft += 1024;
        }
        setTimeout(() => {
          scrollIt.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 700);
      }
      setIsClicked(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 500);
    }
  };

  const handleBack = () => {
    setButtonDisabled(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsClicked(true);
    if (scrollIt.current) {
      if (isMobile) {
        scrollIt.current.scrollLeft -= window.innerWidth;
      } else {
        scrollIt.current.scrollLeft -= 1024;
      }
      setTimeout(() => {
        scrollIt.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 700);
    }
    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };

  const handleDeSelectConfirm = () => {
    setLookupNext(false);
    BenEnableNextButton(false);
  };

  const handleSelectFieldsChange = (selectedFields) => {
    setSelectedFields(selectedFields);
  };

  const handlePanChange = (e) => {
    const inputPan = e.target.value.toUpperCase();

    if (!specialRegex.test(inputPan)) {
      return;
    }

    if (inputPan.length > 10) {
      return;
    }
    if (!panRegex.test(inputPan) && inputPan.length === 10) {
      setVerifiedPan(false);
      toast.error("Invalid PAN", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(resetBeneData());
    } else if (inputPan.length === 10) {
      dispatch(checkUsertoken());
      dispatch(verifyThisPAN({ pan_number: inputPan }));
      setGSTStatus(null);
    } else {
      setVerifiedPan(null);
      BenEnableNextButton(true);
      setLookupNext(false);
      setShowAccInfo(false);
    }

    setPayload({ ...payload, panNumber: inputPan, gstin: "" });
    setAccountType({ type: "", id: "" });
    setIsArchived(false);
    setIsApproved(false);
    setIsDennied(false);
    setIsPending(false);
    setDisableCreateNext(false);
    setLookupNext(false);
    setNewMessage(true);
    setIsActive(false);
    setHideBlock(false);
    setHideCreate(false);
    setPanGstConfrimBtn(false);
  };

  const accountHome = () => {
    // if (isCreateAcc === true) {
    //   navigate("/transaction/beneficiary_transaction/create");
    //   // dispatch(resetIsCreateAcc());
    // } else {
    //   navigate("/accounts/beneficiary/dashboard");
    // }
    navigate(-1);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isStepper, setIsStepper] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      setIsStepper(window.innerWidth <= 800);
      // window.location.reload();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const renderBeneAccountCreate = () => {
    switch (activeStep) {
      case 0:
        return (
          <CreateBeneAccPanDetails
            panNumber={payload.panNumber}
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            verifiedPan={verifiedPan}
            handlePanChange={handlePanChange}
            panDetails={verifyPAN?.pan_details}
            beneLookData={beneLookData}
            beneLookLoading={beneLookLoading}
            lookupNext={lookupNext}
            setLookupNext={setLookupNext}
            BenEnableNextButton={BenEnableNextButton}
            verifyPANisLoading={verifyPANisLoading}
            isAchived={isAchived}
            setIsArchived={setIsArchived}
            setIsApproved={setIsApproved}
            isApproved={isApproved}
            accountType={accountType}
            setAccountType={setAccountType}
            isDennied={isDennied}
            setIsDennied={setIsDennied}
            setIsPending={setIsPending}
            isPending={isPending}
            shutViewAll={shutViewAll}
            setShutViewAll={setShutViewAll}
            disableCreateNext={disableCreateNext}
            setDisableCreateNext={setDisableCreateNext}
            showAction={showAction}
            setShowAction={setShowAction}
            isActive={isActive}
            setIsActive={setIsActive}
            setNewMessage={setNewMessage}
            newMessage={newMessage}
            beneLookError={beneLookError}
            setHideBlock={setHideBlock}
            setHideCreate={setHideCreate}
            hideBlock={hideBlock}
            hideCreate={hideCreate}
            type="createFlow"
            nonArchive={nonArchive}
            setNonArchive={setNonArchive}
            isLinkedAccount={isLinkedAccount}
            setIsLinkedAccount={setIsLinkedAccount}
            unachivedOpenPop={unachivedOpenPop}
            setUnachivedOpenPop={setUnachivedOpenPop}
            achivedOpenPop={achivedOpenPop}
            setAchivedOpenPop={setAchivedOpenPop}
            isButtonDisabled={isButtonDisabled}
            setPayload={setPayload}
            payload={payload}
            showAccInfo={showAccInfo}
            setShowAccInfo={setShowAccInfo}
            handleDeSelectConfirm={handleDeSelectConfirm}
            showRejectTnxPopup={showRejectTnxPopup}
            setRejectTxnPopup={setRejectTxnPopup}
            showRejectAllTxnReson={showRejectAllTxnReson}
            setShowRejectAllTxnReson={setShowRejectAllTxnReson}
            GSTStatus={GSTStatus}
            setGSTStatus={setGSTStatus}
            activeStep={activeStep}
            isVerifiedPan={isVerifiedPan}
            pangstConfrimBtn={pangstConfrimBtn}
            setPanGstConfrimBtn={setPanGstConfrimBtn}
          />
        );
      case 1:
        return (
          <BeneBankDetails
            isButtonDisabled={isButtonDisabled}
            bankIsAchived={bankIsAchived}
            setBankIsArchived={setBankIsArchived}
            setBankIsApproved={setBankIsApproved}
            bankIsApproved={bankIsApproved}
            bankSelectedItem={bankSelectedItem}
            setBankSelectedItem={setBankSelectedItem}
            bankLookupNext={bankLookupNext}
            setBankLookupNext={setBankLookupNext}
            setBankIsPending={setBankIsPending}
            bankIsPending={bankIsPending}
            bankIsDennied={bankIsDennied}
            setBankIsDennied={setBankIsDennied}
            nextButtonEnabled={nextButtonEnabled}
            BenEnableNextButton={BenEnableNextButton}
            activeStep={activeStep}
            payload={payload}
            setPayload={setPayload}
            setBankShutViewAll={setBankShutViewAll}
            bankShutViewAll={bankShutViewAll}
            setBankDisableCreateNext={setBankDisableCreateNext}
            bankDisableCreateNext={bankDisableCreateNext}
            bankAccountType={bankAccountType}
            setBankAccountType={setBankAccountType}
            active={active}
            setActive={setActive}
            setBankNewMessage={setBankNewMessage}
            hideBankBlock={hideBankBlock}
            setBankHideBlock={setBankHideBlock}
            setBankHideCreate={setBankHideCreate}
            setBankAchivedOpenPop={setBankAchivedOpenPop}
            bankAchivedOpenPop={bankAchivedOpenPop}
            setBankUnachivedOpenPop={setBankUnachivedOpenPop}
            bankUnachivedOpenPop={bankUnachivedOpenPop}
            setBankUserInfo={setBankUserInfo}
          />
        );
      case 2:
        return (
          <BeneContactDetails
            payload={payload}
            setPayload={setPayload}
            categories={categories}
            onSelectFieldsChange={handleSelectFieldsChange}
            BenEnableNextButton={BenEnableNextButton}
            title="Beneficiary's Contact Details"
            parentCreateBen={dataReceiveFromContact}
            showAlertCategories={showAlertCategories}
            setShowAlertCategories={setShowAlertCategories}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <BeneConfirmAccount
            payload={payload}
            selectedFields={selectedFields}
            bankAccountName={bankAccountName}
            setShowModalPopup={setShowModalPopup}
            showModalPopup={showModalPopup}
            bankUserInfo={bankUserInfo}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="main_Container"
      style={{
        height:
          unachivedOpenPop ||
          achivedOpenPop ||
          bankUnachivedOpenPop ||
          bankAchivedOpenPop ||
          showModalPopup ||
          showRejectTnxPopup ||
          showRejectAllTxnReson ||
          showAlertCategories
            ? "100vh"
            : "fit-content",
      }}
    >
      <div className="stepper_Div" style={{ width: isMobile ? "100vw" : "" }}>
        <BeneficiaryStepper
          nextButtonEnabled={nextButtonEnabled}
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          isClicked={isClicked}
          lookupNext={lookupNext}
          bankLookupNext={bankLookupNext}
          payload={payload}
          selectedFields={selectedFields}
          panNumber={payload.panNumber}
          verifiedPan={verifiedPan}
          isButtonDisabled={isButtonDisabled}
          beneLookError={beneLookError}
          setShowModalPopup={setShowModalPopup}
          showAlertCategories={showAlertCategories}
          setShowAlertCategories={setShowAlertCategories}
        />
      </div>

      {isMobile && (
        <div className=" h-[74px] bg-[#3A5686] flex items-center pl-[22px] w-full fixed z-20">
          <img
            onClick={accountHome}
            src={arrow_left_white}
            className=" w-[16px] h-[16px] cursor-pointer"
          />

          <div className=" flex flex-col pl-[20px]">
            <p className=" text-[16px] font-sans font-[400px] text-[#f9f9f9]">
              {" "}
              Beneficiary Account
            </p>
            {activeStep === 0 && (
              <p className="text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                {" "}
                Enter PAN details
              </p>
            )}
            {activeStep === 1 && (
              <p className="text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                {" "}
                Enter bank details
              </p>
            )}
            {activeStep === 2 && (
              <p className="text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                {" "}
                Enter contact details
              </p>
            )}
            {activeStep === 3 && (
              <p className="text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                {" "}
                Confirm and create account
              </p>
            )}
          </div>
        </div>
      )}

      {isMobile ? (
        <>
          <div
            className={`form_Div`}
            ref={scrollIt}
            style={{ width: isMobile ? "100%" : "1024px" }}
          >
            {renderBeneAccountCreate()}
          </div>
        </>
      ) : (
        <>
          <div
            className={`${isMobile ? "form_Div_responsive" : "form_Div"}`}
            ref={scrollIt}
            style={{ width: isMobile ? "100%" : "1024px" }}
          >
            <CreateBeneAccPanDetails
              panNumber={payload.panNumber}
              isFocus={isFocus}
              setIsFocus={setIsFocus}
              verifiedPan={verifiedPan}
              handlePanChange={handlePanChange}
              panDetails={verifyPAN?.pan_details}
              beneLookData={beneLookData}
              beneLookLoading={beneLookLoading}
              lookupNext={lookupNext}
              setLookupNext={setLookupNext}
              BenEnableNextButton={BenEnableNextButton}
              verifyPANisLoading={verifyPANisLoading}
              isAchived={isAchived}
              setIsArchived={setIsArchived}
              setIsApproved={setIsApproved}
              isApproved={isApproved}
              accountType={accountType}
              setAccountType={setAccountType}
              isDennied={isDennied}
              setIsDennied={setIsDennied}
              setIsPending={setIsPending}
              isPending={isPending}
              shutViewAll={shutViewAll}
              setShutViewAll={setShutViewAll}
              disableCreateNext={disableCreateNext}
              setDisableCreateNext={setDisableCreateNext}
              showAction={showAction}
              setShowAction={setShowAction}
              isActive={isActive}
              setIsActive={setIsActive}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              beneLookError={beneLookError}
              setHideBlock={setHideBlock}
              setHideCreate={setHideCreate}
              hideBlock={hideBlock}
              hideCreate={hideCreate}
              type="createFlow"
              nonArchive={nonArchive}
              setNonArchive={setNonArchive}
              isLinkedAccount={isLinkedAccount}
              setIsLinkedAccount={setIsLinkedAccount}
              unachivedOpenPop={unachivedOpenPop}
              setUnachivedOpenPop={setUnachivedOpenPop}
              achivedOpenPop={achivedOpenPop}
              setAchivedOpenPop={setAchivedOpenPop}
              isButtonDisabled={isButtonDisabled}
              setPayload={setPayload}
              payload={payload}
              showAccInfo={showAccInfo}
              setShowAccInfo={setShowAccInfo}
              handleDeSelectConfirm={handleDeSelectConfirm}
              showRejectTnxPopup={showRejectTnxPopup}
              setRejectTxnPopup={setRejectTxnPopup}
              showRejectAllTxnReson={showRejectAllTxnReson}
              setShowRejectAllTxnReson={setShowRejectAllTxnReson}
              GSTStatus={GSTStatus}
              setGSTStatus={setGSTStatus}
            />

            <BeneBankDetails
              isButtonDisabled={isButtonDisabled}
              bankIsAchived={bankIsAchived}
              setBankIsArchived={setBankIsArchived}
              setBankIsApproved={setBankIsApproved}
              bankIsApproved={bankIsApproved}
              bankSelectedItem={bankSelectedItem}
              setBankSelectedItem={setBankSelectedItem}
              bankLookupNext={bankLookupNext}
              setBankLookupNext={setBankLookupNext}
              setBankIsPending={setBankIsPending}
              bankIsPending={bankIsPending}
              bankIsDennied={bankIsDennied}
              setBankIsDennied={setBankIsDennied}
              nextButtonEnabled={nextButtonEnabled}
              BenEnableNextButton={BenEnableNextButton}
              activeStep={activeStep}
              payload={payload}
              setPayload={setPayload}
              setBankShutViewAll={setBankShutViewAll}
              bankShutViewAll={bankShutViewAll}
              setBankDisableCreateNext={setBankDisableCreateNext}
              bankDisableCreateNext={bankDisableCreateNext}
              bankAccountType={bankAccountType}
              setBankAccountType={setBankAccountType}
              active={active}
              setActive={setActive}
              setBankNewMessage={setBankNewMessage}
              hideBankBlock={hideBankBlock}
              setBankHideBlock={setBankHideBlock}
              setBankHideCreate={setBankHideCreate}
              setBankAchivedOpenPop={setBankAchivedOpenPop}
              bankAchivedOpenPop={bankAchivedOpenPop}
              setBankUnachivedOpenPop={setBankUnachivedOpenPop}
              bankUnachivedOpenPop={bankUnachivedOpenPop}
              setBankUserInfo={setBankUserInfo}
            />
            <BeneContactDetails
              payload={payload}
              setPayload={setPayload}
              categories={categories}
              onSelectFieldsChange={handleSelectFieldsChange}
              BenEnableNextButton={BenEnableNextButton}
              title="Beneficiary's Contact Details"
              parentCreateBen={dataReceiveFromContact}
              activeStep={activeStep}
              showAlertCategories={showAlertCategories}
              setShowAlertCategories={setShowAlertCategories}
              handleNext={handleNext}
            />
            <BeneConfirmAccount
              payload={payload}
              selectedFields={selectedFields}
              bankAccountName={bankAccountName}
              setShowModalPopup={setShowModalPopup}
              showModalPopup={showModalPopup}
              bankUserInfo={bankUserInfo}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CreateBeneficiaryAccount;
