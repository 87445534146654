import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import loader from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import arrowRight from "../../../assets/ArrowsIcons/arrow_right.svg";
import arrowRightWhite from "../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import pan from "../../../assets/VerificationIcons/pan_verification_blue.svg";
import refresh_balance from "../../../assets/RejectIcons/refresh_balance.svg";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import { toast, Bounce } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  verifyThisPANGet,
  resetGetPanData,
  resetSelectedPan,
  resetGetPanError,
  selectedGst,
  verifyThisPANPost,
  verifyThisPAN,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { useSelector, useDispatch } from "react-redux";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneVerifyThisGST } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { Tooltip, tooltipClasses } from "@mui/material";
import formatGSTDate from "../../../utils/formatGSTDate";

const PanVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [panNo, setPanNo] = useState("");
  const [updatedPan, setUpdatePan] = useState("");
  const services = useSelector((state) => state.refreshToken.services);

  const toast_position_fectching = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "229px",
      height: "49px",
      borderRadius: "10px",
      color: "#F9F9F9",
      fontSize: "14px",
    },
  };

  const { state } = useLocation();
  const handlePan = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetGetPanData());
    }
    if (e.target.value.length > 10) {
      return;
    }
    if (!specialRegex.test(e.target.value)) {
      return;
    }
    setPanNo(e.target.value.toUpperCase());
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const inputRef = useRef("");
  const {
    getVerifyPanData,
    getVerifyPanLoading,
    getVerifyPanError,
    selectedPanData,
    verifyPANisLoading,
  } = useSelector((state) => state.verify);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const specialRegex = /^[a-zA-Z0-9]*$/;

  const handleArbitraryPan = async (panNo) => {
    if ((!panRegex.test(panNo) && panNo.length === 10) || panNo.length < 10) {
      dispatch(resetGetPanError());
      toast.error("Invalid PAN", toast_position);
      return;
    }

    if (panNo.length === 10) {
      dispatch(checkUsertoken());
      dispatch(resetGetPanData());

      const response = await dispatch(
        verifyThisPANGet({ search_string: panNo })
      );

      if (response?.payload?.gstin_details === undefined) {
        const response = await dispatch(
          verifyThisPAN({ search_string: panNo })
        );
      }
    }
  };
  const handlePanClick = async () => {
    if ((!panRegex.test(panNo) && panNo.length === 10) || panNo.length < 10) {
      dispatch(resetGetPanError());
      toast.error("Invalid PAN", toast_position);
      return;
    }

    if (panNo.length === 10) {
      setUpdatePan(panNo);
      dispatch(checkUsertoken());
      dispatch(resetGetPanData());

      const response = await dispatch(
        verifyThisPANGet({ search_string: panNo })
      );

      if (response?.payload?.gstin_details === undefined) {
        const response = await dispatch(
          verifyThisPAN({ search_string: panNo })
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && panNo.length === 10) {
      handlePanClick();
    }
  };

  useEffect(() => {
    if (services && !services?.includes("VPAN")) {
      navigate("/feature_not_assigned");
    }
    if (state !== null) {
      setPanNo(state);
      handleArbitraryPan(state);
    }
    if (selectedPanData) {
      setPanNo(selectedPanData);
    }
    return () => {
      dispatch(resetSelectedPan());
    };
  }, []);

  const handleNavigate = (gst) => {
    dispatch(checkUsertoken());
    dispatch(selectedGst(gst));
    dispatch(beneVerifyThisGST({ search_string: gst }));
    dispatch(resetGetPanData());
    navigate("/settings/GST_Verification");
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [panNo]);

  const shouldSearchBarBeHidden = state !== null || selectedPanData !== null;
  const searchDiv = (
    <div className="cin_search">
      <div className="cin_search_1024">
        <div className="cin_input_div">
          <input
            ref={inputRef}
            value={panNo}
            onChange={handlePan}
            placeholder="Enter PAN number"
            style={{
              color: panNo !== "" ? "#f9f9f9" : "#DADADA",
            }}
          />
          {panNo.length === 0 ? (
            <img
              alt="search"
              src={search}
              onClick={() => inputRef.current.focus()}
            />
          ) : (
            <img
              alt="cancel"
              src={cancel}
              onClick={() => {
                dispatch(resetGetPanData());
                setPanNo("");
              }}
            />
          )}
        </div>
        <Button
          className="cin_fetch_button"
          onClick={handlePanClick}
          disabled={panNo.length !== 10}
          style={{
            color: panNo.length === 10 ? "#1d3a6d" : "#F9F9F9",
            background: panNo.length === 10 ? "#F9F9F9" : "#707070",
          }}
        >
          Fetch details
        </Button>
      </div>
    </div>
  );

  const initialGSTData = getVerifyPanData?.gstin_details?.length;

  const handleUpdateDetails = async () => {
    const up = state ?? updatedPan;

    const response = await dispatch(verifyThisPAN({ search_string: up }));

    const paragraphStyle = {
      width: "161px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#F9F9F9",
      Opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };
    const imageStyle = {
      width: "20px",
      height: "20px",
      Opacity: 1,
    };
    toast(
      <div style={divStyle} className="approving-toast-beneTxn">
        <img src={loader} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Fetching details</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: window.innerWidth > 600 ? "229px" : window.innerWidth + "px",
          height: "49px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    if (response?.payload?.status === true) {
      const res = await dispatch(verifyThisPANGet({ search_string: up }));

      setTimeout(() => {
        toast.success("Updated successfully", toast_position_fectching);
      }, 2000);
    } else {
      setTimeout(() => {
        toast.error("Failed to update", toast_position_fectching);
      }, 2000);
    }
  };

  return (
    <div className="cin_main_div">
      {shouldSearchBarBeHidden ? <></> : searchDiv}
      {getVerifyPanLoading ? (
        <div className="w-full flex items-center justify-center mt-[220px]">
          <img
            src={loader}
            alt="inputLoader"
            style={{
              width: "60px",
              height: "42px",
              maxWidth: "60px",
              maxHeight: "42px",
            }}
          />
        </div>
      ) : !getVerifyPanLoading && getVerifyPanData?.status ? (
        <>
          <div
            className={`cin_details_pan 
              ${
                shouldSearchBarBeHidden
                  ? "pt-[6rem]"
                  : "border-t border-solid border-[#1D3A6D]"
              }`}
            style={{
              marginTop: shouldSearchBarBeHidden ? "0.125rem" : "",
            }}
          >
            <div className="cin_details_1024">
              <div className="cin_details_1_pan">
                <div className="pan_details flex flex-row w-full h-full">
                  <div className="flex flex-col w-1/2 py-6 pr-16 gap-y-1">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            [`& .${tooltipClasses.arrow}`]: {
                              color: (theme) => theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={getVerifyPanData?.pan_details?.full_name}
                      arrow
                      // placement="bottom-start"
                      PopperProps={{
                        style: {
                          maxWidth: "none",
                        },
                      }}
                    >
                      <p className="w-[336px] text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px] lowercase truncate first-line:capitalize">
                        {getVerifyPanData?.pan_details?.full_name
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                    </Tooltip>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      Full name
                    </p>
                  </div>
                  <div className="my-4 border border-solid border-[#1D3A6D]"></div>
                  <div className="flex flex-col w-1/2 py-6 ml-5 gap-y-1">
                    <p className="text-base text-[#F9F9F9] font-sans font-normal leading-[21.79px]">
                      {getVerifyPanData?.pan_details?.pan_number}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                      PAN number
                    </p>
                  </div>
                </div>
                <div className="cin_details_1_right_pan">
                  {getVerifyPanData?.status ? (
                    <>
                      <div
                        className="flex flex-col justify-center items-center gap-y-1 w-[102px] h-[90px] bg-[#506994] cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                        onClick={
                          //   () => {
                          // dispatch(verifyThisPAN({ search_string: panNo }));
                          //   }
                          handleUpdateDetails
                        }
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                          src={refresh_balance}
                          alt="cin"
                        />
                        <p className="text-[10px] text-[#F9F9F9] text-center font-sans font-normal leading-[13.62px]">
                          Update fetched details
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="cin_img_div">
                    <img src={pan} alt="cin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cin_tabs">
            <div className="cin_tabs_1024">
              <div className="cin_tabs_button w-28 h-7 px-2.5 rounded-[20px] bg-[#FFFFFF]">
                <span className="text-xs text-[#1D3A6D] text-center font-sans font-semibold leading-[16.34px]">
                  Associated GSTs
                </span>
                {/* <Button
                  className="cin_tabs_button_1"
                  style={{
                    background: "#F9F9F9",
                    color: "#3A3649",
                    font: "normal normal 600 12px/17px Open Sans",
                    cursor: "default",
                  }}
                >
                  Associated GSTs
                </Button> */}
              </div>

              {getVerifyPanData?.gstin_details ? (
                <div className="pan_gst_have">
                  {getVerifyPanData?.gstin_details?.map((val) => {
                    return (
                      <div
                        className="pan_gst_have_1"
                        onClick={() => handleNavigate(val?.gstin_no)}
                      >
                        <div className="flex flex-row justify-between w-full mb-4">
                          <span className="text-sm text-[#DADADA] font-sans font-semibold leading-[19.07px]">
                            GST Details
                          </span>
                          <div className="grid place-content-center w-5 h-5 rounded-[3px] bg-[#1D3A6D]">
                            <img
                              className="w-2.5 h-2.5"
                              src={arrowRightWhite}
                              alt="arrowright"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-1 border-b border-solid border-[#1D3A6D]">
                          <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px]">
                            {val?.pan_no}
                          </p>
                          <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px] mb-4">
                            PAN number
                          </p>
                        </div>
                        <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-[#1D3A6D]">
                          <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px] break-words">
                            {val?.gstin_no}
                          </p>
                          <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                            GST no.
                          </p>
                        </div>
                        <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-[#1D3A6D]">
                          <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px]">
                            {val?.business_name}
                          </p>
                          <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px] break-words">
                            Company name
                          </p>
                        </div>
                        <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-[#1D3A6D]">
                          <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px]">
                            {formatGSTDate(val?.date_of_registration)}
                          </p>
                          <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                            Estd. on
                          </p>
                        </div>
                        <div className="flex flex-col pt-4 gap-y-1">
                          <p className="text-sm text-[#4DDD37] font-sans font-semibold leading-[19.07px]">
                            {val?.status}
                          </p>
                          <p className="text-xs text-[#DADADA] font-sans font-normal leading-[16.34px]">
                            Company status
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="pan_gst_div"
                  style={{ marginTop: shouldSearchBarBeHidden ? "10%" : "" }}
                >
                  <img src={notFound} alt="notfound" />
                  <span className="text-base text-[#F9F9F9] text-center font-sans font-normal leading-[21.79px] mt-5">
                    No associated GSTs found.
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ) : !getVerifyPanLoading && getVerifyPanError === true ? (
        <div className="cin_not_found mx-auto">
          <img src={notFound} alt="notfound" style={{ marginTop: "8rem" }} />
          <p className="text-base text-[#F9F9F9] text-center font-sans font-normal leading-[21.79px]">
            No data found.
            <br /> Please check the PAN number entered above.
          </p>
        </div>
      ) : (
        <div className="cin_default_img mx-auto">
          <img
            src={enter_ifsc}
            alt="bgDefault"
            style={{ marginTop: "105px" }}
          />
          <p>
            Enter PAN number in the search bar, to fetch the verified PAN
            details.
          </p>
        </div>
      )}
    </div>
  );
};

export default PanVerification;
