import React, { useEffect, useState } from "react";
import successImage from "../../../../assets/Graphics/Success page graphic.svg";
import WhitedashBoard from "../../../../assets/GeneralIcons/dashboard.svg";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import Queued from "../../../../assets/AwaitingIcons/queued.svg";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { Link } from "react-router-dom";
import pending_yellow from "../../../../assets/PendingIcons/pending_blue.svg";

const BeneficiaryMultiApprovalSuccess = () => {
  const [successPageData, setsuccessPageData] = useState([]);
  const [successPageID, setsuccessPageID] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [approvedTransactions, setApprovedTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState("approved");
  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      const transactions =
        storedSelectedApproval?.selectedSendTransactions || [];

      const pending = transactions.filter(
        (transaction) => transaction.lifecycle_status === "pending"
      );
      const approved = transactions.filter(
        (transaction) => transaction.lifecycle_status === "approved"
      );
      setsuccessPageData(storedSelectedApproval?.selectedSendTransactions);
      setPendingTransactions(pending);
      setApprovedTransactions(approved);
      setsuccessPageID(storedSelectedApproval.selectedTransactions);
    } else {
      setsuccessPageData([]);
      setsuccessPageID([]);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const TransactionList = ({ transactions, type }) => (
    <>
      {isMobileResponsive ? (
        <>
          <div
            className=" bg-[#F9F9F9]"
            style={{
              overflowY: transactions.length > 4 ? "scroll" : "hidden",

              paddingBottom: "10px",
              height: "calc(100vh - 352px)",
            }}
          >
            <div
              className=" pl-[10px] pr-[12px]"
              style={{
                overflowY: transactions.length > 4 ? "scroll" : "hidden",

                paddingBottom: "10px",
              }}
            >
              {transactions?.map((account, i) => {
                return (
                  <>
                    <div className="flex   mt-[10px]   justify-between">
                      <div className="flex justify-center items-center gap-[10px]">
                        <img
                          className="w-[20px] h-[20px]"
                          src={type === "Approved" ? Queued : pending_yellow}
                          alt=""
                        />
                        <div>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account?.beneficiary_account_name
                              .toLowerCase()
                              .split(" ")
                              .map(function (word) {
                                return (
                                  word.charAt(0).toUpperCase() + word.slice(1)
                                );
                              })
                              .join(" ")}
                          >
                            <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-[14px] leading-[22px] tracking-normal text-[#1D1D1D]">
                              {account.beneficiary_account_name
                                .toLowerCase()
                                .split(" ")
                                .map(function (word) {
                                  return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  );
                                })
                                .join(" ")}
                            </p>
                          </Tooltip>
                          <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#707070] opacity-100">
                            Beneficiary name
                          </span>
                        </div>
                      </div>
                      <div>
                        <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[14px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                          ₹ {<FormatAmount price={account?.amount} />}
                        </p>

                        <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#707070] opacity-100">
                          Amount
                        </span>
                      </div>
                    </div>
                    {i < transactions.length - 1 && (
                      <div
                        style={{
                          marginTop: "10px",
                          border: "0.5px solid #4A664D",
                          height: "0px",
                          opacity: "100",
                        }}
                      ></div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="  relative h-[105px] rounded-tl-[20px] bg-[#55985C] top-[-13px] rounded-tr-[20px]">
            <Link to={"/transaction/beneficiary_transaction/dashboard"}>
              <div className="flex flex-col gap-1 justify-center items-center h-full">
                <img
                  className="w-[33px] h-[33px]"
                  src={WhitedashBoard}
                  alt=""
                />
                <p className="w-[63px] h-[32px]  text-center text-[11px] font-semibold  text-[#F9F9F9]">
                  Go to dashboard
                </p>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <div
          className="h-[345px] w-[472px] px-5"
          style={{
            overflowY: transactions.length > 5 ? "scroll" : "hidden",
          }}
        >
          <div className="w-[411px] h-[422px] mt-[10px]">
            {transactions?.map((account, i) => {
              return (
                <>
                  <div className="flex   mt-[10px]   justify-between">
                    <div className="flex justify-center items-center gap-[10px]">
                      <img
                        className="w-[30px] h-[30px]"
                        src={type === "Approved" ? Queued : pending_yellow}
                        alt=""
                      />
                      <div>
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={account?.beneficiary_account_name
                            .toLowerCase()
                            .split(" ")
                            .map(function (word) {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")}
                        >
                          <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                            {account.beneficiary_account_name
                              .toLowerCase()
                              .split(" ")
                              .map(function (word) {
                                return (
                                  word.charAt(0).toUpperCase() + word.slice(1)
                                );
                              })
                              .join(" ")}
                          </p>
                        </Tooltip>
                        <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                          Beneficiary name
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                        ₹ {<FormatAmount price={account?.amount} />}
                      </p>
                      <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                        Amount
                      </span>
                    </div>
                  </div>
                  {i < transactions.length - 1 && (
                    <div
                      style={{
                        marginTop: "10px",
                        border: "0.5px solid #4A664D",
                        height: "0px",
                        width: "auto",
                        opacity: "100",
                      }}
                    ></div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {isMobileResponsive ? (
        <div className=" bg-[#4D8352]" style={{ height: "100vh" }}>
          <div className="flex flex-col justify-center items-center">
            <img className="h-[170px]" src={successImage} alt="successImage" />
            <p className="text-center   text-[14px] font-semibold  text-[#F9F9F9] opacity-100">
              Transactions have been approved.
            </p>
          </div>

          <div
            className=" pt-5"
            style={{
              height: "calc(100vh - 232px)",
            }}
          >
            {pendingTransactions.length > 0 &&
              approvedTransactions.length === 0 && (
                <>
                  <div
                    className="w-full flex flex-col justify-center items-center h-[46px] gap-0  rounded-b-none "
                    style={{
                      background: "#69B76F",
                    }}
                  >
                    <p className=" text-[16px] font-semibold  text-[#F9F9F9]">
                      Pending - {pendingTransactions.length}
                    </p>
                  </div>
                  <div
                    className=" bg-[#F9F9F9]"
                    style={{
                      overflowY:
                        pendingTransactions.length > 4 ? "scroll" : "hidden",

                      paddingBottom: "10px",
                      height: "calc(100vh - 352px)",
                    }}
                  >
                    <div
                      className=" pl-[10px] pr-[12px]"
                      style={{
                        overflowY:
                          pendingTransactions.length > 4 ? "scroll" : "hidden",

                        paddingBottom: "10px",
                      }}
                    >
                      {pendingTransactions?.map((account, i) => {
                        return (
                          <>
                            <div className="flex   mt-[10px]   justify-between">
                              <div className="flex justify-center items-center gap-[10px]">
                                <img
                                  className="w-[20px] h-[20px]"
                                  src={pending_yellow}
                                  alt=""
                                />
                                <div>
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account?.beneficiary_account_name
                                      .toLowerCase()
                                      .split(" ")
                                      .map(function (word) {
                                        return (
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        );
                                      })
                                      .join(" ")}
                                  >
                                    <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-[14px] leading-[22px] tracking-normal text-[#1D1D1D]">
                                      {account.beneficiary_account_name
                                        .toLowerCase()
                                        .split(" ")
                                        .map(function (word) {
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        })
                                        .join(" ")}
                                    </p>
                                  </Tooltip>
                                  <span className="flex items-start  w-[110px] h-[17px] font-medium text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#707070] opacity-100">
                                    Beneficiary name
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[14px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                  ₹ {<FormatAmount price={account?.amount} />}
                                </p>

                                <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                  Amount
                                </span>
                              </div>
                            </div>
                            {i < pendingTransactions.length - 1 && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  border: "0.5px solid #4A664D",
                                  height: "0px",
                                  opacity: "100",
                                }}
                              ></div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="  relative h-[105px] rounded-tl-[20px] bg-[#55985C] top-[-13px] rounded-tr-[20px]">
                    <Link to={"/transaction/beneficiary_transaction/dashboard"}>
                      <div className="flex flex-col gap-1 justify-center items-center h-full">
                        <img
                          className="w-[33px] h-[33px]"
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[63px] h-[32px]  text-center text-[12px] font-semibold  text-[#F9F9F9]">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </>
              )}

            {approvedTransactions.length > 0 &&
              pendingTransactions.length === 0 && (
                <>
                  <div
                    className="w-full flex flex-col justify-center items-center h-[46px] gap-0  rounded-b-none "
                    style={{
                      background: "#69B76F",
                    }}
                  >
                    <p className=" text-[12px] font-semibold  text-[#F9F9F9]">
                      Distribution created - {approvedTransactions.length}
                    </p>
                  </div>
                  <div
                    className=" bg-[#F9F9F9]"
                    style={{
                      overflowY:
                        successPageData.length > 4 ? "scroll" : "hidden",

                      paddingBottom: "10px",
                      height: "calc(100vh - 352px)",
                    }}
                  >
                    <div
                      className=" pl-[10px] pr-[12px]"
                      style={{
                        overflowY:
                          successPageData.length > 4 ? "scroll" : "hidden",

                        paddingBottom: "10px",
                      }}
                    >
                      {successPageData?.map((account, i) => {
                        return (
                          <>
                            <div className="flex   mt-[10px]   justify-between">
                              <div className="flex justify-center items-center gap-[10px]">
                                <img
                                  className="w-[20px] h-[20px]"
                                  src={Queued}
                                  alt=""
                                />
                                <div>
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account?.beneficiary_account_name
                                      .toLowerCase()
                                      .split(" ")
                                      .map(function (word) {
                                        return (
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        );
                                      })
                                      .join(" ")}
                                  >
                                    <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-[14px] leading-[22px] tracking-normal text-[#1D1D1D]">
                                      {account.beneficiary_account_name
                                        .toLowerCase()
                                        .split(" ")
                                        .map(function (word) {
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        })
                                        .join(" ")}
                                    </p>
                                  </Tooltip>
                                  <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#707070] opacity-100">
                                    Beneficiary name
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[14px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                  ₹ {<FormatAmount price={account?.amount} />}
                                </p>

                                <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#707070] opacity-100">
                                  Amount
                                </span>
                              </div>
                            </div>
                            {i < successPageData.length - 1 && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  border: "0.5px solid #4A664D",
                                  height: "0px",
                                  opacity: "100",
                                }}
                              ></div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="  relative h-[105px] rounded-tl-[20px] bg-[#55985C] top-[-13px] rounded-tr-[20px]">
                    <Link to={"/transaction/beneficiary_transaction/dashboard"}>
                      <div className="flex flex-col gap-1 justify-center items-center h-full">
                        <img
                          className="w-[33px] h-[33px]"
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[63px] h-[32px]  text-center text-[12px] font-semibold  text-[#F9F9F9]">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </>
              )}

            {approvedTransactions.length > 0 &&
              pendingTransactions.length > 0 && (
                <>
                  <div
                    className=" flex justify-between h-[46px] gap-0  rounded-b-none "
                    style={{
                      background: "#69B76F",
                    }}
                  >
                    <button
                      onClick={() => setActiveTab("approved")}
                      className={
                        activeTab === "approved"
                          ? "activeoneMobile"
                          : "tabbutton1Mobile"
                      }
                    >
                      <h5 className=" h-[17px]   whitespace-nowrap text-left  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                        Distribution created - {approvedTransactions.length}
                      </h5>
                    </button>
                    <button
                      onClick={() => setActiveTab("pending")}
                      className={
                        activeTab === "pending"
                          ? "activetwoMobile"
                          : "tabbutton2Mobile"
                      }
                    >
                      <h5 className="h-[17px] whitespace-nowrap  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                        Pending - {pendingTransactions.length}
                      </h5>
                    </button>
                  </div>
                  {activeTab === "approved" ? (
                    <TransactionList
                      transactions={approvedTransactions}
                      type={"Approved"}
                    />
                  ) : (
                    <TransactionList
                      transactions={pendingTransactions}
                      type={"Pending"}
                    />
                  )}
                </>
              )}
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-[#457549]">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-lg justify-center">
              <div className="flex-1 mt-[66px] pt-[112px]">
                <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
                  <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                    <img
                      src={successImage}
                      width={"100%"}
                      height={"100%"}
                      alt=""
                      srcSet=""
                    />
                    <div className="pt-7">
                      <p className="text-xl">
                        Transactions have been approved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 mt-[66px] py-[35px]">
                <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF] rounded-[20px] items-center justify-between">
                  {pendingTransactions.length > 0 &&
                    approvedTransactions.length === 0 && (
                      <>
                        <div className="flex flex-col gap-1 justify-center items-center h-[85px] w-[472px] bg-[#55985C] rounded-tl-[20px] rounded-tr-[20px]">
                          <p className=" whitespace-nowrap  font-semibold text-[16px]  text-[#F9F9F9] opacity-100">
                            {" "}
                            Pending - {pendingTransactions.length}
                          </p>

                          <span className="w-[472px] text-center font-normal text-[12px] h-[16px] text-[#F9F9F9] opacity-100 ">
                            It is approved and requires another user's approval
                            before distribution.
                          </span>
                        </div>

                        <div
                          className="h-[345px] w-[472px] px-5"
                          style={{
                            overflowY:
                              pendingTransactions.length > 5
                                ? "scroll"
                                : "hidden",
                          }}
                        >
                          <div className="w-full h-[422px] mt-[10px]">
                            {pendingTransactions?.map((account, i) => {
                              return (
                                <>
                                  <div className="flex mt-[10px] justify-between">
                                    <div className="flex justify-center items-center gap-[10px]">
                                      <img
                                        className="w-[30px] h-[30px]"
                                        src={pending_yellow}
                                        alt=""
                                      />
                                      <div>
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(function (word) {
                                              return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                              );
                                            })
                                            .join(" ")}
                                        >
                                          <p className="whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                                            {account.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(function (word) {
                                                return (
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                                );
                                              })
                                              .join(" ")}
                                          </p>
                                        </Tooltip>
                                        <span className="flex items-start w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                          Beneficiary name
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                        ₹{" "}
                                        {
                                          <FormatAmount
                                            price={account?.amount}
                                          />
                                        }
                                      </p>

                                      <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                        Amount
                                      </span>
                                    </div>
                                  </div>
                                  {i < pendingTransactions.length - 1 && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        border: "0.5px solid #4A664D",
                                        height: "0px",
                                        width: "auto",
                                        opacity: "100",
                                      }}
                                    ></div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}

                  {approvedTransactions.length > 0 &&
                    pendingTransactions.length === 0 && (
                      <>
                        <div className="h-[77px] w-[472px]  bg-[#55985C] rounded-tl-[20px] rounded-tr-[20px]">
                          <div className="flex justify-center mt-[14px] gap-[10px]">
                            <p className="w-[181px] whitespace-nowrap text-left font-semibold text-[16px] leading-[22px] tracking-[0px] text-[#F9F9F9] opacity-100">
                              {" "}
                              Distribution created -{" "}
                              {approvedTransactions.length}
                            </p>
                          </div>
                          <div className="flex justify-center mt-[8px]">
                            <p className="w-[284px] text-center font-normal whitespace-nowrap mr-8 text-[12px] leading-[17px] tracking-[0px] text-[#DADADA] opacity-100">
                              The transaction has been approved and is set for
                              distribution.
                            </p>
                          </div>
                        </div>

                        <div
                          className="h-[345px] w-[472px] px-5"
                          style={{
                            overflowY:
                              successPageData.length > 5 ? "scroll" : "hidden",
                            paddingBottom: "10px",
                          }}
                        >
                          <div
                            className="w-full mt-[10px]"
                            style={{
                              height: "fit-content",
                              paddingBottom: "5px",
                            }}
                          >
                            {successPageData?.map((account, i) => {
                              return (
                                <>
                                  <div className="flex mt-[10px] justify-between">
                                    <div className="flex justify-center items-center gap-[10px]">
                                      <img
                                        className="w-[30px] h-[30px]"
                                        src={Queued}
                                        alt=""
                                      />
                                      <div>
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(function (word) {
                                              return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                              );
                                            })
                                            .join(" ")}
                                        >
                                          <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                                            {account.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(function (word) {
                                                return (
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                                );
                                              })
                                              .join(" ")}
                                          </p>
                                        </Tooltip>
                                        <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                          Beneficiary name
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                        ₹{" "}
                                        {
                                          <FormatAmount
                                            price={account?.amount}
                                          />
                                        }
                                      </p>

                                      <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                        Amount
                                      </span>
                                    </div>
                                  </div>
                                  {i < successPageData.length - 1 && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        border: "0.5px solid #4A664D",
                                        height: "0px",
                                        width: "auto",
                                        opacity: "100",
                                      }}
                                    ></div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}

                  {approvedTransactions.length > 0 &&
                    pendingTransactions.length > 0 && (
                      <>
                        <div className="h-[93px] w-[472px] bg-[#4C8452] rounded-tl-[27px] rounded-tr-[27px]">
                          <div className=" flex justify-between">
                            <button
                              onClick={() => setActiveTab("approved")}
                              className={
                                activeTab === "approved"
                                  ? "activeone"
                                  : "tabbutton1"
                              }
                            >
                              <h5 className="w-[136px] h-[17px]   whitespace-nowrap text-left  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                                Distribution created -{" "}
                                {approvedTransactions.length}
                              </h5>
                              <p className="w-[204px] h-[34px] text-center font-normal text-[12px] leading-[14px] text-[#DADADA] opacity-100">
                                The transaction has been approved and is set for
                                distribution.
                              </p>
                            </button>
                            <button
                              onClick={() => setActiveTab("pending")}
                              className={
                                activeTab === "pending"
                                  ? "activetwo"
                                  : "tabbutton2"
                              }
                            >
                              <h5 className="h-[17px] whitespace-nowrap  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                                Pending - {pendingTransactions.length}
                              </h5>
                              <p className="w-[204px] h-[34px] text-center text-[12px] leading-[14px] text-[#DADADA] opacity-100 font-normal tracking-normal">
                                It is approved and requires another user's
                                approval before distribution.
                              </p>
                            </button>
                          </div>
                        </div>

                        {activeTab === "approved" ? (
                          <TransactionList
                            transactions={approvedTransactions}
                            type={"Approved"}
                          />
                        ) : (
                          <TransactionList
                            transactions={pendingTransactions}
                            type={"Pending"}
                          />
                        )}
                      </>
                    )}

                  <div className="flex justify-center items-center h-[136px] w-[472px] bg-[#55985C] rounded-bl-[27px] rounded-br-[27px]">
                    <Link to={"/transaction/beneficiary_transaction/dashboard"}>
                      <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                        <img
                          className="w-[40px] h-[40px]"
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-[#FFFFFF] opacity-100">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BeneficiaryMultiApprovalSuccess;
