import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import LoginPage from "./containers/LoginPage";
import NotFound from "./containers/LoginPage/PageNotFound/index";
import AppBar from "./AppBar";
import InstatntPayRefill from "./containers/InstantPayRefill";
import ClientTransactions from "./containers/ClientTransactions";
import AdjustmentTransactions from "./containers/AdjustmentTransactions";
import NodalTransactions from "./containers/NodalTransactions";
import FieldForceTransactions from "./containers/FieldForceTransactions";
import InternalTransactions from "./containers/InternalTransactions";
import ReimbursementTransactions from "./containers/ReimbursementTransactions";
import DuplicateTransactions from "./containers/DuplicateTransactions";
import ChargebackTransactions from "./containers/ChargebackTransactions";
import VendorTransactions from "./containers/VendorTransactions";
import RecieveTxn from "./containers/RecieveTxn";
import InstantPayRefillViewAllT from "./containers/InstantPayRefill/InstaPayRefillViewAllT";
import ClientViewAllT from "./containers/ClientTransactions/ClientViewAllT";
import VendorViewAllT from "./containers/VendorTransactions/VendorViewAllT";
import NodalViewAllT from "./containers/NodalTransactions/NodalViewAllT";
import FieldForceViewAllT from "./containers/FieldForceTransactions/FieldForceViewAllT";
import InternalViewAllT from "./containers/InternalTransactions/InternalViewAllT";
import ReimbursementViewAllT from "./containers/ReimbursementTransactions/ReimbursementViewAllT";
import DuplicateViewAllT from "./containers/DuplicateTransactions/DuplicateViewAllT";
import ChargebackViewAllT from "./containers/ChargebackTransactions/ChargebackViewAllT";
import CreateTxn from "./containers/CreateTxn";
import Development from "./containers/Development";
import CreateVendorTxn from "./containers/CreateTxn/VendorTxn";
import TransactionStatus from "./containers/CreateTxn/VendorTxn/TransactionStatus";
import VendorApproval from "./containers/ApprovalTxn/VendorTxn";
import SettleVendorTxns from "./containers/SettlementRequired/VendorTxn/SelectTxnList";
import VendorSettleVendorTxns from "./containers/VendorSettleVendorTxns";
import SelectTxnList from "./containers/SettlementRequired/InstantPay/SelectTxnList";
import VenApprovalStatus from "./containers/ApprovalTxn/VendorTxn/VenApprovalStatus";
import SelectPayment from "./containers/CreateTxn/InstantPayRefill/SelectPayment";
import SettlementStatus from "./containers/SettlementRequired/InstantPay/SettlementStatus";
import CreateClientTxn from "./containers/CreateTxn/ClientTxn";
import ClientCreateStatus from "./containers/CreateTxn/ClientTxn/ClientCreateStatus";
import CreateInternalTxn from "./containers/CreateTxn/InternalTxn";
import FieldForceApproval from "./containers/ApprovalTxn/FieldForceTxn";
import SettleFFTxn from "./containers/SettlementRequired/FieldForceTxn/SelectTxnList";
import FFApprovalStatus from "./containers/ApprovalTxn/FieldForceTxn/ApprovalStatus";
import VendorTxnDetails from "./containers/TxnDetails/VendorTxn";
import InstantPayTxnDetails from "./containers/TxnDetails/InstantPayTxn";
import CreateFFTxn from "./containers/CreateTxn/FFTxn";
import FFTxnDetails from "./containers/TxnDetails/FFTxn";
import ClientTxnDetails from "./containers/TxnDetails/ClientTxn";
import InternalTxnDetails from "./containers/TxnDetails/InternalTxn";
import PaymentGateway from "./components/PaymentGateway";
import SettlementTransactionStatus from "./containers/SettlementRequired/SettlementTransactionStatus";
import CreateIPTxn from "./containers/CreateTxn/InstantPayRefill";
import SettleInstantPayTxns from "./containers/SettlementRequired/InstantPay/SelectTxnList";
import ClientRefundsViewAllT from "./containers/ClientTransactions/ClientRefundsViewAllT";
import CreateAccount from "./containers/CreateAccount";
import BeneTxnSuccess from "./containers/BeneficiaryTransactions/BeneTxnSuccess";
import BeneTxnDetails from "./containers/BeneficiaryTransactions/BeneTxnDetails";
import BeneTxnViewall from "./containers/BeneficiaryTransactions/BeneTxnViewall";
import BeneCreateTxn from "./containers/BeneficiaryTransactions/BeneCreateTxn";
import BeneTxnDashboard from "./containers/BeneficiaryTransactions/BeneTxnDashboard";
import BeneTxnRequireApproval from "./containers/BeneficiaryTransactions/BeneTxnRequiredApproval";
import BeneTxnMultiApproval from "./containers/BeneficiaryTransactions/BeneTxnMultiView";
import BeneficiaryMultiApprovalTxnDetails from "./components/BeneficiaryMultiApprovalTxn/BeneficiaryMultiApprovalTxnDetails";
import BeneficiaryMultiApprovalSuccess from "./components/BeneficiaryMultiApprovalTxn/BeneficiaryMultiApprovalTxnDetails/BeneficiaryMultiApprovalSuccesPage";
import BeneficiaryMultiApprovalErrorPage from "./components/BeneficiaryMultiApprovalTxn/BeneficiaryMultiApprovalTxnDetails/BeneficiaryMultiApprovalError";
import BeneficiaryMultiApprovalDeclined from "./components/BeneficiaryMultiApprovalTxn/BeneficiaryMultiApprovalTxnDetails/BeneficiaryMultiApprovalDeclinedPage";
import BeneTxnFailed from "./containers/BeneficiaryTransactions/BeneTxnFailed";
import settingsDashboardMapper from "./services/Settings/Dashboard/settingsDashboard";
import MyDevices from "./containers/Settings/MyDevices";
import MyDeviceDetails from "./containers/Settings/MyDeviceDetails";
import MyProfile from "./containers/MyProfile";
import CINVerification from "./containers/Settings/CINVerification/index";
import DINVerification from "./containers/Settings/DINVerification/index";
import UsersSettings from "./containers/Settings/Users";
import UserDetails from "./containers/Settings/Users/UserDetails";
import PanVerification from "./containers/Settings/PanVerification";
import IfscSettings from "./containers/Settings/IFSCDetails";
import SettingsDashboard from "./containers/Settings/Dashboard";
import OwnerAccountIFSC from "./containers/Accounts/OwnerAccount/OwnerAccountIFSC";
import GSTVerification from "./containers/Settings/GSTDetails";
import SettingGSTDetails from "./containers/Settings/GSTDetails/GSTPage";
import VendorAccountApproval from "./containers/Accounts/VendorAccount/Approval";
import FieldForceAccountApproval from "./containers/Accounts/FieldForceAccount/Approval";
import AccountApprovalStatus from "./containers/Accounts/VendorAccount/AccountApprovalStatus/index.";
import FFAccountApprovalStatus from "./containers/Accounts/FieldForceAccount/FFAccountApprovalStatus/index.";
import AccountDashboard from "./containers/Accounts/Dashboard";
import AccountViewAll from "./containers/Accounts/ViewAll";
import AccountDetails from "./containers/Accounts/Details";
import CreateVendorAccount from "./containers/Accounts/VendorAccount/Create";
import CreateFFAccount from "./containers/Accounts/FieldForceAccount/Create";
import CreateClientAccount from "./containers/Accounts/ClientAccount/Create";
import CreateOwnerAccount from "./containers/Accounts/OwnerAccount/Create";
import AddCard from "./containers/Accounts/CardAccount/Create";
import AccCreateStatus from "./containers/Accounts/CreateResponse";
import CreateBeneficiaryAccount from "./containers/Accounts/BeneficiaryAccount/BeneCreate";
import Dashboard from "./containers/Accounts/BeneficiaryAccount/Dashboard";
import BeneCreateSuccess from "./containers/Accounts/BeneficiaryAccount/BeneCreateSuccess";
import BeneAccDetails from "./containers/Accounts/BeneficiaryAccount/BeneAccDetails";
import BeneAccViewAll from "./containers/Accounts/BeneficiaryAccount/BeneAccViewAll";
import RequireApproval from "./containers/Accounts/BeneficiaryAccount/BeneAcountApproval/RequireApproval";
import BeneEditCategories from "./containers/Accounts/BeneficiaryEditAccount/BeneEditCategories";
import BeneEditContact from "./containers/Accounts/BeneficiaryEditAccount/BeneEditContact";
import OwnerDashboard from "./containers/Accounts/OwnerAccount/OwnerDashboard";
import OwnerViewAll from "./containers/Accounts/OwnerAccount/OwnerViewAll";
import OwnerDetailsPage from "./containers/Accounts/OwnerAccount/OwnerDetailsPage";
import BeneGSTDetails from "./containers/Accounts/BeneficiaryAccount/BeneGSTDetails";
import ContactAdmin from "./containers/LoginPage/AdminContact/index";
import BeneEditPan from "./containers/Accounts/BeneficiaryEditAccount/BeneEditPan";
import BeneEditBankAccount from "./containers/Accounts/BeneficiaryEditAccount/BeneEditBankMain";
import BenePanEditSucess from "./containers/Accounts/BeneficiaryEditAccount/BenePanEditSucess";
import BeneEditSucess from "./containers/Accounts/BeneficiaryEditAccount/BeneEditSuccess";
import BeneBankEditSuccess from "./containers/Accounts/BeneficiaryEditAccount/BeneBankEditSuccess/Index";
import SetNewPassword from "./containers/LoginPage/SetNewPassword";
import BeneCreateFailed from "./containers/Accounts/BeneficiaryAccount/BeneCreateFailed";
import CheckUserLogin from "./containers/LoginPage/checkUserLogin/CheckUserLogin";
import BeneAdditionDetails from "./containers/BeneficiaryTransactions/BeneAdditionalDetails";
import FeatureNotAssigned from "./containers/FeatureNotAssigned";
import OwnerAllStatement from "./containers/Accounts/OwnerAccount/OwnerAllStatement";
import LandingPage from "./containers/LandingPage";
import AMC from "./containers/LandingPage/pages/AMC";
import Corporates from "./containers/LandingPage/pages/Corporates";
import BookDemo from "./containers/LandingPage/pages/BookDemo";
import AboutUs from "./containers/LandingPage/pages/AboutUs";
import InternalTransactionViewAll from "./containers/InternalTransactionMain/InternalTransactionViewAll";
import InternalTransactionCreate from "./containers/InternalTransactionMain/InternalTransactionCreate";
import InternalTransactionDashboard from "./containers/InternalTransactionMain/InternalTransactionDashboard";
import InternalTransactionDetails from "./containers/InternalTransactionMain/InternalTransactionDetails";
import InternalTxnErrorPage from "./components/InternalTransactionMain/InternalTxnErrorPage";
import InternalTxnSuccessPage from "./components/InternalTransactionMain/InternalTxnSuccessPage";
import ContactUs from "./containers/LandingPage/pages/ContactUs";
import Pricing from "./containers/LandingPage/pages/Pricing";
import BankStatement from "./containers/LandingPage/pages/BankStatement";
import ViewAllBalances from "./containers/LandingPage/pages/ViewAllBalances";
import FeatureNotAssign from "./containers/LoginPage/FeatureNotAssign/FeatureNotAssign";
import HomeDashboard from "./containers/HomeDashboard";
import PrivacyPolicy from "./containers/LandingPage/pages/PrivacyPolicy";
import TermsConditions from "./containers/LandingPage/pages/TermsConditions";
import Advisors from "./containers/LandingPage/pages/Advisors";
import LandingPageContainer from "./containers/LandingPage/LandingPageContainer";
import LogoutProfileResponsive from "./components/LogoutProfileResponsive";
import InternalTransactionSingleApproval from "./containers/InternalTransactionMain/InternalTransactionSingleApproval";
import InternalTransactionMultiApproval from "./containers/InternalTransactionMain/InternalTransactionMultiApproval";
import InternalMultiApprovalTxnDetails from "./containers/InternalTransactionMain/InternalTransactionMultiApproval/InternalMultiApprovalTxnDetails";
import InternalMultiApprovalTxnSuccess from "./containers/InternalTransactionMain/InternalTransactionMultiApproval/InternalMultiApprovalTxnDetails/InternalMultiApprovalTxnSucces";
import IntenralMultiApprovalTxnError from "./containers/InternalTransactionMain/InternalTransactionMultiApproval/InternalMultiApprovalTxnDetails/IntenralMultiApprovalTxnError";
import InternalMultiApprovalDeclined from "./containers/InternalTransactionMain/InternalTransactionMultiApproval/InternalMultiApprovalTxnDetails/InternalMultiApprovalDeclined";
import MyProfileDetails from "./containers/MyProfileDetails";
// import BBPS_DetailPage from "./containers/Accounts/BBPSAccount/BBPS_DetailPage";
// import BBPS_Dashboard from "./containers/Accounts/BBPSAccount/BBPS_Dashboard";
// import BBPS_Create from "./containers/Accounts/BBPSAccount/BBPS_Create";
// import BBPS_Success from "./containers/Accounts/BBPSAccount/BBPS_Success";
// import BBPS_Error from "./containers/Accounts/BBPSAccount/BBPS_Error";
// import BBPS_ViewAll from "./containers/Accounts/BBPSAccount/BBPS_ViewAll/index";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<LandingPageContainer />}>
        <Route path="/" element={<CheckUserLogin />}>
          <Route path="/" element={<LandingPage />} />
        </Route>
        <Route path="/amc" element={<CheckUserLogin />}>
          <Route path="/amc" element={<AMC />} />
        </Route>
        <Route path="/corporates" element={<CheckUserLogin />}>
          <Route path="/corporates" element={<Corporates />} />
        </Route>
        <Route path="/aboutus" element={<CheckUserLogin />}>
          <Route path="/aboutus" element={<AboutUs />} />
        </Route>
        <Route path="/advisors" element={<CheckUserLogin />}>
          <Route path="/advisors" element={<Advisors />} />
        </Route>
        <Route path="/book_demo" element={<CheckUserLogin />}>
          <Route path="/book_demo" element={<BookDemo />} />
        </Route>
        <Route path="/contactus" element={<CheckUserLogin />}>
          <Route path="/contactus" element={<ContactUs />} />
        </Route>
        <Route path="/pricing" element={<CheckUserLogin />}>
          <Route path="/pricing" element={<Pricing />} />
        </Route>
        <Route path="/bank_statement" element={<CheckUserLogin />}>
          <Route path="/bank_statement" element={<BankStatement />} />
        </Route>
        <Route path="/view_all_balances" element={<CheckUserLogin />}>
          <Route path="/view_all_balances" element={<ViewAllBalances />} />
        </Route>
        <Route path="/privacy_policy" element={<CheckUserLogin />}>
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="/terms_conditions" element={<CheckUserLogin />}>
          <Route path="/terms_conditions" element={<TermsConditions />} />
        </Route>
      </Route>

      <Route path="/login" element={<LoginPage />} />
      <Route path="/setNewPassword" element={<SetNewPassword />} />
      <Route path="/contactAdmin" element={<ContactAdmin />} />
      <Route path="/" element={<AppBar />}>
        <Route path="/home" element={<HomeDashboard />} />
        <Route path="/instantPay_refill" element={<InstatntPayRefill />} />
        <Route path="/vendor_transactions" element={<VendorTransactions />} />
        <Route path="/client_transactions" element={<ClientTransactions />} />
        <Route
          path="/transaction/beneficiary_transaction/dashboard"
          element={<BeneTxnDashboard />}
        />

        <Route
          path="/home/LogoutProfile"
          element={<LogoutProfileResponsive />}
        />
        <Route
          path="/adjustment_transactions"
          element={<AdjustmentTransactions />}
        />
        <Route path="/nodal_transactions" element={<NodalTransactions />} />
        <Route
          path="/field_force_transactions"
          element={<FieldForceTransactions />}
        />
        <Route
          path="/internal_transactions"
          element={<InternalTransactions />}
        />
        <Route
          path="/reimbursement_transactions"
          element={<ReimbursementTransactions />}
        />
        <Route
          path="/duplicate_transactions"
          element={<DuplicateTransactions />}
        />
        <Route
          path="/chargeback_transactions"
          element={<ChargebackTransactions />}
        />
        <Route
          path="/instant_pay_refill/view_all_transactions"
          element={<InstantPayRefillViewAllT />}
        />
        <Route
          path="/vendor_transactions/view_all_transactions"
          element={<VendorViewAllT />}
        />
        <Route
          path="/client_transactions/view_all_transactions"
          element={<ClientViewAllT />}
        />
        <Route
          path="/client_transactions/view_refund_cards"
          element={<ClientRefundsViewAllT />}
        />
        <Route
          path="/adjustment_transactions/view_all_transactions"
          element={<InstantPayRefillViewAllT />}
        />
        <Route
          path="/nodal_transactions/view_all_transactions"
          element={<NodalViewAllT />}
        />
        <Route
          path="/ff_transactions/view_all_transactions"
          element={<FieldForceViewAllT />}
        />
        <Route
          path="/internal_transactions/view_all_transactions"
          element={<InternalViewAllT />}
        />

        <Route
          path="/reimbursement_transactions/view_all_transactions"
          element={<ReimbursementViewAllT />}
        />
        <Route
          path="/duplicate_transactions/view_all_transactions"
          element={<DuplicateViewAllT />}
        />
        <Route
          path="/chargeback_transactions/view_all_transactions"
          element={<ChargebackViewAllT />}
        />
        <Route path="/create_transactions" element={<CreateTxn />} />
        <Route path="/recieve_transactions" element={<RecieveTxn />} />
        <Route path="/create_account" element={<CreateAccount />} />
        <Route
          path="/create_transaction/vendor_transaction"
          element={<CreateVendorTxn />}
        />
        <Route
          path="/create_transaction/client_transaction"
          element={<CreateClientTxn />}
        />
        <Route
          path="/create_transaction/field_force_transaction"
          element={<CreateFFTxn />}
        />
        <Route path="/development" element={<Development />} />
        <Route path="/transactionStatus" element={<TransactionStatus />} />
        <Route path="/vendor/approval_status" element={<VenApprovalStatus />} />
        <Route
          path="/fieldforce/approval_status"
          element={<FFApprovalStatus />}
        />
        <Route
          path="/client/transaction_status"
          element={<ClientCreateStatus />}
        />
        <Route
          path="approval_required/vendor_transactions"
          element={<VendorApproval />}
        />
        <Route
          path="/settlement_required/vendor_transactions"
          element={<SettleVendorTxns />}
        />
        <Route
          path="approval_required/ff_transactions"
          element={<FieldForceApproval />}
        />
        <Route
          path="/settlement_required/ff_transactions"
          element={<SettleFFTxn />}
        />
        <Route
          path="/settlement_required/instant_pay_refill"
          element={<SettleInstantPayTxns />}
        />
        <Route path="/vendor/txn/:id" element={<VendorTxnDetails />} />
        <Route path="/instantpay/txn/:id" element={<InstantPayTxnDetails />} />
        <Route path="/ff/txn/:id" element={<FFTxnDetails />} />
        <Route path="/client/txn/:id" element={<ClientTxnDetails />} />
        <Route path="/internal/txn/:id" element={<InternalTxnDetails />} />
        <Route path="/vendor/settle/:id" element={<VendorSettleVendorTxns />} />
        <Route path="/instantPay/settle_txn" element={<SelectTxnList />} />
        <Route
          path="/create_transaction/instantpay_transactions"
          element={<CreateIPTxn />}
        />
        <Route
          path="/create_transaction/internal"
          element={<CreateInternalTxn />}
        />
        <Route
          path="/select_payment_instant_pay/:amount"
          element={<SelectPayment />}
        />
        <Route
          path="/instant/settlement_status"
          element={<SettlementStatus />}
        />
        <Route path="/payments/card" element={<PaymentGateway />} />
        <Route
          path="/transaction/status"
          element={<SettlementTransactionStatus />}
        />
        <Route
          path="/transaction/beneficiary_transaction/dashboard"
          element={<BeneTxnDashboard />}
        />
        <Route
          path="/transaction/beneficiary_transaction/create"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/transaction/beneficiary_transaction/create"
            element={<BeneCreateTxn />}
          />
        </Route>

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />

        <Route
          path="/transaction/beneficiary_transaction/beneAdditional"
          element={<BeneAdditionDetails />}
        />
        <Route
          path="/transaction/beneficiary_transaction/success/:id"
          element={<BeneTxnSuccess />}
        />
        <Route
          path="/transaction/beneficiary_transaction/failed/:id"
          element={<BeneTxnFailed />}
        />
        <Route
          path="/transaction/beneficiary_transaction/view_all"
          element={<BeneTxnViewall />}
        />
        <Route
          path="/transaction/beneficiary_transaction/details/:id"
          element={<BeneTxnDetails />}
        />
        <Route
          path="/transaction/beneficiary_transaction/transaction_single_approval"
          element={<FeatureNotAssign userServices={"ABeneficiaryTxn"} />}
        >
          <Route
            path="/transaction/beneficiary_transaction/transaction_single_approval"
            element={<BeneTxnRequireApproval />}
          />
        </Route>

        <Route
          path="/transaction/Beneficiary_Transaction/multi_transaction"
          element={<BeneTxnMultiApproval />}
        />
        <Route
          path="/transaction/Beneficiary_Transaction/multi_transaction_approval"
          element={<BeneficiaryMultiApprovalTxnDetails />}
        />
        <Route
          path="/transaction/Beneficiary_Transaction/multi_transaction_approval/success"
          element={<BeneficiaryMultiApprovalSuccess />}
        />
        <Route
          path="/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
          element={<BeneficiaryMultiApprovalErrorPage />}
        />
        <Route
          path="/transaction/Beneficiary_Transaction/multi_transaction_approval/declined"
          element={<BeneficiaryMultiApprovalDeclined />}
        />

        <Route
          path="/transaction/internal_transaction/view_all"
          element={<InternalTransactionViewAll />}
        />
        <Route
          path="/transaction/internal_transaction/details/:id"
          element={<InternalTransactionDetails />}
        />
        <Route
          path="/transaction/internal_transaction/create"
          element={<InternalTransactionCreate />}
        />
        <Route
          path="/transaction/internal_transaction/declined"
          element={<InternalTxnErrorPage />}
        />
        <Route
          path="/transaction/internal_transaction/dashboard"
          element={<InternalTransactionDashboard />}
        />
        <Route
          path="/internal_transaction/single_approval_transaction"
          element={<FeatureNotAssign userServices={"ABeneficiaryTxn"} />}
        >
          <Route
            path="/internal_transaction/single_approval_transaction"
            element={<InternalTransactionSingleApproval />}
          />
        </Route>
        <Route
          path="/internal_transaction/multi_transaction"
          element={<FeatureNotAssign userServices={"ABeneficiaryTxn"} />}
        >
          <Route
            path="/internal_transaction/multi_transaction"
            element={<InternalTransactionMultiApproval />}
          />
        </Route>
        <Route
          path="/internal_transaction/multi_approval_transaction"
          element={<FeatureNotAssign userServices={"ABeneficiaryTxn"} />}
        >
          <Route
            path="/internal_transaction/multi_approval_transaction"
            element={<InternalMultiApprovalTxnDetails />}
          />
        </Route>

        <Route
          path="/internal_transaction/multi_approval_transaction/success"
          element={<InternalMultiApprovalTxnSuccess />}
        />
        <Route
          path="/internal_transaction/multi_approval_transaction/error"
          element={<IntenralMultiApprovalTxnError />}
        />
        <Route
          path="/internal_transaction/multi_approval_transaction/declined"
          element={<InternalMultiApprovalDeclined />}
        />

        <Route
          path="/transaction/internal_transaction/success/:id"
          element={<InternalTxnSuccessPage />}
        />
        <Route path="/settings" element={<SettingsDashboard />} />
        <Route path="settings/Devices" element={<MyDevices />} />
        <Route path="/settings/Profile" element={<MyProfile />} />
        <Route path="/settings/MyProfile" element={<MyProfileDetails />} />
        <Route path="/settings/Device/Details" element={<MyDeviceDetails />} />
        <Route path="/settings/Users" element={<UsersSettings />} />
        <Route
          path="/settings/Users/User_Details/:id"
          element={<UserDetails />}
        />
        <Route path="/settings/IFSC" element={<IfscSettings />} />
        <Route
          path="/settings/CIN_Verification"
          element={<CINVerification />}
        />
        <Route
          path="/settings/DIN_Verification"
          element={<DINVerification />}
        />
        <Route
          path="/settings/PAN_Verification"
          element={<PanVerification />}
        />
        <Route
          path="/settings/IFSC/IFSC_Details/:id"
          element={<OwnerAccountIFSC />}
        />
        <Route
          path="/settings/GST_Verification"
          element={<GSTVerification />}
        />
        <Route
          path="/accounts/beneficiary_account/details/IFSC/:id"
          element={<OwnerAccountIFSC />}
        />
        {settingsDashboardMapper.map((item, index) => {
          return item.options.map((option, index) => {
            if (option?.container)
              return (
                <Route
                  key={index}
                  path={option.path}
                  element={option.container}
                />
              );
            else return null;
          });
        })}
        <Route
          path="/accounts/:account_type/details/:account_id"
          element={<AccountDetails />}
        />
        <Route
          path="/accounts/:account_type/dashboard"
          element={<AccountDashboard />}
        />
        <Route
          path="/accounts/:account_type/view"
          element={<AccountViewAll />}
        />
        <Route
          path="/accounts/:account_type/view/:approval_status"
          element={<AccountViewAll />}
        />
        <Route
          path="/approval_required/vendor_accounts"
          element={<VendorAccountApproval />}
        />
        <Route
          path="/approval_required/field_force_accounts"
          element={<FieldForceAccountApproval />}
        />
        <Route
          path="/accounts/vendor/approval_status"
          element={<AccountApprovalStatus />}
        />
        <Route
          path="/accounts/ff/approval_status"
          element={<FFAccountApprovalStatus />}
        />
        <Route
          path="/accounts/vendor/create"
          element={<CreateVendorAccount />}
        />
        <Route
          path="/accounts/field_force/create"
          element={<CreateFFAccount />}
        />
        <Route
          path="/accounts/client/create"
          element={<CreateClientAccount />}
        />
        <Route path="/accounts/owner/create" element={<CreateOwnerAccount />} />
        <Route path="/accounts/card/create" element={<AddCard />} />
        <Route path="/accounts/create/status" element={<AccCreateStatus />} />
        <Route path="/accounts/beneficiary/dashboard" element={<Dashboard />} />
        <Route
          path="/accounts/beneficiary_account/create"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/create"
            element={<CreateBeneficiaryAccount />}
          />
        </Route>
        <Route
          path="/accounts/beneficiary_account/success/:id"
          element={<BeneCreateSuccess />}
        />
        <Route
          path="/accounts/beneficiary_account/failed/:id"
          element={<BeneCreateFailed />}
        />
        <Route
          path="/accounts/beneficiary_account/details/:id"
          element={<BeneAccDetails />}
        />
        <Route
          path="/accounts/beneficiary_account/view_all"
          element={<BeneAccViewAll />}
        />
        <Route
          path="/accounts/beneficiary_account/view/:approval_status"
          element={<BeneAccViewAll />}
        />
        <Route
          path="/accounts/beneficiary_account/approval_required"
          element={<FeatureNotAssign userServices={"BAAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/approval_required"
            element={<RequireApproval />}
          />
        </Route>
        <Route path="/accounts/owner/dashboard" element={<OwnerDashboard />} />
        <Route path="/accounts/owner/view_all" element={<OwnerViewAll />} />
        <Route
          path="/accounts/owner/owner_account_details/:id"
          element={<OwnerDetailsPage />}
        />
        <Route
          path="/accounts/owner/owner_account_details/IFSC_Details/:id"
          element={<OwnerAccountIFSC />}
        />
        <Route
          path="/accounts/beneficiary_account/details/GST_details/:id"
          element={<BeneGSTDetails />}
        />
        <Route
          path="/accounts/beneficiary_account/edit/Bank_Details/:id"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/edit/Bank_Details/:id"
            element={<BeneEditBankAccount />}
          />
        </Route>
        <Route
          path="/accounts/beneficiary_account/edit/PAN_Details/:id"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/edit/PAN_Details/:id"
            element={<BeneEditPan />}
          />
        </Route>
        <Route
          path="/accounts/beneficiary_account/edit/Contact_Details/:id"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/edit/Contact_Details/:id"
            element={<BeneEditContact />}
          />
        </Route>
        <Route
          path="/accounts/beneficiary_account/edit/categories/:id"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/beneficiary_account/edit/categories/:id"
            element={<BeneEditCategories />}
          />
        </Route>
        <Route
          path="/accounts/beneficiary_account/bank_edit_success/:id"
          element={<BeneBankEditSuccess />}
        />
        <Route
          path="/accounts/beneficiary_account/pan_edit_success/:id"
          element={<BenePanEditSucess />}
        />
        <Route
          path="/accounts/beneficiary_account/:successType/:id"
          element={<BeneEditSucess />}
        />
        <Route
          path="/accounts/internal_account/:successType/:id"
          element={<BeneEditSucess internalAccount={"internalAccount"} />}
        />
        <Route path="/owner_statement" element={<OwnerAllStatement />} />
        <Route path="feature_not_assigned" element={<FeatureNotAssigned />} />

        <Route
          path="/accounts/internal_account/dashboard"
          element={<Dashboard internalAccount={"internalAccount"} />}
        />

        <Route
          path="/accounts/internal_account/approval_required"
          element={<RequireApproval internalAccount={"internalAccount"} />}
        />

        <Route
          path="/accounts/internal_account/view_all"
          element={<BeneAccViewAll internalAccount={"internalAccount"} />}
        />

        <Route
          path="/accounts/internal_account/details/:id"
          element={<BeneAccDetails internalAccount={"internalAccount"} />}
        />
        <Route
          path="/accounts/internal_account/edit/Contact_Details/:id"
          element={<FeatureNotAssign userServices={"BUAccount"} />}
        >
          <Route
            path="/accounts/internal_account/edit/Contact_Details/:id"
            element={<BeneEditContact internalAccount={"internalAccount"} />}
          />
        </Route>

        {/* <Route
          path="/Accounts/BBPS_Dashboard/BBPS/Detailspage/:id"
          element={<BBPS_DetailPage />}
        />
        <Route path="/Accounts/BBPS_Dashboard" element={<BBPS_Dashboard />} />
        <Route path="/accounts/BBPS/create" element={<BBPS_Create />} />
        <Route path="/accounts/BBPS/Success" element={<BBPS_Success />} />
        <Route path="/accounts/BBPS/Error" element={<BBPS_Error />} />
        <Route path="/accounts/BBPS/view_all" element={<BBPS_ViewAll />} /> */}
        <Route
          path="/accounts/owner/consolidated_statement"
          element={<OwnerAllStatement />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

// Usage:
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router}></RouterProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
