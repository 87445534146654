import React, { useEffect, useState } from "react";
import "./index.css";
import { Button } from "@mui/material";
import {
  beneTxnOwnerAccounts,
  resetBeneOwnerData,
  beneTxnOwnerAccountsNonActive,
  resetBeneOwnerDataNonActive,
} from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import { useDispatch, useSelector } from "react-redux";
import Holder from "../../../assets/CreateTransactionIcons/Select account.png";
import Edit from "../../../assets/CreateTransactionIcons/editAccount.png";
import BankReload from "../../../assets/CreateTransactionIcons/BalanceReload.png";
import {
  resetOwnerBalance,
  getCreateTransactionBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import BalanceLoader from "../../_utils/BalanceLoader";
import { Tooltip, tooltipClasses } from "@mui/material";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import OwnerPopup from "../../BeneTransaction/OwnerPopup";
import OwnerAccountPopUp from "../../BeneTransaction/OwnerAccountPopUp";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import edit_white from "../../../assets/EditIcons/edit_white.svg";
import refresh_balance from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import WhiteReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import ConfidentailImage from "../../../assets/GeneralIcons/confidential_transaction.svg";

const InternalTxnSettleMethod = ({
  filterPayload,

  txnPayLoad,
  setTxnPayLoad,
  setIsNeft,
  setIsRtgs,
  setIsImps,

  refreshId,
  setRefreshId,
  BenEnableNextButton,
  openOwner,
  setOpenOwner,

  filterPayloadNonActive,

  setDirection,
  beneAccountId,
  isConfidential,
  setIsConfidential,
}) => {
  const dispatch = useDispatch();
  const {
    beneOwnerData,
    beneOwnerLoading,
    beneOwnerDataNonActive,
    beneOwnerLoadingNonActive,
  } = useSelector((state) => state.beneTransactionAccounts) || {};

  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
    ownerAccBalanceLoading,
  } = useSelector((state) => state.ownerAllVerification);

  useEffect(() => {
    dispatch(resetBeneOwnerData());
    dispatch(checkUsertoken());
    dispatch(
      beneTxnOwnerAccounts({
        page_size: 16,
        page_no: filterPayload?.currentPage,
        bank_name: filterPayload?.bankNameInput || undefined,
        sort_by_balance: filterPayload?.sortAccountNo || undefined,
        search_string: filterPayload?.accNoInput,
      })
    );
  }, [
    dispatch,
    filterPayload?.bankNameInput,
    filterPayload?.currentPage,
    filterPayload?.accNoInput,
    filterPayload?.sortAccountNo,
  ]);

  useEffect(() => {
    dispatch(resetBeneOwnerDataNonActive());
    dispatch(checkUsertoken());
    dispatch(
      beneTxnOwnerAccountsNonActive({
        page_size: 16,
        page_no: filterPayloadNonActive?.currentPage,
        bank_name: filterPayloadNonActive?.bankNameInput || undefined,
        sort_by_balance: filterPayload?.sortAccountNo || undefined,
        search_string: filterPayloadNonActive?.accNoInput,
      })
    );
  }, [
    dispatch,
    filterPayloadNonActive?.bankNameInput,
    filterPayloadNonActive?.currentPage,
    filterPayloadNonActive?.accNoInput,
    filterPayload?.sortAccountNo,
  ]);

  const [balanceRefresh, setBalanceRefresh] = useState(false);

  const [balanceRefreshDisabled, setBalanceRefreshDisabled] = useState(false);

  const [refreshAnimationId, setRefreshAnimationId] = useState(null);
  const [previousRefreshData, setPreviousRefreshData] = useState(null);
  const [refreshAll, setRefreshAll] = useState(false);
  const [refreshType, setRefreshType] = useState(false);
  const [loader, setLoader] = useState(false);
  const [accId, setAccId] = useState("");
  const [refreshId1, setRefreshId1] = useState("");

  const openOwnerAccountPopup = () => {
    setRefreshAnimationId("");
    setOpenOwner(true);
    setDirection(null);
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      RefreshBalanceToast();
    } else if (createTransactionBankRefreshData?.status) {
      setBalanceRefresh(true);
      toast.dismiss();
      toast.success("Balance refreshed successfully", toast_position);
      setTimeout(() => {
        setBalanceRefresh(false);
        setBalanceRefreshDisabled(false);
      }, 1000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      toast.error(createTransactionBankRefreshError, toast_position);
    }
    if (createTransactionBankRefreshData) {
      setTxnPayLoad({
        ...txnPayLoad,
        ownerBankBalance:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance,
        ownerLastUpdated:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance_last_fetched_at_in_words,
      });
    }
    return () => {
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);

  const handleRefresh1 = () => {
    setRefreshId1(beneAccountId);
  };

  const handleRefresh = (e, id, prevBalance) => {
    setBalanceRefreshDisabled(true);
    setPreviousRefreshData(prevBalance);
    setRefreshType("single");
    e.stopPropagation();
    setRefreshId(id);
    setRefreshAnimationId(id);
    dispatch(checkUsertoken());
    dispatch(getCreateTransactionBalance(id));
  };

  return (
    <div className={`beneSettle_Main1 `}>
      {openOwner && (
        <OwnerAccountPopUp
          setOpenOwner={setOpenOwner}
          setIsNeft={setIsNeft}
          setIsImps={setIsImps}
          setIsRtgs={setIsRtgs}
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          BenEnableNextButton={BenEnableNextButton}
          flow={"internalTxnSource"}
          TypeAccount={"InternalAccountTxn"}
        />
      )}

      <div
        className="settle_main_div_container_internal"
        style={{
          backgroundColor:
            txnPayLoad?.ownerBankAccountNo === null ? "transparent" : "#314d7e",
          boxShadow:
            txnPayLoad?.ownerBankAccountNo === null
              ? "none"
              : "0px 3px 6px #00000029",
        }}
      >
        <div
          className="Inter_settle_main_div_container_img"
          style={{
            display: txnPayLoad?.ownerBankAccountNo === null ? "none" : "flex",
          }}
        >
          <img
            src={require("../../../assets/IfscLogo/" +
              IfscCodeMapper(txnPayLoad.ownerBankLogo))}
            alt="bank"
          />
        </div>
        <div className="settle_main_div">
          {txnPayLoad?.ownerBankAccountNo && (
            <>
              <div
                className=" absolute right-0 top-0 py-[10px] px-[15px] cursor-pointer bg-[#667CA3] rounded-tr-[20px] rounded-bl-[20px] w-[46px] h-[36px]"
                onClick={openOwnerAccountPopup}
              >
                <img className=" w-[16px] h-[16px]" src={edit_white} />
              </div>
            </>
          )}
          <div className="select_owner_box">
            {txnPayLoad?.ownerBankAccountNo ? (
              <>
                <div className=" p-[20px]">
                  <p className="  text-[16px] font-[600px] text-[#dadada] font-sans">
                    Source Account Details
                  </p>
                  <div className=" w-[432px] h-[59px] bg-[#314D7E] rounded-[10px] my-3">
                    <div className=" px-[20px] flex py-[10px]">
                      <div className=" w-1/2 border-r-2 h-[39px] border-[#031F4F]">
                        <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                          {formatAccountNumber(txnPayLoad?.ownerBankAccountNo)}
                        </p>
                        <p className=" font-sans text-[#dadada] text-[12px] font-[400px]">
                          Account Number
                        </p>
                      </div>
                      <div className=" pl-[20px] ">
                        <div className="source-account-latest-balance">
                          <p
                            className="font-sans  text-[14px] font-[600px] owner-Fetch-Balance"
                            style={{
                              color:
                                Number(txnPayLoad?.ownerBankBalance) >= 0
                                  ? "#4DDD37"
                                  : "#FF5860",
                            }}
                          >
                            ₹{" "}
                            <FormatAmount
                              price={txnPayLoad?.ownerBankBalance}
                            />
                          </p>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Refresh Balance"
                          >
                            <button
                              onClick={(e) =>
                                handleRefresh(e, txnPayLoad?.ownerId)
                              }
                              className="internal_amount_button"
                            >
                              {createTransactionBankRefreshLoading &&
                              refreshId === txnPayLoad?.ownerId ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  type={"internalTxn"}
                                />
                              ) : !createTransactionBankRefreshLoading &&
                                refreshId === txnPayLoad?.ownerId ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  setAccId={setRefreshId}
                                  type={"internalTxn"}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <img
                                    className="internal_reload"
                                    src={WhiteReload}
                                    alt="Img"
                                  />
                                </>
                              )}
                            </button>
                          </Tooltip>
                        </div>
                        <div className="internal_new_balance_New">
                          <p className=" text-[12px] text-[#dadada] font-sans font-[400px]">
                            Balance - {txnPayLoad?.ownerLastUpdated}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="select_tag_name">
                  <p className="source_select_p1">Source Account Details</p>
                  <p className="source_select_p2">
                    This account will be used to settle this transaction once it
                    is approved.
                  </p>
                </div>
                <Button
                  className="Inter_select_owner_butt "
                  style={{
                    marginTop: "9px",
                  }}
                  onClick={openOwnerAccountPopup}
                >
                  <img
                    src={Holder}
                    alt="select user"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                      display:
                        txnPayLoad?.ownerBankAccountNo === null
                          ? "block"
                          : "none",
                    }}
                  />
                  <span
                    className="capitalize"
                    style={{
                      color: "#FF7F33",
                      textTransform: "capitalize",
                      fontSize:
                        txnPayLoad?.ownerBankAccountNo === null
                          ? "16px"
                          : "20px",
                    }}
                  >
                    {txnPayLoad?.ownerBankAccountNo === null
                      ? "Select owner account"
                      : `${txnPayLoad?.ownerBankAccountName?.toLowerCase()}`}
                  </span>
                  <img
                    src={Edit}
                    alt="select user"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginLeft: "15.73px",
                      display:
                        txnPayLoad?.ownerBankAccountNo === null
                          ? "none"
                          : "block",
                    }}
                  />
                </Button>
              </>
            )}
          </div>
        </div>
        {/* <div
          className="settle_main_div_inner_con"
          style={{
            display: txnPayLoad?.ownerBankAccountNo === null ? "none" : "flex",
          }}
        >
          <AnimationUpAndDown
            loader={createTransactionBankRefreshLoading}
            currentId={txnPayLoad?.ownerId}
            previousId={refreshAnimationId}
            currentBalance={txnPayLoad?.ownerBankBalance}
            previousBalance={previousRefreshData}
            refreshType={refreshType}
            classType={"beneOwner"}
          />
          <div className="settle_main_div_inner_con_amt">
            <p className="settle_main_div_inner_con_amt_1">
              {" "}
              ₹ {<FormatAmount price={txnPayLoad.ownerBankBalance} />}
            </p>
            <p className="settle_main_div_inner_con_amt_2">
              {`Balance updated ${txnPayLoad.ownerLastUpdated}`}
            </p>
          </div>

          <Tooltip
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                },
              },
            }}
            title="Refresh Balance"
          >
            <button
              disabled={balanceRefreshDisabled}
              className="bank_amt_div_refresh_settle"
              onClick={(e) =>
                handleRefresh(
                  e,
                  txnPayLoad?.ownerId,
                  txnPayLoad?.ownerBankBalance
                )
              }
            >
              {createTransactionBankRefreshLoading ? (
                <BalanceLoader
                  loader={createTransactionBankRefreshLoading}
                  type={"createTxn"}
                />
              ) : balanceRefresh ? (
                <BalanceLoader
                  loader={createTransactionBankRefreshLoading}
                  setAccId={setRefreshId}
                  type={"createTxn"}
                />
              ) : (
                <img src={BankReload} alt="reload" />
              )}
            </button>
          </Tooltip>
        </div> */}
        {/* <div
          className="settle_main_div_BankAccount"
          style={{
            display: txnPayLoad?.ownerBankAccountNo === null ? "none" : "block",
          }}
        >
          <h6>{formatAccountNumber(txnPayLoad?.ownerBankAccountNo)}</h6>
          <p>Account Number</p>
        </div> */}
        {/* <div
          className="settle_main_div_ifsc"
          style={{
            display: txnPayLoad?.ownerBankAccountNo === null ? "none" : "block",
          }}
        >
          <h6>{txnPayLoad.ownerBankIFSC}</h6>
          <p>IFSC code</p>
        </div> */}
      </div>
      <div
        className="right_side_select_owner_div_top"
        style={{ marginTop: "20px", marginLeft: "10px" }}
      >
        <div className="right_side_select_owner_div_top_img">
          <img src={ConfidentailImage} alt="confidential" />
        </div>
        <div className="right_side_select_owner_div_top_para">
          <h3>Create As Confidential Transaction</h3>
          <p>
            Enabling confidential transaction will make this transaction visible
            to only those users who have the feature to view confidential
            transactions.
          </p>
        </div>
        <div
          className="right_side_select_owner_div_top_button"
          onClick={() => {
            setIsConfidential(!isConfidential);
          }}
          style={{ backgroundColor: isConfidential ? "#69B76F" : "#707070" }}
        >
          <button
            className="right_side_select_owner_div_top_button_inner"
            style={{
              transform: isConfidential
                ? "translateX(18px)"
                : "translateX(0px)",
            }}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default InternalTxnSettleMethod;
